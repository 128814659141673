import React from "react";
import UserProfile from "../UserProfile";
import { ButtonExeFilled } from "../Button";
import { t } from "i18next";

const ExistUserModal = () => {
  return (
    <div className="w-[600px] relative flex flex-col justify-center items-center bg-white p-[40px] rounded-[20px]">
      <UserProfile width="80px" height="80px" name="VS" fontSize="34px" />
      <p className="text-[18px] leading-[24px] font-medium pt-[12px] pb-[20px]">
        Vatsal Sonani
      </p>
      <p className="text-[24px] leading-[32px] tracking-[-1px] font-bold pt-[12px] pb-[20px]">
        {t("sorry")}
      </p>
      <p className="text-[14px] leading-[20px] text-center text-[#6D77A0] pb-[20px]">
        {t("sorry_helper")}
      </p>
      <ButtonExeFilled
        text={t("invite_friends")}
        fontColor="#FFFFFF"
        height={50}
        hoverCss={"hover:!bg-DarkHover"}
      />
    </div>
  );
};

export default ExistUserModal;
