import React from 'react';

const ListOfText = ({ listOfText, heading }) => {
  return (
    <div>
      <div className="discoverHeadingDM text-LightPrimary">{heading}</div>
      {listOfText.map((list, index) => {
        return (
          <React.Fragment key={index}>
            <div className="privacyTextDM mt-[10px] max_sm:mt-[10px]">
              {list}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ListOfText;
