import React, { useEffect, useRef, useState } from 'react';
import SnackLine from '../../assets/images/HomePage/SnackLine.svg';
import { useTranslation } from 'react-i18next';
// import ProductivityAnimation from '../../animations/En/BoostProductivity.json';
// import ProductivityAnimationAR from '../../animations/Ar/BoostProductivityAR.json';
import Lottie from 'react-lottie';
import useIsInViewport from '../../utils/useIsInViewport';

const Productivity = ({ productivityAnimRef }) => {
  const getLangauge = localStorage.getItem('lang');
  const { t, i18n } = useTranslation();
  const productivityRef = useRef();
  const productivityView = useIsInViewport(productivityRef);
  const [productivityPlay, setproductivityPlay] = useState(false);

  // const defaultProductivityAR = {
  //   loop: false,
  //   autoplay: true,
  //   animationData: ProductivityAnimationAR,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice',
  //   },
  // };

  useEffect(() => {
    if (!productivityPlay && productivityView) {
      setproductivityPlay(true);
    }
  }, [productivityView]);
  const [productivityD, setProductivityD] = useState(null);

  const defaultProductivity = {
    loop: false,
    autoplay: true,
    animationData: productivityD,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  useEffect(() => {
    const fetchLottieData = async (url, setter) => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        setter(data);
      } catch (error) {
        console.error('Error fetching Lottie data:', error);
      }
    };

    const urls = {
      en: {
        productivity:
          'https://images.dcdial.com/resala.ai/BoostProductivity.json',
      },
      ar: {
        productivity:
          'https://images.dcdial.com/resala.ai/BoostProductivityAR.json',
      },
    };

    const lang = i18n.language === 'en' ? 'en' : 'ar';

    fetchLottieData(urls[lang].productivity, setProductivityD);
  }, [i18n.language]);

  return (
    <>
      <div className="relative m-auto max-w-[1240px] max_xl:max-w-[980px] max_md:px-[40px] max_sm:px-[20px] pt-[50px] pb-[130px] max_md:pb-[120px] max_sm:pb-[80px]">
        <div
          className="h-[586px] max_xl:h-[465px] overflow-y-hidden max_md:h-auto flex justify-between max_md:flex-col max_md:items-center bg-[#EEF3FC] rounded-[30px] max_md:pt-[30px]"
          ref={productivityAnimRef}
        >
          {/* h-[586px] max_xl:h-[465px] overflow-y-hidden max_md:h-auto */}
          <div className="hidden text-center text-LightPrimary font-DMSansFont font-bold max_md:block max_xl:text-MediumSubHeading max_sm:text-[30px]">
            {t('BoostProductivity')}
          </div>
          <div className="mt-4 hidden text-center font-DMSansFont font-medium text-LightTextParagraph text-TextMedium max_md:block max_sm:text-[16px]">
            {t('ProductyText')}
          </div>
          <div className="hidden max_md:block rotate-[60deg] w-[52%] mt-[130px] max_sm:mt-[40px] max_sm:mb-[20px] ">
            <img src={SnackLine} alt="line" />
          </div>
          <div
            className="w-[46%] max_md:w-[100%] pt-[30px] pl-[30px] max_md:flex justify-center max_md:mt-4 max_md:pt-0"
            ref={productivityRef}
          >
            {productivityPlay && (
              <>
                <div className="">
                  <Lottie
                    // options={
                    //   i18n?.language === 'ar'
                    //     ? defaultProductivityAR
                    //     : defaultProductivity
                    // }
                    options={defaultProductivity}
                    // eventListeners={[
                    //   {
                    //     eventName: 'complete', // Example event name
                    //     callback: () => {
                    //       setTimeout(() => {
                    //         playAgain();
                    //       }, 2000);
                    //     },
                    //   },
                    // ]}
                  />
                </div>
              </>
            )}
          </div>
          <div className="flex justify-center flex-col pb-[60px] pr-[30px] w-[40%] max_md:mt-1 max_sm:w-[100%] max_md:w-[80%] max_md:items-center max_md:flex max_md:justify-center">
            <div className="relative flex flex-col px-5 gap-2 max_md:gap-1 max_md:hidden">
              <div className="title-fonts text-HeadingLarge text-LightPrimary font-DMSansFont font-bold max_md:text-MediumHeading">
                {t('BoostProductivity')}
              </div>
              <div className="w-[90%] description-fonts text-SmallsubHeading font-DMSansFont font-medium text-LightTextParagraph max_md:text-TextMedium">
                {t('ProductyText')}
              </div>
              <div
                className={`absolute rotate-[-5deg] ${
                  getLangauge === 'en'
                    ? 'bottom-[-140px] left-[-105px]'
                    : 'right-[-176px] bottom-[-164px] -scale-x-100'
                }`}
              >
                <img src={SnackLine} alt="line" />
              </div>
            </div>
            {/* <div className="w-[95%] mt-2 max_md:mt-1 max_md:w-[100%]">
              <img
                src={Productivity2}
                className="w-[100%]"
                alt="ProductivityImage"
              />
            </div> */}
          </div>
        </div>

        <div
          className="absolute invisible bottom-[110px] max_1680:bottom-[120px] left-0 z-[-1]"
          id="featureScrollId"
        >
          op
        </div>
      </div>
    </>
  );
};

export default Productivity;
