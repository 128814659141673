import { cn } from "./utils";

export const ButtonOutLine = ({
  text,
  width,
  fontColor,
  backgroundColor,
  borderColor,
  onClick,
  paddingBlock,
  fontSize,
  height,
  isDisable,
  hoverCss,
  type = "submit",
}) => {
  return (
    <>
      <button
        type={type}
        style={{
          height: height ? height : '',
          width: width ? width : '100%',
          backgroundColor: backgroundColor ? backgroundColor : '',
          borderColor: borderColor ? borderColor : '#1678F2',
          paddingBlock: paddingBlock ? paddingBlock : '',
          color: fontColor ? fontColor : '',
        }}
        disabled={isDisable}
        onClick={onClick}
        className={cn(
          'F-JC-AI-CENTER rounded-md border-[1px] py-3 cursor-pointer h-fit ',
          hoverCss
        )}
      >
        <p
          className={cn(
            'text-TextSmall font-DMSansFont font-medium max_xl:text-MediumText',
            hoverCss
          )}
          style={{
            fontSize: fontSize ? fontSize : '',
          }}
        >
          {text}
        </p>
      </button>
    </>
  );
};

export const ButtonExeFilled = ({
  type = "submit",
  text,
  width,
  fontColor,
  backgroundColor,
  borderColor,
  onClick,
  height,
  isDisable,
  hoverCss,
}) => {
  return (
    <>
      <button
        type={type}
        style={{
          height: height ? height : "",
          width: width ? width : "100%",
          backgroundColor: backgroundColor ? backgroundColor : "",
          borderColor: borderColor ? borderColor : "",
        }}
        disabled={isDisable}
        onClick={onClick}
        className={cn(
          "Text16Heading F-JC-AI-CENTER rounded-md border-[1px] border-LightPrimaryActive bg-LightPrimaryActive py-[9px] cursor-pointer",
          hoverCss
        )}
      >
        <p className="" style={{ color: fontColor ? fontColor : "" }}>
          {text}
        </p>
      </button>
    </>
  );
};
