export const RequestIcon = () => {
    return (
      <>
        <svg
          width="80"
          height="80"
          viewBox="0 0 80 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="40" cy="40" r="40" fill="#F3F4F8" />
          <path
            d="M53.7501 32.6667C56.2814 32.6667 58.3334 30.6146 58.3334 28.0833C58.3334 25.552 56.2814 23.5 53.7501 23.5C51.2188 23.5 49.1667 25.552 49.1667 28.0833C49.1667 30.6146 51.2188 32.6667 53.7501 32.6667Z"
            fill="#19224C"
          />
          <path
            d="M53.7501 32.6667C56.2814 32.6667 58.3334 30.6146 58.3334 28.0833C58.3334 25.552 56.2814 23.5 53.7501 23.5C51.2188 23.5 49.1667 25.552 49.1667 28.0833C49.1667 30.6146 51.2188 32.6667 53.7501 32.6667Z"
            fill="#19224C"
          />
          <path
            opacity="0.4"
            d="M55.9867 35.0684C57.1418 34.7017 58.3334 35.6001 58.3334 36.8284V46.4351C58.3334 52.8517 54.6667 55.6017 49.1667 55.6017H30.8334C25.3334 55.6017 21.6667 52.8517 21.6667 46.4351V33.6017C21.6667 27.1851 25.3334 24.4351 30.8334 24.4351H44.7851C45.9767 24.4351 46.7834 25.5351 46.5634 26.6901C46.3434 27.7717 46.3801 28.9267 46.7101 30.1184C47.3884 32.5751 49.3867 34.5367 51.8434 35.1784C53.2917 35.5451 54.7034 35.4717 55.9867 35.0684Z"
            fill="#19224C"
          />
          <path
            d="M40 41.5951C38.46 41.5951 36.9017 41.1184 35.71 40.1468L29.9717 35.5634C29.385 35.0868 29.275 34.2251 29.7517 33.6384C30.2283 33.0517 31.09 32.9418 31.6767 33.4184L37.415 38.0018C38.8083 39.1201 41.1733 39.1201 42.5667 38.0018L44.73 36.2784C45.3166 35.8017 46.1967 35.8934 46.655 36.4984C47.1317 37.0851 47.04 37.9651 46.435 38.4234L44.2716 40.1468C43.0983 41.1184 41.54 41.5951 40 41.5951Z"
            fill="#19224C"
          />
        </svg>
      </>
    );
 }
