import React from "react";
import { ButtonExeFilled } from "../Button";
import PaypalImg from "./Paypal.png";

const PaypalPay = () => {
  return (
    <>
      <div className="mt-[40px]">
        <ButtonExeFilled
          height={40}
          isDisable={false}
          fontColor={"#FFFFFF"}
          onClick={() => {}}
          borderColor={"#FFC43A"}
          backgroundColor={"#FFC43A"}
          text={<img src={PaypalImg} alt="PaypalImg" />}
        />
      </div>
    </>
  );
};

export default PaypalPay;
