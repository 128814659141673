import React from 'react'

const ListNumber = ({ data ,number}) => {
    return (
        <>
      {data?.map((text1, index1) => {
        return (
          <React.Fragment key={index1}>
            <div className="flex mt-[10px] max_sm:mt-[5px] items-start">
              <div
                className={` ${
                  number ? 'w-[2%]' : 'p-[8px] w-[3%]'
                }  max_md:w-[5%] max_sm:w-[7%]`}
              >
                <div
                  className={` ${
                    number
                      ? 'w-[20px] h-[20px] privacyTextDM'
                      : 'bg-LightTextParagraph rounded-full w-[6px] h-[6px]'
                  } `}
                >
                  {number ? index1 + 1 + '.' : ''}
                </div>
              </div>
              <div className=" w-[95%] privacyTextDM">{text1.title}</div>
            </div>
          </React.Fragment>
        );
      })}
        </>
  );
};

export default ListNumber
    