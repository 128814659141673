import React from "react";

const Layout = ({ children }) => {
  return (
    <div className="h-screen w-full flex justify-center items-center bg-slate-200">
      {children}
    </div>
  );
};

export default Layout;
