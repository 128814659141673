import React, { useEffect, useMemo, useRef, useState } from "react";
import { ModalTitle } from "./DeleteAccount";
import InputField, { ErrorMsg } from "../InputField";
import Otp from "../../Otp";
import { TwoToneText } from "./UpdateEmail-old";
import { fontCommons } from "./UpdateEmail";
import { forgotPass, sendEmailOtp, verifyOtp } from "../../../../Exe-api/apis";
import { ButtonExeFilled, ButtonOutLine } from "../Button";
import { CloseEyeIcon, EyeIcon } from "./UpdatePassword";
import PasswordValidator from "./PasswordValidator";
import SpinnerLoader from "../../../../utils/LoaderCommon";
import { t } from "i18next";

const SetNewPass = ({ onClose, email, updateUserData }) => {
  const passRef = useRef();
  const newPassRef = useRef();
  const confirmPassRef = useRef();
  const [currentStep, setCurrentStep] = useState(1);
  const [isOtpSented, setIsOtpSented] = useState(false);
  const [isNewPassShow, setIsNewPassShow] = useState(false);
  const [isConfirmNewPass, setIsConfirmNewPass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(undefined);
  const [userInput, setUserInput] = useState({
    otp: "",
    pass: "",
    confPass: "",
  });

  const sendOtp = async () => {
    setIsOtpSented(false);
    try {
      await sendEmailOtp({
        topic: "Set Password",
        email,
        is_email_check: false,
      });
      setIsOtpSented(true);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    sendOtp();
  }, []);

  const handleContinue = async () => {
    setIsLoading(true);
    try {
      const res = await verifyOtp({ email, otp: userInput.otp });
      if (res.Error || res.detail) {
        console.log(res.Message);
        setErrMsg(res.Message);
        return;
      }
      setCurrentStep(2);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const activePassValidate = () => {
    switch (document.activeElement) {
      case passRef.current:
        return userInput.pass;
      case newPassRef.current:
        return userInput.pass;
      case confirmPassRef.current:
        return userInput.confPass;
      default:
        return userInput.pass;
    }
  };

  const inputPassword = useMemo(() => {
    const value = activePassValidate();
    return {
      minLength: value ? value?.length >= 8 : false,
      uppercase: value ? /[A-Z]/.test(value) : false,
      lowercase: value ? /[a-z]/.test(value) : false,
      number: value ? /\d/.test(value) : false,
      specialChar: value
        ? /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value)
        : false,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInput, activePassValidate]);

  const handleUpdatePass = (e) => {
    // const { value, name } = e.target;
    // setUserInput({ ...userInput, [name]: value });

    const { name, value } = e.target;
    if (name === "otp") {
      if (e.target.value?.length > 6) return;
      setUserInput({ ...userInput, [name]: value });
    } else {
      setUserInput({ ...userInput, [name]: value });
    }
  };

  const handleConfirm = async () => {
    if (userInput.confPass !== userInput.pass) {
      setErrMsg("Passwords not matched");
      return;
    }
    setIsLoading(true);
    try {
      const res = await forgotPass({
        password: userInput.pass,
      });
      if (res.Error || res.detail) {
        setErrMsg(res.Message);
        return;
      }
      updateUserData((prev) => {
        return {
          ...prev,
          is_password: true,
        };
      });
      onClose();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-[400px]">
      {currentStep === 1 && (
        <>
          <ModalTitle title={t("otp_verification")} />
          <div className="pt-[20px] pb-[40px]">
            <TwoToneText
              fontCommons={fontCommons}
              text1={t("enter_code_sent")}
              text2={email}
            />
          </div>
          <InputField
            name="otp"
            type="number"
            maxlength={6}
            inputCss="text-center"
            // placeholder={t("enter_otp")}
            placeholder={"000 000"}
            value={userInput?.otp || ""}
            error={errMsg}
            onChange={handleUpdatePass}
          />
          <Otp startTimer={isOtpSented} resendOtp={() => sendOtp()} />
          <div className="flex gap-x-[20px] mt-[20px]">
            <ButtonExeFilled
              height={40}
              isDisable={isLoading || userInput?.otp.length === 0}
              text={isLoading ? <SpinnerLoader /> : t("continue")}
              fontColor={"#FFFFFF"}
              onClick={handleContinue}
              hoverCss={"hover:!bg-DarkHover"}
            />
          </div>
        </>
      )}

      {currentStep === 2 && (
        <>
          <InputField
            ref={newPassRef}
            name="pass"
            error={undefined}
            onChange={handleUpdatePass}
            className={"mt-[16px]"}
            value={userInput.pass}
            label={t("new_password")}
            placeholder={t("new_password")}
            type={isNewPassShow ? "text" : "password"}
            suffix={
              <div
                onClick={() => setIsNewPassShow((prev) => !prev)}
                className="min-w-[25px]"
              >
                {isNewPassShow ? <CloseEyeIcon /> : <EyeIcon />}
              </div>
            }
          />

          <InputField
            ref={confirmPassRef}
            name="confPass"
            error={undefined}
            onChange={handleUpdatePass}
            className={"mt-[16px]"}
            value={userInput.confPass}
            label={t("confirm_new_pass")}
            placeholder={t("confirm_pass")}
            type={isConfirmNewPass ? "text" : "password"}
            suffix={
              <div
                onClick={() => setIsConfirmNewPass((prev) => !prev)}
                className="min-w-[25px]"
              >
                {isConfirmNewPass ? <CloseEyeIcon /> : <EyeIcon />}
              </div>
            }
          />
          {errMsg && <ErrorMsg errorMsg={errMsg} />}
          <div className="mt-[20px]">
            <PasswordValidator require={inputPassword} />
          </div>
          <div className="flex gap-x-[20px] mt-[20px]">
            <ButtonOutLine
              height={40}
              text={t("cancel")}
              fontColor={"#5F6583"}
              borderColor={"#DFE4EC"}
              onClick={onClose}
              hoverCss={
                "hover:!text-LightPrimaryActive hover:!bg-[#eef6ff] hover:!border-[#D3E7FF]"
              }
            />
            <ButtonExeFilled
              height={40}
              text={isLoading ? <SpinnerLoader /> : t("confirm")}
              isDisable={isLoading}
              fontColor={"#FFFFFF"}
              onClick={handleConfirm}
              hoverCss={"hover:!bg-DarkHover"}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SetNewPass;
