import { t } from "i18next";
import React from "react";
import i18n from "../i18";

const QueryTable = ({ tableData, onlyTitle = undefined, cellCss = "" }) => {
  const isArabic = i18n.language === "ar";

  const HeadingCellCss = `w-1/5 px-6 py-3 text-LightPrimary text-[20px] leading-[30px] tracking-[-1px] font-medium text-center ${cellCss}`;
  const BodyCellCss = `px-6 py-4 whitespace-nowrap text-[18px] leading-[24px] font-medium text-center text-LightPrimaryActive ${
    isArabic
      ? "border-r-[2px] border-r-[#F3F4F8]"
      : "border-l-[2px] border-l-[#F3F4F8]"
  } ${cellCss}`;

  return (
    <div className="overflow-x-auto bg-white">
      <table className="min-w-full table-fixed">
        <thead className="h-20 relative bg-[#F3F4F8] rounded-tl-lg rounded-tr-lg">
          <tr>
            <th
              className={`min-w-[300px] w-1/5 px-6 py-3 text-LightPrimary text-[20px] max_xl:text-[18px] leading-[30px] tracking-[-1px] font-medium ${
                isArabic ? "text-right" : "text-left"
              }`}
            >
              {onlyTitle ?? t("Features")}
            </th>
            <th className={HeadingCellCss}>{onlyTitle ? "" : t("Free")}</th>
            <th className={HeadingCellCss}>{onlyTitle ? "" : t("Start")}</th>
            <th className={HeadingCellCss}>{onlyTitle ? "" : t("Pro")}</th>
            <th className={HeadingCellCss}>
              {onlyTitle ? "" : t("Unlimited")}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {tableData.map((item, idx) => (
            <tr key={idx}>
              <td className="min-w-[300px] px-6 py-4 text-[#5F6583] text-[18px] leading-[24px] font-medium font-DMSansFont">
                {t(item.feature)}
              </td>
              <td className={BodyCellCss}>{item.free}</td>
              <td className={BodyCellCss}>{item.start}</td>
              <td className={BodyCellCss}>{item.pro}</td>
              <td className={BodyCellCss}>{item.unlimited}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QueryTable;

export const Included = () => (
  <div className="w-full flex justify-center items-center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="12"
      viewBox="0 0 17 12"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7071 0.292893C17.0976 0.683417 17.0976 1.31658 16.7071 1.70711L6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289C0.683417 4.90237 1.31658 4.90237 1.70711 5.29289L6 9.58579L15.2929 0.292893C15.6834 -0.0976311 16.3166 -0.0976311 16.7071 0.292893Z"
        fill="#1678F2"
      />
    </svg>
  </div>
);

export const NotIncluded = () => (
  <div className="w-full flex justify-center items-center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.46967 6.46967C6.76256 6.17678 7.23744 6.17678 7.53033 6.46967L12 10.9393L16.4697 6.46967C16.7626 6.17678 17.2374 6.17678 17.5303 6.46967C17.8232 6.76256 17.8232 7.23744 17.5303 7.53033L13.0607 12L17.5303 16.4697C17.8232 16.7626 17.8232 17.2374 17.5303 17.5303C17.2374 17.8232 16.7626 17.8232 16.4697 17.5303L12 13.0607L7.53033 17.5303C7.23744 17.8232 6.76256 17.8232 6.46967 17.5303C6.17678 17.2374 6.17678 16.7626 6.46967 16.4697L10.9393 12L6.46967 7.53033C6.17678 7.23744 6.17678 6.76256 6.46967 6.46967Z"
        fill="#6D77A0"
      />
    </svg>
  </div>
);

export const DashIcon = () => (
  <div className="w-full flex justify-center items-center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18 12.75H6C5.59 12.75 5.25 12.41 5.25 12C5.25 11.59 5.59 11.25 6 11.25H18C18.41 11.25 18.75 11.59 18.75 12C18.75 12.41 18.41 12.75 18 12.75Z"
        fill="#1678F2"
      />
    </svg>
  </div>
);

export const ClockIcon = () => (
  <div className="w-full flex justify-center items-center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
        fill="#FDB600"
      />
      <path
        d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75Z"
        fill="#FDB600"
      />
      <path
        d="M12 17.0009C11.87 17.0009 11.74 16.9709 11.62 16.9209C11.5 16.8709 11.39 16.8009 11.29 16.7109C11.2 16.6109 11.13 16.5109 11.08 16.3809C11.03 16.2609 11 16.1309 11 16.0009C11 15.8709 11.03 15.7409 11.08 15.6209C11.13 15.5009 11.2 15.3909 11.29 15.2909C11.39 15.2009 11.5 15.1309 11.62 15.0809C11.86 14.9809 12.14 14.9809 12.38 15.0809C12.5 15.1309 12.61 15.2009 12.71 15.2909C12.8 15.3909 12.87 15.5009 12.92 15.6209C12.97 15.7409 13 15.8709 13 16.0009C13 16.1309 12.97 16.2609 12.92 16.3809C12.87 16.5109 12.8 16.6109 12.71 16.7109C12.61 16.8009 12.5 16.8709 12.38 16.9209C12.26 16.9709 12.13 17.0009 12 17.0009Z"
        fill="#FDB600"
      />
    </svg>
  </div>
);
