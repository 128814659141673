import React, { useState } from "react";
import { ButtonExeFilled } from "../Button";
import {
  cardIcons,
  creditCardType,
  expiryDateValidation,
} from "../../utils/utils";

const CardPay = () => {
  const [cardNumber, setcardNumber] = useState("");
  const [cardType, setCardType] = useState(undefined);
  const [isExpiryDateBlurred, setIsExpiryDateBlurred] = useState(false);

  const [errorState, setErrorState] = useState({
    cardError: false,
    dateError: false,
  });
  const [formData, setFormData] = useState({
    card: "",
    date: "",
    cvv: "",
    cardname: "",
  });

  const handleChange = (e, type, fieldType) => {
    if (fieldType === "input") {
      if (type === "card") {
        if (e.target.value.length < 20) {
          const cardNumber = e.target.value.replace(/\s/g, "");
          const formattedCardNumber = cardNumber
            .replace(/\D/g, "")
            .replace(/(\d{4})(?=\d)/g, "$1 ");
          setcardNumber(formattedCardNumber);
          setFormData({ ...formData, [type]: cardNumber });
          if (formattedCardNumber.length === 0) {
            setCardType(undefined);
            setErrorState({
              ...errorState,
              cardError: false,
            });
          } else {
            const card = creditCardType(cardNumber);
            if (card) {
              setCardType(card);
              setErrorState({
                ...errorState,
                cardError: false,
              });
            } else {
              if (e.target.value.length === 19) {
                setErrorState({
                  ...errorState,
                  cardError: true,
                });
              }
            }
          }
        }
      } else if (type === "date") {
        const inputValue = e.target.value;
        if (e.target.value?.length < 6) {
          setIsExpiryDateBlurred(false);
          const date = e.target.value;
          const date_arr = date.split("/");
          const tmpError = { ...errorState };
          const isValidDate = expiryDateValidation(inputValue);

          if (date_arr?.length !== 2) {
            tmpError.dateError = true;
            setErrorState(tmpError);
          } else {
            const month = date_arr[0];
            const year = date_arr[1];

            if (month?.length !== 2 || year?.length !== 2) {
              tmpError.dateError = true;
              setErrorState(tmpError);
            } else {
              if (e.target.value?.length === 5 && !isValidDate.isValid) {
                tmpError.dateError = true;
                setErrorState(tmpError);
              } else {
                tmpError.dateError = false;
                setErrorState(tmpError);
              }
            }
          }
          if (e.target?.value?.length === 2 && formData?.date?.length < 3) {
            setFormData({ ...formData, [type]: e.target.value + "/" });
          } else {
            setFormData({ ...formData, [type]: e.target.value });
          }
        }
      } else if (type === "cvv") {
        if (e.target.value.length < 4) {
          setFormData({ ...formData, [type]: e.target.value });
        }
      } else if (type === "zipcode") {
        setFormData({ ...formData, [type]: e.target.value });
      } else if (type === "cardname") {
        setFormData({ ...formData, [type]: e.target.value });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("--- formData BankPay.js [Line-no 23] ---", formData);
  };

  return (
    <form onSubmit={handleSubmit} className="w-full mx-auto mt-[40px]">
      <div className="relative">
        <label
          className="block text-LightStrokActive uppercase text-[12px] font-bold mb-[3px]"
          htmlFor="cardNumber"
        >
          Card Information
        </label>

        <input
          type="text"
          placeholder="1234 1234 1234 1234"
          value={cardNumber}
          onChange={(e) => handleChange(e, "card", "input")}
          className="appearance-none text-[12px] bg-transparent h-[32px] border rounded-t-[6px] text-LightPrimary border-LightStrokPrimary w-full py-1 px-2 focus:outline-none"
        />
        {cardType && (
          <img
            className={`absolute right-[15px] top-[29px] ${
              cardType ? "w-[35px]" : "w-[174px]"
            } h-[18px] object-contain`}
            alt="AllCards"
            src={cardIcons[cardType]}
          />
        )}
      </div>

      <div className="flex mt-[-1px]">
        <div className="w-1/2 relative">
          <input
            type="text"
            placeholder="MM/YY"
            value={formData.date}
            onChange={(e) => handleChange(e, "date", "input")}
            onBlur={() => setIsExpiryDateBlurred(true)}
            className="appearance-none text-[12px] h-[32px] border rounded-bl-[6px] w-full py-1 px-2 text-LightPrimary focus:outline-none border-LightStrokPrimary"
          />
          {isExpiryDateBlurred && errorState.dateError === true && (
            <div className="text-[12px] text-red-500 absolute top-[32px] left-0">
              Invalid date
            </div>
          )}
        </div>
        <div className="w-1/2">
          <input
            type="number"
            placeholder="CVV"
            value={formData.cvv}
            onChange={(e) => handleChange(e, "cvv", "input")}
            className="appearance-none text-[12px] h-[32px] border rounded-br-[6px] w-full py-1 px-2 text-LightPrimary focus:outline-none border-LightStrokPrimary"
          />
        </div>
      </div>

      <div className="mt-[20px] mb-[40px]">
        <label
          className="block text-LightStrokActive uppercase text-[12px] font-bold mb-[3px]"
          htmlFor="cardNumber"
        >
          Cardholder name
        </label>

        <input
          type="text"
          label="Cardholder name"
          placeholder="Full name on card"
          value={formData.cardname}
          onChange={(e) => handleChange(e, "cardname", "input")}
          className="appearance-none text-[12px] bg-transparent h-[32px] border rounded-[6px] border-LightStrokPrimary w-full text-LightPrimary py-1 px-2 focus:outline-none"
        />
      </div>

      <ButtonExeFilled
      type="submit"
        height={40}
        isDisable={false}
        text={"Subscribe"}
        fontColor={"#FFFFFF"}
        onClick={() => {}}
        hoverCss={"hover:!bg-DarkHover"}
      />
    </form>
  );
};

export default CardPay;
