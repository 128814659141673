import React from "react";
import { CircularArrow, Toggle } from "../../Components/ToggleYear";
import { t } from "i18next";
import PriceCardWeb from "./PriceCard";
import {
  featues3,
  features2,
  features4,
  features5,
  features6,
  featuresData,
  pricingOptions,
} from "./jsons";
import QueryTable from "../../Components/QueryTable";
import Skeleton from "react-loading-skeleton";

const WebView = ({
  activeSubscription,
  selectedPlan,
  selected,
  setSelected,
  allPlans,
}) => {
  const DEFAULT_PLAN_INDEX = 2;

  return (
    <>
      {/* {TOGGLE} */}
      <div className="w-full flex justify-center items-center mt-[56px]">
        <div className="relative">
          <Toggle
            Options={pricingOptions}
            selected={selected ?? pricingOptions[0]}
            setSelected={setSelected}
          />
          <div className="absolute left-full bottom-0">
            <CircularArrow className="ml-[3px]" />
            <div className="absolute top-[-20px] left-[42px] text-[16px] leading-[20px] font-medium text-LightPrimary whitespace-nowrap text-center min-w-[86px]">
              {t('SaveUpTo')} <br />
              <span className="text-[22px] leading-[28px] font-bold text-LightPrimaryActive">
                50%
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* CARDS */}
      <div className="mt-[100px] w-full max-w-[1240px] m-auto px-[40px]">
        {allPlans?.length === 0 ? (
          <>
            <div className="flex justify-center gap-x-[20px]">
              {Array.from({ length: 4 }).map((_, index) => (
                <div
                  className="relative rounded-lg overflow-hidden w-64 h-96 border px-5"
                  key={index}
                >
                  <Skeleton height={50} className="mt-4" />
                  <Skeleton height={40} className="mt-4" />
                  <Skeleton height={30} className="mt-12" />
                  <Skeleton height={30} className="mt-3" />
                  <Skeleton height={30} className="mt-3" />
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <PriceCardWeb
              plans={
                (allPlans && allPlans[selected?.val]) ||
                (allPlans && allPlans['monthly']) ||
                []
              }
              selectedPlan={
                selectedPlan ||
                (allPlans &&
                  allPlans['monthly'] &&
                  allPlans['monthly'][DEFAULT_PLAN_INDEX]) ||
                {}
              }
              setSelectedPlan={() => {}}
              activeSubscription={activeSubscription}
              // selectedPlan={selectedPlan}
              // setSelectedPlan={setSelectedPlan}
            />
          </>
        )}
      </div>

      {/* TABLE */}
      <div className="mt-[100px] w-full max-w-[1240px] m-auto px-[40px]">
        <QueryTable cellCss="!p-[28px]" tableData={featuresData} />
        <QueryTable
          cellCss="!p-[28px]"
          onlyTitle={t('ResalaChat')}
          tableData={features2}
        />
        <QueryTable
          cellCss="!p-[28px]"
          onlyTitle={t('Summarization')}
          tableData={featues3}
        />
        <QueryTable
          cellCss="!p-[28px]"
          onlyTitle={t('WritingAssistant')}
          tableData={features4}
        />
        <QueryTable
          cellCss="!p-[28px]"
          onlyTitle={t('SocialInteractions')}
          tableData={features5}
        />
        <QueryTable
          cellCss="!p-[28px]"
          onlyTitle={t('Support')}
          tableData={features6}
        />
      </div>
    </>
  );
};

export default WebView;
