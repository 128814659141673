import { useEffect, useState } from "react";

export const useDetectCountry = () => {
  const [country, setCountry] = useState(null);

  useEffect(() => {
    if (country) return;
    const detectCountry = async () => {
      try {
        // const userCountry = await fetch('https://geolocation-db.com/json/')
        // const userCountry = await fetch('https://ipapi.co/json/')
        const userCountry = await fetch("https://ipinfo.io/json")
          .then((response) => response.json())
          .then((data) => data.country);
        // .then((data) => data.country_code);

        setCountry(userCountry);
      } catch (error) {
        console.error("Error detecting country:", error);
        // Handle error, set a default country, or leave it as null
      }
    };

    detectCountry();
  }, []);

  return country;
};
