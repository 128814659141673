import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
// import Close from "../../icons/billing/Close.svg";
import { cn } from "./utils";
import { useTranslation } from "react-i18next";

const ModalBaseLayout = ({
  isOpen,
  setClose,
  title = "",
  titleCss,
  children,
  overflow,
  isPreventOutsideClick = false,
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isArabic = language === "ar";

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-70"
          onClose={() => {
            if (isPreventOutsideClick) return;
            setClose();
          }}
        >
          <div
            className={cn("w-full fixed inset-0 bg-black/30", {})}
            aria-hidden="true"
          />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={cn("w-full fixed inset-0 bg-black bg-opacity-25", {})}
            />
          </Transition.Child>

          <div className={cn("w-full fixed inset-0 overflow-y-auto", {})}>
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  style={{ overflow: overflow ? overflow : "" }}
                  className="w-fit transform overflow-hidden rounded-2xl bg-white py-[30px] px-[40px] text-left align-middle shadow-xl transition-all"
                >
                  <Dialog.Title as="h3" className={cn()}>
                    <div
                      className={cn(
                        "text-[24px] leading-[32px] font-bold text-LightPrimary flex justify-between",
                        titleCss,
                        {
                          "mb-[20px]": title.length > 0,
                        }
                      )}
                    >
                      {title}
                    </div>
                    <button
                      className={cn("absolute top-[15px]",isArabic ? "left-[15px]" : "right-[15px]")}
                      onClick={setClose}
                    >
                      <Close />
                    </button>
                  </Dialog.Title>
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalBaseLayout;

const Close = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_166_37179)">
      <path
        d="M3.29549 4.22697L13.9021 14.8336M3.29549 14.8336L13.9021 4.22697"
        stroke="#8C90A5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_166_37179">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
