import { RadioGroup } from "@headlessui/react";
import { t } from "i18next";

export const Toggle = ({ Options, selected, setSelected }) => (
  <RadioGroup
    value={selected}
    onChange={setSelected}
    className="w-fit h-12 p-1 bg-[#EEF3FC] rounded-md inline-flex items-center"
  >
    <RadioGroup.Label className="sr-only">Pricing Plan</RadioGroup.Label>
    {Options.map((option) => (
      <RadioGroup.Option
        key={option.name}
        value={option}
        className={({ active, checked }) =>
          `w-fit h-10 px-6 py-[9px] ${
            selected?.id === option?.id ? "bg-white shadow" : ""
          } rounded justify-center items-center gap-1.5 flex cursor-pointer focus:outline-none`
        }
      >
        {({ active, checked }) => (
          <>
            <button
              className={`text-base font-bold font-DMSansFont ${
                selected?.id === option?.id
                  ? "text-LightPrimaryActive"
                  : "text-LightPrimary"
              }`}
            >
              {t(option?.name)}
            </button>
          </>
        )}
      </RadioGroup.Option>
    ))}
  </RadioGroup>
);

export const CircularArrow = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="52"
    height="28"
    viewBox="0 0 52 28"
    fill="none"
  >
    <g clipPath="url(#clip0_7570_104370)">
      <path
        d="M8.48321 17.3931C8.35845 17.6427 7.36038 18.0169 6.23756 18.516C5.11473 19.015 3.74239 19.3893 2.86908 19.6388C3.99191 20.3873 5.11473 21.1359 6.1128 21.7597C9.48128 23.8806 13.0993 25.3777 16.7173 25.752C20.3353 26.1262 23.9533 25.2529 26.698 23.132C29.4426 21.1359 31.314 18.1417 32.0626 15.0227C32.8111 12.0285 32.3121 8.66004 30.3159 6.66391C29.3179 5.66584 28.0703 5.04204 26.698 5.04204C25.4504 5.04204 24.2028 5.66584 23.3295 6.53915C22.4562 7.53721 21.9571 8.66004 21.9571 9.90763C21.8324 11.1552 22.2066 12.278 22.8304 13.4009C24.078 15.5218 26.0742 16.8941 28.3198 17.3931C30.4407 18.0169 32.6864 18.0169 34.8073 17.6427C36.8034 17.2684 38.6748 16.6446 40.2966 15.7713C41.9185 14.898 43.2908 13.8999 44.5384 12.7771C46.9088 10.6562 48.4059 8.41053 49.2792 6.41439C51.1506 2.42212 51.0259 0.0517063 51.4001 0.0517063C51.5249 0.0517063 51.6497 0.176464 51.7744 0.800257C51.7744 1.42405 51.7744 2.54688 51.4001 4.41825C50.9011 6.28963 49.903 8.90956 47.5326 12.0285C46.285 13.5256 44.6632 15.1475 42.4175 16.5198C40.1719 17.8922 37.4272 19.1398 34.0587 19.514C31.9378 19.7636 29.8169 19.6388 27.696 19.015C25.6999 18.3912 23.7037 17.2684 22.3314 15.5218C20.9591 13.8999 20.0857 11.6542 20.3353 9.40859C20.5848 7.16294 21.8324 5.04205 23.7037 3.91922C24.7018 3.29543 25.8246 2.92115 26.9475 3.04591C28.0703 3.04591 29.1931 3.29543 30.0664 3.79446C31.9378 4.79253 33.3102 6.53915 33.9339 8.41053C35.1815 12.1533 34.433 16.0208 32.8111 19.1398C31.1893 22.2587 28.6941 24.6291 25.9494 26.0015C22.9552 27.7481 19.961 28.1224 17.2163 27.9976C14.5964 27.7481 12.226 27.1243 10.2298 26.251C8.23369 25.3777 6.61183 24.5044 5.11473 23.6311C2.99384 22.2587 2.37004 21.8844 1.87101 21.3854C1.99577 23.132 2.24528 26.3758 1.87101 26.9995C0.498665 24.7539 0.12439 21.1359 -0.125125 18.8902C0.24915 18.6407 5.73853 17.3931 8.48321 17.3931Z"
        fill="#1678F2"
      />
    </g>
    <defs>
      <clipPath id="clip0_7570_104370">
        <rect
          width="27.5716"
          height="51.65"
          fill="white"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 51.6499 28)"
        />
      </clipPath>
    </defs>
  </svg>
);
