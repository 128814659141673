import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { cn } from "../utils";

const TableComp = ({ data }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isArabic = language === "ar";
  return (
    <div className="overflow-hidden">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="">
          <tr>
            <th
              scope="col"
              className={cn("w-1/3 py-3 text-[16px] leading-[24px] text-LightPrimary font-bold",isArabic ? "text-right" : "text-left")}
            >
              {t("friends")}
            </th>
            <th
              scope="col"
              className={cn("w-1/3 py-3 text-[16px] leading-[24px] text-LightPrimary font-bold",isArabic ? "text-right" : "text-left")}
            >
              {t("rewards")}
            </th>
            <th
              scope="col"
              className={cn("w-1/3 py-3 text-[16px] leading-[24px] text-LightPrimary font-bold",isArabic ? "text-right" : "text-left")}
            >
              {t("time")}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((item, index) => (
            <tr key={index}>
              <td className="w-1/3 py-4 whitespace-nowrap text-[14px] leading-[18px] text-LightPrimary font-normal">
                {item.email}
              </td>
              <td className="w-1/3 py-4 whitespace-nowrap text-[14px] leading-[18px] text-LightPrimary font-normal">
                {item.reward_query} {t("Queries")}
              </td>
              <td className="w-1/3 py-4 whitespace-nowrap text-[14px] leading-[18px] text-LightPrimary font-normal">
                {item.created_at}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableComp;
