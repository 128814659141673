import React, { useEffect, useMemo, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import GradientBg from "../../../Components/GradientBg";
import { OtpCheck, OtpMailCheck, forgotPassword } from "../../../api/auth/auth";
import SpinnerLoader from "../../../utils/LoaderCommon";
import PasswordValidator from "../Components/AccoutSetting/PasswordValidator";
import {
  CloseEyeIcon,
  EyeIcon,
} from "../Components/AccoutSetting/UpdatePassword";
import { ButtonExeFilled } from "../Components/Button";
import InputField, { ErrorMsg } from "../Components/InputField";
import PasswordChanged from "../Components/PasswordChanged";
import { ResalaLogo } from "../Icons/Icons";
import Otp from "../Otp";
import { t } from "i18next";
import { ArabicLogo } from "../layout/Sidebar";
import { cn } from "../Components/utils";
import { useTranslation } from "react-i18next";
import { Footer, langEnum } from "../Login";

const ForgotPassword = () => {
  const passRef = useRef();
  const confirmPassRef = useRef();
  const [currentStep, setCurrentStep] = useState(1);
  const [isNewPassShow, setIsNewPassShow] = useState(false);
  const [isOtpSented, setIsOtpSented] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const [isConfirmNewPass, setIsConfirmNewPass] = useState(false);
  const navigate = useNavigate();
  const ErrInitial = {
    email: undefined,
    otp: undefined,
    pass: undefined,
    confPass: undefined,
  };
  const [errorMsg, setErrorMsg] = useState(ErrInitial);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    email: "",
    otp: "",
    pass: "",
    confPass: "",
  });

  const {
    i18n: { language },
  } = useTranslation();
  const isArabic = language === "ar";

  const handleBack = () => {
    if (currentStep === 1) navigate("/login");
    if (currentStep === 2) setCurrentStep(1);
    if (currentStep === 3) setCurrentStep(2);
  };

  const activePassValidate = () => {
    switch (document.activeElement) {
      case passRef.current:
        return data.pass;
      case confirmPassRef.current:
        return data.pass;
      default:
        return data.pass;
    }
  };

  const inputPassword = useMemo(() => {
    const value = activePassValidate();
    return {
      minLength: value ? value?.length >= 8 : false,
      uppercase: value ? /[A-Z]/.test(value) : false,
      lowercase: value ? /[a-z]/.test(value) : false,
      number: value ? /\d/.test(value) : false,
      specialChar: value
        ? /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value)
        : false,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, activePassValidate]);

  const handleChange = (e) => {
    setErrorMsg(ErrInitial);
    // const { name, value } = e.target;
    // setData({ ...data, [name]: value });

    const { name, value } = e.target;
    if (name === "otp") {
      if (e.target.value?.length > 6) return;
      setData({ ...data, [name]: value });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  useEffect(() => {
    if (data?.otp.length === 6) {
      verifyOtp();
    }
  }, [data?.otp]);

  const checkValidMail = async () => {
    setIsOtpSented(false);
    setIsLoading(true);
    try {
      const res = await OtpMailCheck({
        email: data.email.trim(),
        interface_language: langEnum[language],
      });
      if (res.data?.Error) {
        setErrorMsg({ ...errorMsg, email: res.data.Message });
        return;
      }
      setIsOtpSented(true);
      setCurrentStep(2);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const verifyOtp = async () => {
    setIsVerifying(true);
    try {
      const res = await OtpCheck({
        otp: data.otp,
        email: data.email.trim(),
        interface_language: langEnum[language],
      });
      if (res.data?.Error) {
        setErrorMsg({ ...errorMsg, otp: res.data.Message });
        return;
      }
      setCurrentStep(3);
    } catch (error) {
      console.log("error");
    } finally {
      setIsVerifying(false);
    }
  };

  const handleChangePass = async () => {
    setIsLoading(true);
    try {
      const res = await forgotPassword({
        password: data.pass,
        re_password: data.confPass,
        email: data.email.trim(),
        interface_language: langEnum[language],
      });
      if (res.data?.Error) {
        setErrorMsg({ ...errorMsg, pass: res.data.Message });
        return;
      }
      setCurrentStep(4);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleContinue = (e) => {
    e.preventDefault();

    switch (currentStep) {
      case 1:
        checkValidMail();
        break;
      case 2:
        verifyOtp();
        break;
      case 3:
        handleChangePass();
        // setCurrentStep(4);
        break;
      default:
    }
  };

  return (
    <GradientBg>
      <div className="w-full flex justify-center items-center min-h-screen">
        {/* SECTION 1 */}
        <div
          style={{ boxShadow: "0px 4px 60px 0px #3C425714" }}
          className="w-[600px] h-fit rounded-[20px] bg-white py-[30px] px-[60px] flex flex-col items-center"
        >
          {currentStep !== 4 && (
            <>
              <ResalaLogo
                onClick={() => navigate("/")}
                className={cn(
                  "min-h-[40px] mb-[30px] cursor-pointer",
                  !isArabic ? "block" : "hidden"
                )}
              />
              <ArabicLogo
                onClick={() => navigate("/")}
                className={cn(
                  "min-h-[40px] mb-[30px] cursor-pointer",
                  isArabic ? "block" : "hidden"
                )}
              />
            </>
          )}
          {currentStep === 1 && (
            <>
              <div className="Heading !mb-[8px]">Reset your password</div>
              <div className="SubHeading mb-[30px]">
                Enter your email address and we will send you instructions to
                reset your password.
              </div>
              <form className="w-full" onSubmit={handleContinue}>
                <InputField
                  name="email"
                  value={data.email}
                  type={"text"}
                  className={"mb-[24px]"}
                  inputCss={"py-[15px] px-[18px]"}
                  placeholder={t("email_address")}
                  onChange={handleChange}
                  onKeyPress={(e) => {
                    if (e.which === 32) e.preventDefault();
                  }}
                  isDisabled={currentStep > 1 || isLoading}
                  error={errorMsg.email}
                />

                <ButtonExeFilled
                  height={50}
                  isDisable={isLoading}
                  text={isLoading ? <SpinnerLoader /> : t("continue")}
                  variant={"primary"}
                  fontColor={"#FFFFFF"}
                  hoverCss={"hover:!bg-DarkHover"}
                  isDisabled={isLoading}
                  onClick={handleContinue}
                ></ButtonExeFilled>
              </form>

              <ButtonExeFilled
                type="button"
                height={52}
                isDisabled={isLoading}
                borderColor={"transparent"}
                backgroundColor={"transparent"}
                hoverCss={
                  "text-[#1678F2] hover:text-[#1263CB] !text-[14px] leading-[16px] font-bold mt-[10px]"
                }
                onClick={() => {
                  handleBack();
                }}
                text={t("go_back")}
              ></ButtonExeFilled>
            </>
          )}

          {/* ENTER OTP */}
          {currentStep === 2 && (
            <div className="w-full">
              <form onSubmit={handleContinue}>
                {/* <div className="flex items-center justify-center gap-[8px] mb-[40px]">
                  <OtpVerifying className="h-[70px] w-[70px]" />
                </div> */}
                <div className="Heading !mb-[8px] text-center font-bold">
                  Verification Code
                </div>
                <div className="SubHeading mb-[30px] flex !gap-[4px] !flex-row">
                  Enter the code we sent to{" "}
                  <span className="font-bold"> {data.email}</span>
                </div>
                <InputField
                  name="otp"
                  type="number"
                  maxlength={6}
                  inputCss="text-center"
                  // placeholder={t("enter_otp")}
                  placeholder={"000 000"}
                  value={data.otp || ""}
                  error={errorMsg.otp}
                  onChange={handleChange}
                />
                <Otp
                  isLoading={isVerifying}
                  startTimer={isOtpSented}
                  resendOtp={() => checkValidMail()}
                  error={errorMsg.otp}
                  onSucess={({ otp }) => {}}
                />

                {/* <div className="flex gap-x-[20px] mt-[20px]">
                  <ButtonExeFilled
                    height={52}
                    isDisable={isLoading || data.otp?.length === 0}
                    text={isLoading ? <SpinnerLoader /> : t("continue")}
                    fontColor={"#FFFFFF"}
                    onClick={handleContinue}
                    hoverCss={"hover:!bg-DarkHover"}
                  />
                </div> */}
                <ButtonExeFilled
                  type="button"
                  // height={52}
                  isDisabled={isLoading}
                  borderColor={"transparent"}
                  backgroundColor={"transparent"}
                  hoverCss={
                    "text-[#1678F2] hover:text-[#1263CB] !text-[14px] leading-[16px] font-bold mt-[10px]"
                  }
                  onClick={() => {
                    handleBack();
                  }}
                  text={t("go_back")}
                ></ButtonExeFilled>
              </form>
            </div>
          )}

          {/* RESET PASSWORD */}
          {currentStep === 3 && (
            <>
              <div className="Heading my-[30px]">Reset your password</div>
              <form className="w-full" onSubmit={handleContinue}>
                <>
                  <InputField
                    ref={passRef}
                    name="pass"
                    error={undefined}
                    onChange={handleChange}
                    className={"mt-[16px]"}
                    inputCss={"h-[52px]"}
                    value={data.pass}
                    placeholder={t("new_password")}
                    type={isNewPassShow ? "text" : "password"}
                    suffix={
                      <div
                        onClick={() => setIsNewPassShow((prev) => !prev)}
                        className="min-w-[25px]"
                      >
                        {isNewPassShow ? <CloseEyeIcon /> : <EyeIcon />}
                      </div>
                    }
                  />

                  <InputField
                    ref={confirmPassRef}
                    name="confPass"
                    error={undefined}
                    onChange={handleChange}
                    className={"mt-[16px]"}
                    inputCss={"h-[52px]"}
                    value={data.confPass}
                    placeholder={t("new_password")}
                    type={isConfirmNewPass ? "text" : "password"}
                    suffix={
                      <div
                        onClick={() => setIsConfirmNewPass((prev) => !prev)}
                        className="min-w-[25px]"
                      >
                        {isConfirmNewPass ? <CloseEyeIcon /> : <EyeIcon />}
                      </div>
                    }
                  />
                  {errorMsg.pass && <ErrorMsg errorMsg={errorMsg.pass} />}
                  <div className="mt-[12px]">
                    <PasswordValidator require={inputPassword} />
                  </div>
                  <div className="flex gap-x-[20px] mt-[24px]">
                    <ButtonExeFilled
                      height={52}
                      text={isLoading ? <SpinnerLoader /> : t("confirm")}
                      isDisable={isLoading}
                      fontColor={"#FFFFFF"}
                      onClick={handleContinue}
                      hoverCss={"hover:!bg-DarkHover"}
                    />
                  </div>
                </>
                <div className="flex justify-center items-center gap-[4px] mt-[24px] text-[14px]">
                  <span className="text-[#6d77a0] font-medium">
                    Already have an account?
                  </span>
                  <button
                    className={`text-LightPrimaryActive hover:text-DarkHover font-bold ${
                      false ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={() => {
                      navigate("/login");
                    }}
                    // disabled={isLoading}
                  >
                    Login
                  </button>
                </div>
              </form>
            </>
          )}
          {currentStep !== 4 && <Footer />}

          {/* SUCCESS PASSWORD CHANGED */}
          {currentStep === 4 && (
            <>
              <PasswordChanged />
            </>
          )}
        </div>
      </div>
    </GradientBg>
  );
};

export default ForgotPassword;

const OtpVerifying = (props) => (
  <svg
    {...props}
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="35"
      cy="35"
      r="33.6"
      fill="white"
      stroke="#1678F2"
      strokeWidth="2.8"
    />
    <path
      d="M52.5 35C52.5 44.66 44.66 52.5 35 52.5C25.34 52.5 17.5 44.66 17.5 35C17.5 25.34 25.34 17.5 35 17.5C44.66 17.5 52.5 25.34 52.5 35Z"
      stroke="#1678F2"
      strokeWidth="2.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.4925 40.5646L36.0675 37.3271C35.1225 36.7671 34.3525 35.4196 34.3525 34.3171V27.1421"
      stroke="#1678F2"
      strokeWidth="2.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
