import React from "react";

const CardWrapper = ({ children }) => {
  return (
    <div className="w-full max-w-[900px] rounded-[12px] boeder border-[#DFE4EC] h-[620px] bg-white overflow-hidden">
      {children}
    </div>
  );
};

export default CardWrapper;
