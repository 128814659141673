import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const DiscoverResala = ({
  discoverRef,
  discoverFocus,
  setDiscoverFocus,
  cardRef,
  discoverFocus2,
  discoverResalaTextRef,
  itemLeftRefs,
  itemRightRefs,
  cardRef2,
  discoverFocusSecondCard,
}) => {
  const { t } = useTranslation();
  // const [discoverList1, setDiscoverList1] = useState([
  //   {
  //     heading: t('MasterYourWorkload'),
  //     text: t('MasterYourWorkloadText'),
  //   },
  //   {
  //     heading: t('InstantExpertise'),
  //     text: t('InstantExpertiseText'),
  //   },
  //   {
  //     heading: t('UniversalCompatibility'),
  //     text: t('UniversalCompatibilityText'),
  //   },
  //   {
  //     heading: t('InteractiveExperience'),
  //     text: t('InteractiveExperienceText'),
  //   },
  // ]);
  // const [discoverList2, setDiscoverList2] = useState([
  //   {
  //     heading: t('ElevateConversation'),
  //     text: t('ElevateConversationText'),
  //   },
  //   {
  //     heading: t('StayInformed'),
  //     text: t('StayInformedText'),
  //   },
  //   {
  //     heading: t('SeamlessIntegration'),
  //     text: t('SeamlessIntegrationText'),
  //   },
  //   {
  //     heading: t('YourPrivacy'),
  //     text: t('YourPrivacyText'),
  //   },
  // ]);
  const [discoverList1, setDiscoverList1] = useState(
    getTranslatedList(t, "list1")
  );
  const [discoverList2, setDiscoverList2] = useState(
    getTranslatedList(t, "list2")
  );

  useEffect(() => {
    // Update lists when language changes
    setDiscoverList1(getTranslatedList(t, "list1"));
    setDiscoverList2(getTranslatedList(t, "list2"));
  }, [t]);

  const divARef = useRef(null);
  const divBRef = useRef(null);

  const onScroll = () => {
    if (divBRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = divBRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight;

      if (isNearBottom) {
        setDiscoverFocus(false);
        // DO SOMETHING HERE
      }
    }
  };

  useEffect(() => {
    const listInnerElement = divBRef?.current;

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", onScroll);

      // Clean-up
      return () => {
        listInnerElement.removeEventListener("scroll", onScroll);
      };
    }
  }, []);

  return (
    <>
      <div className="w-[100%] mb-[160px] max_md:hidden max_sm:hidden">
        <div id="work-container" ref={discoverRef} className="">
          <div className="gallery">
            <div id="featured-work-text" className="text-content">
              <div id="firstDiv" className="w-[90%]">
                <div
                  className="title-fonts HeadingLargeCommon"
                  ref={discoverResalaTextRef}
                >
                  {t("DiscoverResala")}
                </div>
                <div className="description-fonts SubTextLargeCommon mt-4 font-DMSansFont font-medium">
                  {t("ResalaExtension")}
                </div>
              </div>
            </div>
            <div id="work-list">
              <div id="secondDiv" className={`w-[100%] flex gap-5`}>
                <div className={` flex flex-col gap-5`} ref={cardRef}>
                  {discoverList1?.map((item1, index1) => {
                    return (
                      <React.Fragment key={index1 + 1}>
                        <div
                          className={`mainCard 
                          bg-[#EEF3FC] rounded-[10px] max-w-[250px] max_sm:max-w-[220px] p-5`}
                          ref={(el) => (itemLeftRefs.current[index1] = el)}
                        >
                          <div className="description-fonts font-DMSansFont !font-bold discoverHeading text-LightPrimary">
                            {item1.heading}
                          </div>
                          <div
                            style={{ letterSpacing: "-1px" }}
                            className="leading-[30px] font-DMSansFont font-medium discoverText mt-5 max_esm:mt-3"
                          >
                            {item1.text}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
                <div className="flex flex-col gap-5 mt-[70px] max_md:mt-0">
                  {discoverList2.map((item2, index2) => {
                    return (
                      <React.Fragment key={index2 + 1}>
                        <div
                          className={`mainCard bg-[#EEF3FC] rounded-[10px] max-w-[250px] max_sm:max-w-[220px] p-5`}
                          ref={(el) => (itemRightRefs.current[index2] = el)}
                        >
                          <div className="description-fonts font-DMSansFont !font-bold discoverHeading text-LightPrimary">
                            {item2.heading}
                          </div>
                          <div
                            style={{ letterSpacing: "-1px" }}
                            className="leading-[30px] font-DMSansFont font-medium discoverText mt-5 max_esm:mt-3 "
                          >
                            {item2.text}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile design */}

      <div
        id="discover-section"
        className="hidden max_md:block m-auto max_md:px-[40px] max_sm:px-[20px] pb-[100px] max_md:pb-[60px] max_sm:pb-[80px]"
      >
        <div
          className="flex justify-between flex-col items-center"
          ref={discoverRef}
        >
          <div id="firstDiv" ref={divARef} className="text-center">
            <div className="HeadingLargeCommon" ref={discoverResalaTextRef}>
              {t("DiscoverResala")}
            </div>
            <div className="SubTextLargeCommon mt-4">
              {t("ResalaExtension")}
            </div>
          </div>
          <div
            id="secondDiv"
            ref={divBRef}
            className={`flex gap-5 w-[100%] justify-center mt-10 h-auto max_sm:flex-col max_sm:items-center ${
              !discoverFocus ? "overflow-hidden" : "overflow-auto scroll-smooth"
            }`}
          >
            <div className={` flex flex-col gap-5`} ref={cardRef}>
              {discoverList1?.map((item3, index3) => {
                return (
                  <React.Fragment key={index3 + 1}>
                    <div
                      className={`mainCardRes ${
                        discoverFocus2 ? "flipRes" : ""
                      }  bg-[#EEF3FC] rounded-[10px] max-w-[250px] max_sm:max-w-[230px] p-5`}
                    >
                      <div className="discoverHeading text-LightPrimary">
                        {item3.heading}
                      </div>
                      <div className="discoverText mt-5 max_esm:mt-3">
                        {item3.text}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            <div
              className="flex flex-col gap-5 mt-16 max_md:mt-0"
              ref={cardRef2}
            >
              {discoverList2.map((item4, index4) => {
                return (
                  <React.Fragment key={index4 + 1}>
                    <div
                      className={`mainCardRes ${
                        discoverFocusSecondCard ? "flipRes" : ""
                      } bg-[#EEF3FC] rounded-[10px] max-w-[250px] max_sm:max-w-[230px] p-5`}
                    >
                      <div className="discoverHeading text-LightPrimary">
                        {item4.heading}
                      </div>
                      <div className="discoverText mt-5 max_esm:mt-3 ">
                        {item4.text}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const getTranslatedList = (t, listKey) => {
  // Define your lists here
  const lists = {
    list1: [
      {
        heading: t("MasterYourWorkload"),
        text: t("MasterYourWorkloadText"),
      },
      {
        heading: t("InstantExpertise"),
        text: t("InstantExpertiseText"),
      },
      {
        heading: t("UniversalCompatibility"),
        text: t("UniversalCompatibilityText"),
      },
      {
        heading: t("InteractiveExperience"),
        text: t("InteractiveExperienceText"),
      },
      // ... rest of the items in list1
    ],
    list2: [
      {
        heading: t("ElevateConversation"),
        text: t("ElevateConversationText"),
      },
      {
        heading: t("StayInformed"),
        text: t("StayInformedText"),
      },
      {
        heading: t("SeamlessIntegration"),
        text: t("SeamlessIntegrationText"),
      },
      {
        heading: t("YourPrivacy"),
        text: t("YourPrivacyText"),
      },
      // ... rest of the items in list2
    ],
  };

  return lists[listKey];
};

export default DiscoverResala;
