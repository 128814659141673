import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DownArrow from "../../assets/images/HomePage/arrow-down.svg";
import ArrowUp from "../../assets/images/HomePage/arrow-up.svg";

const PricingFaq = () => {
  const { t } = useTranslation();
  const [openQuestionIndex, setOpenQuestionIndex] = useState(-1);

  const [questionList, setQuestionList] = useState([]);
  useEffect(() => {
    // Update lists when language changes
    setQuestionList(getTranslatedList(t));
  }, [t]);
  
  return (
    <>
      <div
        style={{
          zIndex: 20,
        }}
        className={``}
      >
        <div className="z-40 m-auto max-w-[1040px] max_xl:max-w-[980px] max_md:px-[40px] max_sm:px-[20px] pb-[160px] max_md:pb-[120px] max_sm:pb-[50px] ">
          <div className="flex flex-col">
            {questionList.map((list, index) => {
              return (
                <React.Fragment key={index + 1}>
                  <div
                    className="py-10 max_md:py-5 border-b-[1px] border-[#B1BED2] cursor-pointer"
                    onClick={() => {
                      if (openQuestionIndex === index) {
                        setOpenQuestionIndex(-1);
                      } else {
                        setOpenQuestionIndex(index);
                      }
                    }}
                  >
                    <div className="flex justify-between">
                      <div
                        className={`discoverHeading ${
                          openQuestionIndex === index
                            ? 'text-LightPrimary'
                            : 'text-LightStrokActive'
                        } font-DMSansFont font-semibold w-[95%] description-fonts`}
                      >
                        {list.question}
                      </div>
                      <div className="w-[30px]">
                        <img
                          src={
                            openQuestionIndex === index ? ArrowUp : DownArrow
                          }
                          alt="Downarrow"
                          className={`${
                            openQuestionIndex !== index
                              ? 'rotate-[0deg]'
                              : 'rotate-[180deg]'
                          } transition-all`}
                        />
                      </div>
                    </div>
                    <div
                      className={`answerSection ${
                        openQuestionIndex === index ? 'open' : ''
                      }`}
                    >
                      <div
                        style={{ letterSpacing: '-1px' }}
                        className={`SubTextCommon leading-[30px] text-LightTextParagraph mt-2 w-[90%] max_sm:w-[97%] `}
                      >
                        {list.answer}
                      </div>
                    </div>
                  </div>
                  <div></div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

const getTranslatedList = (t) => {
  // Define your lists here
  const lists = [
    {
      question: t("PricingQue1"),
      answer: t("PricingAns1"),
    },
    {
      question: t("PricingQue2"),
      answer: t("PricingAns2"),
    },
    {
      question: t("PricingQue3"),
      answer: t("PricingAns3"),
    },
    {
      question: t("PricingQue4"),
      answer: t("PricingAns4"),
    },
    {
      question: t("PricingQue5"),
      answer: t("PricingAns5"),
    },
    {
      question: t("PricingQue6"),
      answer: t("PricingAns6"),
    },
  ];

  return lists;
};

export default PricingFaq;
