import React from "react";

export const SettingRow = ({ title, desc, action }) => {
  return (
    <div className="flex justify-between items-center">
      <div>
        <p className="Text20Heading">{title}</p>
        <p className="Text16Heading">{desc}</p>
      </div>
      {action}
    </div>
  );
};

export const DeviderLine = () => (
  <div className="w-[100%] h-[1px] bg-LightStrokPrimary"></div>
);

export const DeviderLineDashed = () => (
  <div className="w-[100%] h-[1px] border-b border-dashed border-b-LightStrokPrimary"></div>
);

export const SettingCard = ({ children }) => (
  <div className="mt-5 rounded-[20px] p-[30px] bg-LightBgPrimary flex flex-col gap-[30px]">
    {children}
  </div>
);
