import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { cn } from "../../Components/utils";

const QueryTable = ({ tableData, onlyTitle = undefined }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isArabic = language === "ar";
  return (
    <div className="overflow-x-auto bg-white ">
      <table className="min-w-full table-fixed">
        <thead className="h-20 relative bg-[#F3F4F8] rounded-tl-lg rounded-tr-lg">
          <tr>
            <th
              className={cn(
                "min-w-[300px] w-1/5 px-6 py-3 text-LightPrimary text-[20px] leading-[30px] tracking-[-1px] font-medium font-DMSansFont",
                isArabic ? "text-right" : "text-left"
              )}
            >
              {onlyTitle ?? t("Features")}
            </th>
            <th className="w-1/5 px-6 py-3 text-LightPrimary text-[20px] leading-[30px] tracking-[-1px] font-medium font-DMSansFont text-center">
              {onlyTitle ? "" : t("Free")}
            </th>
            <th className="w-1/5 px-6 py-3 text-LightPrimary text-[20px] leading-[30px] tracking-[-1px] font-medium font-DMSansFont text-center">
              {onlyTitle ? "" : t("Start")}
            </th>
            <th className="w-1/5 px-6 py-3 text-LightPrimary text-[20px] leading-[30px] tracking-[-1px] font-medium font-DMSansFont text-center">
              {onlyTitle ? "" : t("Pro")}
            </th>
            <th className="w-1/5 px-6 py-3 text-LightPrimary text-[20px] leading-[30px] tracking-[-1px] font-medium font-DMSansFont text-center">
              {onlyTitle ? "" : t("Unlimited")}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {tableData.map((item, idx) => (
            <tr key={idx}>
              <td className="min-w-[300px] px-6 py-4 text-[#5F6583] text-[18px] leading-[24px] font-normal font-DMSansFont">
                {t(item.feature)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-[18px] leading-[24px] font-medium text-center text-LightPrimaryActive border-l-[2px] border-l-[#F3F4F8]">
                {item.free}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-[18px] leading-[24px] font-medium text-center text-LightPrimaryActive border-l-[2px] border-l-[#F3F4F8]">
                {item.start}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-[18px] leading-[24px] font-medium text-center text-LightPrimaryActive border-l-[2px] border-l-[#F3F4F8]">
                {item.pro}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-[18px] leading-[24px] font-medium text-center text-LightPrimaryActive border-l-[2px] border-l-[#F3F4F8]">
                {typeof item.unlimited === "string"
                  ? t(item.unlimited)
                  : item.unlimited}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QueryTable;

export const Included = () => (
  <div className="w-full flex justify-center items-center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="12"
      viewBox="0 0 17 12"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7071 0.292893C17.0976 0.683417 17.0976 1.31658 16.7071 1.70711L6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289C0.683417 4.90237 1.31658 4.90237 1.70711 5.29289L6 9.58579L15.2929 0.292893C15.6834 -0.0976311 16.3166 -0.0976311 16.7071 0.292893Z"
        fill="#1678F2"
      />
    </svg>
  </div>
);

export const NotIncluded = () => (
  <div className="w-full flex justify-center items-center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.46967 6.46967C6.76256 6.17678 7.23744 6.17678 7.53033 6.46967L12 10.9393L16.4697 6.46967C16.7626 6.17678 17.2374 6.17678 17.5303 6.46967C17.8232 6.76256 17.8232 7.23744 17.5303 7.53033L13.0607 12L17.5303 16.4697C17.8232 16.7626 17.8232 17.2374 17.5303 17.5303C17.2374 17.8232 16.7626 17.8232 16.4697 17.5303L12 13.0607L7.53033 17.5303C7.23744 17.8232 6.76256 17.8232 6.46967 17.5303C6.17678 17.2374 6.17678 16.7626 6.46967 16.4697L10.9393 12L6.46967 7.53033C6.17678 7.23744 6.17678 6.76256 6.46967 6.46967Z"
        fill="#6D77A0"
      />
    </svg>
  </div>
);
