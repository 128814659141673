import React, { useState } from "react";
import { cn } from "../utils";
import { fontCommons } from "./UpdateEmail";
import { ButtonExeFilled } from "../Button";
import PaymentTabs from "../Subscription/PaymentTabs";
import {
  AppleIcon,
  BankIcon,
  CardIcon,
  CashAppIcon,
  Gpay,
  PaypalIcon,
  WireIcon,
} from "../Subscription/PayIcons";
import CardPay from "../Subscription/CardPay";
import BankPay from "../Subscription/BankPay";
import PaypalPay from "../Subscription/PaypalPay";
import CashPay from "../Subscription/CashPay";
import { t } from "i18next";

const CType = {
  CARD: "Card",
  BANK: "Bank",
  PAYPAL: "PayPal",
  CASH: "Cash App",
  APPLEPAY: "Apple Pay",
  GPAY: "GPay",
  WIRE: "Wire",
};

const PayOptions = [
  {
    icon: <CardIcon className="h-[20px] w-[20px]" />,
    iconActive: <CardIcon className="activeCard h-[20px] w-[20px]" />,
    name: CType["CARD"],
  },
  {
    icon: <BankIcon className="h-[20px] w-[20px]" />,
    iconActive: <BankIcon className="activeCard h-[20px] w-[20px]" />,
    name: CType["BANK"],
  },
  {
    icon: <PaypalIcon className="h-[20px] w-[20px]" />,
    iconActive: <PaypalIcon className="activeCard h-[20px] w-[20px]" />,
    name: CType["PAYPAL"],
  },
  {
    icon: <WireIcon className="h-[20px] w-[20px]" />,
    iconActive: <WireIcon className="activeCardStroke h-[20px] w-[20px]" />,
    name: CType["WIRE"],
  },
  {
    icon: <CashAppIcon className="h-[20px] w-[20px]" />,
    iconActive: <CashAppIcon className="activeCard h-[20px] w-[20px]" />,
    name: CType["CASH"],
  },
  {
    icon: <AppleIcon className="h-[20px] w-[20px]" />,
    iconActive: <AppleIcon className="activeCard h-[20px] w-[20px]" />,
    name: CType["APPLEPAY"],
  },
  {
    icon: <Gpay className="h-[20px] w-[20px]" />,
    iconActive: <Gpay className="activeCard h-[20px] w-[20px]" />,
    name: CType["GPAY"],
  },
];

const UpdateSubscription = ({ onClose, paymentDetails }) => {
  const { amount, frequency, next_payment, payment_method, start_date } =
    paymentDetails || {};

  const [currentStep, setCurrentStep] = useState(0);
  const [selectedMethods, setSelectedMethods] = useState("Card");

  const handlePayMethodSelect = (payType) => {
    setSelectedMethods(payType);
    // if (tmp.includes(payType)) {
    //   const filtered = tmp.filter((method) => method !== payType);
    //   setSelectedMethods(filtered);
    // } else {
    //   tmp.push(payType);
    //   setSelectedMethods(tmp);
    // }
  };

  const handlePay = () => {};
  const changePaymentMethod = () => {};

  return (
    <div className="w-[474px]">
      {currentStep === 0 && (
        <div className={cn(fontCommons, "mt-[40px] flex flex-col gap-[20px]")}>
          <div className="flex flex-col gap-[12px]">
            <p className="text-LightPrimary font-bold">
              {t("last_payment_methods")}
            </p>
            <p className="font-medium">{payment_method?.last_4_digits}</p>
          </div>

          {/* Content */}
          <div className="flex flex-col">
            <p className="text-LightPrimary font-bold">{t("amount_to_pay")}</p>
            <div className="flex flex-col gap-[20px] mt-[12px]">
              <TableRow title={t("start_date")} val={start_date} />
              <TableRow title={t("frequency")} val={frequency} />
              <TableRow title={t("next_payment")} val={next_payment} />
              <div className="h-[1px] w-full bg-LightStrokPrimary" />
              <div className="text-LightPrimary flex justify-between">
                <p className="font-medium">{t("total_pay_amount")}</p>
                <p className="text-LightPrimary font-bold">${amount}</p>
              </div>
            </div>
          </div>

          {/* button */}
          <div className="flex flex-col gap-[20px]">
            <ButtonExeFilled
              height={40}
              isDisable={false}
              text={t('pay')}
              fontColor={"#FFFFFF"}
              onClick={handlePay}
              hoverCss={"hover:!bg-DarkHover"}
            />
            <button
              onClick={() => setCurrentStep(1)}
              className="text-LightPrimaryActive"
            >
              {t('change_pay_method')}
            </button>
          </div>
        </div>
      )}

      {currentStep === 1 && (
        <>
          <div className="grid grid-cols-3 gap-x-[12px] gap-y-[10px] mt-[12px]">
            {PayOptions.map((itm, index) => (
              <div key={index} onClick={() => handlePayMethodSelect(itm.name)}>
                <PaymentTabs
                  name={itm.name}
                  icon={itm.icon}
                  activeIcon={itm.iconActive}
                  isActive={selectedMethods.includes(itm.name)}
                />
              </div>
            ))}
          </div>

          {selectedMethods === CType["CARD"] && <CardPay />}
          {selectedMethods === CType["BANK"] && <BankPay />}
          {selectedMethods === CType["PAYPAL"] && <PaypalPay />}
          {selectedMethods === CType["CASH"] && <CashPay />}
        </>
      )}
    </div>
  );
};

export default UpdateSubscription;

const TableRow = ({ title, val }) => (
  <div className="flex justify-between">
    <p className="flex items-center gap-x-[2px]">
      {title}{" "}
      <button>
        <InfoIcon />
      </button>
    </p>
    <p className="text-LightPrimary">{val}</p>
  </div>
);

export const InfoIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99984 12.8346C8.61065 12.8346 10.069 12.1817 11.1246 11.1261C12.1802 10.0705 12.8332 8.61212 12.8332 7.0013C12.8332 5.39049 12.1802 3.93215 11.1246 2.87651C10.069 1.82089 8.61065 1.16797 6.99984 1.16797C5.38902 1.16797 3.93069 1.82089 2.87505 2.87651C1.81942 3.93215 1.1665 5.39049 1.1665 7.0013C1.1665 8.61212 1.81942 10.0705 2.87505 11.1261C3.93069 12.1817 5.38902 12.8346 6.99984 12.8346Z"
      stroke="#B1BED2"
      stroke-width="1.5"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00016 4.72396C7.40287 4.72396 7.72933 4.3975 7.72933 3.99479C7.72933 3.59209 7.40287 3.26562 7.00016 3.26562C6.59746 3.26562 6.271 3.59209 6.271 3.99479C6.271 4.3975 6.59746 4.72396 7.00016 4.72396Z"
      fill="#B1BED2"
    />
    <path
      d="M7 5.83203V10.4987"
      stroke="#B1BED2"
      stroke-width="1.5"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
