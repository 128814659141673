import { cn } from "../Pages/Extension/Components/utils";

export const ButtonFilled = ({
  className,
  title = "Button",
  onClick = () => {},
  rounded,
  height,
  isDisabled,
}) => {
  return (
    <button
      disabled={isDisabled}
      onClick={onClick}
      style={{
        borderRadius: rounded ? rounded : "6px",
        height: height ? height : "",
      }}
      className={cn(
        "z-[80] w-full cursor-pointer rounded-ButtonRadius border-[1px] border-bateau bg-[#1678F2] hover:bg-[#1263CB] px-[10px] max_sm:px-[8px] py-[8px] max_sm:py-1",
        className
      )}
    >
      <div
        style={{ height: height ? "38px" : "" }}
        className="F-JC-AI-CENTER font-DMSansFont font-medium text-white text-TextSmall max_sm:text-[14px]"
      >
        {title}
      </div>
    </button>
  );
};
export const ChromeButtonFilled = ({
  title = "Button",
  onClick = () => {},
  image,
}) => {
  return (
    <div
      onClick={onClick}
      className="cursor-pointer rounded-ButtonRadius10 border-[1px] border-bateau bg-[#1678F2] hover:bg-[#1263CB] px-[12px] max_sm:px-[8px] py-[12px] max_sm:py-1 flex gap-2"
    >
      <div className="max_esm:w-[12%]">
        <img src={image} alt="title" />
      </div>
      <div className="F-JC-AI-CENTER font-DMSansFont font-medium text-white text-TextRegular max_sm:text-TextSmall max_esm:text-[13px]">
        {title}
      </div>
    </div>
  );
};

export const ButtonFilledArrow = ({
  title = "Button",
  background = "",
  onClick = () => {},
  image,
  maxWidth,
}) => {
  return (
    <div
      style={{
        // background: background ? background : '',
        maxWidth: maxWidth ? maxWidth : "",
      }}
      onClick={onClick}
      className={`F-JC-AI-CENTER cursor-pointer rounded-ButtonRadius10 p-4 bg-[#1678F2] hover:bg-[#1263CB]`}
    >
      <div className="F-JC-AI-CENTER text-TextRegular font-DMSansFont font-medium text-white max_md:text-TextMedium max_sm:text-TextSmall mr-2">
        {title}
      </div>
      <div className="">
        <img src={image} alt="title" />
      </div>
    </div>
  );
};

export const ButtonFill = ({
  title = "Button",
  onClick = () => {},
  rounded,
  height,
  width,
  fontWeight,
  fontSize,
  isLoading,
  disabled,
}) => {
  return (
    <div
      onClick={() => {
        if (!isLoading && !disabled) {
          onClick();
        }
      }}
      style={{
        borderRadius: rounded ? rounded : "6px",
        height: height ? height : "",
        width: width ? width : "",
      }}
      className={`px-[10px] max_sm:px-[8px] py-[8px] max_sm:py-1 F-JC-AI-CENTER ${
        disabled
          ? "bg-LightPrimaryInactive cursor-not-allowed"
          : "bg-LightPrimaryActive hover:bg-DarkHover cursor-pointer"
      }`}
    >
      {isLoading ? (
        <div role="status">
          <svg
            aria-hidden="true"
            class="inline w-8 h-8 text-gray-200 animate-spinner dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="#ffffff"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="#008CDD"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      ) : (
        <div
          style={{
            // height: height ? '38px' : '',
            fontWeight: fontWeight ? fontWeight : "",
            fontSize: fontSize ? fontSize : "",
          }}
          className="F-JC-AI-CENTER text-white text-headingNormal font-DMSansFont font-medium max_sm:text-textSmall"
        >
          {title}
        </div>
      )}
    </div>
  );
};

export const UseResalaButton = ({ width, title }) => {
  return (
    <a
      href="https://chromewebstore.google.com/detail/resala-ai-copilot-powered/cjednklddameicjagmglkjkgdcjmmgml"
      rel="noopener noreferrer"
      target="_self"
    >
      <div
        className="flex gap-3 p-3 border-[1px] rounded-md border-LightOutline cursor-pointer items-center bg-white"
        style={{ width: width ? width : "" }}
      >
        <div className="">🚀</div>
        <p className="text-LightPrimaryActive font-DMSansFont text-textSmall font-normal">
          {title}
        </p>
      </div>
    </a>
  );
};
