import React, { useContext } from "react";
import LoadingContext from "../Context/LoadingContext";
import Skeleton from "react-loading-skeleton";

const TitleComp = ({ children, icon, title }) => {
  const {
    loading: { isAccountsPage },
  } = useContext(LoadingContext);
  return (
    <div className="flex justify-between items-center">
      <div className="flex gap-[10px] items-center">
        <div>{isAccountsPage ? <Skeleton circle width={46} height={46} /> : icon}</div>
        <p className="Text20Heading uppercase">{isAccountsPage ? <Skeleton width={140} height={24} /> :title}</p>
      </div>
      {children}
    </div>
  );
};

export default TitleComp;

export const BulbIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M21.5651 15.6132C21.2101 18.5694 19.2738 19.7507 18.4376 20.6082C17.6001 21.4682 17.7501 21.6394 17.8063 22.9007C17.8157 23.0567 17.793 23.213 17.7397 23.36C17.6864 23.507 17.6035 23.6414 17.4963 23.7551C17.389 23.8689 17.2596 23.9594 17.116 24.0212C16.9724 24.083 16.8176 24.1147 16.6613 24.1144H13.3388C13.1825 24.1145 13.0278 24.0827 12.8842 24.0208C12.7406 23.959 12.6112 23.8685 12.5039 23.7548C12.3966 23.6412 12.3136 23.5068 12.26 23.3599C12.2065 23.213 12.1835 23.0568 12.1926 22.9007C12.1926 21.6632 12.3076 21.3757 11.5626 20.6082C10.6126 19.6582 8.37757 18.3169 8.37757 14.6282C8.37186 13.7119 8.55603 12.8044 8.91846 11.9629C9.28089 11.1214 9.81373 10.364 10.4834 9.73862C11.153 9.11323 11.945 8.63335 12.8093 8.32921C13.6736 8.02508 14.5916 7.90331 15.5053 7.97155C16.419 8.0398 17.3087 8.2966 18.1183 8.72575C18.9278 9.15491 19.6397 9.74712 20.209 10.465C20.7783 11.183 21.1927 12.0111 21.4261 12.8971C21.6595 13.7832 21.7068 14.7079 21.5651 15.6132Z"
      stroke="#1678F2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0752 24.0464V25.9364C13.0752 26.4527 13.3627 26.8764 13.7164 26.8764H16.2827C16.6389 26.8764 16.9252 26.4514 16.9252 25.9364V24.0464M14.0252 10.8714C13.2871 10.8717 12.5794 11.1652 12.0576 11.6872C11.5358 12.2092 11.2427 12.9171 11.2427 13.6552M24.1652 14.8814H26.4577M21.8577 7.36641L23.4839 5.74016M23.1077 21.2514L24.7339 22.8764M15.0002 2.97266V4.82641M6.55019 5.73891L8.1652 7.36641M5.30019 22.8764L6.9152 21.2514M5.8352 14.8814H3.54395"
      stroke="#1678F2"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
