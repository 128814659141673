import React, { forwardRef } from "react";
import { checkLanguage, cn } from "./utils";
import { useTranslation } from "react-i18next";

const InputField = forwardRef(
  (
    {
      type,
      className,
      isFilled = false,
      label,
      placeholder,
      isDisabled,
      error,
      suffix = undefined,
      containerCss = "w-350px",
      inputCss = "",
      maxlength,
      onBlur,
      value = "",
      onChange,
      name = "",
      inputContainerCss,
      errorContainerCss,
      ...rest
    },
    ref
  ) => {
    const {
      i18n: { language },
    } = useTranslation();

    const isArabic = language === "ar";

    return (
      <div className={cn(className)}>
        <p
          className={cn(
            "font-normal text-[14px] text-LightStrokActive mb-[2px]",
            label ? "block" : "hidden",
            isArabic ? "text-right" : "text-left"
          )}
        >
          {label}
        </p>
        <div
          dir={isArabic ? "rtl" : "ltr"}
          className={cn(
            "flex border rounded-[7px] border-LightStrokPrimary overflow-hidden",
            inputContainerCss
          )}
        >
          <input
            {...rest}
            dir={
              value.length
                ? isArabic && checkLanguage(value)
                  ? "rtl"
                  : "ltr"
                : isArabic
                ? "rtl"
                : "ltr"
            }
            ref={ref}
            name={name}
            type={type}
            value={value}
            disabled={isDisabled}
            placeholder={placeholder}
            className={cn(
              "w-full outline-none font-normal text-[14px] leading-[18px] text-LightPrimary bg-white px-[15px] py-[11px] justify-between items-center inline-flex focus:outline-0",
              inputCss
            )}
            onChange={(e) => {
              e.persist();
              onChange(e);
            }}
            onBlur={onBlur}
            maxLength={maxlength}
            onKeyUp={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
          />
          <div
            className={cn(
              "flex items-center cursor-pointer pointer text-LightPrimaryActive text-[14px] bg-white",
              {
                "mx-[15px]": suffix,
              }
            )}
          >
            {suffix}
          </div>
        </div>
        <div className={cn(errorContainerCss, error ? "block" : "hidden")}>
          {error && (
            <ErrorMsg errorMsg={error} dir={isArabic ? "rtl" : "ltr"} />
          )}
        </div>
      </div>
    );
  }
);

export default InputField;

export const ErrorMsg = ({ errorMsg, dir = "ltr" }) => {
  return (
    <>
      <div dir={dir} className="bg-ErrorBg mt-[4px] mb-[16px] rounded-[6px]">
        <div className="flex gap-[8px] items-center py-[12px] px-[10px]">
          <div className="h-full self-start">
            <ErrorIcon />
          </div>
          <span
            style={{ textAlign: dir === "rtl" ? "right" : "left" }}
            className="text-[12px] text-ErrorText font-normal"
          >
            {errorMsg}
          </span>
        </div>
      </div>
    </>
  );
};

export const SuccessMsg = ({ successMsg }) => {
  return (
    <>
      <div className="bg-[#EEF9F0] mt-[4px] mb-[16px] rounded-[6px]">
        <div className="flex gap-[8px] items-center py-[12px] px-[10px]">
          <div className="h-full self-start">
            <SuccessIcon />
          </div>
          <span className="text-[12px] text-[#23B339] font-normal">
            {successMsg}
          </span>
        </div>
      </div>
    </>
  );
};

const SuccessIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99996 18.9596C5.05829 18.9596 1.04163 14.943 1.04163 10.0013C1.04163 5.05964 5.05829 1.04297 9.99996 1.04297C14.9416 1.04297 18.9583 5.05964 18.9583 10.0013C18.9583 14.943 14.9416 18.9596 9.99996 18.9596ZM9.99996 2.29297C5.74996 2.29297 2.29163 5.7513 2.29163 10.0013C2.29163 14.2513 5.74996 17.7096 9.99996 17.7096C14.25 17.7096 17.7083 14.2513 17.7083 10.0013C17.7083 5.7513 14.25 2.29297 9.99996 2.29297Z"
      fill="#23B339"
    />
    <path
      d="M8.8167 12.9846C8.65003 12.9846 8.4917 12.918 8.37503 12.8013L6.0167 10.443C5.77503 10.2013 5.77503 9.8013 6.0167 9.55964C6.25837 9.31797 6.65837 9.31797 6.90003 9.55964L8.8167 11.4763L13.1 7.19297C13.3417 6.9513 13.7417 6.9513 13.9834 7.19297C14.225 7.43464 14.225 7.83463 13.9834 8.0763L9.25837 12.8013C9.1417 12.918 8.98337 12.9846 8.8167 12.9846Z"
      fill="#23B339"
    />
  </svg>
);

const ErrorIcon = () => (
  <svg
    style={{ minWidth: "20px" }}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99984 18.4582C5.33431 18.4582 1.5415 14.6654 1.5415 9.99984C1.5415 5.33431 5.33431 1.5415 9.99984 1.5415C14.6654 1.5415 18.4582 5.33431 18.4582 9.99984C18.4582 14.6654 14.6654 18.4582 9.99984 18.4582ZM9.99984 1.7915C5.47369 1.7915 1.7915 5.47369 1.7915 9.99984C1.7915 14.526 5.47369 18.2082 9.99984 18.2082C14.526 18.2082 18.2082 14.526 18.2082 9.99984C18.2082 5.47369 14.526 1.7915 9.99984 1.7915Z"
      fill="#292D32"
      stroke="#FF0000"
    />
    <path
      d="M10 10.9582C9.96985 10.9582 9.93857 10.9459 9.91293 10.9202C9.88728 10.8946 9.875 10.8633 9.875 10.8332V6.6665C9.875 6.63636 9.88728 6.60508 9.91293 6.57943C9.93857 6.55379 9.96985 6.5415 10 6.5415C10.0301 6.5415 10.0614 6.55379 10.0871 6.57943C10.1127 6.60508 10.125 6.63636 10.125 6.6665V10.8332C10.125 10.8633 10.1127 10.8946 10.0871 10.9202C10.0614 10.9459 10.0301 10.9582 10 10.9582Z"
      fill="#292D32"
      stroke="#FF0000"
    />
    <path
      d="M9.69984 13.4707L9.70002 13.4707L9.69471 13.4579C9.67435 13.4091 9.6665 13.366 9.6665 13.3336C9.6665 13.3011 9.67435 13.2581 9.69471 13.2092C9.71092 13.1703 9.73297 13.1329 9.76267 13.0964C9.79917 13.0667 9.83656 13.0446 9.87548 13.0284C9.9524 12.9964 10.0473 12.9964 10.1242 13.0284C10.1631 13.0446 10.2005 13.0667 10.237 13.0964C10.2667 13.1329 10.2887 13.1703 10.305 13.2092C10.3253 13.2581 10.3332 13.3011 10.3332 13.3336C10.3332 13.366 10.3253 13.4091 10.305 13.4579L10.3048 13.4578L10.2998 13.4707C10.2881 13.5011 10.2724 13.5291 10.2381 13.5698C10.2013 13.6 10.1635 13.6223 10.1242 13.6387L10.3165 14.1002L10.1242 13.6387C10.0753 13.659 10.0323 13.6669 9.99984 13.6669C9.96735 13.6669 9.92433 13.659 9.87548 13.6387C9.83616 13.6223 9.79841 13.6 9.76155 13.5698C9.72724 13.5291 9.71154 13.5011 9.69984 13.4707Z"
      fill="#292D32"
      stroke="#FF0000"
    />
  </svg>
);
