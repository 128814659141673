import React, { useEffect, useState } from 'react';
import { ChromeButtonFilled } from '../../Components/Button';
import ChromeIcon from '../../assets/images/HomePage/chrome.png';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import AnimationData from '../../animations/En/ElevateExperience.json';
import AnimationDataAR from '../../animations/Ar/ElevateExperienceAR.json';
import { SplashLoader } from '../../utils/LoaderCommon';
// import AnimationDataMobile from '../../animations/En/ThinkLess.json';
// import AnimationDataARMobile from '../../animations/Ar/ThinkLessAR.json';

const BannerPage = ({ toggleModal }) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: AnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const defaultOptionsAR = {
    loop: false,
    autoplay: true,
    animationData: AnimationDataAR,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  // const defaultOptionsMobileEn = {
  //   loop: false,
  //   autoplay: true,
  //   animationData: AnimationDataMobile,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice',
  //   },
  // };
  // const defaultOptionsMobileAR = {
  //   loop: false,
  //   autoplay: true,
  //   animationData: AnimationDataARMobile,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice',
  //   },
  // };
  const { t, i18n } = useTranslation();

  // const [HeroSectionAnim, setHeroSectionAnim] = useState(null);
  const [HeroSectionAnimRes, setHeroSectionAnimRes] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // const defaultProductivity = {
  //   loop: false,
  //   autoplay: true,
  //   animationData: HeroSectionAnim,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice',
  //   },
  // };
  const defaultProductivityRes = {
    loop: false,
    autoplay: true,
    animationData: HeroSectionAnimRes,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  useEffect(() => {
    const fetchLottieData = async (url, setter) => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        setter(data);
        setIsLoading(false); // Update loading status
      } catch (error) {
        console.error('Error fetching Lottie data:', error);
        setIsLoading(false); // Update loading status even if there's an error
      }
    };

    const urls = {
      en: {
        // elevateExperience:
        //   'https://images.dcdial.com/resala.ai/ElevateExperience.json',
        thinkLess: 'https://images.dcdial.com/resala.ai/ThinkLess.json',
      },
      ar: {
        // elevateExperience:
        //   'https://images.dcdial.com/resala.ai/ElevateExperienceAR.json',
        thinkLess: 'https://images.dcdial.com/resala.ai/ThinkLessAR.json',
      },
    };

    const lang = i18n.language === 'en' ? 'en' : 'ar';

    // fetchLottieData(urls[lang].elevateExperience, setHeroSectionAnim);
    fetchLottieData(urls[lang].thinkLess, setHeroSectionAnimRes);
  }, [i18n.language]);

  return (
    <>
      <div className="z-[40] relative flex flex-col items-center justify-center m-auto max-w-[1220px] max_xl:max-w-[1180px] max_md:px-[40px] max_sm:px-[20px] max_md:max-w-[800px] mb-[100px] max_md:mt-0 mt-[80px]">
        <div className="max_md:hidden">
          <Lottie
            // options={defaultProductivity}
            options={i18n.language === 'en' ? defaultOptions : defaultOptionsAR}
          />
        </div>
        <div className="hidden max_md:block w-[80%] min-h-[240px]">
          {isLoading ? (
            <>
              <div className="w-full h-[320px] F-JC-AI-CENTER">
                <SplashLoader />
              </div>
            </>
          ) : (
            <>
              <Lottie
                // options={
                //   i18n.language === 'en'
                //     ? defaultOptionsMobileEn
                //     : defaultOptionsMobileAR
                // }
                options={defaultProductivityRes}
              />
            </>
          )}
        </div>
        <div className="description-fonts relative text-LightTextParagraph text-SmallsubHeading font-DMSansFont font-medium text-center mt-2 mb-12 max_md:text-TextMedium max_sm:text-TextSmall">
          {t('BannerText')}
        </div>

        <div>
          <ChromeButtonFilled
            image={ChromeIcon}
            title={t('ChromeIcon')}
            onClick={toggleModal}
          />
        </div>
      </div>

      {/* LEFT SIDE STARS */}
      {/* <div className="absolute  top-[1.8%] left-[21%]">
        <img src={StarImg} alt="star" className="w-[100%]" />
      </div>
      <div className="absolute top-[5.8%] max_sm:top-[0.8%] left-[15%] ">
        <img src={TriangleImg} alt="star" className="w-[100%]" />
      </div>
      <div className="absolute top-[7.5%] left-[25%] max_sm:top-[3%] max_sm:left-[10%]">
        <img src={SquareImg} alt="star" className="w-[100%]" />
      </div>
      <div className="absolute w-[0.6%] top-[3%] left-[30%]">
        <img src={SquareImg} alt="star" className="w-[100%]" />
      </div> */}
      {/* RIGHT SIDE STARS */}
      {/* <div className="absolute top-[3.5%] right-[32%]">
        <img src={StarImg} alt="star" className="w-[100%]" />
      </div>
      <div className="absolute top-[8.2%] right-[28%]">
        <img src={StarImg} alt="star" className="w-[100%]" />
      </div>
      <div className="absolute top-[5.5%] max_sm:top-[1%] right-[26%]">
        <img src={SquareImg} alt="star" className="w-[100%]" />
      </div>
      <div className="absolute top-[2.4%] right-[21%]">
        <img src={TriangleImg} alt="star" className="w-[100%]" />
      </div> */}
    </>
  );
};

export default BannerPage;

// {
/* <div className="relative flex w-[90%] max_xl:w-[68%] text-HeadingLarge text-LightPrimary font-DMSansFont font-bold max_xl:text-SubHeading max_sm:text-SmallHeading max_800:text-SemiMediumHeading max_md:hidden max_350:block max_esm:hidden">
          <div className="text-center">{t('BannerHaeding')}</div>

          <div className="absolute top-[50px] left-[-120px]">
            <img src={RoundFull} alt="" />
          </div>
        </div>
        <div className="w-[85%] max_sm:w-[82%] hidden max_md:block text-Heading text-LightPrimary font-DMSansFont font-bold max_md:text-[36px] max_sm:text-SmallHeading max_800:text-SemiMediumHeading max_350:hidden max_esm:block max_esm:w-[100%]">
          <div className="text-center ">{t('BannerHaeding')}</div>
        </div>
        <div className="relative text-LightTextParagraph text-SmallsubHeading font-DMSansFont font-normal text-center my-12 max_md:text-TextMedium max_sm:text-TextSmall">
          {t('BannerText')}
          <div className="absolute top-[-20px] right-[-90px]">
            <img src={HalfRound} alt="" />
          </div>
        </div>
        <div>
          <ChromeButtonFilled image={ChromeIcon} title={t('ChromeIcon')} />
        </div> */
// }
