import { Listbox, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { cn } from "./utils";

export const TransparentLangSelect = () => {
  const {
    t,
    i18n: { changeLanguage, language, reloadResources },
  } = useTranslation();
  // const dispatch = useDispatch();
  return (
    <>
      <LanguageSelect
        isDisabled={false}
        optionsWidth={160}
        valueWidth={"w-fit"}
        valueCss="text-[14px]"
        className={"h-[26px] w-[102px] border-transparent text-[14px]"}
        selectedLang={InterfaceLang.find((itm) => itm.val === language)}
        optionsList={InterfaceLang}
        optionClassname={
          "!w-[160px] border-0 border-[#d3e7ff] rounded-[8px] shadow-lg"
        }
        onChangeSelect={(option) => {
          changeLanguage(option.val);
          reloadResources(option.val);
          localStorage.setItem("lang", option.val);
          const abc = document.getElementById("main-app");
          if (option.val === "en") {
            abc.dir = "ltr";
          } else {
            abc.dir = "rtl";
          }
        }}
      />
    </>
  );
};

export const langEnum = {
  en: 1,
  ar: 2,
};

export const InterfaceLang = [
  {
    id: 1,
    language: "English",
    translate_language: "English",
    val: "en",
  },
  {
    id: 2,
    language: "Arabic",
    translate_language: "عربي",
    val: "ar",
  },
];

const LanguageSelect = ({
  selectedLang,
  onChangeSelect,
  optionsList,
  optionsWidth,
  valueWidth,
  className,
  isDisabled,
  optionClassname,
  buttonTextClassName,
  inlineOptionStyle,
  languageListLength,
  valueCss,
  isLanguageIcon = false,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [searchInput, setSearchInput] = useState("");
  const [listOfLanguage, setListOfLangauge] = useState();

  useEffect(() => {
    let filteredData = JSON.parse(JSON.stringify(optionsList));
    const searchTerm = searchInput.toLowerCase();
    const filteredLanguages = filteredData.filter(
      (lang) =>
        lang.language.toLowerCase().includes(searchTerm) ||
        (lang.translate_language &&
          lang.translate_language.toLowerCase().includes(searchTerm))
    );

    setListOfLangauge(filteredLanguages);
  }, [searchInput, optionsList]);

  const handleOnChangeSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const checkLanguage = (text) => {
    switch (true) {
      case /[\u0600-\u06FF]/.test(text):
        return true; // Arabic
      default:
        return false;
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
  };

  return (
    <Listbox value={selectedLang} onChange={onChangeSelect}>
      {({ open }) => {
        return (
          <div
            style={{ width: valueWidth ? valueWidth : "220px" }}
            className="relative"
          >
            <Listbox.Button
              onKeyDown={handleKeyDown}
              style={{ pointerEvents: isDisabled ? "none" : "auto" }}
              className={cn(
                "relative w-full cursor-pointer text-LightPrimary justify-between h-[24px] py-[6px] bg-white rounded border border-LightStrokPrimary items-center gap-[8px] inline-flex focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300",
                language === "ar" ? "pr-[8px] pl-[4px]" : "pl-[8px] pr-[4px]",
                className
              )}
            >
              {/* <div className="text-LightPrimary text-12px font-normal">{isDisabled ? <SpinnerLoader /> : selected?.language}</div> */}
              <div
                className={cn(
                  "text-[12px] font-normal text-LightPrimary flex items-center gap-[8px]",
                  buttonTextClassName
                )}
              >
                {isLanguageIcon && (
                  <SelectedLangaugeIcon
                    style={{ width: "20px", height: "20px" }}
                  />
                )}
                {isDisabled ? (
                  <SpinnerLoader
                    height="12"
                    width="12"
                    strokeWidth={6}
                    color="#DFE4EC"
                  />
                ) : (
                  <span
                    className={cn(
                      "truncate text-[12px] font-normal capitalize",
                      {
                        "w-[66px] text-left": isLanguageIcon,
                        "text-start": language === "ar",
                      },
                      valueCss
                    )}
                  >
                    {t(selectedLang?.language)}
                    {/* {selectedLang?.language} */}
                  </span>
                )}
              </div>
              <div className="pointer-events-none flex items-center">
                <ArrowIcon
                  className={cn("w-[14px] rotate-0", {
                    "rotate-180": open,
                  })}
                />
              </div>
            </Listbox.Button>

            {open && (
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  style={{
                    width: optionsWidth ? `${optionsWidth}px` : "100%",
                    zIndex: 99,
                    ...inlineOptionStyle,
                  }}
                  className={cn(
                    (languageListLength - 4) % 5 === 0
                      ? language === "ar"
                        ? "left-0"
                        : "right-0"
                      : language === "ar"
                      ? "right-0"
                      : "left-0",
                    "absolute bottom-full space-y-[8px] w-full rounded-[6px] bg-white border border-LightStrokPrimary text-base py-[8px] focus:outline-none hover:border-[#d3e7ff]",
                    optionClassname
                  )}
                >
                  <div
                    className="Phone relative w-full"
                    onClick={(event) => event.stopPropagation()}
                  >
                    <input
                      placeholder={t("search")}
                      className={cn(
                        "border-b-LightStrokPrimary font-normal border-b-[1px] text-[12px] text-LightPrimary h-[30px] w-full pl-[32px] pr-[10px] focus:outline-none placeholder:text-LightStrokPrimary bg-white outline-none pb-[8px]",
                        {
                          "pr-[32px] pl-[10px]": language === "ar",
                        }
                      )}
                      dir={
                        searchInput.length == 0
                          ? "unset"
                          : checkLanguage(searchInput)
                          ? "rtl"
                          : "ltr"
                      }
                      name="searchInput"
                      value={searchInput}
                      onChange={handleOnChangeSearch}
                    />
                    <div
                      className={cn("absolute top-[8px] left-[10px]", {
                        "left-unset right-[10px]": language === "ar",
                      })}
                    >
                      <SearchIcon />
                    </div>
                  </div>
                  <div
                    style={{ height: "fit-content", maxHeight: "200px" }}
                    className="overflow-auto w-full"
                  >
                    {listOfLanguage.map((option, personIdx) => (
                      <Listbox.Option
                        key={personIdx}
                        className={({ active }) =>
                          `relative select-none py-[2px] pl-[6px] rounded-[4px] pr-[6px] cursor-pointer mx-[8px] ${
                            active
                              ? "bg-LightGray text-LightPrimary"
                              : "text-TextSecondary"
                          }`
                        }
                        value={option}
                      >
                        {({ selected }) => (
                          <div
                            className="w-full pt-[4px] pb-[4px] px-[8px] rounded-[8px] justify-between items-center inline-flex"
                            key={personIdx}
                          >
                            <div className="w-full flex-col justify-start items-start gap-[4px] inline-flex">
                              <div className="w-full flex justify-between items-center">
                                <span
                                  className={`font-normal leading-normal text-[14px] font-['DM Sans'] ${
                                    selectedLang?.language == option.language
                                      ? "text-Primary"
                                      : "text-LightPrimary"
                                  }`}
                                >
                                  {t(option.language)}
                                  {/* {option.language} */}
                                </span>
                                {selectedLang?.language == option.language && (
                                  <SelectedLangaugeRightIcon />
                                )}
                              </div>
                              <span className="font-normal leading-normal text-[12px] font-['DM Sans'] text-TextSecondary">
                                {option?.translate_language}
                              </span>
                            </div>
                          </div>
                          // <>
                          //   <span className={`block truncate text-12px ${selected ? 'font-medium' : 'font-normal'}`}>
                          //     {option.language}
                          //   </span>
                          // </>
                        )}
                      </Listbox.Option>
                    ))}
                    {!listOfLanguage ||
                      (listOfLanguage.length <= 0 && (
                        <div className="flex justify-center items-center flex-col gap-[8px] w-full h-full">
                          <SearchNoDataIcon className="h-[30px] w-[30px]" />
                          <span className="font-normal text-[14px] text-center text-TextThird leading-normal">
                            {t("notAvailable")}
                          </span>
                        </div>
                      ))}
                  </div>
                </Listbox.Options>
              </Transition>
            )}
          </div>
        );
      }}
    </Listbox>
  );
};

export default LanguageSelect;

const SpinnerLoader = ({
  color = "var(--color-resala-bg-primary)",
  height = "16",
  strokeWidth = 5,
  width = "16",
}) => {
  return (
    <TailSpin
      className="compose-popup-container"
      height={height}
      width={width}
      radius="1"
      color={color}
      strokeWidth={strokeWidth}
    />
  );
};

const SelectedLangaugeIcon = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M9.92578 4.8125L6.72656 12.6875H8.01828L8.68793 11.1016H11.1636L11.8333 12.6875H13.125L9.92578 4.8125ZM9.19598 9.89844L9.92578 7.65215L10.6556 9.89844H9.19598ZM7.4375 8.75C7.43066 8.7448 6.87449 8.31879 6.19555 7.58324C7.27781 6.11652 7.89086 4.44937 8.14106 3.66406H9.625V2.46094H5.85156V1.3125H4.64844V2.46094H0.875V3.66406H6.87012C6.6098 4.40098 6.13047 5.56445 5.3993 6.62703C4.5057 5.43922 4.11031 4.55219 4.10512 4.54289L3.91016 4.15625L2.87109 4.75781L3.05895 5.1368C3.08328 5.17945 3.52898 6.17312 4.55492 7.50395C4.58008 7.53703 4.60551 7.5693 4.63094 7.60156C3.27141 9.15633 2.19324 9.76418 2.1793 9.77457L1.75 10.0625L2.37891 11.0469L2.90664 10.7332C2.9668 10.6876 4.03676 10.077 5.42227 8.52441C6.09273 9.24301 6.60406 9.64086 6.63359 9.66383L6.97266 9.89844L7.4375 8.75Z"
        fill="var(--color-resala-primary)"
      />
    </svg>
  );
};

export const SelectedLangaugeRightIcon = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      color="white"
    >
      <path
        d="M15 5.25L7.5 12.75L3.75 9"
        stroke="var(--color-resala-primary)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const SearchIcon = (props) => {
  return (
    <svg
      {...props}
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="white"
    >
      <path
        d="M6.7775 12.3363C9.57137 12.3363 11.8363 10.0714 11.8363 7.2775C11.8363 4.48363 9.57137 2.21875 6.7775 2.21875C3.98363 2.21875 1.71875 4.48363 1.71875 7.2775C1.71875 10.0714 3.98363 12.3363 6.7775 12.3363Z"
        stroke="var(--color-resala-icon-obscure)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.3545 10.8545L13.2814 13.7814"
        stroke="var(--color-resala-icon-obscure)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const SearchNoDataIcon = (props) => {
  return (
    <svg
      {...props}
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.7775 12.3363C9.57137 12.3363 11.8363 10.0714 11.8363 7.2775C11.8363 4.48363 9.57137 2.21875 6.7775 2.21875C3.98363 2.21875 1.71875 4.48363 1.71875 7.2775C1.71875 10.0714 3.98363 12.3363 6.7775 12.3363Z"
        stroke="var(--color-resala-stroke-active)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.3545 10.8545L13.2814 13.7814"
        stroke="var(--color-resala-stroke-active)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const ArrowIcon = (props) => (
  <svg
    {...props}
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_58_30697)">
      <path
        d="M1.83595 3.99C1.84181 3.99 1.84637 3.99106 1.84987 3.99247C1.85308 3.99376 1.85726 3.99608 1.86232 4.00114L4.79631 6.93512C5.12946 7.26828 5.67041 7.26828 6.00356 6.93512L8.93755 4.00114C8.94322 3.99547 8.95203 3.99113 8.96392 3.99113C8.97581 3.99113 8.98462 3.99547 8.99029 4.00114C8.99595 4.0068 9.00029 4.01562 9.00029 4.0275C9.00029 4.03939 8.99595 4.04821 8.99029 4.05387L6.0563 6.98786C5.69715 7.34701 5.10817 7.34858 4.74301 6.9873C4.74287 6.98716 4.74272 6.98701 4.74257 6.98687L1.80958 4.05387C1.80392 4.04821 1.79958 4.03939 1.79958 4.0275C1.79958 4.01751 1.80265 4.00968 1.807 4.00407C1.8228 3.99113 1.83467 3.99 1.83595 3.99Z"
        fill="#8C90A5"
        stroke="#8C90A5"
        stroke-width="0.599997"
      />
    </g>
    <defs>
      <clipPath id="clip0_58_30697">
        <rect
          width="10.8"
          height="10.8"
          fill="white"
          transform="translate(10.7999 10.7999) rotate(-180)"
        />
      </clipPath>
    </defs>
  </svg>
);
