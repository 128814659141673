import { t } from "i18next";
import { gradientText } from "../../Pages/Subscriptions/AdBanner";
import { ButtonExeFilled } from "../Button";
import { Celebrate } from "./Celebrate";

export const InviteBanner = ({ queryCount = 100 }) => {
  return (
    <div
      style={{
        background:
          "linear-gradient(94.68deg, #A2C9FA 0%, #E9F3FF 31.25%, #D6CEFF 59.08%, #D5B6FF 100%)",
      }}
      className="relative w-full h-[250px] rounded-[20px] flex justify-center items-center bg-pink-100 overflow-hidden"
    >
      <Celebrate className="min-w-[1026px]" />
      <div
        style={{ transform: "translateY(15%)" }}
        className="absolute inset-0 flex flex-col items-center z-10"
      >
        <ButtonExeFilled
          width={"351px"}
          fontColor={"#FFFFFF"}
          borderColor={"#19224C"}
          backgroundColor={"#19224C"}
          text={t("invite_earn")}
        />
        <p className="text-[30px] leading-[40px] text-LightPrimary font-bold mt-[28px]">
          {t("get")}
        </p>
        <p
          className="text-[60px] leading-[78px] font-bold"
          style={gradientText}
        >
          {queryCount} {t("queries")}
        </p>
      </div>
    </div>
  );
};

export const IconComp = ({ icon }) => {
  return (
    <div className="w-[74px] h-[74px] rounded-full flex justify-center items-center bg-[#EFF6FF]">
      {icon}
    </div>
  );
};

export const DescribedIcon = ({ icon, desc }) => (
  <div className="flex flex-col items-center justify-center">
    <IconComp icon={icon} />
    <p className="text-[16px] leading-[24px] font-medium text-center w-[150px] mt-[16px]">
      {desc}
    </p>
  </div>
);

export const ReferIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
  >
    <path
      d="M17.7085 20.8958H14.1668C13.586 20.8958 13.1043 20.4142 13.1043 19.8333C13.1043 19.2525 13.586 18.7708 14.1668 18.7708H17.7085C21.4202 18.7708 24.4377 15.7533 24.4377 12.0417C24.4377 8.33 21.4202 5.3125 17.7085 5.3125H10.6252C6.91351 5.3125 3.89601 8.33 3.89601 12.0417C3.89601 13.6 4.4485 15.1158 5.44017 16.32C5.8085 16.7733 5.75184 17.4391 5.2985 17.8216C4.84517 18.19 4.17934 18.1333 3.79684 17.68C2.47934 16.0933 1.75684 14.0958 1.75684 12.0417C1.75684 7.15417 5.7235 3.1875 10.611 3.1875H17.6943C22.5818 3.1875 26.5485 7.15417 26.5485 12.0417C26.5485 16.9292 22.596 20.8958 17.7085 20.8958Z"
      fill="#1678F2"
    />
    <path
      d="M23.375 30.8138H16.2917C11.4042 30.8138 7.4375 26.8471 7.4375 21.9596C7.4375 17.0721 11.4042 13.1055 16.2917 13.1055H19.8333C20.4142 13.1055 20.8958 13.5871 20.8958 14.168C20.8958 14.7488 20.4142 15.2305 19.8333 15.2305H16.2917C12.58 15.2305 9.5625 18.248 9.5625 21.9596C9.5625 25.6713 12.58 28.6888 16.2917 28.6888H23.375C27.0867 28.6888 30.1042 25.6713 30.1042 21.9596C30.1042 20.4013 29.5517 18.8855 28.56 17.6813C28.1917 17.228 28.2483 16.5622 28.7017 16.1797C29.155 15.7972 29.8208 15.868 30.2033 16.3213C31.5208 17.908 32.2433 19.9055 32.2433 21.9596C32.2292 26.8471 28.2625 30.8138 23.375 30.8138Z"
      fill="#1678F2"
    />
  </svg>
);

export const PCIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
  >
    <path
      d="M24.8768 25.4562H9.1235C3.48516 25.4562 1.771 23.742 1.771 18.1037V9.12203C1.771 3.4837 3.48516 1.76953 9.1235 1.76953H24.8627C30.501 1.76953 32.2152 3.4837 32.2152 9.12203V18.0895C32.2293 23.742 30.5152 25.4562 24.8768 25.4562ZM9.1235 3.89453C4.67516 3.89453 3.896 4.6737 3.896 9.12203V18.0895C3.896 22.5379 4.67516 23.317 9.1235 23.317H24.8627C29.311 23.317 30.0902 22.5379 30.0902 18.0895V9.12203C30.0902 4.6737 29.311 3.89453 24.8627 3.89453H9.1235V3.89453Z"
      fill="#1678F2"
    />
    <path
      d="M17 32.2287C16.4192 32.2287 15.9375 31.747 15.9375 31.1662V24.3945C15.9375 23.8137 16.4192 23.332 17 23.332C17.5808 23.332 18.0625 23.8137 18.0625 24.3945V31.1662C18.0625 31.747 17.5808 32.2287 17 32.2287Z"
      fill="#1678F2"
    />
    <path
      d="M31.1668 19.4805H2.8335C2.25266 19.4805 1.771 18.9988 1.771 18.418C1.771 17.8371 2.25266 17.3555 2.8335 17.3555H31.1668C31.7477 17.3555 32.2293 17.8371 32.2293 18.418C32.2293 18.9988 31.7477 19.4805 31.1668 19.4805Z"
      fill="#1678F2"
    />
    <path
      d="M23.375 32.2305H10.625C10.0442 32.2305 9.5625 31.7488 9.5625 31.168C9.5625 30.5871 10.0442 30.1055 10.625 30.1055H23.375C23.9558 30.1055 24.4375 30.5871 24.4375 31.168C24.4375 31.7488 23.9558 32.2305 23.375 32.2305Z"
      fill="#1678F2"
    />
  </svg>
);

export const GiftIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
  >
    <path
      d="M22.624 32.2305H11.2907C6.44569 32.2305 4.56152 30.3463 4.56152 25.5013V14.168C4.56152 13.5871 5.04319 13.1055 5.62402 13.1055H28.2907C28.8715 13.1055 29.3532 13.5871 29.3532 14.168V25.5013C29.3532 30.3463 27.469 32.2305 22.624 32.2305ZM6.68652 15.2305V25.5013C6.68652 29.1563 7.63569 30.1055 11.2907 30.1055H22.624C26.279 30.1055 27.2282 29.1563 27.2282 25.5013V15.2305H6.68652Z"
      fill="#1678F2"
    />
    <path
      d="M27.6248 15.2279H6.37484C3.89567 15.2279 2.479 13.8112 2.479 11.332V9.91536C2.479 7.4362 3.89567 6.01953 6.37484 6.01953H27.6248C30.0332 6.01953 31.5207 7.50703 31.5207 9.91536V11.332C31.5207 13.7404 30.0332 15.2279 27.6248 15.2279ZM6.37484 8.14453C5.08567 8.14453 4.604 8.6262 4.604 9.91536V11.332C4.604 12.6212 5.08567 13.1029 6.37484 13.1029H27.6248C28.8715 13.1029 29.3957 12.5787 29.3957 11.332V9.91536C29.3957 8.6687 28.8715 8.14453 27.6248 8.14453H6.37484Z"
      fill="#1678F2"
    />
    <path
      d="M16.4901 8.14526H8.67005C8.37255 8.14526 8.08922 8.01776 7.89089 7.80526C7.02672 6.85609 7.05505 5.39693 7.96172 4.49026L9.97339 2.47859C10.9084 1.54359 12.4526 1.54359 13.3876 2.47859L17.2409 6.33193C17.5384 6.62943 17.6376 7.09693 17.4676 7.49359C17.3117 7.89026 16.9292 8.14526 16.4901 8.14526ZM9.44922 6.02026H13.9401L11.8859 3.98026C11.7726 3.86693 11.5884 3.86693 11.4751 3.98026L9.46338 5.99193C9.46338 6.00609 9.44922 6.00609 9.44922 6.02026Z"
      fill="#1678F2"
    />
    <path
      d="M25.316 8.14526H17.496C17.071 8.14526 16.6743 7.89026 16.5185 7.49359C16.3485 7.09693 16.4476 6.64359 16.7451 6.33193L20.5985 2.47859C21.5335 1.54359 23.0776 1.54359 24.0126 2.47859L26.0243 4.49026C26.931 5.39693 26.9735 6.85609 26.0951 7.80526C25.8968 8.01776 25.6135 8.14526 25.316 8.14526ZM20.0743 6.02026H24.5651C24.551 6.00609 24.551 6.00609 24.5368 5.99193L22.5251 3.98026C22.4118 3.86693 22.2276 3.86693 22.1143 3.98026L20.0743 6.02026Z"
      fill="#1678F2"
    />
    <path
      d="M14.0817 23.943C13.685 23.943 13.2742 23.8438 12.9059 23.6455C12.0984 23.2063 11.6025 22.3705 11.6025 21.4638V14.168C11.6025 13.5871 12.0842 13.1055 12.665 13.1055H21.2217C21.8025 13.1055 22.2842 13.5871 22.2842 14.168V21.4355C22.2842 22.3563 21.7884 23.1921 20.9809 23.6171C20.1734 24.0563 19.1959 23.9996 18.4309 23.4896L17.17 22.6396C17.0567 22.5546 16.9009 22.5546 16.7734 22.6396L15.4417 23.518C15.0309 23.8013 14.5492 23.943 14.0817 23.943ZM13.7275 15.2305V21.4496C13.7275 21.6338 13.8409 21.7188 13.9117 21.7613C13.9825 21.8038 14.1242 21.8463 14.28 21.7471L15.6117 20.8688C16.4475 20.3163 17.5242 20.3163 18.3459 20.8688L19.6067 21.7188C19.7625 21.818 19.9042 21.7755 19.975 21.733C20.0459 21.6905 20.1592 21.6055 20.1592 21.4213V15.2163H13.7275V15.2305Z"
      fill="#1678F2"
    />
  </svg>
);

export const ArrowDottedIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="101"
    height="12"
    viewBox="0 0 101 12"
    fill="none"
  >
    <path
      d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM101 6L91 0.226497V11.7735L101 6ZM1 7H2V5H1V7ZM4 7H6V5H4V7ZM8 7H10V5H8V7ZM12 7H14V5H12V7ZM16 7H18V5H16V7ZM20 7H22V5H20V7ZM24 7H26V5H24V7ZM28 7H30V5H28V7ZM32 7H34V5H32V7ZM36 7H38V5H36V7ZM40 7H42V5H40V7ZM44 7H46V5H44V7ZM48 7H50V5H48V7ZM52 7H54V5H52V7ZM56 7H58V5H56V7ZM60 7H62V5H60V7ZM64 7H66V5H64V7ZM68 7H70V5H68V7ZM72 7H74V5H72V7ZM76 7H78V5H76V7ZM80 7H82V5H80V7ZM84 7H86V5H84V7ZM88 7H90V5H88V7ZM92 7H94V5H92V7ZM96 7H98V5H96V7Z"
      fill="#DFE4EC"
    />
  </svg>
);
