import {
  HelpIcon,
  ReferAndEarnIcon,
  SettingIcon,
  SubscriptionIcon
} from "../Icons/Icons";

export const SideMenuItem = [
  {
    name: "account_setting_side",
    link: "/profile",
    icon: <SettingIcon />,
  },
  {
    name: "subscription",
    link: "/billing",
    icon: <SubscriptionIcon />,
  },
  {
    name: "refer_earn",
    link: "/invite",
    icon: <ReferAndEarnIcon />,
  },
  {
    name: "help_support",
    link: "/support",
    icon: <HelpIcon />,
  },
];
