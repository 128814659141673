import React from "react";
import { CheckboxInput } from "../../Components/InputFields";
import { ButtonFill } from "../../Components/Button";
import { useTranslation } from "react-i18next";
import { ButtonExeFilled } from "../Extension/Components/Button";
import SpinnerLoader from "../../utils/LoaderCommon";

const Feedback = ({
  checkedValue,
  formState,
  handleChange,
  handleSubmit,
  isLoading,
}) => {
  const { t ,i18n} = useTranslation();
  return (
    <div className={`Div1 ${i18n.language === 'en' ? checkedValue ? 'slide-out' : 'slide-out-back': checkedValue ? 'slide-out2' : 'slide-out-back2'}`}>
      <div className="flex flex-col mt-10 w-[460px] gap-10">
        <div>
          <p className="text-[22px] font-DMSansFont font-semibold text-LightTextParagraph text-center">
            {t('AboutExp')}
          </p>
          <div className="mt-3 flex flex-col gap-[10px] items-start">
            <div className="bg-white shadow-[0_1px_2px_0_#f3f8fc] rounded-[10px] p-4 w-full">
              <CheckboxInput
                handleChange={handleChange}
                name="slow_down_pc"
                formState={formState.slow_down_pc}
                labelText={t('SlowsdownComp')}
              />
            </div>
            <div className="bg-white shadow-[0_1px_2px_0_#f3f8fc] rounded-[10px] p-4 w-full">
              <CheckboxInput
                handleChange={handleChange}
                name="not_working"
                formState={formState.not_working}
                labelText={t('DoesNotwork')}
              />
            </div>
            <div className="bg-white shadow-[0_1px_2px_0_#f3f8fc] rounded-[10px] p-4 w-full">
              <CheckboxInput
                handleChange={handleChange}
                name="donotknow"
                formState={formState.donotknow}
                labelText={t('HowToUseResala')}
              />
            </div>
            <div className="bg-white shadow-[0_1px_2px_0_#f3f8fc] rounded-[10px] p-4 w-full">
              <CheckboxInput
                handleChange={handleChange}
                name="difficult2use"
                formState={formState.difficult2use}
                labelText={t('DifficultToUse')}
              />
            </div>
          </div>
        </div>
        <div>
          <p className="text-[22px] font-DMSansFont font-semibold text-LightTextParagraph text-center">
            {t('CostsLimits')}
          </p>
          <div className="mt-3 flex flex-col gap-[10px] items-start">
            <div className="bg-white shadow-[0_1px_2px_0_#f3f8fc] rounded-[10px] p-4 w-full">
              <CheckboxInput
                handleChange={handleChange}
                name="is_not_free"
                formState={formState.is_not_free}
                labelText={t('Resalafree')}
              />
            </div>
            <div className="bg-white shadow-[0_1px_2px_0_#f3f8fc] rounded-[10px] p-4 w-full">
              <CheckboxInput
                handleChange={handleChange}
                name="queries_not_enough"
                formState={formState.queries_not_enough}
                labelText={t('QueriesNotEnough')}
              />
            </div>
            <div className="bg-white shadow-[0_1px_2px_0_#f3f8fc] rounded-[10px] p-4 w-full">
              <CheckboxInput
                handleChange={handleChange}
                name="price_is_more"
                formState={formState.price_is_more}
                labelText={t('PriceLabel')}
              />
            </div>
          </div>
        </div>
        <div>
          <p className="text-[22px] font-DMSansFont font-semibold text-LightTextParagraph text-center">
            {t('Other')}
          </p>
          <div className="mt-3 flex flex-col gap-[10px] items-start">
            <div className="bg-white shadow-[0_1px_2px_0_#f3f8fc] rounded-[10px] p-4 w-full">
              <CheckboxInput
                handleChange={handleChange}
                name="product_manager"
                formState={formState.product_manager}
                labelText={t('productManager')}
              />
            </div>
            <div className="bg-white shadow-[0_1px_2px_0_#f3f8fc] rounded-[10px] p-4 w-full">
              <CheckboxInput
                handleChange={handleChange}
                name="find_better_tool"
                formState={formState.find_better_tool}
                labelText={t('FoundBetter')}
              />
            </div>
            <div className="bg-white shadow-[0_1px_2px_0_#f3f8fc] rounded-[10px] p-4 w-full">
              <CheckboxInput
                handleChange={handleChange}
                name="privacy_security_concern"
                formState={formState.privacy_security_concern}
                labelText={t('SecurityConcern')}
              />
            </div>
          </div>

          <div className="mt-10">
            <ButtonExeFilled
              height={'40px'}
              text={isLoading ? <SpinnerLoader /> : t('SubmitUninstall')}
              fontColor={'#FFFFFF'}
              variant={'primary'}
              isDisable={isLoading || !checkedValue}
              hoverCss={'hover:!bg-DarkHover mt-[16px]'}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
