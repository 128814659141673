import React, { useState } from "react";
import InputField from "../InputField";
import { ButtonExeFilled, ButtonOutLine } from "../Button";
import ImageUpload from "./ImageUpload";
import { updateProfile } from "../../../../Exe-api/apis";
import { cn, profileInitials } from "../utils";
import SpinnerLoader from "../../../../utils/LoaderCommon";
import { t } from "i18next";
import i18n from "../../../../i18";

const UpdateProfile = ({
  onClose,
  first_name,
  last_name,
  profile_picture,
  updateUserData,
}) => {
  const isArabic = i18n.language === "ar";
  const initial = {
    img: profile_picture ?? null,
    fname: first_name || "",
    lname: last_name || "",
  };
  const [userData, setUserData] = useState(initial);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleCancel = () => {
    onClose();
    setUserData(initial);
  };

  const handleUpdate = async () => {
    setIsUpdating(true);
    const payload = {
      first_name: userData.fname,
      last_name: userData.lname,
      ...(typeof userData.img !== "string" && {
        profile_picture: userData.img,
      }),
    };
    try {
      const updateRes = await updateProfile(payload);
      const { profile_picture, first_name, last_name } = updateRes;
      updateUserData((prev) => {
        return {
          ...prev,
          profile_picture: profile_picture ?? null,
          first_name: first_name || "",
          last_name: last_name || "",
        };
      });
      onClose();
    } catch (err) {
      console.log("--- err UpdateProfile.js [Line-no 25] ---", err);
    }
  };

  return (
    <div className="w-[480px]">
      <div className="p-[40px]">
        <ImageUpload
          initials={profileInitials(first_name, last_name)}
          img={userData.img}
          onImgChange={(img) => {
            setUserData({ ...userData, img: img.file });
          }}
        />
      </div>
      <InputField
        label={t("first_name")}
        value={userData.fname}
        onChange={(e) => setUserData({ ...userData, fname: e.target.value })}
        // inputCss={cn(isArabic ? "text-right" : "text-left")}
      />
      <InputField
        className={"mt-[16px]"}
        label={t("last_name")}
        value={userData.lname}
        onChange={(e) => setUserData({ ...userData, lname: e.target.value })}
        // inputCss={cn(isArabic ? "text-right" : "text-left")}
      />
      <div className="flex gap-x-[20px] mt-[20px]">
        <ButtonOutLine
          height={40}
          text={t("cancel")}
          fontColor={"#5F6583"}
          borderColor={"#DFE4EC"}
          onClick={handleCancel}
          hoverCss={
            "hover:!text-LightPrimaryActive hover:!bg-[#eef6ff] hover:!border-[#D3E7FF]"
          }
        />
        <ButtonExeFilled
          height={40}
          isDisable={
            isUpdating ||
            userData.fname.length === 0 ||
            userData.lname.length === 0
          }
          text={isUpdating ? <SpinnerLoader /> : t("update")}
          fontColor={"#FFFFFF"}
          onClick={handleUpdate}
          hoverCss={"hover:!bg-DarkHover"}
        />
      </div>
    </div>
  );
};

export default UpdateProfile;
