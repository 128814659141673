import React, { useEffect, useState } from "react";
import PrivacyImage from "../../assets/images/Footer/PrivacyImage.svg";
import List from "../../Components/List";
import CommonData from "../PrivacyPolicy/CommonData";
import ListOfText from "./ListOfText";
import ListNumber from "../../Components/ListNumber";
import Layout from "../../utils/Layout";
import { useTranslation } from "react-i18next";
// import { usePageTracking } from '../../utils/usePageTracking';

const TermsOfUse = () => {
  // usePageTracking()
  const { t, i18n } = useTranslation();

  const [termsList, setTermsList] = useState(
    getTranslatedList(t, "termsListKey")
  );
  const [cancellationList, setCancellationList] = useState(
    getTranslatedList(t, "cancellationListKey")
  );

  const [copyRight, setCopyRight] = useState(
    getTranslatedList(t, "copyRightKey")
  );
  const [ContentRestrictions, setContentRestrictions] = useState(
    getTranslatedList(t, "ContentRestrictionsKey")
  );
  const [subscriptionList, setSubscriptionList] = useState(
    getTranslatedList(t, "subscriptionListKey")
  );

  useEffect(() => {
    // Update lists when language changes
    setTermsList(getTranslatedList(t, "termsListKey"));
    setCancellationList(getTranslatedList(t, "cancellationListKey"));
    setCopyRight(getTranslatedList(t, "copyRightKey"));
    setContentRestrictions(getTranslatedList(t, "ContentRestrictionsKey"));
    setSubscriptionList(getTranslatedList(t, "subscriptionListKey"));
  }, [t]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const lang = queryParams.get("lang");
    const abc = document.getElementById("main-app");
    if (lang === "ar") {
      localStorage.setItem("lang", "ar");
      i18n.changeLanguage("ar");
      abc.dir = "rtl";
    } else {
      localStorage.setItem("lang", "en");
      i18n.changeLanguage("en");
      abc.dir = "ltr";
    }
  }, []);

  return (
    <>
      <Layout>
        <div className="m-auto max-w-[1040px] max_md:mt-2 max_sm:mt-2 max_xl:max-w-[980px] py-[50px] max_md:px-[40px] max_sm:px-[20px] max_sm:py-[20px] max_md:py-[35px]">
          <div className="flex flex-col gap-[30px] max_sm:gap-[20px]">
            {/* Header */}
            <div className="flex items-center gap-3">
              <div className="max_sm:w-[18%]">
                <img src={PrivacyImage} alt="PrivacyImage" />
              </div>

              <div className="discoverHeadingDM text-LightPrimary">
                {t("TermsOfUse")}
              </div>
            </div>
            <div>
              <div className="privacyTextDM">{t("TermsText")}</div>

              <List data={termsList} number={false} />
            </div>
            {/* Who can Use */}
            <div>
              <div className="discoverHeadingDM text-LightPrimary">
                {t("InformationHead")}
              </div>
              <div className="privacyTextDM mt-[16px] max_sm:mt-[10px]">
                {t("InfoPrivacyText1")} {""}
                <span className="">
                  <a
                    href="mailto:support@resala.ai"
                    onClick={() => "window.location=another.html"}
                  >
                    support@resala.ai
                  </a>
                </span>
              </div>
              <div className="privacyTextDM mt-[10px] max_sm:mt-[6px]">
                {t("InfoPrivacyText2")}
              </div>
            </div>
            {/* Pricing order */}
            <CommonData
              heading={t("PricingOrderHead")}
              text={t("PricingOrderText")}
              list={false}
            />
            {/* Your Information */}
            <div>
              <CommonData
                text={t("YourInfoText1")}
                heading={t("YourInfoHead")}
                list={false}
              />
              <div className="privacyTextDM mt-[10px] max_sm:mt-[6px]">
                {t("YourInfoText2")}
              </div>
              <div className="privacyTextDM mt-[10px] max_sm:mt-[6px]">
                {t("YourInfoText3")}
              </div>
            </div>
            {/* Order Cancellation */}
            <div>
              <CommonData
                heading={t("OrderHead")}
                text={t("OrderHeadText1")}
                list={true}
                data={cancellationList}
              />
              <div className="privacyTextDM mt-[10px] max_sm:mt-[6px]">
                {t("OrderHeadText2")}
              </div>
            </div>
            {/* Availability, Errors and Inaccuracies */}
            <CommonData
              heading={t("AvailabilityHead")}
              text={t("AvailabilityText1")}
              text2={t("AvailabilityText2")}
            />
            {/* Prices Policy */}
            <CommonData
              heading={t("PricesPolicy")}
              text={t("PricesPolicyText")}
              list={false}
            />
            {/* Payments*/}
            <CommonData
              heading={t("Payments")}
              text={t("PaymentsTextTerms")}
              list={false}
            />
            <div>
              <div className="discoverHeadingDM text-LightPrimary">
                {t("Subscriptions")}
              </div>
              {subscriptionList.map((list1, index1) => {
                return (
                  <React.Fragment>
                    <div className="discoverHeadingDM text-[18px] max_sm:text-[14px] max_md:text-[16px] text-[#5F6583] mt-[16px]">
                      {list1.title}
                    </div>
                    <div className="privacyTextDM mt-[20px] max_sm:mt-[10px]">
                      {list1.text}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            {/* SUBSCRIPTION FEES AND TRIAL PERIOD */}
            <div>
              <div className="discoverHeadingDM text-LightPrimary">
                {t("Subscriptions")}
              </div>
              <div className="flex mt-[10px] max_sm:mt-[5px] items-start">
                <div className="w-[2%] p-[8px] max_md:w-[5%] max_sm:w-[7%]">
                  <div className="w-[6px] h-[6px] bg-LightTextParagraph rounded-full"></div>
                </div>
                <div className="w-[95%] privacyTextDM">
                  {t("SubscriptionsText1")}{" "}
                  <span className="">
                    <a
                      className="text-[#1678F2]"
                      href="https://resala.ai/billing"
                      onClick={() => "window.location=another.html"}
                    >
                      https://resala.ai/billing
                    </a>
                  </span>
                  .
                </div>
              </div>
              <div className="flex mt-[10px] max_sm:mt-[5px] items-start">
                <div className="w-[2%] p-[8px] max_md:w-[5%] max_sm:w-[7%]">
                  <div className="w-[6px] h-[6px] bg-LightTextParagraph rounded-full"></div>
                </div>
                <div className="privacyTextDM ">{t("SubscriptionsText2")}</div>
              </div>
              <div className="flex mt-[10px] max_sm:mt-[5px] items-start">
                <div className="w-[2%] p-[8px] max_md:w-[5%] max_sm:w-[7%]">
                  <div className="w-[6px] h-[6px] bg-LightTextParagraph rounded-full"></div>
                </div>
                <div className="w-[95%] privacyTextDM">
                  {t("SubscriptionsText3.1")}{" "}
                  <span className="">
                    <a
                      className="text-[#1678F2]"
                      href="https://resala.ai/billing"
                      onClick={() => "window.location=another.html"}
                    >
                      https://resala.ai/billing
                    </a>
                  </span>
                  {t("SubscriptionsText3.2")}{" "}
                </div>
              </div>
              <div className="flex mt-[10px] max_sm:mt-[5px] items-start">
                <div className="w-[2%] p-[8px] max_md:w-[5%] max_sm:w-[7%]">
                  <div className="w-[6px] h-[6px] bg-LightTextParagraph rounded-full"></div>
                </div>
                <div className="w-[95%] privacyTextDM">
                  {t("SubscriptionsText4")}
                </div>
              </div>

              <div className="flex mt-[10px] max_sm:mt-[5px] items-start">
                <div className="w-[2%] p-[8px] max_md:w-[5%] max_sm:w-[7%]">
                  <div className="w-[6px] h-[6px] bg-LightTextParagraph rounded-full"></div>
                </div>
                <div className="w-[95%] privacyTextDM">
                  {t("SubscriptionsText5")}
                </div>
              </div>
            </div>
            {/* Promotions */}
            <CommonData
              heading={t("Promotions")}
              text={t("PromotionsText1")}
              text2={t("PromotionsText2")}
            />
            {/* User Accounts */}
            <ListOfText
              heading={t("UserAccounts")}
              listOfText={[
                t("UserAccounts1"),
                t("UserAccounts2"),
                t("UserAccounts3"),
                t("UserAccounts4"),
                t("UserAccounts5"),
              ]}
            />
            <div>
              <div className="discoverHeadingDM text-LightPrimary">
                {t("Content")}
              </div>
              <div className="discoverHeadingDM text-[18px] max_sm:text-[14px] max_md:text-[16px] text-[#5F6583] mt-[20px] max_sm:[10px]">
                {t("ContentSubHead")}
              </div>
              <div className="privacyTextDM mt-[16px] max_sm:mt-[10px]">
                {t("ContentText1")}
              </div>
              <div className="privacyTextDM mt-[10px] max_sm:mt-[5px]">
                {t("ContentText2")}
              </div>
              <div className="discoverHeadingDM text-[18px] max_sm:text-[14px] max_md:text-[16px] text-[#5F6583] mt-[20px] max_sm:[10px]">
                {t("ContentRestrictions")}
              </div>
              <div className="privacyTextDM mt-[10px] max_sm:mt-[5px]">
                {t("ContentRestrictionsText1")}
              </div>
              <div className="privacyTextDM mt-[10px] max_sm:mt-[5px]">
                {t("ContentRestrictionsText2")}
              </div>
              <List data={ContentRestrictions} />
              <div className="privacyTextDM mt-[10px] max_sm:mt-[5px]">
                {t("ContentRestrictionsText3")}
              </div>
            </div>
            {/* Content Backups */}
            <ListOfText
              heading={t("ContentBackups")}
              listOfText={[
                t("ContentBackupsText1"),
                t("ContentBackupsText2"),
                t("ContentBackupsText3"),
              ]}
            />
            {/* Copyright Policy */}
            <div>
              <ListOfText
                heading={t("CopyrightPolicy")}
                listOfText={[t("CopyrightPolicy1"), t("CopyrightPolicy2")]}
              />

              <ListNumber number={true} data={copyRight} />
              <div className="privacyTextDM mt-[10px] max_sm:mt-[6px]">
                {t("CopyrightPolicy3")}
              </div>
            </div>
            {/* Intellectual Property */}
            <div>
              <div className="discoverHeadingDM text-LightPrimary">
                {t("InformationHead")}
              </div>
              <div className="flex mt-[10px] max_sm:mt-[5px] items-start">
                <div className="w-[2%] p-[8px] max_md:w-[5%] max_sm:w-[7%]">
                  <div className="w-[6px] h-[6px] bg-LightTextParagraph rounded-full"></div>
                </div>
                <div className="w-[95%] privacyTextDM">
                  {t("CollectInfoText1")}
                </div>
              </div>
              <div className="flex mt-[10px] max_sm:mt-[5px] items-start">
                <div className="w-[2%] p-[8px] max_md:w-[5%] max_sm:w-[7%]">
                  <div className="w-[6px] h-[6px] bg-LightTextParagraph rounded-full"></div>
                </div>
                <div className="w-[95%] privacyTextDM">
                  {t("CollectInfoText2")}
                  {""}
                </div>
              </div>
              <div className="flex mt-[10px] max_sm:mt-[5px] items-start">
                <div className="w-[2%] p-[8px] max_md:w-[5%] max_sm:w-[7%]">
                  <div className="w-[6px] h-[6px] bg-LightTextParagraph rounded-full"></div>
                </div>
                <div className="w-[95%] privacyTextDM">
                  {t("CollectInfoText3")} {""}
                </div>
              </div>
              <div className="flex mt-[10px] max_sm:mt-[5px] items-start">
                <div className="w-[2%] p-[8px] max_md:w-[5%] max_sm:w-[7%]">
                  <div className="w-[6px] h-[6px] bg-LightTextParagraph rounded-full"></div>
                </div>
                <div className="w-[95%] privacyTextDM">
                  {t("CollectInfoText4")}
                </div>
              </div>
            </div>
            {/* Links to Other Websites */}
            <ListOfText
              heading={t("LinksWebsites")}
              listOfText={[
                t("LinksWebsites1"),
                t("LinksWebsites2"),
                t("LinksWebsites3"),
              ]}
            />
            {/* Termination */}
            <ListOfText
              heading={t("Termination")}
              listOfText={[
                t("TerminationText1"),
                t("TerminationText2"),
                t("TerminationText3"),
              ]}
            />
            {/* Indemnity */}
            <ListOfText
              heading={t("Indemnity")}
              listOfText={[t("IndemnityText")]}
            />
            {/* Limitation of Liability */}
            <ListOfText
              heading={t("LimitationOfLiability")}
              listOfText={[t("LimitationOfLiabilityText")]}
            />
            {/* Disclaimer */}
            <div>
              <div className="discoverHeadingDM text-LightPrimary">
                {t("Disclaimer")}
              </div>
              <div className="flex mt-[10px] max_sm:mt-[5px] items-start">
                <div className="w-[2%] p-[8px] max_md:w-[5%] max_sm:w-[7%]">
                  <div className="w-[6px] h-[6px] bg-LightTextParagraph rounded-full"></div>
                </div>
                <div className="w-[95%] privacyTextDM">{t("Disclaimer1")}</div>
              </div>
              <div className="flex mt-[10px] max_sm:mt-[5px] items-start">
                <div className="w-[2%] p-[8px] max_md:w-[5%] max_sm:w-[7%]">
                  <div className="w-[6px] h-[6px] bg-LightTextParagraph rounded-full"></div>
                </div>
                <div className="w-[95%] privacyTextDM">{t("Disclaimer2")}</div>
              </div>
              <div className="flex mt-[10px] max_sm:mt-[5px] items-start">
                <div className="w-[2%] p-[8px] max_md:w-[5%] max_sm:w-[7%]">
                  <div className="w-[6px] h-[6px] bg-LightTextParagraph rounded-full"></div>
                </div>
                <div className="w-[95%] privacyTextDM">{t("Disclaimer3")}</div>
              </div>
              <div className="flex mt-[10px] max_sm:mt-[5px] items-start">
                <div className="w-[2%] p-[8px] max_md:w-[5%] max_sm:w-[7%]">
                  <div className="w-[6px] h-[6px] bg-LightTextParagraph rounded-full"></div>
                </div>
                <div className="w-[95%] privacyTextDM">{t("Disclaimer4")}</div>
              </div>
            </div>
            {/* Governing Law */}
            <ListOfText
              heading={t("GoverningLaw")}
              listOfText={[t("GoverningLawText")]}
            />
            <ListOfText
              heading={t("DisputesResolution")}
              listOfText={[t("DisputesResolutionText")]}
            />
            <ListOfText
              heading={t("ForEuropeanUser")}
              listOfText={[t("ForEuropeanUserText")]}
            />
            <ListOfText
              heading={t("UnitedStatesProvisions")}
              listOfText={[t("UnitedStatesProvisionsText")]}
            />
            <ListOfText
              heading={t("UnitedStatesCompliance")}
              listOfText={[t("UnitedStatesComplianceText")]}
            />
            <div>
              <div className="discoverHeadingDM text-LightPrimary">
                {t("Disclaimer")}
              </div>
              <div className="discoverHeadingDM text-[18px] max_sm:text-[14px] max_md:text-[16px] text-[#5F6583] mt-[20px] max_sm:[10px]">
                {t("Severability")}
              </div>
              <div className="privacyTextDM mt-[10px] max_sm:mt-[6px]">
                {t("SeverabilityText")}
              </div>
              <div className="discoverHeadingDM text-[18px] max_sm:text-[14px] max_md:text-[16px] text-[#5F6583] mt-[20px] max_sm:[10px]">
                {t("Waiver")}
              </div>
              <div className="privacyTextDM mt-[10px] max_sm:mt-[6px]">
                {t("WaiverText")}
              </div>
            </div>
            {/* Translation Interpretation */}
            <ListOfText
              heading={t("TranslationInterpretation")}
              listOfText={[t("TranslationText")]}
            />
            {/* Changes to These Terms and Conditions */}
            <ListOfText
              heading={t("ChangesTerms")}
              listOfText={[t("ChangesTermsText")]}
            />

            {/* Contact us */}
            <div>
              <div className="discoverHeadingDM text-LightPrimary">
                {t("ContactUs")}
              </div>
              <div className="privacyTextDM mt-[16px] max_sm:mt-[10px]">
                {t("ContactUsSupport")} {""}
                <span className="">
                  <a
                    href="mailto:support@resala.ai"
                    onClick={() => "window.location=another.html"}
                  >
                    support@resala.ai
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

const getTranslatedList = (t, listKey) => {
  // Define your lists here
  const lists = {
    termsListKey: [
      { title: t("TermList1") },
      {
        title: t("TermList2"),
      },
    ],
    cancellationListKey: [
      { title: t("CancellationList1") },
      {
        title: t("CancellationList2"),
      },
      {
        title: t("CancellationList3"),
      },
    ],
    copyRightKey: [
      {
        title: t("CopyRight1"),
      },
      {
        title: t("CopyRight2"),
      },
      {
        title: t("CopyRight3"),
      },
      {
        title: t("CopyRight4"),
      },
      {
        title: t("CopyRight5"),
      },
      {
        title: t("CopyRight6"),
      },
      {
        title: t("CopyRight7"),
      },
      {
        title: t("CopyRight8"),
      },
      {
        title: t("CopyRight9"),
      },
      {
        title: t("CopyRight10"),
      },
      {
        title: t("CopyRight11"),
      },
      {
        title: t("CopyRight12"),
      },
      {
        title: t("CopyRight13"),
      },
    ],
    subscriptionListKey: [
      {
        title: t("SubList1Title"),
        text: t("SubList1Text"),
      },
      {
        title: t("SubList2Title"),
        text: t("SubList2Text"),
      },
      {
        title: t("SubList3Title"),
        text: t("SubList3Text"),
      },
      {
        title: t("SubList4Title"),
        text: t("SubList4Text"),
      },
      {
        title: t("SubList5Title"),
        text: t("SubList5Text"),
      },
    ],
    ContentRestrictionsKey: [
      {
        title: t("ContentRestrictions1"),
      },
      {
        title: t("ContentRestrictions2"),
      },
      {
        title: t("ContentRestrictions3"),
      },
      {
        title: t("ContentRestrictions4"),
      },
      {
        title: t("ContentRestrictions5"),
      },
      {
        title: t("ContentRestrictions6"),
      },
      {
        title: t("ContentRestrictions7"),
      },
      {
        title: t("ContentRestrictions8"),
      },
      {
        title: t("ContentRestrictions9"),
      },
      {
        title: t("ContentRestrictions10"),
      },
    ],
  };

  return lists[listKey];
};

export default TermsOfUse;
