import Skeleton from "react-loading-skeleton";

export const QueryDetails = ({
  isLoading = false,
  title,
  usedCount,
  remainCount,
}) => {

  return (
    <div className="w-[200px] flex-col justify-start items-start gap-5 inline-flex">
      <div className="text-LightPrimary text-base font-bold font-DMSansFont">
        {isLoading ? <Skeleton width={120} height={18} /> : title}
      </div>
      <div className="flex-col justify-start items-start gap-3 flex">
        <div className="w-[200px] justify-between items-center inline-flex">
          <div className="w-[130px] text-[#5F6583] text-sm font-medium font-DMSansFont">
            {isLoading ? <Skeleton width={120} height={18} /> : 'Used'}
          </div>
          <div className="text-indigo-950 text-sm font-bold font-DMSansFont">
            {isLoading ? <Skeleton width={40} height={18} /> : usedCount}
          </div>
        </div>
        <div className="w-[200px] justify-between items-center inline-flex">
          <div className="text-[#5F6583] text-sm font-medium font-DMSansFont">
            {isLoading ? <Skeleton width={120} height={18} /> : 'Remaining'}{' '}
          </div>
          <div className="text-LightPrimary text-sm font-bold font-DMSansFont">
            {isLoading ? <Skeleton width={40} height={18} /> : remainCount}
          </div>
        </div>
      </div>
    </div>
  );
};
