import React from "react";
import { cn } from "../utils";
import InputField from "../InputField";
import { ButtonExeFilled, ButtonOutLine } from "../Button";
import Otp from "../../Otp";

const fontCommons = "text-[14px] leading-[18px] text-LightStrokActive";
const UpdateEmail = ({ onClose }) => {
  const handleCancel = () => {
    onClose();
  };

  const handleUpdate = () => {
    console.log("update");
  };

  return (
    <div className="mt-[40px] w-[400px]">
      <TwoToneText
        fontCommons={fontCommons}
        text1="Verify your old email"
        text2="vsonani@dcdial.com"
      />
      <div className="mt-[16px] flex gap-x-[12px] items-end">
        <InputField
          className={"w-full"}
          label={"OTP"}
          placeholder={"Enter OTP"}
        />
        <ButtonOutLine
          height={42}
          width={"147px"}
          paddingBlock={9}
          text={"Send OTP"}
          fontColor={"#5F6583"}
          fontSize={16}
          borderColor={"#DFE4EC"}
          onClick={() => console.log("dd")}
          hoverCss={
            "hover:!text-LightPrimaryActive hover:!bg-[#eef6ff] hover:!border-[#D3E7FF]"
          }
        />
      </div>
      <div className="mt-[16px]">
        <TwoToneText
          fontCommons={fontCommons}
          text1="A verification code has been sent to"
          text2="vsonani@dcdial.com."
        />
        <Otp />

        <DeviderDashed className="my-[40px]" />

        <div>
          <p className={cn(fontCommons, "text-LightPrimary font-bold")}>
            New Email
          </p>
          <div className="mt-[16px]">
            <InputField label={"Email"} placeholder={"Enter new email"} />
            <div className="mt-[16px] flex gap-x-[12px] items-end">
              <InputField
                className={"w-full"}
                label={"OTP"}
                placeholder={"Enter new OTP"}
              />
              <ButtonOutLine
                height={42}
                width={"147px"}
                paddingBlock={9}
                text={"Send OTP"}
                fontColor={"#5F6583"}
                fontSize={16}
                borderColor={"#DFE4EC"}
                onClick={() => console.log("dd")}
                hoverCss={
                  "hover:!text-LightPrimaryActive hover:!bg-[#eef6ff] hover:!border-[#D3E7FF]"
                }
              />
            </div>
          </div>
        </div>

        <div className="flex gap-x-[20px] mt-[20px]">
          <ButtonOutLine
            height={40}
            text={"Cancel"}
            fontColor={"#5F6583"}
            borderColor={"#DFE4EC"}
            onClick={handleCancel}
            hoverCss={
              "hover:!text-LightPrimaryActive hover:!bg-[#eef6ff] hover:!border-[#D3E7FF]"
            }
          />
          <ButtonExeFilled
            height={40}
            text={"Update"}
            fontColor={"#FFFFFF"}
            onClick={handleUpdate}
            hoverCss={"hover:!bg-DarkHover"}
          />
        </div>
      </div>
    </div>
  );
};

export default UpdateEmail;

const DeviderDashed = ({ className }) => (
  <div className={cn("border-b border-dashed w-full", className)} />
);

export const TwoToneText = ({
  fontCommons,
  text1 = "",
  text2 = "",
  text3 = "",
  className,
}) => (
  <p className={cn(fontCommons, "flex gap-x-[4px]", className)}>
    {text1}
    <span className={cn(fontCommons, "font-bold text-LightPrimary")}>
      {" "}
      {text2}
    </span>
    {text3}
  </p>
);
