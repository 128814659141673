import React from "react";
import Skeleton from "react-loading-skeleton";

const QueryDetailsRow = ({ isLoading = false, title, value, valueBy }) => {
  return (
    <div className="flex w-full justify-between">
      <p
        className="text-[14px] font-medium text-[#5F6583]"
        style={{ lineHeight: "18px" }}
      >
        {isLoading ? <Skeleton width={200} height={18} /> : title}
      </p>
      <p
        className="text-[18px] font-bold text-LightPrimary"
        style={{ lineHeight: "18px" }}
      >
        {isLoading ? (
          <Skeleton width={60} height={20} />
        ) : (
          <>
            {value} <span className="text-[16px] text-[#5F6583] font-medium">{valueBy}</span>
          </>
        )}
      </p>
    </div>
  );
};

export default QueryDetailsRow;
