import React, { useState, useEffect } from "react";
import PrivacyImage from "../../assets/images/Footer/PrivacyImage.svg";
import CommonData from "./CommonData";
import PrivacyList from "../../Components/List";
import Layout from "../../utils/Layout";
import { useTranslation } from "react-i18next";
import i18n from "../../i18";
// import { usePageTracking } from '../../utils/usePageTracking';

const PrivacyPolicy = () => {
  // usePageTracking()
  const { t } = useTranslation();
  const [PersonalData, setPersonalData] = useState(
    getTranslatedList(t, "list1")
  );
  const [useOfData, setUseOfData] = useState(
    getTranslatedList(t, "useOfDataList")
  );
  const [PersonalData1, setPersonalData1] = useState(
    getTranslatedList(t, "PersonalData1List")
  );
  const [shareInformation, setShareInformation] = useState(
    getTranslatedList(t, "shareInformation")
  );

  const [GDPRPrivacy, setGDPRPrivacy] = useState(
    getTranslatedList(t, "GDPRPrivacyList")
  );
  const [rightsUnderGDPR, setRightsUnderGDPR] = useState(
    getTranslatedList(t, "rightsUnderGDPRList")
  );
  const [CCPAPrivacy, setCCPAPrivacy] = useState(
    getTranslatedList(t, "CCPAPrivacyList")
  );
  const [commercialPurpose, setCommercialPurpose] = useState(
    getTranslatedList(t, "commercialPurposeList")
  );
  const [rightsUnderCCPA, setRightsUnderCCPA] = useState(
    getTranslatedList(t, "rightsUnderCCPA")
  );
  useEffect(() => {
    // Update lists when language changes
    setPersonalData(getTranslatedList(t, "PersonalDataList"));
    setUseOfData(getTranslatedList(t, "useOfDataList"));
    setPersonalData1(getTranslatedList(t, "PersonalData1List"));
    setShareInformation(getTranslatedList(t, "shareInformation"));
    setGDPRPrivacy(getTranslatedList(t, "GDPRPrivacyList"));
    setRightsUnderGDPR(getTranslatedList(t, "rightsUnderGDPRList"));
    setCCPAPrivacy(getTranslatedList(t, "CCPAPrivacyList"));
    setCommercialPurpose(getTranslatedList(t, "commercialPurposeList"));
    setRightsUnderCCPA(getTranslatedList(t, "rightsUnderCCPA"));
  }, [t]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const lang = queryParams.get("lang");
    const abc = document.getElementById("main-app");
    if (lang === "ar") {
      localStorage.setItem("lang", "ar");
      i18n.changeLanguage("ar");
      abc.dir = "rtl";
    } else {
      localStorage.setItem("lang", "en");
      i18n.changeLanguage("en");
      abc.dir = "ltr";
    }
  }, []);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        setTimeout(() => {
          const elementPosition =
            element.getBoundingClientRect().top + window.scrollY;
          const offsetPosition = elementPosition - 80;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }, 400);
      }
    }
  }, []);

  return (
    <>
      <Layout>
        <div className="m-auto max-w-[1040px] max_md:mt-2 max_xl:max-w-[980px] py-[50px] max_md:px-[40px] max_sm:px-[20px] max_sm:py-[20px] max_md:py-[35px]">
          <div className="flex flex-col gap-[30px] max_sm:gap-[20px]">
            <div className="flex items-center gap-3">
              <div>
                <img src={PrivacyImage} alt="PrivacyImage" />
              </div>
              <div>
                <div className="discoverHeadingDM text-LightPrimary">
                  {t("PrivacyPolicy")}
                </div>
                <div className="text-[16px] max_sm:text-[12px] text-LightTextParagraph font-DMSansFont font-normal">
                  {t("LastUpdatedPrivacy")}
                </div>
              </div>
            </div>
            {/* Section 2 */}
            <div className="privacyTextDM">{t("PrivacyText")}</div>

            {/* Section 2 */}
            <div className="">
              <div className="discoverHeadingDM text-LightPrimary">
                {t("InformationHead")}
              </div>
              <div className="privacyTextDM mt-[16px] max_sm:mt-[10px]">
                {t("PrivacyInformation")}
              </div>
            </div>
            {/* Section 3 */}

            <CommonData
              heading={t("CollectDataHead")}
              data={PersonalData}
              text={t("CollectDataSubHead")}
              list={true}
            />

            {/* Section 4 */}

            <CommonData
              heading={t("PersonalDataHead")}
              data={PersonalData1}
              text={t("PersonalDataSubHead")}
              list={true}
            />
            <CommonData
              heading={t("UseOfDataHead")}
              data={useOfData}
              text={t("UseOfDataSubHead")}
              list={true}
            />
            <CommonData
              heading={t("ShareInfoHead")}
              data={shareInformation}
              text={t("ShareInfoSubHead")}
              list={true}
            />
            <CommonData
              heading={t("RetentionHead")}
              text={t("RetentionText")}
              list={false}
            />
            <CommonData
              heading={t("ProtectInfoHead")}
              text={t("ProtectText")}
              list={false}
            />
            <CommonData
              heading={t("ServiceHead")}
              text={t("ServiceText")}
              list={false}
            />

            <div>
              <div className="discoverHeadingDM text-LightPrimary">
                {t("EmailMarketing")}
              </div>
              <div className="privacyTextDM mt-[16px]">
                {t("EmailMarketingText")}{" "}
                <span className="">
                  <a
                    className="text-[#1678F2]"
                    href="https://aws.amazon.com/privacy/"
                    onClick={() => "window.location=another.html"}
                  >
                    https://aws.amazon.com/privacy/
                  </a>
                </span>
              </div>
            </div>

            <div>
              <div className="discoverHeadingDM text-LightPrimary">
                {t("Payments")}
              </div>
              <div className="privacyTextDM mt-[16px]">{t("PaymentsText")}</div>
              <div className="flex mt-[10px] items-start">
                <div className="w-[3%] p-[8px] max_md:w-[5%] max_sm:w-[7%]">
                  <div className="w-[6px] h-[6px] bg-LightTextParagraph rounded-full"></div>
                </div>
                <div className=" w-[95%] privacyTextDM">
                  {t("PaymentView")} {""}
                  <span className="">
                    <a
                      className="text-[#1678F2]"
                      href="https://www.avnovo.com/privacy-policy/"
                      onClick={() => "window.location=another.html"}
                    >
                      https://www.avnovo.com/privacy-policy/
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div>
              <div className="discoverHeadingDM text-LightPrimary">
                {t("GDPRPrivacyHead")}
              </div>
              <div className="discoverHeadingDM text-[18px] max_sm:text-[14px] max_md:text-[16px] text-[#5F6583] mt-[16px]">
                {t("GDPRPrivacySubHead")}
              </div>
              <div className="privacyTextDM mt-[16px] max_sm:mt-[10px]">
                {t("GDPRPrivacySubHead2")}
              </div>

              <PrivacyList data={GDPRPrivacy} />

              <div className="privacyTextDM mt-[16px] max_sm:mt-[10px]">
                {t("GDPRText")}
              </div>

              <div className="discoverHeadingDM text-[18px] max_sm:text-[14px] max_md:text-[16px] text-[#5F6583] mt-[16px]">
                {t("GDPRPrivacySubHead3")}
              </div>

              <div className="privacyTextDM mt-[16px] max_sm:mt-[10px]">
                {t("GDPRPrivacySubHead4")}
              </div>

              <PrivacyList data={rightsUnderGDPR} />
            </div>

            <div id="ccpa">
              <div className="discoverHeadingDM text-LightPrimary">
                {t("CCPAPrivacy")}
              </div>
              <div className="privacyTextDM mt-[16px] max_sm:mt-[10px]">
                {t("CCPAPrivacySubHead")}
              </div>
              <div className="discoverHeadingDM text-[18px] max_sm:text-[14px] max_md:text-[16px] text-[#5F6583] mt-[16px]">
                {t("CCPAPrivacySubHead2")}
              </div>
              <div className="privacyTextDM mt-[16px] max_sm:mt-[10px]">
                {t("CCPAPrivacySubHead3")}
              </div>

              <PrivacyList data={CCPAPrivacy} />

              <div className="discoverHeadingDM text-[18px] max_sm:text-[14px] max_md:text-[16px] text-[#5F6583] mt-[16px]">
                {t("CommercialPurpose")}
              </div>

              <div className="privacyTextDM mt-[16px] max_sm:mt-[10px]">
                {t("CommercialPurposeSubHead")}
              </div>

              <PrivacyList data={commercialPurpose} />
              <div className="privacyTextDM mt-[16px] max_sm:mt-[10px]">
                {t("CommercialText")}
              </div>
            </div>

            <CommonData
              heading={t("PersonalInfoMinor")}
              text={t("PersonalInfoMinorText")}
              list={false}
            />

            <CommonData
              heading={t("YourRights")}
              text={t("YourRightsSubHead")}
              list={true}
              data={rightsUnderCCPA}
            />

            <div>
              <div className="discoverHeadingDM text-LightPrimary">
                {t("RightsProtectionHead")}
              </div>
              <div className="privacyTextDM mt-[16px] max_sm:mt-[10px]">
                {t("RightsProtectionSubHead")}
              </div>
              <div className="flex mt-[10px] max_sm:mt-[5px] items-start">
                <div className="w-[3%] p-[8px] max_md:w-[5%] max_sm:w-[7%]">
                  <div className="w-[6px] h-[6px] bg-LightTextParagraph rounded-full"></div>
                </div>
                <div className=" w-[95%] privacyTextDM">
                  {t("ByEmail")} {""}
                  <span className="">
                    <a
                      href="mailto:support@resala.ai"
                      onClick={() => "window.location=another.html"}
                    >
                      {t("SupportEmail")}
                    </a>
                  </span>
                </div>
              </div>
            </div>

            {/* Other Website */}
            <div>
              <div className="discoverHeadingDM text-LightPrimary">
                {t("LinksWebsites")}
              </div>
              <div className="privacyTextDM mt-[16px] max_sm:mt-[10px]">
                {t("LinksWebsitesText")}
              </div>
              <div className="privacyTextDM mt-[16px] max_sm:mt-[10px]">
                {t("LinksWebsitesText2")}
              </div>
            </div>

            {/* Privacy Policy */}
            <div>
              <div className="discoverHeadingDM text-LightPrimary">
                {t("ChangesPrivacy")}
              </div>
              <div className="privacyTextDM mt-[16px] max_sm:mt-[10px]">
                {t("ChangesPrivacyText1")}
              </div>
              <div className="privacyTextDM mt-[16px] max_sm:mt-[10px]">
                {t("ChangesPrivacyText2")}
              </div>
              <div className="privacyTextDM mt-[16px] max_sm:mt-[10px]">
                {t("ChangesPrivacyText3")}
              </div>
            </div>

            {/* Contact us */}
            <div>
              <div className="discoverHeadingDM text-LightPrimary">
                {t("ContactUs")}
              </div>
              <div className="privacyTextDM mt-[16px] max_sm:mt-[10px]">
                {t("ContactUsSupport")} {""}
                <span className="">
                  <a
                    href="mailto:support@resala.ai"
                    onClick={() => "window.location=another.html"}
                  >
                    {t("SupportEmail")}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
const getTranslatedList = (t, listKey) => {
  // Define your lists here
  const lists = {
    PersonalDataList: [
      {
        title: t("CollectDataTitle1"),
      },
      {
        title: t("CollectDataTitle2"),
      },
      {
        title: t("CollectDataTitle3"),
      },
    ],
    useOfDataList: [
      {
        title: t("UseOfDataTitle1"),
      },
      {
        title: t("UseOfDataTitle2"),
      },
      {
        title: t("UseOfDataTitle3"),
      },
      {
        title: t("UseOfDataTitle4"),
      },
      {
        title: t("UseOfDataTitle5"),
      },
      {
        title: t("UseOfDataTitle6"),
      },
      {
        title: t("UseOfDataTitle7"),
      },
      {
        title: t("UseOfDataTitle8"),
      },
    ],
    PersonalData1List: [
      {
        title: t("PersonalDataTitle1"),
      },
      {
        title: t("PersonalDataTitle2"),
      },
      {
        title: t("PersonalDataTitle3"),
      },
      {
        title: t("PersonalDataTitle4"),
      },
    ],
    shareInformation: [
      {
        title: t("ShareInfo1"),
      },
      {
        title: t("ShareInfo2"),
      },
      {
        title: t("ShareInfo3"),
      },
      {
        title: t("ShareInfo4"),
      },
      {
        title: t("ShareInfo5"),
      },
      {
        title: t("ShareInfo6"),
      },
      {
        title: t("ShareInfo7"),
      },
    ],
    GDPRPrivacyList: [
      {
        title: t("GDPRTitle1"),
      },
      {
        title: t("GDPRTitle2"),
      },
      {
        title: t("GDPRTitle3"),
      },
      {
        title: t("GDPRTitle4"),
      },
      {
        title: t("GDPRTitle5"),
      },
      {
        title: t("GDPRTitle6"),
      },
    ],
    rightsUnderGDPRList: [
      {
        title: t("UnderGDPR1"),
      },
      {
        title: t("UnderGDPR2"),
      },
      {
        title: t("UnderGDPR3"),
      },
      {
        title: t("UnderGDPR4"),
      },
      {
        title: t("UnderGDPR5"),
      },
      {
        title: t("UnderGDPR6"),
      },
    ],
    CCPAPrivacyList: [
      {
        title: t("CCPAPrivacy1"),
      },
      {
        title: t("CCPAPrivacy2"),
      },
      {
        title: t("CCPAPrivacy3"),
      },
      {
        title: t("CCPAPrivacy4"),
      },
    ],
    commercialPurposeList: [
      {
        title: t("CommercialPurpose1"),
      },
      {
        title: t("CommercialPurpose2"),
      },
      {
        title: t("CommercialPurpose3"),
      },
      {
        title: t("CommercialPurpose4"),
      },
      {
        title: t("CommercialPurpose5"),
      },
      {
        title: t("CommercialPurpose6"),
      },
      {
        title: t("CommercialPurpose7"),
      },
    ],
    rightsUnderCCPA: [
      {
        title: t("rightsUnderCCPA1"),
      },
      {
        title: t("rightsUnderCCPA2"),
      },
      {
        title: t("rightsUnderCCPA3"),
      },
      {
        title: t("rightsUnderCCPA4"),
      },
      {
        title: t("rightsUnderCCPA5"),
      },
      {
        title: t("rightsUnderCCPA6"),
      },
      {
        title: t("rightsUnderCCPA7"),
      },
      {
        title: t("rightsUnderCCPA8"),
      },
      {
        title: t("rightsUnderCCPA9"),
      },
      {
        title: t("rightsUnderCCPA10"),
      },
      {
        title: t("rightsUnderCCPA11"),
      },
      {
        title: t("rightsUnderCCPA12"),
      },
      {
        title: t("rightsUnderCCPA13"),
      },
      {
        title: t("rightsUnderCCPA14"),
      },
      {
        title: t("rightsUnderCCPA15"),
      },
      {
        title: t("rightsUnderCCPA16"),
      },
      {
        title: t("rightsUnderCCPA17"),
      },
      {
        title: t("rightsUnderCCPA18"),
      },
      {
        title: t("rightsUnderCCPA19"),
      },
      {
        title: t("rightsUnderCCPA20"),
      },
      {
        title: t("rightsUnderCCPA21"),
      },
      {
        title: t("rightsUnderCCPA22"),
      },
      {
        title: t("rightsUnderCCPA23"),
      },
      {
        title: t("rightsUnderCCPA24"),
      },
      {
        title: t("rightsUnderCCPA25"),
      },
      {
        title: t("rightsUnderCCPA26"),
      },
    ],
  };

  return lists[listKey];
};
export default PrivacyPolicy;
