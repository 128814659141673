import React from "react";

const CardSubscription = ({ children, padding }) => {
  return (
    <div
      style={{ padding: padding ? padding : "" }}
      className="p-[30px] border border-[#DFE4EC] rounded-[10px] w-full"
    >
      {children}
    </div>
  );
};

export default CardSubscription;
