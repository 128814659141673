import React, { useEffect, useMemo, useState } from "react";
import { bannerDescription } from "../../Pricing/jsons";
import Header from "../Components/Header";
import ModalBaseLayout from "../Components/Modal";
import GetDiscount from "../Components/Subscription/GetDiscount";
import { AddBanner } from "../Pages/Subscriptions/AdBanner";
import Sidebar from "./Sidebar";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { cn } from "../Components/utils";

const ExeLayout = ({ children, profile_picture, initials }) => {
  const isOfferAvailable = true;
  const {
    i18n: { language },
  } = useTranslation();
  const [isDiscountModal, setIsDiscountModal] = useState(false);
  const openDiscountModal = () => setIsDiscountModal(true);
  const closeDiscountModal = () => setIsDiscountModal(false);
  const dummyData = {
    is_discount: true,
    discount: 50,
  };

  const [discountData, setDiscountData] = useState(undefined);

  // const getDiscountData = async () => {
  //   try {
  //     const response = await getDiscount();
  //     setDiscountData(response);
  //   } catch (err) {
  //     console.log("error", err);
  //   }
  // };

  // useEffect(() => getDiscountData(), []);

  const isArabic = language === "ar";

  return (
    <>
      <div id="dashboard-root" className="w-full m-auto flex h-screen">
        <div className={cn("w-[255px]",isArabic ? "border-l border-l-LightStrokPrimary" :"border-r border-r-LightStrokPrimary")}>
          <Sidebar isArabic={isArabic} />
        </div>
        <div
          id="content-main"
          className="w-[calc(100%-254px)] max-w-[1190px] m-auto"
        >
          {discountData?.is_discount && (
            <AddBanner
              className=""
              title={`<span>
                  GET <br /> ${discountData.discount}% OFF
                </span>`}
              description={bannerDescription}
              onClick={openDiscountModal}
            />
          )}
          <Header
            title={t("account_setting")}
            Initials={initials}
            profile_picture={profile_picture}
          />
          <div
            style={{
              height: discountData?.is_discount
                ? "calc(100vh - 200px)"
                : "calc(100vh - 80px)",
            }}
            className="overflow-y-scroll"
          >
            {children}
          </div>
        </div>
      </div>

      {/* isDiscountModal */}
      <ModalBaseLayout
        isPreventOutsideClick
        overflow={"hidden"}
        title=""
        isOpen={isDiscountModal}
        setClose={closeDiscountModal}
      >
        <GetDiscount setClose={closeDiscountModal} />
      </ModalBaseLayout>
    </>
  );
};

export default ExeLayout;

// import React from "react";
// import Sidebar from "./Sidebar";
// import Header from "../Components/Header";

// const ExeLayout = ({ children, offerSection, profile_picture, initials }) => {
//   return (
//     <>
//       <div className="w-full m-auto flex h-screen">
//         <div className="w-[254px] border-r border-r-LightStrokPrimary">
//           <Sidebar />
//         </div>
//         {/* <div className="w-[calc(100%-254px)] max-w-[1190px] m-auto border-r border-r-LightStrokPrimary"> */}
//         <div id="content-main" className="w-full">
//           {offerSection && offerSection}
//           <div className="w-[calc(100%-254px)] max-w-[1190px] m-auto relative">
//             <Header
//               title="Account Settings"
//               Initials={initials}
//               profile_picture={profile_picture}
//             />

//             {children}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ExeLayout;
