import React from 'react';
import SlowDownComp from './SlowDownComp';
import MasterImg from '../../assets/images/Extension/Guide.svg';
import ActionwidgetImg from '../../assets/images/Extension/ActionWidget.svg';
import ActionwidgetImgAR from '../../assets/images/Extension/ActionWidgetAR.svg';
import { useTranslation } from 'react-i18next';

const FeedbackReason = ({
  formState,
  checkedValue,
  reasonText,
  handleChangeText,
}) => {
  // const [mode, setMode] = useState('in-out');
  const { t,i18n } = useTranslation();
  return (
    <div className={`Div2 absolute ${i18n.language === 'en' ? checkedValue ? 'slide-in' : 'hidden': checkedValue ? 'slide-in2' : 'hidden'}`}>
      {/* <div className={`Div2 ${checkedValue ? 'slide-in' : 'hidden'}`}> */}
      {formState.slow_down_pc && checkedValue === 'slow_down_pc' && (
        <SlowDownComp
          inputValue={reasonText.slow_down_pc}
          handleChangeText={handleChangeText}
          titleText={t('WorkAround')}
          text={t('HoldTight')}
          name="slow_down_pc"
          placeholderT={t('ProvideDetails')}
          buttonText={t('ContinueToResala')}
          isInput
        />
      )}
      {formState.not_working && checkedValue === 'not_working' && (
        <SlowDownComp
          inputValue={reasonText.not_working}
          handleChangeText={handleChangeText}
          titleText={t('PreferToUse')}
          text={t('HoldTight')}
          name="not_working"
          placeholderT={t('WebsiteLink')}
          buttonText={t('ContinueToResala')}
          isInput
        />
      )}
      {formState.donotknow && checkedValue === 'donotknow' && (
        <SlowDownComp
          inputValue={reasonText.slow_down_pc}
          handleChangeText={handleChangeText}
          titleText={t('MasterInIt')}
          isImage
          buttonWidth="274px"
          image={MasterImg}
          text={t('CheckGuide')}
          buttonText={t('BoostEfficiency')}
        />
      )}
      {formState.difficult2use && checkedValue === 'difficult2use' && (
        <SlowDownComp
          inputValue={reasonText.difficult2use}
          handleChangeText={handleChangeText}
          titleText={t('QuickAction')}
          isImage
          buttonWidth="254px"
          image={i18n.language === 'en' ?ActionwidgetImg : ActionwidgetImgAR}
          text={t('TurnOnWidget')}
          buttonText={t('ContinueToResala')}
        />
      )}
      {formState.is_not_free && checkedValue === 'is_not_free' && (
        <SlowDownComp
          inputValue={reasonText.is_not_free}
          handleChangeText={handleChangeText}
          titleText={t('FreeServices')}
          isNotFree
          buttonWidth="188px"
          buttonText={t('TryFreeServices')}
        />
      )}
      {formState.queries_not_enough &&
        checkedValue === 'queries_not_enough' && (
          <SlowDownComp
            inputValue={reasonText.queries_not_enough}
            handleChangeText={handleChangeText}
            titleText={t('AllQueries')}
            isNotFree
            isNotEnough
            buttonWidth="186px"
            buttonText={t('GetFQueries')}
          />
        )}
      {formState.price_is_more && checkedValue === 'price_is_more' && (
        <SlowDownComp
          inputValue={reasonText.price_is_more}
          handleChangeText={handleChangeText}
          titleText={t('Freeservices')}
          isNotFree
          buttonWidth="188px"
          buttonText={t('TryFreeServices')}
        />
      )}
      {formState.product_manager && checkedValue === 'product_manager' && (
        <SlowDownComp
          inputValue={reasonText.product_manager}
          handleChangeText={handleChangeText}
          titleText={t('LetsVent')}
          name="product_manager"
          placeholderT={t('DeliveredTo')}
          buttonText={t('ContinueToResala')}
          isInput
        />
      )}
      {formState.find_better_tool && checkedValue === 'find_better_tool' && (
        <SlowDownComp
          inputValue={reasonText.find_better_tool}
          handleChangeText={handleChangeText}
          titleText={t('WhatIsPreferred')}
          text={t('striveTo')}
          name="find_better_tool"
          placeholderT={t('NameofAI')}
          buttonText={t('ContinueToResala')}
          isInput
        />
      )}
      {formState.privacy_security_concern &&
        checkedValue === 'privacy_security_concern' && (
          <SlowDownComp
            inputValue={reasonText.privacy_security_concern}
            handleChangeText={handleChangeText}
            titleText={t('CareDeeply')}
            text={t('RequestPermissions')}
            name="privacy_security_concern"
            buttonText={t('ContinueToResala')}
          />
        )}
    </div>
  );
};

export default FeedbackReason;
