import axios from "axios";

import { commonHeaders } from "./apis";
import { BASE_URL } from "../config";

export const uninstallForm = async (payload) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/web/uninstall_feedback`,
      payload,
      {
        headers: { ...commonHeaders },
      }
    );
    return res.data;
  } catch (error) {
    return error?.response?.data || "Something went wrong";
  }
};
