import { t } from "i18next";
import {
  ClockIcon,
  DashIcon,
  Included,
  NotIncluded,
} from "../../Components/QueryTable";
import CustomTooltip from "../Extension/Components/Tooltip/Tooltip";

export const pricingOptions = [
  { id: 1, name: "MonthlyToggle", val: "monthly" },
  { id: 2, name: "YearlyToggle", val: "yearly" },
];

export const planOptions = [
  { id: 1, name: t("MonthlyToggle"), val: "monthly" },
  { id: 2, name: t("YearlyToggle"), val: "yearly" },
  { id: 3, name: t("YearlyToggle"), val: "yearly" },
  { id: 4, name: t("YearlyToggle"), val: "yearly" },
];

export const plans = [
  {
    id: 1,
    name: t("Free"),
    queries: "50",
    originalPrice: "19",
    discountedPrice: "0",
    billingCycle: "(Billed Yearly)",
    discount: "50%",
  },
  {
    id: 2,
    name: t("Start"),
    queries: "5000",
    originalPrice: "9",
    discountedPrice: "6.75",
    billingCycle: "(Billed Yearly)",
    discount: "25%",
  },
  {
    id: 3,
    name: t("Pro"),
    queries: "15000",
    originalPrice: "9",
    discountedPrice: "9.75",
    billingCycle: "(Billed Yearly)",
    discount: "50%",
  },
  {
    id: 4,
    name: t("Unlimited"),
    queries: "Unlimited",
    originalPrice: "39",
    discountedPrice: "23.75",
    billingCycle: "(Billed Yearly)",
    discount: "60%",
  },
];

export const featuresData = [
  {
    feature: "AdvancedQueries",
    // feature: "Advanced Queries",
    free: "10",
    start: "200",
    pro: "600",
    unlimited: "Unlimited",
  },
  {
    feature: "Images",
    // feature: "Images",
    free: <DashIcon />,
    start: "200",
    pro: "400",
    unlimited: "600",
  },
];

export const features2 = [
  {
    feature: "SocialInteractions",
    // feature: "Chat Bot",
    free: (
      <CustomTooltip
        maxWidth="430px"
        place="top"
        id="PendigIcon1"
        content={`<div class="font-normal text-[12px] leading-[18px] text-LightPrimary"> ${t(
          "limitedQuery"
        )} </div>`}
      >
        <button id="PendigIcon1" className="pendingIcon">
          <ClockIcon />
        </button>
      </CustomTooltip>
    ),
    start: <Included />,
    pro: <Included />,
    unlimited: <Included />,
  },
  {
    feature: "ChatWithImages",
    // feature: "Chat with Image",
    free: (
      <CustomTooltip
        maxWidth="430px"
        place="top"
        id="PendigIcon2"
        content={`<div class="font-normal text-[12px] leading-[18px] text-LightPrimary"> ${t(
          "limitedQuery"
        )} </div>`}
      >
        <button id="PendigIcon2" className="pendingIcon">
          <ClockIcon />
        </button>
      </CustomTooltip>
    ),
    start: <Included />,
    pro: <Included />,
    unlimited: <Included />,
  },
  {
    feature: "ChatWithDocuments",
    // feature: "Chat with Document",
    free: (
      <CustomTooltip
        maxWidth="430px"
        place="top"
        id="PendigIcon3"
        content={`<div class="font-normal text-[12px] leading-[18px] text-LightPrimary"> ${t(
          "limitedQuery"
        )} </div>`}
      >
        <button id="PendigIcon3" className="pendingIcon">
          <ClockIcon />
        </button>
      </CustomTooltip>
    ),
    start: <Included />,
    pro: <Included />,
    unlimited: <Included />,
  },
  {
    feature: "Real-timeWebAccess",
    // feature: "Real-time Web Access",
    free: (
      <CustomTooltip
        maxWidth="430px"
        place="top"
        id="PendigIcon4"
        content={`<div class="font-normal text-[12px] leading-[18px] text-LightPrimary"> ${t(
          "limitedQuery"
        )} </div>`}
      >
        <button id="PendigIcon4" className="pendingIcon">
          <ClockIcon />
        </button>
      </CustomTooltip>
    ),
    start: <Included />,
    pro: <Included />,
    unlimited: <Included />,
  },
];

export const featues3 = [
  {
    feature: "SummarizeWebpages",
    // feature: "Summarize Webpages",
    free: (
      <CustomTooltip
        maxWidth="430px"
        place="top"
        id="PendigIcon5"
        content={`<div class="font-normal text-[12px] leading-[18px] text-LightPrimary"> ${t(
          "limitedQuery"
        )} </div>`}
      >
        <button id="PendigIcon5" className="pendingIcon">
          <ClockIcon />
        </button>
      </CustomTooltip>
    ),
    start: <Included />,
    pro: <Included />,
    unlimited: <Included />,
  },
  {
    feature: "SummarizeVideos",
    // feature: "Summarize Videos",
    free: (
      <CustomTooltip
        maxWidth="430px"
        place="top"
        id="PendigIcon6"
        content={`<div class="font-normal text-[12px] leading-[18px] text-LightPrimary"> ${t(
          "limitedQuery"
        )} </div>`}
      >
        <button id="PendigIcon6" className="pendingIcon">
          <ClockIcon />
        </button>
      </CustomTooltip>
    ),
    start: <Included />,
    pro: <Included />,
    unlimited: <Included />,
  },
];

export const features4 = [
  // {
  //   feature: "EmailReply",
  //   // feature: "Email Reply",
  //   free: <Included />,
  //   start: <Included />,
  //   pro: <Included />,
  //   unlimited: <Included />,
  // },
  {
    feature: "QuickReply",
    // feature: "Quick Reply",
    free: <Included />,
    start: <Included />,
    pro: <Included />,
    unlimited: <Included />,
  },
  {
    feature: "QuickCompose",
    // feature: "Quick Compose",
    free: <Included />,
    start: <Included />,
    pro: <Included />,
    unlimited: <Included />,
  },
  {
    feature: "Grammar&Spelling",
    // feature: "Grammar & Spelling",
    free: <Included />,
    start: <Included />,
    pro: <Included />,
    unlimited: <Included />,
  },
  {
    feature: "Translation",
    // feature: "Translation",
    free: <Included />,
    start: <Included />,
    pro: <Included />,
    unlimited: <Included />,
  },
];

export const features5 = [
  {
    feature: "Facebook",
    // feature: "Facebook",
    free: <Included />,
    start: <Included />,
    pro: <Included />,
    unlimited: <Included />,
  },
  {
    feature: "LinkedIn",
    // feature: "LinkedIn",
    free: <Included />,
    start: <Included />,
    pro: <Included />,
    unlimited: <Included />,
  },
  {
    feature: "Twitter",
    // feature: "Twitter",
    free: <Included />,
    start: <Included />,
    pro: <Included />,
    unlimited: <Included />,
  },
];

export const features6 = [
  {
    feature: "AccessToLatestFeatures",
    // feature: "Access to latest features",
    free: <NotIncluded />,
    start: <Included />,
    pro: <Included />,
    unlimited: <Included />,
  },
  {
    feature: "2XFasterResponseSpeed",
    // feature: "2X faster response speed",
    free: <NotIncluded />,
    start: <Included />,
    pro: <Included />,
    unlimited: <Included />,
  },
  {
    feature: "PriorityEmailSupport",
    free: <NotIncluded />,
    start: <Included />,
    pro: <Included />,
    unlimited: <Included />,
  },
];

export const SubPlans = {
  monthly: [
    {
      id: 1,
      name: t("Free"),
      queries: 50,
      discountedPrice: 0,
      billingCycle: t("BilledMonthly"),
    },
    {
      id: 2,
      name: t("Start"),
      queries: 5000,
      discountedPrice: 9,
      billingCycle: t("BilledMonthly"),
    },
    {
      id: 3,
      name: t("Pro"),
      queries: 15000,
      discountedPrice: 19,
      billingCycle: t("BilledMonthly"),
    },
    {
      id: 4,
      name: t("Unlimited"),
      queries: t("Unlimited"),
      discountedPrice: 39,
      billingCycle: t("BilledMonthly"),
    },
  ],
  yearly: [
    {
      id: 1,
      name: t("Free"),
      queries: 50,
      originalPrice: 0,
      discountedPrice: 0,
      discount: 0,
      billingCycle: t("BilledYearly"),
    },
    {
      id: 2,
      name: t("Start"),
      queries: 5000,
      originalPrice: 9,
      discountedPrice: 6.75,
      discount: 25,
      billingCycle: t("BilledYearly"),
    },
    {
      id: 3,
      name: t("Pro"),
      queries: 15000,
      originalPrice: 19,
      discountedPrice: 9.5,
      discount: 50,
      billingCycle: t("BilledYearly"),
    },
    {
      id: 4,
      name: t("Unlimited"),
      queries: t("Unlimited"),
      originalPrice: 39,
      discountedPrice: 23.4,
      discount: 40,
      billingCycle: t("BilledYearly"),
    },
  ],
};

export const bannerTitle = `<span>
GET <br /> 60% OFF
</span>`;

export const bannerDescription = `<span>
Dive into our vibrant rainbow of discounts! Explore an array of
savings <br />
for a limited time only! Don't miss out!
</span>`;
