import { useTranslation } from "react-i18next";
import { cn } from "../Components/utils";

export const Socialbutton = ({
  icon,
  text,
  onClick,
  disabled,
  className,
  ...rest
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  const isArabic = language === "ar";

  return (
    <button
      // dir={isArabic ? "rtl" : "ltr"}
      {...rest}
      onClick={onClick}
      disabled={disabled}
      className={cn(
        "px-[15px] py-[14px] text-[#6D77A0] border border-LightStrokPrimary flex gap-x-[8px] justify-start items-center w-full rounded-[6px]",
        className
      )}
    >
      {icon}
      <p className="text-[14px] leading-[16px] ">{text}</p>
    </button>
  );
};

export const ResalaLogo = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="134"
    height="40"
    viewBox="0 0 134 40"
    fill="none"
  >
    <g clipPath="url(#clip0_5341_77208)">
      <path
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill="#1779F2"
      />
      <path
        d="M23.1064 22.0028L21.7529 20.6494L20.3995 22.0028L21.7529 23.3563L23.1064 22.0028Z"
        fill="white"
      />
      <path
        d="M20.4012 22.0027L19.7217 21.3232L19.0422 22.0027L19.7217 22.6822L20.4012 22.0027Z"
        fill="white"
      />
      <path
        d="M32.2734 29.4922L30.625 31.8516C26.8828 31.7344 26.1172 28.7656 25.375 25.8906C24.9687 24.3203 24.5547 22.7422 23.6172 22L23.7578 21.9609C25.8516 21.2969 26.1562 19.8281 26.1562 18.4531C26.1562 15.9219 23.8438 14.0781 21.5781 12.6094L20.8984 12.1719L20.2266 11.7578C20.1328 11.7031 20.0391 11.6484 19.9531 11.5938C19.8906 11.5547 19.8281 11.5156 19.7656 11.4766C19.7031 11.4375 19.6406 11.3984 19.5781 11.3594C19.3438 11.1797 19.1016 11.0078 18.8438 10.8281C18.3594 10.5078 17.9141 10.1562 17.5078 9.77344C16.9141 9.22656 16.6172 8.64844 16.6172 8.04688C16.6172 7.65625 16.7266 7.29687 16.9375 6.96094C17.1484 6.625 17.4141 6.32031 17.7188 6.03906C17.7266 6.03125 17.7344 6.03125 17.7344 6.02344C18.0313 5.75 18.3359 5.49219 18.6484 5.24219C18.8203 5.10937 18.9844 4.98438 19.1328 4.85938L19.1016 4.91406C18.9297 5.11719 18.7656 5.33594 18.6172 5.57813C18.4609 5.82031 18.3906 6.07813 18.3906 6.35156C18.3906 6.78125 18.6641 7.23437 19.2031 7.70312C19.6172 8.07031 20.1172 8.45312 20.7188 8.875C20.8359 8.96094 20.9531 9.03906 21.0781 9.13281C21.3516 9.29688 21.6328 9.46875 21.9141 9.63281C21.9922 9.67969 22.0703 9.72656 22.1484 9.76562C22.2188 9.8125 22.2969 9.85156 22.3594 9.89062C25.2891 11.6016 28.6016 13.5469 28.6016 16.875C28.6016 19.6016 27.0703 21.2812 23.9141 21.9922C26.875 22.6562 27.75 24.4531 28.6094 26.1953C29.3516 27.7109 30.1172 29.2656 32.2734 29.4922Z"
        fill="white"
      />
      <path
        d="M14.9531 30.6875L13.7812 31.8516L12.6172 30.6875L13.6719 29.6328V22.5469L13.1641 22.0313L13.6719 21.5234V14.6094L13.8906 14.1719V21.5234L14.3984 22.0313L13.8906 22.5469V29.6328L14.9531 30.6875Z"
        fill="white"
      />
      <path
        d="M18.2578 16.3594V23.1875C18.2578 23.25 18.2578 23.3047 18.25 23.3672C17.9844 25.9062 16.4062 28.0547 14.2031 29.125V27.7422C14.2891 27.6172 14.3672 27.4922 14.4375 27.3594C14.4453 27.3516 14.4531 27.3438 14.4531 27.3359C14.5547 27.1484 14.6562 26.9609 14.7422 26.75C14.7422 26.7422 14.75 26.7344 14.75 26.7266C14.8438 26.5156 14.9297 26.3047 15 26.0781C15.0156 26.0391 15.0234 26.0078 15.0312 25.9766C15.0469 25.9297 15.0547 25.8906 15.0625 25.8516C15.2344 25.2578 15.3281 24.6328 15.3281 23.9922C15.3281 23.9141 15.3281 23.8438 15.3203 23.7656V23.75L15.3359 23.3594C15.3438 23.2266 15.3437 23.0859 15.3437 22.9531V15.5625L15.5156 15.4688L17.5547 14.3672L18.25 14.0234L18.2578 16.3594Z"
        fill="white"
      />
      <path
        d="M13.3516 29.3438V29.3594H13.3359C13.3437 29.3594 13.3516 29.3516 13.3516 29.3438Z"
        fill="white"
      />
      <path
        d="M13.3516 28.5703V29.5469C13.3359 29.5547 13.3281 29.5625 13.3125 29.5781C13.1875 29.6641 13.0469 29.7422 12.9141 29.8125C12.8984 29.8203 12.8906 29.8281 12.875 29.8281C12.8594 29.8359 12.8516 29.8438 12.8359 29.8438C12.3828 30.0547 11.8906 30.1719 11.3672 30.1719C9.35937 30.1719 7.72656 28.4297 7.72656 26.2812C7.72656 25.6172 7.88281 24.9844 8.16406 24.4375C8.1875 24.3828 8.21875 24.3359 8.25 24.2812C8.28125 24.2344 8.30469 24.1875 8.33594 24.1328C8.32031 24.1875 8.30469 24.2344 8.28906 24.2891C8.27344 24.3438 8.25781 24.3984 8.24219 24.4531C8.16406 24.7813 8.11719 25.1172 8.10938 25.4688C8.08594 27.5703 9.54687 29.2969 11.3594 29.3125H11.3906C11.8984 29.3125 12.3906 29.1797 12.8359 28.9219C12.8516 28.9141 12.8594 28.9062 12.875 28.8984C12.8906 28.8906 12.8984 28.8828 12.9141 28.875C13.0547 28.7891 13.1875 28.6953 13.3125 28.5859C13.3281 28.5938 13.3437 28.5859 13.3516 28.5703Z"
        fill="white"
      />
      <path
        d="M57.1875 29.5312L53.1797 22.4453H51.4609V29.5312H47.8125V11.3516H54.6406C56.0469 11.3516 57.2422 11.6016 58.2344 12.0938C59.2266 12.5859 59.9688 13.2578 60.4609 14.1016C60.9531 14.9453 61.2031 15.8828 61.2031 16.9297C61.2031 18.125 60.8594 19.2109 60.1641 20.1719C59.4687 21.1328 58.4375 21.7969 57.0625 22.1641L61.4062 29.5312H57.1875ZM51.4531 19.7109H54.5C55.4922 19.7109 56.2266 19.4688 56.7109 18.9922C57.1953 18.5156 57.4375 17.8516 57.4375 17C57.4375 16.1641 57.1953 15.5234 56.7109 15.0625C56.2266 14.6016 55.4844 14.375 54.5 14.375H51.4531V19.7109Z"
        fill="#848182"
      />
      <path
        d="M77.8203 23.4063H67.2656C67.3516 24.4453 67.7188 25.2656 68.3594 25.8516C69 26.4453 69.7891 26.7344 70.7266 26.7344C72.0781 26.7344 73.0469 26.1562 73.6172 24.9922H77.5469C77.1328 26.3828 76.3281 27.5234 75.1484 28.4141C73.9688 29.3125 72.5156 29.7578 70.7969 29.7578C69.4063 29.7578 68.1641 29.4531 67.0625 28.8359C65.9609 28.2188 65.1016 27.3438 64.4844 26.2188C63.8672 25.0938 63.5625 23.7891 63.5625 22.3125C63.5625 20.8203 63.8672 19.5078 64.4766 18.3828C65.0859 17.2578 65.9375 16.3828 67.0313 15.7812C68.125 15.1719 69.3828 14.8672 70.8047 14.8672C72.1797 14.8672 73.4063 15.1641 74.4922 15.75C75.5781 16.3438 76.4219 17.1797 77.0156 18.2656C77.6172 19.3516 77.9141 20.5938 77.9141 22C77.9219 22.5234 77.8828 22.9922 77.8203 23.4063ZM74.1406 20.9609C74.125 20.0234 73.7813 19.2734 73.125 18.7109C72.4609 18.1484 71.6563 17.8672 70.7031 17.8672C69.7969 17.8672 69.0391 18.1406 68.4219 18.6875C67.8047 19.2344 67.4297 19.9922 67.2891 20.9688H74.1406V20.9609Z"
        fill="#848182"
      />
      <path
        d="M82.9219 29.125C81.9844 28.7031 81.2422 28.125 80.6953 27.3906C80.1484 26.6563 79.8516 25.8516 79.7969 24.9688H83.4688C83.5391 25.5234 83.8125 25.9844 84.2891 26.3516C84.7656 26.7187 85.3594 26.8984 86.0703 26.8984C86.7656 26.8984 87.3047 26.7578 87.6953 26.4844C88.0859 26.2031 88.2812 25.8516 88.2812 25.4141C88.2812 24.9453 88.0391 24.5938 87.5625 24.3594C87.0859 24.125 86.3281 23.8672 85.2812 23.5938C84.2031 23.3359 83.3203 23.0625 82.6406 22.7891C81.9531 22.5078 81.3672 22.0859 80.8672 21.5156C80.375 20.9453 80.125 20.1719 80.125 19.1953C80.125 18.3984 80.3516 17.6641 80.8125 17.0078C81.2734 16.3516 81.9297 15.8281 82.7891 15.4453C83.6484 15.0625 84.6562 14.875 85.8203 14.875C87.5391 14.875 88.9141 15.3047 89.9375 16.1641C90.9609 17.0234 91.5234 18.1797 91.6328 19.6406H88.1406C88.0859 19.0703 87.8516 18.6094 87.4219 18.2734C87 17.9375 86.4297 17.7656 85.7187 17.7656C85.0547 17.7656 84.5469 17.8906 84.1953 18.1328C83.8359 18.375 83.6641 18.7188 83.6641 19.1484C83.6641 19.6328 83.9062 20.0078 84.3906 20.2578C84.875 20.5078 85.6328 20.7656 86.6562 21.0234C87.6953 21.2813 88.5547 21.5547 89.2344 21.8281C89.9141 22.1094 90.5 22.5391 90.9922 23.1172C91.4844 23.7031 91.7422 24.4688 91.7578 25.4219C91.7578 26.2578 91.5312 27 91.0703 27.6641C90.6094 28.3281 89.9531 28.8438 89.0938 29.2109C88.2344 29.5781 87.2344 29.7734 86.0859 29.7734C84.9219 29.7656 83.8594 29.5547 82.9219 29.125Z"
        fill="#848182"
      />
      <path
        d="M94.7109 18.3828C95.2891 17.2578 96.0859 16.3828 97.0781 15.7812C98.0781 15.1719 99.1953 14.8672 100.422 14.8672C101.5 14.8672 102.437 15.0859 103.25 15.5156C104.055 15.9531 104.703 16.5 105.188 17.1563V15.1016H108.859V29.5312H105.188V27.4219C104.719 28.1016 104.07 28.6562 103.25 29.1016C102.422 29.5469 101.477 29.7656 100.398 29.7656C99.1797 29.7656 98.0781 29.4531 97.0781 28.8281C96.0781 28.2031 95.2891 27.3203 94.7109 26.1875C94.125 25.0469 93.8359 23.7422 93.8359 22.2656C93.8359 20.8047 94.125 19.5078 94.7109 18.3828ZM104.672 20.0391C104.328 19.4062 103.852 18.9219 103.266 18.5781C102.672 18.2422 102.039 18.0703 101.367 18.0703C100.688 18.0703 100.063 18.2344 99.4922 18.5625C98.9219 18.8906 98.4531 19.375 98.1016 20.0078C97.7422 20.6406 97.5703 21.3906 97.5703 22.2578C97.5703 23.125 97.75 23.8828 98.1016 24.5391C98.4609 25.1875 98.9297 25.6875 99.5078 26.0391C100.086 26.3828 100.711 26.5625 101.367 26.5625C102.047 26.5625 102.68 26.3906 103.266 26.0547C103.859 25.7188 104.328 25.2266 104.672 24.5937C105.016 23.9609 105.195 23.2031 105.195 22.3125C105.195 21.4297 105.023 20.6719 104.672 20.0391Z"
        fill="#848182"
      />
      <path d="M116.086 10.25V29.5312H112.438V10.25H116.086Z" fill="#848182" />
      <path
        d="M119.609 18.3828C120.187 17.2578 120.984 16.3828 121.977 15.7812C122.977 15.1719 124.094 14.8672 125.32 14.8672C126.398 14.8672 127.336 15.0859 128.148 15.5156C128.953 15.9531 129.602 16.5 130.086 17.1563V15.1016H133.758V29.5312H130.086V27.4219C129.617 28.1016 128.969 28.6562 128.148 29.1016C127.32 29.5469 126.375 29.7656 125.297 29.7656C124.078 29.7656 122.977 29.4531 121.977 28.8281C120.977 28.2031 120.187 27.3203 119.609 26.1875C119.023 25.0469 118.734 23.7422 118.734 22.2656C118.742 20.8047 119.031 19.5078 119.609 18.3828ZM129.578 20.0391C129.234 19.4062 128.758 18.9219 128.172 18.5781C127.578 18.2422 126.945 18.0703 126.273 18.0703C125.594 18.0703 124.969 18.2344 124.398 18.5625C123.828 18.8906 123.359 19.375 123.008 20.0078C122.648 20.6406 122.477 21.3906 122.477 22.2578C122.477 23.125 122.656 23.8828 123.008 24.5391C123.367 25.1875 123.836 25.6875 124.414 26.0391C124.992 26.3828 125.617 26.5625 126.273 26.5625C126.953 26.5625 127.586 26.3906 128.172 26.0547C128.766 25.7188 129.234 25.2266 129.578 24.5937C129.922 23.9609 130.102 23.2031 130.102 22.3125C130.094 21.4297 129.922 20.6719 129.578 20.0391Z"
        fill="#848182"
      />
    </g>
    <defs>
      <clipPath id="clip0_5341_77208">
        <rect width="133.773" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const GoogleIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
      fill="#FFC107"
    />
    <path
      d="M3.15332 7.3455L6.43882 9.755C7.32782 7.554 9.48082 6 12.0003 6C13.5298 6 14.9213 6.577 15.9808 7.5195L18.8093 4.691C17.0233 3.0265 14.6343 2 12.0003 2C8.15932 2 4.82832 4.1685 3.15332 7.3455Z"
      fill="#FF3D00"
    />
    <path
      d="M12.0002 22.0003C14.5832 22.0003 16.9302 21.0118 18.7047 19.4043L15.6097 16.7853C14.5719 17.5745 13.3039 18.0014 12.0002 18.0003C9.39916 18.0003 7.19066 16.3418 6.35866 14.0273L3.09766 16.5398C4.75266 19.7783 8.11366 22.0003 12.0002 22.0003Z"
      fill="#4CAF50"
    />
    <path
      d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
      fill="#1976D2"
    />
  </svg>
);

export const FacebookIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_5341_77256)">
      <path
        d="M24 12C24 5.37263 18.6274 0 12 0C5.37262 0 0 5.37253 0 12C0 17.9895 4.38825 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6575 4.6875C15.9705 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.3398 7.875 13.875 8.80003 13.875 9.74906V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6117 22.954 24 17.9896 24 12Z"
        fill="#1877F2"
      />
      <path
        d="M16.6711 15.4688L17.2031 12H13.875V9.74906C13.875 8.79994 14.3399 7.875 15.8306 7.875H17.3438V4.92188C17.3438 4.92188 15.9705 4.6875 14.6575 4.6875C11.9166 4.6875 10.125 6.34875 10.125 9.35625V12H7.07812V15.4688H10.125V23.8542C10.7453 23.9514 11.3722 24.0002 12 24C12.6278 24.0002 13.2547 23.9514 13.875 23.8542V15.4688H16.6711Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5341_77256">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const AppleIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M16.0477 3.50877C16.4635 3.02951 16.7806 2.47282 16.9807 1.87069C17.1808 1.26855 17.2599 0.632818 17.2136 0C15.937 0.103049 14.752 0.703465 13.9139 1.67189C13.5125 2.13603 13.2083 2.67599 13.0194 3.25979C12.8305 3.84359 12.7606 4.45937 12.8139 5.07066C13.4366 5.07585 14.0522 4.93768 14.613 4.66683C15.1737 4.39599 15.6646 3.99975 16.0477 3.50877ZM18.8195 11.6922C18.8269 10.8494 19.0489 10.0223 19.4646 9.28911C19.8803 8.55588 20.476 7.94066 21.1954 7.5015C20.7413 6.84731 20.1411 6.3079 19.4424 5.92605C18.7436 5.5442 17.9654 5.33041 17.1696 5.30165C15.4538 5.12566 13.8699 6.30258 12.9569 6.30258C12.044 6.30258 10.7571 5.32365 9.32716 5.34564C8.39237 5.37644 7.48151 5.64898 6.68343 6.1367C5.88535 6.62441 5.2273 7.31064 4.77347 8.12846C2.83759 11.4942 4.2785 16.4989 6.21437 19.2157C7.09431 20.5466 8.19424 22.0535 9.64614 21.9985C11.098 21.9435 11.571 21.0966 13.2539 21.0966C14.9368 21.0966 15.4538 21.9985 16.8837 21.9655C18.3136 21.9325 19.3255 20.6016 20.2494 19.2707C20.9039 18.3045 21.4152 17.2486 21.7673 16.1359C20.8956 15.7642 20.1519 15.1452 19.628 14.3555C19.1041 13.5658 18.8231 12.6399 18.8195 11.6922Z"
      fill="black"
    />
  </svg>
);

export const ResalaWhiteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <g clipPath="url(#clip0_5258_77284)">
      <path
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill="white"
      />
      <path
        d="M23.1061 22.0028L21.7527 20.6494L20.3993 22.0028L21.7527 23.3563L23.1061 22.0028Z"
        fill="#1678F2"
      />
      <path
        d="M20.4009 22.0027L19.7214 21.3232L19.042 22.0027L19.7214 22.6822L20.4009 22.0027Z"
        fill="#1678F2"
      />
      <path
        d="M32.2734 29.4922L30.625 31.8516C26.8828 31.7344 26.1172 28.7656 25.375 25.8906C24.9687 24.3203 24.5547 22.7422 23.6172 22L23.7578 21.9609C25.8516 21.2969 26.1562 19.8281 26.1562 18.4531C26.1562 15.9219 23.8438 14.0781 21.5781 12.6094L20.8984 12.1719L20.2266 11.7578C20.1328 11.7031 20.0391 11.6484 19.9531 11.5938C19.8906 11.5547 19.8281 11.5156 19.7656 11.4766C19.7031 11.4375 19.6406 11.3984 19.5781 11.3594C19.3438 11.1797 19.1016 11.0078 18.8438 10.8281C18.3594 10.5078 17.9141 10.1562 17.5078 9.77344C16.9141 9.22656 16.6172 8.64844 16.6172 8.04688C16.6172 7.65625 16.7266 7.29687 16.9375 6.96094C17.1484 6.625 17.4141 6.32031 17.7188 6.03906C17.7266 6.03125 17.7344 6.03125 17.7344 6.02344C18.0313 5.75 18.3359 5.49219 18.6484 5.24219C18.8203 5.10937 18.9844 4.98438 19.1328 4.85938L19.1016 4.91406C18.9297 5.11719 18.7656 5.33594 18.6172 5.57813C18.4609 5.82031 18.3906 6.07813 18.3906 6.35156C18.3906 6.78125 18.6641 7.23437 19.2031 7.70312C19.6172 8.07031 20.1172 8.45312 20.7188 8.875C20.8359 8.96094 20.9531 9.03906 21.0781 9.13281C21.3516 9.29688 21.6328 9.46875 21.9141 9.63281C21.9922 9.67969 22.0703 9.72656 22.1484 9.76562C22.2188 9.8125 22.2969 9.85156 22.3594 9.89062C25.2891 11.6016 28.6016 13.5469 28.6016 16.875C28.6016 19.6016 27.0703 21.2812 23.9141 21.9922C26.875 22.6562 27.75 24.4531 28.6094 26.1953C29.3516 27.7109 30.1172 29.2656 32.2734 29.4922Z"
        fill="#1678F2"
      />
      <path
        d="M14.9531 30.6875L13.7812 31.8516L12.6172 30.6875L13.6719 29.6328V22.5469L13.1641 22.0312L13.6719 21.5234V14.6094L13.8906 14.1719V21.5234L14.3984 22.0312L13.8906 22.5469V29.6328L14.9531 30.6875Z"
        fill="#1678F2"
      />
      <path
        d="M18.2578 16.3594V23.1875C18.2578 23.25 18.2578 23.3047 18.25 23.3672C17.9844 25.9062 16.4062 28.0547 14.2031 29.125V27.7422C14.2891 27.6172 14.3672 27.4922 14.4375 27.3594C14.4453 27.3516 14.4531 27.3437 14.4531 27.3359C14.5547 27.1484 14.6562 26.9609 14.7422 26.75C14.7422 26.7422 14.75 26.7344 14.75 26.7266C14.8437 26.5156 14.9297 26.3047 15 26.0781C15.0156 26.0391 15.0234 26.0078 15.0312 25.9766C15.0469 25.9297 15.0547 25.8906 15.0625 25.8516C15.2344 25.2578 15.3281 24.6328 15.3281 23.9922C15.3281 23.9141 15.3281 23.8438 15.3203 23.7656V23.75L15.3359 23.3594C15.3437 23.2266 15.3437 23.0859 15.3437 22.9531V15.5625L15.5156 15.4687L17.5547 14.3672L18.25 14.0234L18.2578 16.3594Z"
        fill="#1678F2"
      />
      <path
        d="M13.3517 29.3438V29.3595H13.3359C13.3438 29.3595 13.3517 29.3516 13.3517 29.3438Z"
        fill="#1678F2"
      />
      <path
        d="M13.3516 28.5703V29.5469C13.3359 29.5547 13.3281 29.5625 13.3125 29.5781C13.1875 29.6641 13.0469 29.7422 12.9141 29.8125C12.8984 29.8203 12.8906 29.8281 12.875 29.8281C12.8594 29.8359 12.8516 29.8438 12.8359 29.8438C12.3828 30.0547 11.8906 30.1719 11.3672 30.1719C9.35937 30.1719 7.72656 28.4297 7.72656 26.2812C7.72656 25.6172 7.88281 24.9844 8.16406 24.4375C8.1875 24.3828 8.21875 24.3359 8.25 24.2812C8.28125 24.2344 8.30469 24.1875 8.33594 24.1328C8.32031 24.1875 8.30469 24.2344 8.28906 24.2891C8.27344 24.3438 8.25781 24.3984 8.24219 24.4531C8.16406 24.7813 8.11719 25.1172 8.10938 25.4688C8.08594 27.5703 9.54687 29.2969 11.3594 29.3125H11.3906C11.8984 29.3125 12.3906 29.1797 12.8359 28.9219C12.8516 28.9141 12.8594 28.9062 12.875 28.8984C12.8906 28.8906 12.8984 28.8828 12.9141 28.875C13.0547 28.7891 13.1875 28.6953 13.3125 28.5859C13.3281 28.5938 13.3437 28.5859 13.3516 28.5703Z"
        fill="#1678F2"
      />
    </g>
    <defs>
      <clipPath id="clip0_5258_77284">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const SettingIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0002 22.63C11.3302 22.63 10.6502 22.48 10.1202 22.17L4.62023 19C2.38023 17.49 2.24023 17.26 2.24023 14.89V9.11005C2.24023 6.74005 2.37023 6.51005 4.57023 5.02005L10.1102 1.82005C11.1602 1.21005 12.8102 1.21005 13.8602 1.82005L19.3802 5.00005C21.6202 6.51005 21.7602 6.74005 21.7602 9.11005V14.88C21.7602 17.25 21.6302 17.48 19.4302 18.97L13.8902 22.17C13.3502 22.48 12.6702 22.63 12.0002 22.63ZM12.0002 2.87005C11.5802 2.87005 11.1702 2.95005 10.8802 3.12005L5.38023 6.30005C3.75023 7.40005 3.75023 7.40005 3.75023 9.11005V14.88C3.75023 16.59 3.75023 16.59 5.42023 17.72L10.8802 20.8701C11.4702 21.2101 12.5402 21.2101 13.1302 20.8701L18.6302 17.6901C20.2502 16.59 20.2502 16.59 20.2502 14.88V9.11005C20.2502 7.40005 20.2502 7.40005 18.5802 6.27005L13.1202 3.12005C12.8302 2.95005 12.4202 2.87005 12.0002 2.87005Z"
      fill={color}
    />
    <path
      d="M12 15.75C9.93 15.75 8.25 14.07 8.25 12C8.25 9.93 9.93 8.25 12 8.25C14.07 8.25 15.75 9.93 15.75 12C15.75 14.07 14.07 15.75 12 15.75ZM12 9.75C10.76 9.75 9.75 10.76 9.75 12C9.75 13.24 10.76 14.25 12 14.25C13.24 14.25 14.25 13.24 14.25 12C14.25 10.76 13.24 9.75 12 9.75Z"
      fill={color}
    />
  </svg>
);

export const GearIcon = () => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="44" height="44" rx="22" fill="#F3F4F8" />
    <path
      d="M22 25C23.6569 25 25 23.6569 25 22C25 20.3431 23.6569 19 22 19C20.3431 19 19 20.3431 19 22C19 23.6569 20.3431 25 22 25Z"
      stroke="#6D77A0"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 22.8814V21.1214C12 20.0814 12.85 19.2214 13.9 19.2214C15.71 19.2214 16.45 17.9414 15.54 16.3714C15.02 15.4714 15.33 14.3014 16.24 13.7814L17.97 12.7914C18.76 12.3214 19.78 12.6014 20.25 13.3914L20.36 13.5814C21.26 15.1514 22.74 15.1514 23.65 13.5814L23.76 13.3914C24.23 12.6014 25.25 12.3214 26.04 12.7914L27.77 13.7814C28.68 14.3014 28.99 15.4714 28.47 16.3714C27.56 17.9414 28.3 19.2214 30.11 19.2214C31.15 19.2214 32.01 20.0714 32.01 21.1214V22.8814C32.01 23.9214 31.16 24.7814 30.11 24.7814C28.3 24.7814 27.56 26.0614 28.47 27.6314C28.99 28.5414 28.68 29.7014 27.77 30.2214L26.04 31.2114C25.25 31.6814 24.23 31.4014 23.76 30.6114L23.65 30.4214C22.75 28.8514 21.27 28.8514 20.36 30.4214L20.25 30.6114C19.78 31.4014 18.76 31.6814 17.97 31.2114L16.24 30.2214C15.33 29.7014 15.02 28.5314 15.54 27.6314C16.45 26.0614 15.71 24.7814 13.9 24.7814C12.85 24.7814 12 23.9214 12 22.8814Z"
      stroke="#6D77A0"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SubscriptionIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.42 11.75H16C15.59 11.75 15.25 11.41 15.25 11V4.01C15.25 3.27 15.54 2.58 16.06 2.06C16.58 1.54 17.27 1.25 18.01 1.25H18.02C19.27 1.26 20.45 1.75 21.35 2.64C22.25 3.55 22.74 4.75 22.74 6V8.42C22.75 10.41 21.41 11.75 19.42 11.75ZM16.75 10.25H19.42C20.58 10.25 21.25 9.58 21.25 8.42V6C21.25 5.14 20.91 4.32 20.3 3.7C19.69 3.1 18.87 2.76 18.02 2.75C18.02 2.75 18.02 2.75 18.01 2.75C17.68 2.75 17.36 2.88 17.12 3.12C16.88 3.36 16.75 3.67 16.75 4.01V10.25Z"
      fill={color}
    />
    <path
      d="M9 23.33C8.53 23.33 8.09 23.15 7.76 22.81L6.1 21.14C6.01 21.05 5.87 21.04 5.77 21.12L4.05 22.4C3.52 22.8 2.82 22.87 2.22 22.57C1.62 22.27 1.25 21.67 1.25 21V6C1.25 2.98 2.98 1.25 6 1.25H18C18.41 1.25 18.75 1.59 18.75 2C18.75 2.41 18.41 2.75 18 2.75C17.31 2.75 16.75 3.31 16.75 4V21C16.75 21.67 16.38 22.27 15.78 22.57C15.18 22.87 14.48 22.81 13.95 22.41L12.24 21.13C12.14 21.05 12 21.07 11.92 21.15L10.24 22.83C9.91 23.15 9.47 23.33 9 23.33ZM5.91 19.57C6.37 19.57 6.82 19.74 7.16 20.09L8.82 21.76C8.88 21.82 8.96 21.83 9 21.83C9.04 21.83 9.12 21.82 9.18 21.76L10.86 20.08C11.48 19.46 12.46 19.4 13.15 19.93L14.85 21.2C14.96 21.28 15.06 21.25 15.11 21.22C15.16 21.19 15.25 21.13 15.25 21V4C15.25 3.55 15.36 3.12 15.55 2.75H6C3.78 2.75 2.75 3.78 2.75 6V21C2.75 21.14 2.84 21.2 2.89 21.23C2.95 21.26 3.05 21.28 3.15 21.2L4.86 19.92C5.17 19.69 5.54 19.57 5.91 19.57Z"
      fill={color}
    />
    <path
      d="M12 13.7598H9C8.59 13.7598 8.25 13.4198 8.25 13.0098C8.25 12.5998 8.59 12.2598 9 12.2598H12C12.41 12.2598 12.75 12.5998 12.75 13.0098C12.75 13.4198 12.41 13.7598 12 13.7598Z"
      fill={color}
    />
    <path
      d="M12 9.75977H9C8.59 9.75977 8.25 9.41977 8.25 9.00977C8.25 8.59977 8.59 8.25977 9 8.25977H12C12.41 8.25977 12.75 8.59977 12.75 9.00977C12.75 9.41977 12.41 9.75977 12 9.75977Z"
      fill={color}
    />
    <path
      d="M5.9707 10.0098C5.4207 10.0098 4.9707 9.55977 4.9707 9.00977C4.9707 8.45977 5.4207 8.00977 5.9707 8.00977C6.5207 8.00977 6.9707 8.45977 6.9707 9.00977C6.9707 9.55977 6.5207 10.0098 5.9707 10.0098Z"
      fill={color}
    />
    <path
      d="M5.9707 14.0098C5.4207 14.0098 4.9707 13.5598 4.9707 13.0098C4.9707 12.4598 5.4207 12.0098 5.9707 12.0098C6.5207 12.0098 6.9707 12.4598 6.9707 13.0098C6.9707 13.5598 6.5207 14.0098 5.9707 14.0098Z"
      fill={color}
    />
  </svg>
);
export const ReferAndEarnIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4002 17.4201H10.8902C9.25016 17.4201 7.92016 16.0401 7.92016 14.3401C7.92016 13.9301 8.26016 13.5901 8.67016 13.5901C9.08016 13.5901 9.42016 13.9301 9.42016 14.3401C9.42016 15.2101 10.0802 15.9201 10.8902 15.9201H13.4002C14.0502 15.9201 14.5902 15.3401 14.5902 14.6401C14.5902 13.7701 14.2802 13.6001 13.7702 13.4201L9.74016 12.0001C8.96016 11.7301 7.91016 11.1501 7.91016 9.36008C7.91016 7.82008 9.12016 6.58008 10.6002 6.58008H13.1102C14.7502 6.58008 16.0802 7.96008 16.0802 9.66008C16.0802 10.0701 15.7402 10.4101 15.3302 10.4101C14.9202 10.4101 14.5802 10.0701 14.5802 9.66008C14.5802 8.79008 13.9202 8.08008 13.1102 8.08008H10.6002C9.95016 8.08008 9.41016 8.66008 9.41016 9.36008C9.41016 10.2301 9.72016 10.4001 10.2302 10.5801L14.2602 12.0001C15.0402 12.2701 16.0902 12.8501 16.0902 14.6401C16.0802 16.1701 14.8802 17.4201 13.4002 17.4201Z"
      fill={color}
    />
    <path
      d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V6C11.25 5.59 11.59 5.25 12 5.25C12.41 5.25 12.75 5.59 12.75 6V18C12.75 18.41 12.41 18.75 12 18.75Z"
      fill={color}
    />
    <path
      d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
      fill={color}
    />
  </svg>
);
export const HelpIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 22.3199C7.72 22.3199 7.42998 22.2499 7.16998 22.1099C6.59998 21.8099 6.25 21.2099 6.25 20.5699V19.15C3.23 18.84 1.25 16.6199 1.25 13.4399V7.43994C1.25 3.99994 3.56 1.68994 7 1.68994H17C20.44 1.68994 22.75 3.99994 22.75 7.43994V13.4399C22.75 16.8799 20.44 19.1899 17 19.1899H13.23L8.96997 22.03C8.67997 22.22 8.34 22.3199 8 22.3199ZM7 3.17993C4.42 3.17993 2.75 4.84993 2.75 7.42993V13.43C2.75 16.01 4.42 17.68 7 17.68C7.41 17.68 7.75 18.02 7.75 18.43V20.56C7.75 20.69 7.83 20.75 7.88 20.78C7.93001 20.81 8.03001 20.84 8.14001 20.77L12.59 17.81C12.71 17.73 12.86 17.68 13.01 17.68H17.01C19.59 17.68 21.26 16.01 21.26 13.43V7.42993C21.26 4.84993 19.59 3.17993 17.01 3.17993H7Z"
      fill={color}
    />
    <path
      d="M11.9998 12.1099C11.5898 12.1099 11.2498 11.7699 11.2498 11.3599V11.1499C11.2498 9.9899 12.0998 9.41989 12.4198 9.19989C12.7898 8.94989 12.9098 8.7799 12.9098 8.5199C12.9098 8.0199 12.4998 7.60986 11.9998 7.60986C11.4998 7.60986 11.0898 8.0199 11.0898 8.5199C11.0898 8.9299 10.7498 9.2699 10.3398 9.2699C9.92984 9.2699 9.58984 8.9299 9.58984 8.5199C9.58984 7.1899 10.6698 6.10986 11.9998 6.10986C13.3298 6.10986 14.4098 7.1899 14.4098 8.5199C14.4098 9.6599 13.5698 10.2299 13.2598 10.4399C12.8698 10.6999 12.7498 10.8699 12.7498 11.1499V11.3599C12.7498 11.7799 12.4098 12.1099 11.9998 12.1099Z"
      fill={color}
    />
    <path
      d="M12 14.6001C11.58 14.6001 11.25 14.2601 11.25 13.8501C11.25 13.4401 11.59 13.1001 12 13.1001C12.41 13.1001 12.75 13.4401 12.75 13.8501C12.75 14.2601 12.42 14.6001 12 14.6001Z"
      fill={color}
    />
  </svg>
);
export const LogOutIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2405 22.27H15.1105C10.6705 22.27 8.5305 20.52 8.1605 16.6C8.1205 16.19 8.4205 15.82 8.8405 15.78C9.2405 15.74 9.6205 16.05 9.6605 16.46C9.9505 19.6 11.4305 20.77 15.1205 20.77H15.2505C19.3205 20.77 20.7605 19.33 20.7605 15.26V8.73998C20.7605 4.66998 19.3205 3.22998 15.2505 3.22998H15.1205C11.4105 3.22998 9.9305 4.41998 9.6605 7.61998C9.6105 8.02998 9.2605 8.33998 8.8405 8.29998C8.4205 8.26998 8.1205 7.89998 8.1505 7.48998C8.4905 3.50998 10.6405 1.72998 15.1105 1.72998H15.2405C20.1505 1.72998 22.2505 3.82998 22.2505 8.73998V15.26C22.2505 20.17 20.1505 22.27 15.2405 22.27Z"
      fill={color}
    />
    <path
      d="M14.9991 12.75H3.61914C3.20914 12.75 2.86914 12.41 2.86914 12C2.86914 11.59 3.20914 11.25 3.61914 11.25H14.9991C15.4091 11.25 15.7491 11.59 15.7491 12C15.7491 12.41 15.4091 12.75 14.9991 12.75Z"
      fill={color}
    />
    <path
      d="M5.84945 16.0998C5.65945 16.0998 5.46945 16.0298 5.31945 15.8798L1.96945 12.5298C1.67945 12.2398 1.67945 11.7598 1.96945 11.4698L5.31945 8.11984C5.60945 7.82984 6.08945 7.82984 6.37945 8.11984C6.66945 8.40984 6.66945 8.88984 6.37945 9.17984L3.55945 11.9998L6.37945 14.8198C6.66945 15.1098 6.66945 15.5898 6.37945 15.8798C6.23945 16.0298 6.03945 16.0998 5.84945 16.0998Z"
      fill={color}
    />
  </svg>
);
export const ProfileIcon = (props) => (
  <svg
    {...props}
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="44" height="44" rx="22" fill="#F3F4F8" />
    <path
      d="M22 22C24.7614 22 27 19.7614 27 17C27 14.2386 24.7614 12 22 12C19.2386 12 17 14.2386 17 17C17 19.7614 19.2386 22 22 22Z"
      stroke="#6D77A0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.2101 25.741L25.67 29.281C25.53 29.421 25.4 29.681 25.37 29.871L25.18 31.221C25.11 31.711 25.45 32.051 25.94 31.981L27.29 31.791C27.48 31.761 27.75 31.631 27.88 31.491L31.42 27.951C32.03 27.341 32.32 26.631 31.42 25.731C30.53 24.841 29.8201 25.131 29.2101 25.741Z"
      stroke="#6D77A0"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.7002 26.25C29.0002 27.33 29.8402 28.17 30.9202 28.47"
      stroke="#6D77A0"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4102 32C13.4102 28.13 17.2602 25 22.0002 25C23.0402 25 24.0402 25.15 24.9702 25.43"
      stroke="#6D77A0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const LinkedAccountIcon = (props) => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="44" height="44" rx="22" fill="#F3F4F8" />
    <path
      d="M24.9902 27.5H26.5002C29.5202 27.5 32.0002 25.03 32.0002 22C32.0002 18.98 29.5302 16.5 26.5002 16.5H24.9902"
      stroke="#6D77A0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 16.5H17.5C14.47 16.5 12 18.97 12 22C12 25.02 14.47 27.5 17.5 27.5H19"
      stroke="#6D77A0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 22H26"
      stroke="#6D77A0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
