import { useTranslation } from "react-i18next";
import { checkLanguage } from "../Pages/Extension/Components/utils";

export const CheckboxInput = ({ name, formState, handleChange, labelText }) => {
  return (
    <div className="cursor-pointer">
      <label className="cursor-pointer text-textSmall font-DMSansFont font-[450] text-LightPrimary flex gap-3 items-center">
        <input
          type="checkbox"
          name={name}
          checked={formState}
          onChange={handleChange}
          className="min-w-[20px]"
        />
        {labelText}
      </label>
    </div>
  );
};

export const TextAreaField = ({
  type,
  placeholder,
  handleOnChange,
  name,
  error,
  value,
  background,
  row,
  radius,
  fontSize,
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isArabic = language === "ar";
  
  return (
    <div dir={isArabic ? "rtl" : "ltr"}>
      <textarea
        style={{
          border: error ? '1px solid #FF0000' : '',
          background: background ? background : '',
          borderRadius: radius ? radius : '',
          fontSize: fontSize ? fontSize : '',
        }}
        dir={
          value
            ? isArabic && checkLanguage(value)
              ? "rtl"
              : "ltr"
            : isArabic
            ? "rtl"
            : "ltr"
        }
        type={type}
        value={value}
        placeholder={placeholder}
        rows={row ? row : 7}
        onChange={(e) => handleOnChange(e, name)}
        className={`w-full outline-none border-[1px] border-LightOutline py-3 px-5 rounded-[20px] font-DMSansFont text-textSmall text-LightPrimary resize-none ${
          value !== '' ? 'font-medium' : 'font-normal'
        }`}
      />
    </div>
  );
};

