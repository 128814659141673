import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { cn } from "./utils";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const ArrowIcon = () => (
  <svg
    style={{ width: 16 }}
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_58_30697)">
      <path
        d="M1.83595 3.99C1.84181 3.99 1.84637 3.99106 1.84987 3.99247C1.85308 3.99376 1.85726 3.99608 1.86232 4.00114L4.79631 6.93512C5.12946 7.26828 5.67041 7.26828 6.00356 6.93512L8.93755 4.00114C8.94322 3.99547 8.95203 3.99113 8.96392 3.99113C8.97581 3.99113 8.98462 3.99547 8.99029 4.00114C8.99595 4.0068 9.00029 4.01562 9.00029 4.0275C9.00029 4.03939 8.99595 4.04821 8.99029 4.05387L6.0563 6.98786C5.69715 7.34701 5.10817 7.34858 4.74301 6.9873C4.74287 6.98716 4.74272 6.98701 4.74257 6.98687L1.80958 4.05387C1.80392 4.04821 1.79958 4.03939 1.79958 4.0275C1.79958 4.01751 1.80265 4.00968 1.807 4.00407C1.8228 3.99113 1.83467 3.99 1.83595 3.99Z"
        fill="#8C90A5"
        stroke="#8C90A5"
        strokeWidth="0.599997"
      />
    </g>
    <defs>
      <clipPath id="clip0_58_30697">
        <rect
          width="10.8"
          height="10.8"
          fill="white"
          transform="translate(10.7999 10.7999) rotate(-180)"
        />
      </clipPath>
    </defs>
  </svg>
);

const SelectList = ({
  label = "",
  selected,
  onChangeSelect,
  optionsList,
  optionsWidth,
}) => {
  const {
    i18n: { language, dir },
  } = useTranslation();
  const isArabic = language === "ar";

  return (
    <>
      <p
        className={cn(
          "font-normal text-[14px] text-LightStrokActive mb-[2px]",
          isArabic ? "text-right" : "text-left"
        )}
      >
        {t(label)}
      </p>
      <Listbox value={selected} onChange={onChangeSelect}>
        <div className="relative mt-[4px]">
          <Listbox.Button
            className={cn(
              "relative cursor-pointer w-full flex gap-[8px] rounded-[7px] bg-white border border-[#DFE4EC] py-[11px] text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300",
              isArabic ? "pl-[40px] pr-[15px] " : "pr-[40px] pl-[15px] "
            )}
          >
            {/* <span className="block">{selected.active}</span> */}
            {/* <img src={selected.active} alt="selected.active" className="block"></img> */}
            <span className="block truncate text-LightPrimary text-[12px] font-medium">
              {t(selected?.title) || "Select Reason"}
            </span>
            <div
              className={cn(
                "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[8px]",
                isArabic ? "right-[94%]" : "left"
              )}
            >
              <ArrowIcon />
            </div>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              style={{ width: optionsWidth ? optionsWidth : "100%" }}
              className="absolute right-0 space-y-[8px] max-h-[240px] w-full overflow-auto rounded-[6px] bg-white text-base shadow-lg py-[8px] focus:outline-none z-70"
            >
              {optionsList.map((option, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `relative select-none py-[4px] pl-[8px] rounded-[6px] pr-4 cursor-pointer mx-[8px] ${
                      active
                        ? "bg-[#f3f4f8] text-LightPrimary"
                        : "text-LightStrokActive"
                    }`
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate text-[12px] leading-[22px] ${
                          (selected ? "font-medium" : "font-normal",
                          isArabic ? "text-right" : "text-left")
                        }`}
                      >
                        {t(option.title)}
                      </span>
                      {option?.icon && (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-[8px] text-LightPrimaryActive">
                          {option.icon}
                        </span>
                      )}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </>
  );
};

export default SelectList;
