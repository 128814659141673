import React from "react";
import { ResalaWhiteIcon } from "./Icons/Icons";

const WelcomeComp = () => {
  return (
    <div className="w-full bg-[#1678F2] p-[30px] flex flex-col h-full gap-[40px]">
      <ResalaWhiteIcon />
      <p className="text-white font-DMSansFont font-medium text-[50px]">
        Welcome to Resala in your browser{' '}
      </p>
    </div>
  );
};

export default WelcomeComp;
