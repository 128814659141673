import clsx from "clsx";
import { twMerge } from "tailwind-merge";

export const cn = (...input) => {
  return twMerge(clsx(...input));
};

export const profileInitials = (fname = "X", lname = "X") => {
  return fname.charAt(0).toUpperCase() + lname.charAt(0).toUpperCase();
};

export const checkLanguage = (text) => {
  switch (true) {
    case /[\u0600-\u06FF]/.test(text):
      return true; // Arabic
    default:
      return false;
  }
};
