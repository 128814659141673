import { t } from "i18next";
import React from "react";

const SocialCompo = ({ icon, email, loginType }) => {
  return (
    <div>
      <div className="flex gap-3 items-center">
        {icon}
        <p className="Text20Heading">{t(loginType)}</p>
      </div>
      <p className="Text16Heading text-[18px] mt-[8px]">{email}</p>
    </div>
  );
};

export default SocialCompo;
