import React from 'react';
import { UseResalaButton } from '../../Components/Button';
import Layout from '../../utils/Layout';
import { useTranslation } from 'react-i18next';

const UninstallSuccess = () => {
  const {t} = useTranslation()
  return (
    <Layout>
      <div className="backImage">
        <div className="py-10 h-[calc(100vh-80px)] overflow-y-auto">
          <div className="flex flex-col gap-5 items-center justify-center h-full">
            <p
              className="text-[30px] font-DMSansFont font-bold text-LightPrimary max-w-[520px] text-center"
              dangerouslySetInnerHTML={{ __html: t('SorryToSee') }}
            >
            </p>
            <p className="text-LightTextParagraph text-[22px] font-DMSansFont font-medium max-w-[800px] text-center">
              {t('MakeEveryEffort')}
            </p>
            <div>
              <UseResalaButton width="236px" title={t('ContinueToResala')} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UninstallSuccess;
