import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Line from '../../assets/images/HomePage/StrateLine.svg';
import Line2 from '../../assets/images/HomePage/RevolutionizeLine.svg';
import Round from '../../assets/images/HomePage/Round.svg';
import Bubble from '../../assets/images/HomePage/bubble.svg';
import HalfRound from '../../assets/images/HomePage/HalfRound.svg';
import HalfFram from '../../assets/images/HomePage/HalfFram.svg';
// import YoutubeInsightImage from "../../assets/images/HomePage/YoutubeImg.svg";
// import YoutubeInsightImageAR from "../../assets/images/HomePage/YoutubeImgAR.svg";
import Lottie from 'react-lottie';
// import EffortEmailData from "../../animations/En/EmailReplies.json";
// import EffortEmailDataAR from "../../animations/Ar/EmailRepliesAR.json";
// import EngageWebData from "../../animations/En/EngageWeb.json";
// import EngageWebDataAR from "../../animations/Ar/EngageWebAR.json";
// import AskAIData from "../../animations/En/AskAI.json";
// import AskAIDataAR from "../../animations/Ar/AskAIAR.json";
// import LanguageShatterData from "../../animations/En/LanguageBarriers.json";
// import LanguageShatterDataAR from "../../animations/Ar/LanguageBarriersAR.json";
// import WriteWavering from "../../assets/images/HomePage/writeUnvavering.svg";
// import WriteWaveringAr from "../../assets/images/HomePage/Grammer-ar.svg";
// import StudychatData from '../../animations/En/Revolutionize.json';
// import StudychatDataAR from '../../animations/Ar/RevolutionizeAR.json';
// import SocialData from '../../animations/En/ElevateMedia.json';
// import SocialDataAR from '../../animations/Ar/ElevateMediaAR.json';

const FeatureNewCopy = () => {
  const getLanguage = localStorage.getItem('lang');
  // const useAnimationVisibility = () => {
  //   const [isPaused, setIsPaused] = useState(true);
  //   const [hasPlayed, setHasPlayed] = useState(false); // New state to track if the animation has been played
  //   const animationRef = useRef(null);

  //   useEffect(() => {
  //     const observer = new IntersectionObserver(
  //       ([entry]) => {
  //         // Check if the animation has not been played yet
  //         if (!hasPlayed) {
  //           // Play the animation if the element is in view and hasn't played yet
  //           if (entry.isIntersecting) {
  //             setIsPaused(false);
  //             setHasPlayed(true); // Mark as played
  //           } else {
  //             setIsPaused(true);
  //           }
  //         }
  //       },
  //       {
  //         root: null, // Default is the viewport
  //         rootMargin: "0px",
  //         threshold: 0.7, // Adjust this value according to when you want the animation to start
  //       }
  //     );

  //     if (animationRef.current) {
  //       observer.observe(animationRef.current);
  //     }

  //     return () => {
  //       if (animationRef.current) {
  //         observer.unobserve(animationRef.current);
  //       }
  //     };
  //   }, [animationRef, hasPlayed]); // Add hasPlayed as a dependency

  //   // Return both the ref and the pause state, no changes here
  //   return [animationRef, isPaused];
  // };

  const useAnimationVisibility = () => {
    const [isPaused, setIsPaused] = useState(true);
    const [hasPlayed, setHasPlayed] = useState(false); // New state to track if the animation has been played
    const animationRef = useRef(null);
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (!hasPlayed) {
            if (entry.isIntersecting) {
              setIsPaused(false);
              setHasPlayed(true);
            } else {
              setIsPaused(true);
            }
          } else {
          }
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0.4,
        }
      );

      if (animationRef.current) {
        observer.observe(animationRef.current);
      }

      return () => {
        if (animationRef.current) {
          observer.unobserve(animationRef.current);
        }
      };
    }, [animationRef, hasPlayed]);

    return [animationRef, isPaused];
  };
  const [feaAnimRef1, isPaused1] = useAnimationVisibility();
  const [feaAnimRef2, isPaused2] = useAnimationVisibility();
  const [feaAnimRef3, isPaused3] = useAnimationVisibility();
  const [feaAnimRef4, isPaused4] = useAnimationVisibility();
  // const [feaAnimRef5] = useAnimationVisibility();
  const [feaAnimRef6, isPaused6] = useAnimationVisibility();
  // const [feaAnimRef7] = useAnimationVisibility();
  const [feaAnimRef8, isPaused8] = useAnimationVisibility();

  const { t, i18n } = useTranslation();

  // const LottieAnimation = ({ isPaused, animationData }) => {
  //   const defaultOptions = {
  //     loop: false,
  //     autoplay: true,
  //     animationData,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice",
  //     },
  //   };

  //   return <Lottie width={800} options={defaultOptions} isPaused={isPaused} />;
  // };

  const LottieAnimation = ({ isPaused, animationData }) => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return <Lottie options={defaultOptions} isPaused={isPaused} />;
  };

  const [effortEmailData, setEffortEmailData] = useState(null);
  const [engageWebData, setEngageWebData] = useState(null);
  const [AskAIDataS, setAskAIDataS] = useState(null);
  const [LanguageShatterDataS, setLanguageShatterDataS] = useState(null);
  const [StudyDataS, setStudyDataS] = useState(null);
  const [ElevateMediaS, setElevateMediaS] = useState(null);
  // const [WriteWaveringS, setWriteWaveringS] = useState(null);

  useEffect(() => {
    const fetchLottieData = async (url, setter) => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        setter(data);
      } catch (error) {
        console.error('Error fetching Lottie data:', error);
      }
    };

    const urls = {
      en: {
        effortEmail: 'https://images.dcdial.com/resala.ai/EmailReplies.json',
        engageWeb: 'https://images.dcdial.com/resala.ai/EngageWeb.json',
        AskAI: 'https://images.dcdial.com/resala.ai/AskAI.json',
        LangShatter:
          'https://images.dcdial.com/resala.ai/LanguageBarriers.json',
        Revolutionize: 'https://images.dcdial.com/resala.ai/Revolutionize.json',
        ElevateMedia: 'https://images.dcdial.com/resala.ai/ElevateMedia.json',
      },
      ar: {
        effortEmail: 'https://images.dcdial.com/resala.ai/EmailRepliesAR.json',
        engageWeb: 'https://images.dcdial.com/resala.ai/EngageWebAR.json',
        AskAI: 'https://images.dcdial.com/resala.ai/AskAIAR.json',
        LangShatter:
          'https://images.dcdial.com/resala.ai/LanguageBarriersAR.json',
        Revolutionize:
          'https://images.dcdial.com/resala.ai/RevolutionizeAR.json',
        ElevateMedia: 'https://images.dcdial.com/resala.ai/ElevateMediaAR.json',
      },
    };

    const lang = i18n.language === 'en' ? 'en' : 'ar';

    fetchLottieData(urls[lang].effortEmail, setEffortEmailData);
    fetchLottieData(urls[lang].engageWeb, setEngageWebData);
    fetchLottieData(urls[lang].AskAI, setAskAIDataS);
    fetchLottieData(urls[lang].LangShatter, setLanguageShatterDataS);
    fetchLottieData(urls[lang].Revolutionize, setStudyDataS);
    fetchLottieData(urls[lang].ElevateMedia, setElevateMediaS);
  }, [i18n.language]);

  return (
    <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] max_md:px-[40px] max_sm:px-[20px] pt-[20px] pb-[160px] max_md:pb-[120px] max_sm:pb-[80px]">
      <div>
        <div className="title-fonts text-HeadingLarge font-DMSansFont font-bold text-LightPrimary text-center max_md:text-[38px] max_sm:text-[30px]">
          {t('Feature')}
        </div>

        <div className="description-fonts text-LightTextParagraph text-SmallsubHeading font-DMSansFont font-medium text-center mt-[20px] mb-[100px] max_sm:mb-10 max_sm:text-[16px]">
          {t('ResalaText')}
        </div>

        {/* FIRST */}
        <div className="flex justify-between max_md:flex-col max_md:items-center max_md:gap-[35px]">
          <div className="flex flex-col mt-[4%] max_md:mt-0 w-[48%] pl-[100px] max_md:pl-0 max_md:w-[80%] max_md:text-center max_md:flex max_md:flex-col max_md:items-center max_sm:w-[100%]">
            <div className="relative title-fonts text-HeadingLarge font-DMSansFont font-bold text-LightPrimary max_md:text-[38px] max_sm:text-[28px] max_md:w-[54%] max-w-[426px] max_xl:max-w-[344px] max_sm:w-[70%] max_sm_380:max-w-[200px]">
              {t('EffortlessEmail')}

              <div
                className={`absolute  ${
                  i18n.language === 'en'
                    ? 'left-[0%] top-[44%]'
                    : 'top-[47%] right-0 max_md:top-[88%] max_800:top-[44%]'
                }`}
              >
                {' '}
                <img src={Line} alt="lineImg" />
              </div>
            </div>
            <div className="w-[78%] max_md:w-[90%] description-fonts text-LightTextParagraph text-SmallsubHeading font-DMSansFont font-medium mt-[20px] max_sm:text-[16px]">
              {t('EffortlessEmailText')}
            </div>
          </div>
          <div
            className="min-h-[470px] max_xl:min-h-[370px] max_sm:min-h-[302px] w-[45%] flex items-center max_md:w-[62%] max_sm:w-[100%]"
            ref={feaAnimRef1}
          >
            {!isPaused1 && (
              <LottieAnimation
                isPaused={isPaused1}
                // animationData={
                //   i18n.language === 'en' ? EffortEmailData : EffortEmailDataAR
                // }
                animationData={effortEmailData}
              />
            )}
          </div>
        </div>

        {/* SECOND */}
        <div className="flex justify-between flex-row-reverse mt-[150px] max_sm:mt-[40px] max_md:flex-col max_md:items-center max_md:gap-[35px]">
          <div className="mt-[11%] max_md:mt-0 flex flex-col  w-[44%] pr-[100px] max_md:pr-0 max_md:w-[80%] max_md:text-center max_md:flex max_md:flex-col max_md:items-center max_sm:w-[100%]">
            <div className="title-fonts z-40 relative text-HeadingLarge font-DMSansFont font-bold text-LightPrimary max_md:text-[38px] max_sm:text-[28px] max_md:w-[53%]">
              {t('EngageWeb')}

              <div
                className={`absolute top-[-13%] z-[-1] max_md:w-[20%] max_xl:top-[-34%] max_md:top-[-13%] ${
                  i18n.language === 'en'
                    ? 'left-[-12%] max_xl:left-[-16%] max_md:left-[-1%]'
                    : 'max_xl:w-[20%] top-[-26%] right-[-15%] max_md:right-[8%]'
                }`}
              >
                {' '}
                <img src={Round} alt="lineImg" className="max_sm:w-[100%]" />
              </div>
            </div>
            <div className="w-[75%] max_md:w-[90%] description-fonts text-LightTextParagraph text-SmallsubHeading font-DMSansFont font-medium mt-[20px] max_sm:text-[16px]">
              {t('EngageWebText')}
            </div>
          </div>
          <div
            className=" min-h-[590px] max_xl:min-h-[465px] max_sm:min-h-[375px] w-[45%] flex items-center max_md:w-[62%] max_sm:w-[100%]"
            ref={feaAnimRef2}
          >
            {!isPaused2 && (
              <LottieAnimation
                isPaused={isPaused2}
                // animationData={
                //   i18n.language === 'en' ? EngageWebData : EngageWebDataAR
                // }
                animationData={engageWebData}
              />
            )}
          </div>
        </div>

        {/* THIRD */}
        <div className="flex justify-between mt-[150px] max_sm:mt-[40px] max_md:flex-col max_md:items-center max_md:gap-[35px]">
          <div
            className={`mt-[10%] max_md:mt-0 pl-[100px] max_md:pl-0 flex flex-col max_md:w-[80%] max_md:text-center max_md:flex max_md:flex-col max_md:items-center max_sm:w-[100%] ${
              i18n.language === 'en'
                ? 'w-[48%] max_xl:w-[44%]'
                : 'w-[45%] max_xl:w-[44%]'
            }`}
          >
            <div className="title-fonts relative text-HeadingLarge font-DMSansFont font-bold text-LightPrimary max_md:text-[38px] max_sm:text-[28px] max_md:w-[54%] z-40">
              {t('AskAI')}
              <div
                className={`absolute z-[-1] w-[14%] max_md:w-[12%] max_sm:w-[14%] ${
                  i18n.language === 'en'
                    ? 'right-[32%] top-[36%] max_xl:w-[20%] max_xl:top-[44%] max_xl:right-[24%] max_sm:right-[-5px] max_sm:top-[22px] max_md:right-[-10px]'
                    : 'flipClass left-[7%] top-[26%] max_xl:w-[18%] max_md:top-0 max_md:left-[-2%]'
                } `}
              >
                {' '}
                <img
                  src={Bubble}
                  alt="lineImg"
                  className="max_sm:w-[100%] max_xl:w-[60%]"
                />
              </div>
            </div>
            <div className="w-[78%] max_md:w-[90%] description-fonts text-LightTextParagraph text-SmallsubHeading font-DMSansFont font-medium mt-[20px] max_sm:text-[16px]">
              {t('AskAIText')}
            </div>
          </div>
          <div
            className=" min-h-[735px] max_xl:min-h-[610px] max_800:min-h-[482px] max_sm:min-h-[430px] w-[54%] flex justify-center items-center max_md:w-[62%] max_sm:w-[100%]"
            ref={feaAnimRef3}
          >
            {!isPaused3 && (
              <LottieAnimation
                isPaused={isPaused3}
                // animationData={i18n.language === 'en' ? AskAIData : AskAIDataAR}
                animationData={AskAIDataS}
              />
            )}
          </div>
        </div>

        {/* FOURTH */}
        <div className="flex justify-between flex-row-reverse mt-[150px] max_sm:mt-[40px] max_md:flex-col max_md:items-center max_md:gap-[35px]">
          <div
            className={`mt-[1%] flex flex-col pr-[100px] max_md:pr-0 max_md:w-[80%] max_md:text-center max_md:flex max_md:flex-col max_md:items-center max_sm:w-[100%] ${
              i18n.language === 'en' ? 'w-[44%]' : 'w-[39%]'
            }`}
          >
            <div
              className={`title-fonts z-40 relative text-HeadingLarge font-DMSansFont font-bold text-LightPrimary max_md:text-[38px] max_sm:text-[28px] max_md:w-[54%] max_sm:w-[90%]`}
            >
              {t('ShatterLanguage')}
              <div
                className={`z-[-1] absolute ${
                  i18n.language === 'en'
                    ? 'right-[165px] top-[-16px] max_md:w-[20%] max_md:left-0'
                    : 'right-[218px] max_xl:w-[28%] top-[-30px] max_md:w-[20%] max_sm:w-[16%] max_md:left-0 max_sm:top-[-16px]'
                } `}
              >
                {' '}
                <img
                  src={HalfRound}
                  alt="lineImg"
                  className="max_sm:w-[100%]"
                />
              </div>
            </div>
            <div className="w-[78%] max_md:w-[90%] description-fonts text-LightTextParagraph text-SmallsubHeading font-DMSansFont font-medium mt-[20px] max_sm:text-[16px]">
              {t('StayInformedText')}
            </div>
          </div>
          <div
            className=" min-h-[416px] max_xl:min-h-[326px] max_sm:min-h-[310px] w-[45%] flex items-center max_md:w-[62%] max_sm:w-[100%]"
            ref={feaAnimRef4}
          >
            {!isPaused4 && (
              <LottieAnimation
                isPaused={isPaused4}
                // animationData={
                //   i18n.language === 'en'
                //     ? LanguageShatterData
                //     : LanguageShatterDataAR
                // }
                animationData={LanguageShatterDataS}
              />
            )}
          </div>
        </div>

        {/* FIFTH */}
        <div className="flex justify-between mt-[150px] max_sm:mt-[40px] max_md:flex-col max_md:items-center max_md:gap-[35px]  max_md:h-fit h-[466px]">
          <div
            className={`flex flex-col pl-[100px] max_md:pl-0 max_md:w-[80%] max_md:text-center max_md:flex max_md:flex-col max_md:items-center max_sm:w-[100%] ${
              i18n.language === 'en' ? 'w-[48%]' : 'w-[44%]'
            }`}
          >
            <div className="title-fonts z-40 relative text-HeadingLarge font-DMSansFont font-bold text-LightPrimary max_sm:text-[28px] max_md:text-[36px] max_md:w-[70%] max_sm:w-[90%] max_sm_380:max-w-[200px]">
              {t('WriteUnwavering')}
              <div
                className={`z-[-1] absolute max_md:w-[12%] max_sm:w-[9%] ${
                  i18n.language === 'en'
                    ? 'right-[129px] top-[-16px] max_md:right-[10px] max_sm:top-[12px]'
                    : 'max_xl:w-[20%] rotate-[270deg] left-[15%] top-[-18px] max_xl:left-[13%] max_md:left-[5%] max_sm:left-[16%] max_sm_380:left-[12%] max_sm:top-[-7px]'
                }`}
              >
                {' '}
                <img src={HalfFram} alt="lineImg" className="max_sm:w-[100%]" />
              </div>
            </div>
            <div className="w-[78%] max_md:w-[90%] description-fonts text-LightTextParagraph text-SmallsubHeading font-DMSansFont font-medium mt-[20px] max_sm:text-[16px]">
              {t('WriteUnwaveringText')}
            </div>
          </div>
          <div
            className="w-[38%] flex items-center max_md:w-[62%] max_sm:w-[100%]"
            // ref={feaAnimRef5}
          >
            <div className="">
              <img
                src={
                  i18n.language === 'en'
                    ? 'https://images.dcdial.com/resala.ai/UnwaveringConfidence.svg'
                    : 'https://images.dcdial.com/resala.ai/UnwaveringConfidenceAR.svg'
                }
                alt="Email"
              />
            </div>
            {/* )} */}
          </div>
        </div>

        {/* SIXTH */}
        <div className="flex justify-between flex-row-reverse mt-[150px] max_sm:mt-[40px] max_md:flex-col max_md:items-center max_md:gap-[35px]">
          <div
            className={`flex mt-[5%] max_md:mt-0 flex-col pr-[100px] max_md:pr-0 max_md:w-[80%] max_md:text-center max_md:flex max_md:flex-col max_md:items-center max_sm:w-[100%] ${
              i18n.language === 'en' ? 'w-[44%]' : 'w-[42%]'
            }`}
          >
            <div className="title-fonts relative text-HeadingLarge font-DMSansFont font-bold text-LightPrimary max_md:text-[38px] max_sm:text-[28px] max_md:w-[54%] max_sm:w-[85%]">
              {t('RevolutionizeStudySessions')}

              <div
                className={`absolute max_sm:w-[60%] max_xl:w-[90%]  ${
                  i18n.language === 'en'
                    ? 'left-[0%] top-[29%] max_sm:left-[22%] max_sm:top-[49%]'
                    : 'left-[22%] top-[30%] max_xl:top-[29%] max_xl:w-[76%] max_xl:left-[24%] max_md:top-[46%] max_md:left-[12%] max_sm:top-[100%] max_sm_380:top-[48%] max_350:top-[48%] max_sm:left-[8%] leading-[90px]'
                }`}
              >
                {' '}
                <img src={Line2} alt="lineImg" />
              </div>
            </div>
            <div className="w-[78%] max_md:w-[90%] description-fonts text-LightTextParagraph text-SmallsubHeading font-DMSansFont font-medium mt-[20px] max_sm:text-[16px]">
              {t('RevolutionizeStudySessionsText')}
            </div>
          </div>
          <div
            className=" w-[45%] min-h-[665px] max_xl:min-h-[528px] max_sm:min-h-[440px] flex items-center max_md:w-[62%] max_sm:w-[100%]"
            ref={feaAnimRef6}
          >
            {!isPaused6 && (
              <LottieAnimation
                isPaused={isPaused6}
                // animationData={
                //   i18n.language === 'en' ? StudychatData : StudychatDataAR
                // }
                animationData={StudyDataS}
              />
            )}
          </div>
        </div>

        {/* SEVEN */}
        <div className="flex justify-between mt-[150px] max_sm:mt-[40px] max_md:flex-col max_md:items-center max_md:gap-[35px] max_md:h-fit h-[500px]">
          <div className="flex justify-center flex-col w-[48%] pl-[100px] max_md:pl-0 max_md:w-[80%] max_md:text-center max_md:flex max_md:flex-col max_md:items-center max_sm:w-[100%]">
            <div className="title-fonts z-40 relative text-HeadingLarge font-DMSansFont font-bold text-LightPrimary max_md:text-[38px] max_sm:text-[28px] max_md:w-[54%] max_sm:w-[95%]">
              {t('YouTubeInsights')}
              <div
                className={`z-[-1] absolute max_xl:w-[14%] max_md:w-[15%] max_sm:w-[10%] ${
                  i18n.language === 'en'
                    ? 'right-[111px] top-[128px] max_md:right-0 max_md:top-[30px] max_sm:top-[6px]'
                    : 'flipClass w-[20%] left-[12%] top-[-28px] max_xl:left-[62px] max_xl:top-[-26px] max_md:left-0 max_md:top-[-15%]  max_720:left-[-7%] max_sm:left-[18%] max_sm_380:left-[10%] max_sm:top-[-12px]'
                }`}
              >
                {' '}
                <img src={Round} alt="lineImg" />
              </div>
            </div>
            <div className="w-[78%] max_md:w-[90%] description-fonts text-LightTextParagraph text-SmallsubHeading font-DMSansFont font-medium mt-[20px] max_sm:text-[16px]">
              {t('YouTubeInsightsText')}
            </div>
          </div>
          <div
            className="w-[38%] flex items-center max_md:w-[62%] max_sm:w-[100%]"
            // ref={feaAnimRef7}
          >
            <div className="">
              <img
                src={
                  i18n.language === 'en'
                    ? 'https://images.dcdial.com/resala.ai/YoutubeInsights.svg'
                    : 'https://images.dcdial.com/resala.ai/YoutubeInsightsAR.svg'
                }
                alt="Email"
              />
            </div>
            {/* )} */}
          </div>
        </div>

        {/* EIGHT */}
        <div className="flex justify-between flex-row-reverse mt-[150px] max_sm:mt-[40px] max_md:flex-col max_md:items-center max_md:gap-[35px]">
          <div className="flex flex-col justify-center w-[45%] pr-[100px] max_md:pr-0 max_md:w-[80%] max_md:text-center max_md:flex max_md:flex-col max_md:items-center max_sm:w-[100%]">
            <div className="title-fonts z-40 relative text-HeadingLarge font-DMSansFont font-bold text-LightPrimary max_md:text-[38px] max_sm:text-[28px] max_md:w-[54%] max_sm:w-[95%]">
              {t('ElevateGame')}
              <div
                className={`z-[-1] absolute rotate-[269deg] max_md:w-[12%] ${
                  i18n.language === 'en'
                    ? 'left-[-60px] top-[-41px] max_md:left-0 max_md:top-[30px]'
                    : 'w-[16%] right-[-14%] top-[-16%] max_xl:right-[-15%] max_xl:top-[-16%] max_md:top-[5%] max_md:right-[-12%] max_720:right-[-5%] max_sm_380:right-[-5%] max_sm:right-[-10%] max_sm:top-[0%]'
                }`}
              >
                {' '}
                <img src={Bubble} alt="lineImg" />
              </div>
            </div>
            <div className="w-[78%] max_md:w-[90%] description-fonts text-LightTextParagraph text-SmallsubHeading font-DMSansFont font-medium mt-[20px] max_sm:text-[16px]">
              {t('ElevateGameText')}
            </div>
          </div>
          <div
            className=" w-[48%] min-h-[394px] max_xl:min-h-[333px] max_sm:min-h-[270px] max_md: flex items-center max_md:w-[62%] max_sm:w-[100%]"
            ref={feaAnimRef8}
          >
            {!isPaused8 && (
              <LottieAnimation
                isPaused={isPaused8}
                // animationData={i18n.language === 'en' ? SocialData : SocialDataAR}
                animationData={ElevateMediaS}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureNewCopy;
