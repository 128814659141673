import React from "react";
import { checkLanguage, cn } from "./utils";
import { ErrorMsg } from "./InputField";
import { useTranslation } from "react-i18next";

const Textarea = ({
  name,
  label,
  disabled,
  onChange,
  error,
  placeholder,
  className,
  value,
}) => {
  const {
    i18n: { language, dir },
  } = useTranslation();

  const isArabic = language === "ar";
  return (
    <>
      <p
        className={cn(
          "font-normal text-[14px] text-LightStrokActive mb-[2px]",
          isArabic ? "text-right" : "text-left"
        )}
      >
        {label}
      </p>
      <textarea
        dir={value.length && (isArabic && checkLanguage(value) ? "rtl" : "ltr")}
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        className={cn(
          "w-full outline-none text-[14px] text-LightPrimary h-[200px] px-[15px] py-[11px] border rounded-[6px] border-[#dfe4ec]",
          className
        )}
      />
      <div className={cn(error && "mt-[-6px]")}>
        {error && <ErrorMsg errorMsg={error} />}
      </div>
    </>
  );
};

export default Textarea;
