import { useState, useEffect } from "react";
import { FACEBOOK_APP_ID } from "../config";

const useFacebookSdk = () => {
  const [isSdkLoaded, setIsSdkLoaded] = useState(false);

  useEffect(() => {
    const loadFacebookSdk = () => {
      if (window.FB) {
        setIsSdkLoaded(true);
        return;
      }

      window.fbAsyncInit = () => {
        window.FB.init({
          appId: FACEBOOK_APP_ID,
          cookie: true,
          xfbml: true,
          version: "v10.0",
        });
        setIsSdkLoaded(true);
      };

      (function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        const js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    };

    loadFacebookSdk();
  }, []);

  return isSdkLoaded;
};

export default useFacebookSdk;
