import React, { useEffect, useState } from "react";
import ExeInstall from "./Icons/exeInstall.png";
import ExeInstalled from "./Icons/Installed.png";
import { useNavigate } from "react-router-dom";
import { authenticateUser } from "../../Exe-api/apis";
import SpinnerLoader from "../../utils/LoaderCommon";
import { langHelper } from "./Pages/AuthCheck/AuthCheck";

const TourContent = () => {
  const navigate = useNavigate();
  const [curStep, setCurStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isExtensionPinned, setIsExtensionPinned] = useState(false);

  let interval = setInterval(checkIsPinned, 500);
  async function checkIsPinned() {
    const isPinned = sessionStorage.getItem("isPinned");
    if (isPinned == "true") {
      setIsExtensionPinned(true);
      clearInterval(interval);
    } else {
      setIsExtensionPinned(false);
    }
  }
  useEffect(() => {
    if (isExtensionPinned) {
      if (curStep === 1) {
        setCurStep(curStep + 1);
        setCompletedSteps((prev) => [...prev, 1]);
      }
    }
  }, [isExtensionPinned]);

  const valiadateUser = async (token) => {
    setIsLoading(true);
    try {
      const res = await authenticateUser({ token: token });

      if (res.Error || res.detail || res.message) {
        setIsLoading(false);
        return false;
      }
      return false;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      return false;
    }
  };

  return (
    <>
      <div className="p-[30px]">
        <div className="pb-[20px] border-b border-[#DFE4EC]">
          <StepHeader
            isCompleted
            isActive={false}
            title={"Add Resala to Chrome"}
          />
        </div>
        <div className="py-[20px] border-b border-[#DFE4EC]">
          <StepHeader
            title={"Keep Resala in easy reach"}
            isCompleted={completedSteps.includes(1)}
            isActive={curStep === 1}
            content={
              <div className="flex flex-col">
                <div className="ml-[29px] mt-[20px]">
                  <img src={ExeInstall} alt="ExeInstall" />
                </div>
                <div className="flex flex-col gap-[12px] text-[#19224c] font-DMSansFont font-normal">
                  <p className="text-[16px] leading-[24px]">
                    Chrome doesn't add Resala to your toolbar for you. To pin
                    Resala to your browser's toolbar:
                  </p>
                  <p className="flex ml-[4px]">
                    1. Click
                    <span className="mx-[4px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="18"
                        viewBox="0 0 19 18"
                        fill="none"
                      >
                        <path
                          d="M16.0832 8.66634H14.8332V5.33301C14.8332 4.41634 14.0832 3.66634 13.1665 3.66634H9.83317V2.41634C9.83317 1.86381 9.61368 1.3339 9.22298 0.943202C8.83228 0.552501 8.30237 0.333008 7.74984 0.333008C7.1973 0.333008 6.6674 0.552501 6.2767 0.943202C5.886 1.3339 5.6665 1.86381 5.6665 2.41634V3.66634H2.33317C1.4165 3.66634 0.674837 4.41634 0.674837 5.33301V8.49967H1.9165C3.15817 8.49967 4.1665 9.50801 4.1665 10.7497C4.1665 11.9913 3.15817 12.9997 1.9165 12.9997H0.666504V16.1663C0.666504 17.083 1.4165 17.833 2.33317 17.833H5.49984V16.583C5.49984 15.3413 6.50817 14.333 7.74984 14.333C8.9915 14.333 9.99984 15.3413 9.99984 16.583V17.833H13.1665C14.0832 17.833 14.8332 17.083 14.8332 16.1663V12.833H16.0832C16.6357 12.833 17.1656 12.6135 17.5563 12.2228C17.947 11.8321 18.1665 11.3022 18.1665 10.7497C18.1665 10.1971 17.947 9.66724 17.5563 9.27654C17.1656 8.88583 16.6357 8.66634 16.0832 8.66634Z"
                          fill="#19224C"
                        />
                      </svg>
                    </span>
                    in the toolsbar.
                  </p>
                  <p className="flex">
                    2. Click
                    <span className="mx-[4px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                      >
                        <path
                          d="M15.9857 7.00521L13.5166 4.53271C11.8274 2.84104 10.9832 1.99687 10.0757 2.19687C9.16906 2.39687 8.75823 3.51937 7.93573 5.76354L7.37906 7.28271C7.1599 7.88104 7.0499 8.18021 6.8524 8.41187C6.7639 8.51584 6.66317 8.60873 6.5524 8.68854C6.30573 8.86604 5.99906 8.95021 5.38573 9.11937C4.0024 9.50104 3.3099 9.69187 3.04906 10.1444C2.93623 10.3402 2.87756 10.5625 2.87906 10.7885C2.8824 11.311 3.3899 11.8185 4.40406 12.8344L5.58323 14.0144L1.85323 17.7477C1.73957 17.8682 1.67734 18.0282 1.67976 18.1939C1.68217 18.3595 1.74904 18.5176 1.86616 18.6348C1.98329 18.7519 2.14145 18.8188 2.30707 18.8212C2.4727 18.8236 2.63274 18.7614 2.75323 18.6477L6.4824 14.9144L7.70406 16.1377C8.72573 17.1594 9.23656 17.671 9.7624 17.671C9.98323 17.671 10.2007 17.6144 10.3932 17.5044C10.8499 17.2435 11.0416 16.5469 11.4257 15.1527C11.5941 14.5402 11.6782 14.2344 11.8549 13.9869C11.9324 13.8794 12.0224 13.781 12.1232 13.6935C12.3524 13.4952 12.6499 13.3835 13.2441 13.1602L14.7807 12.5827C17.0007 11.7494 18.1107 11.3319 18.3057 10.4277C18.5016 9.52271 17.6632 8.68354 15.9857 7.00521Z"
                          fill="#19224C"
                        />
                      </svg>
                    </span>
                    next to Resala.
                  </p>
                  {/* <button
                    onClick={() => {
                      // if (curStep === 1) {
                      //   setCurStep(curStep + 1);
                      //   setCompletedSteps((prev) => [...prev, 1]);
                      // }
                    }}
                    className="w-fit p-[10px] mt-[20px] bg-[#1678F2] text-white flex justify-center items-center text-[16px] font-medium rounded-[6px]"
                  >
                    Done
                  </button> */}
                </div>
              </div>
            }
          />
        </div>
        <div className="py-[20px] border-b border-[#DFE4EC]">
          <StepHeader
            isActive={curStep === 2}
            title={"Get to know the basics"}
            content={
              <div className="flex flex-col">
                <div className="ml-[29px] mt-[20px]">
                  <img src={ExeInstalled} alt="ExeInstalled" />
                </div>
                <div className="flex flex-col gap-[12px] text-[#19224c] font-DMSansFont font-normal">
                  <p className="text-[16px] leading-[24px]">
                    New to Resala? Create an account or login to get started.
                  </p>

                  <button
                    disabled={isLoading}
                    onClick={async () => {
                      const authTokenFirst = JSON.parse(
                        localStorage.getItem("token")
                      );
                      const isValid = await valiadateUser(authTokenFirst);
                      if (isValid) {
                        window.postMessage(
                          {
                            type: "TOKEN_PASS",
                            access_token: authTokenFirst,
                          },
                          "*"
                        );
                      }
                      window.postMessage(
                        {
                          type: "OPEN_EXE",
                        },
                        "*"
                      );
                      navigate("/");
                    }}
                    className="w-fit mt-[20px] p-[10px] bg-[#1678F2] hover:bg-[#1263CB] text-white flex justify-center items-center text-[16px] font-medium rounded-[6px]"
                  >
                    {isLoading ? (
                      <div className="w-[80px] flex justify-center items-center">
                        <SpinnerLoader />
                      </div>
                    ) : (
                      "Open Resala"
                    )}
                  </button>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

export default TourContent;

const StepHeader = ({ isCompleted, isActive = false, content, title }) => (
  <div className="flex flex-col">
    <div className="flex gap-x-[8px] items-center">
      {isCompleted ? <CheckIcon /> : <OutlinedIcon />}
      <div
        style={{ color: isCompleted && "#1678F2" }}
        className="text-[18px] font-DMSansFont font-medium text-[#19224C] font-medium"
      >
        {title}
      </div>
    </div>
    {isActive && content}
  </div>
);

const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <rect y="0.5" width="20" height="20" rx="10" fill="#1678F2" />
    <path
      d="M13.1667 8.33301L8.81254 12.6663L6.83337 10.6966"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const OutlinedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
  >
    <circle cx="11" cy="11.5" r="10.5" fill="white" stroke="#DFE4EC" />
  </svg>
);
