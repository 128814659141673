export const GOOGLE_CLIENT_ID =
  "712708181947-kmjtmbnlfd1q3gvvvlbrh5146r930b4c.apps.googleusercontent.com";
// "1044217586405-h3u6nrggu2jrufe92gjf7o8erth0l7jh.apps.googleusercontent.com";

export const FACEBOOK_APP_ID = "1826515531100822";

export const APPLE_CLIENT_ID = "ai.resala.ResalaApp.service";
export const APPLE_REDIRECT_URI = "https://www.resala.ai/";

export const REDIRECTION_URL = "https://www.resala.ai";
export const BASE_URL = "https://api-qa.resala.ai";
// export const REDIRECTION_URL = 'http://localhost:3000';
// export const BASE_URL =
//   "https://eeed-2405-201-200d-286c-8c20-67e4-a615-9f57.ngrok-free.app";
