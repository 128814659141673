import React from "react";

const SupportCardLayout = ({ children, padding }) => {
  return (
    <div
      style={{ padding: padding ? padding : "" }}
      className="rounded-[20px] p-[30px] bg-[#F9F9FB]"
    >
      {children}
    </div>
  );
};

export default SupportCardLayout;

export const FAQIcon = () => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22" r="22" fill="#F3F4F8" />
    <path
      d="M27 28.4297H23L18.55 31.3897C17.89 31.8297 17 31.3598 17 30.5598V28.4297C14 28.4297 12 26.4297 12 23.4297V17.4297C12 14.4297 14 12.4297 17 12.4297H27C30 12.4297 32 14.4297 32 17.4297V23.4297C32 26.4297 30 28.4297 27 28.4297Z"
      stroke="#6D77A0"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.9998 21.3594V21.1494C21.9998 20.4694 22.4198 20.1094 22.8398 19.8194C23.2498 19.5394 23.6598 19.1794 23.6598 18.5194C23.6598 17.5994 22.9198 16.8594 21.9998 16.8594C21.0798 16.8594 20.3398 17.5994 20.3398 18.5194"
      stroke="#6D77A0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.9955 23.75H22.0045"
      stroke="#6D77A0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Arrow = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M10.8223 4.44922L15.3748 9.00172L10.8223 13.5542"
      stroke="#1678F2"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.625 9H15.2475"
      stroke="#1678F2"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EmailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M28.3335 35.4154H11.6668C5.5835 35.4154 2.0835 31.9154 2.0835 25.832V14.1654C2.0835 8.08203 5.5835 4.58203 11.6668 4.58203H28.3335C34.4168 4.58203 37.9168 8.08203 37.9168 14.1654V25.832C37.9168 31.9154 34.4168 35.4154 28.3335 35.4154ZM11.6668 7.08203C6.90016 7.08203 4.5835 9.3987 4.5835 14.1654V25.832C4.5835 30.5987 6.90016 32.9154 11.6668 32.9154H28.3335C33.1002 32.9154 35.4168 30.5987 35.4168 25.832V14.1654C35.4168 9.3987 33.1002 7.08203 28.3335 7.08203H11.6668Z"
      fill="#6D77A0"
    />
    <path
      d="M19.9995 21.4519C18.5995 21.4519 17.1828 21.0186 16.0995 20.1352L10.8828 15.9686C10.3495 15.5352 10.2495 14.7519 10.6828 14.2186C11.1161 13.6852 11.8995 13.5852 12.4328 14.0186L17.6495 18.1852C18.9161 19.2019 21.0661 19.2019 22.3328 18.1852L27.5495 14.0186C28.0828 13.5852 28.8828 13.6686 29.2995 14.2186C29.7328 14.7519 29.6495 15.5519 29.0995 15.9686L23.8828 20.1352C22.8161 21.0186 21.3995 21.4519 19.9995 21.4519Z"
      fill="#6D77A0"
    />
  </svg>
);

export const ChatIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M20.0002 38.0154C18.8502 38.0154 17.7668 37.432 17.0002 36.4154L14.5002 33.082C14.4502 33.0154 14.2502 32.932 14.1668 32.9154H13.3335C6.3835 32.9154 2.0835 31.032 2.0835 21.6654V13.332C2.0835 5.96536 5.96683 2.08203 13.3335 2.08203H26.6668C34.0335 2.08203 37.9168 5.96536 37.9168 13.332V21.6654C37.9168 29.032 34.0335 32.9154 26.6668 32.9154H25.8335C25.7002 32.9154 25.5835 32.982 25.5002 33.082L23.0002 36.4154C22.2335 37.432 21.1502 38.0154 20.0002 38.0154ZM13.3335 4.58203C7.36683 4.58203 4.5835 7.36536 4.5835 13.332V21.6654C4.5835 29.1987 7.16683 30.4154 13.3335 30.4154H14.1668C15.0168 30.4154 15.9835 30.8987 16.5002 31.582L19.0002 34.9154C19.5835 35.682 20.4168 35.682 21.0002 34.9154L23.5002 31.582C24.0502 30.8487 24.9168 30.4154 25.8335 30.4154H26.6668C32.6335 30.4154 35.4168 27.632 35.4168 21.6654V13.332C35.4168 7.36536 32.6335 4.58203 26.6668 4.58203H13.3335Z"
      fill="#6D77A0"
    />
    <path
      d="M20.0002 20.0013C19.0668 20.0013 18.3335 19.2513 18.3335 18.3346C18.3335 17.418 19.0835 16.668 20.0002 16.668C20.9168 16.668 21.6668 17.418 21.6668 18.3346C21.6668 19.2513 20.9335 20.0013 20.0002 20.0013Z"
      fill="#6D77A0"
    />
    <path
      d="M26.6667 20.0013C25.7333 20.0013 25 19.2513 25 18.3346C25 17.418 25.75 16.668 26.6667 16.668C27.5833 16.668 28.3333 17.418 28.3333 18.3346C28.3333 19.2513 27.6 20.0013 26.6667 20.0013Z"
      fill="#6D77A0"
    />
    <path
      d="M13.3332 20.0013C12.3998 20.0013 11.6665 19.2513 11.6665 18.3346C11.6665 17.418 12.4165 16.668 13.3332 16.668C14.2498 16.668 14.9998 17.418 14.9998 18.3346C14.9998 19.2513 14.2665 20.0013 13.3332 20.0013Z"
      fill="#6D77A0"
    />
  </svg>
);
