import { t } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { parsePhoneNumber } from "react-phone-number-input";
import { sendMobileOtp, updateMobileFinally } from "../../../../Exe-api/apis";
import SpinnerLoader from "../../../../utils/LoaderCommon";
import Otp from "../../Otp";
import { ButtonExeFilled, ButtonOutLine } from "../Button";
import InputField, { ErrorMsg } from "../InputField";
import { cn } from "../utils";
import { ModalTitle } from "./DeleteAccount";
import SelectMobileNumber from "./SelectMobileNumber";
import { TwoToneText } from "./UpdateEmail-old";

const UpdateMobile = ({
  isAddNew,
  userCountry,
  onClose,
  mobile_number,
  country_code,
  updateUserData,
}) => {
  const {
    i18n: { language, dir },
  } = useTranslation();

  const isArabic = language === "ar";
  const fontCommons = "text-[14px] leading-[18px] text-LightStrokActive";
  const [currentStep, setCurrentStep] = useState(1);
  const [isOtpSented, setIsOtpSented] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(undefined);
  const [inputMobileData, setInputMobileData] = useState(
    isAddNew ? "" : country_code + mobile_number
  );
  const [otp, setOtp] = useState(undefined);

  const numberInfo = parsePhoneNumber(
    mobile_number ? country_code + mobile_number : ""
  );
  if (!numberInfo && !isAddNew) {
    throw new Error("Invalid phone number");
  }

  const handleCancel = () => {
    onClose();
  };

  const SendOtp = async () => {
    setIsLoading(true);
    setIsOtpSented(false);
    try {
      const parseNum = parsePhoneNumber(inputMobileData);
      const res = await sendMobileOtp({
        phone_number: parseNum.nationalNumber,
        country_code: `+${parseNum.countryCallingCode}`,
      });
      if (res.Error || res.detail) {
        console.log("--- res.Error UpdateMobile.js [Line-no 49] ---", res);
        setErrMsg(res.Message);
        return;
      }
      setIsOtpSented(true);
      setCurrentStep(2);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateFinallyMobile = async () => {
    setIsLoading(true);
    try {
      const parseNum = parsePhoneNumber(inputMobileData);
      const payload = {
        otp: otp,
        phone_number: parseNum.nationalNumber,
        country_code: `+${parseNum.countryCallingCode}`,
      };
      const res = await updateMobileFinally(payload);
      if (res.Error || res.detail) {
        return;
      }
      updateUserData((prev) => {
        return {
          ...prev,
          mobile_number: parseNum.nationalNumber,
          country_code: `+${parseNum.countryCallingCode}`,
        };
      });
      onClose();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="w-[400px]">
        {currentStep === 1 && (
          <>
            <ModalTitle title={t("update_mobile_number")} />
            <p
              className={cn(
                "font-normal text-[14px] text-LightStrokActive mb-[2px]",
                isArabic ? "text-right" : "text-left"
              )}
            >
              {t("mobile_number")}
            </p>
            <SelectMobileNumber
              number={numberInfo?.number ?? ""}
              userCountry={numberInfo?.country ?? userCountry}
              setInputValue={(data) => {
                setErrMsg(undefined);
              }}
              onChange={setInputMobileData}
            />
            {errMsg && <ErrorMsg errorMsg={errMsg} />}
            {/* <div className="mt-[16px]">
              <TwoToneText
                fontCommons={fontCommons}
                text1="Verify your old email"
                text2="vsonani@dcdial.com"
              />
            </div> */}
            <div className="flex gap-x-[20px] mt-[20px]">
              <ButtonOutLine
                height={40}
                text={t("cancel")}
                fontColor={"#5F6583"}
                borderColor={"#DFE4EC"}
                onClick={handleCancel}
                hoverCss={
                  "hover:!text-LightPrimaryActive hover:!bg-[#eef6ff] hover:!border-[#D3E7FF]"
                }
              />
              <ButtonExeFilled
                height={40}
                isDisable={isLoading}
                text={isLoading ? <SpinnerLoader /> : t("send_verification")}
                fontColor={"#FFFFFF"}
                onClick={SendOtp}
                hoverCss={"hover:!bg-DarkHover"}
              />
            </div>
          </>
        )}

        {currentStep === 2 && (
          <>
            <ModalTitle title={t("otp_verification")} />
            <TwoToneText
              fontCommons={fontCommons}
              text1={t("enter_code_sent")}
              text2={<p dir="ltr">{inputMobileData}</p>}
            />
            <div className="mt-[16px]">
              <InputField
                type="number"
                maxlength={6}
                inputCss="text-center"
                // placeholder={t("enter_otp")}
                placeholder={"000 000"}

                value={otp || ""}
                error={undefined}
                onChange={(e) => {
                  if (e.target.value?.length > 6) return;
                  setOtp(e.target.value);
                }}
              />
            </div>
            <Otp startTimer={isOtpSented} resendOtp={() => SendOtp()} />
            <div className="flex gap-x-[20px] mt-[20px]">
              <ButtonExeFilled
                height={40}
                isDisable={isLoading}
                text={isLoading ? <SpinnerLoader /> : t("continue")}
                fontColor={"#FFFFFF"}
                onClick={() => updateFinallyMobile()}
                hoverCss={"hover:!bg-DarkHover"}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UpdateMobile;
