import React from "react";
import { t } from "i18next";
import i18n from "../i18";

const QueryTableMobile = ({
  tableData,
  onlyTitle = undefined,
  cellCss = "",
  planId,
}) => {
  const isArabic = i18n.language === "ar";
  const HeadingCellCss = `w-1/5 px-6 py-3 text-LightPrimary text-[16px] leading-[21px] tracking-[-1px] font-medium text-center ${cellCss}`;
  const BodyCellCss = `px-6 py-4 whitespace-nowrap text-[16px] leading-[18px] font-medium text-center text-LightPrimaryActive ${
    isArabic
      ? "border-r-[2px] border-r-[#F3F4F8]"
      : "border-l-[2px] border-l-[#F3F4F8]"
  } ${cellCss}`;

  let planType;
  if (planId === 1) {
    planType = "free";
  } else if (planId === 2) {
    planType = "start";
  } else if (planId === 3) {
    planType = "pro";
  } else if (planId === 4) {
    planType = "unlimited";
  }

  return (
    <div className="overflow-x-auto bg-white">
      <table className="min-w-full table-fixed">
        <thead className="px-[16px] py-[14px] relative bg-[#F3F4F8] rounded-tl-lg rounded-tr-lg">
          <tr>
            <th
              className={`min-w-[190px] w-1/5 px-6 py-3 text-LightPrimary text-[20px] leading-[30px] tracking-[-1px] font-medium ${
                isArabic ? "text-right" : "text-left"
              }`}
            >
              {onlyTitle ?? t("Features")}
            </th>
            {["free", "start", "pro", "unlimited"].includes(planType) && (
              <th className={HeadingCellCss}>
                {onlyTitle
                  ? ""
                  : t(planType.charAt(0).toUpperCase() + planType.slice(1))}
              </th>
            )}
          </tr>
        </thead>
        <tbody className="bg-white">
          {tableData.map((item, idx) => (
            <tr key={idx}>
              <td className="min-w-[190px] px-6 py-4 text-[#5F6583] text-[16px] leading-[21px] font-medium">
                {t(item.feature)}
              </td>
              {["free", "start", "pro", "unlimited"].includes(planType) && (
                <td className={BodyCellCss}>{item[planType]}</td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QueryTableMobile;
