import React from "react";
import Layout from "./Layout";
import CardWrapper from "./CardWrapper";
import WelcomeComp from "./WelcomeComp";
import TourContent from "./TourContent";

const ExeTour = () => {
  return (
    <Layout>
      <CardWrapper>
        <div className="grid grid-cols-2 h-full">
          <WelcomeComp />
          <TourContent />
        </div>
      </CardWrapper>
    </Layout>
  );
};

export default ExeTour;
