import React from "react";
import { cn } from "./utils";

const UserProfile = ({ width, height, name, fontSize }) => {
  return (
    <div
      className={cn(
        `w-[40px] h-[40px] rounded-full F-JC-AI-CENTER text-white font-DMSansFont font-bold text-[17px]`,
        name ? "bg-purpleText" : "bg-transparent"
      )}
      style={{
        width: width ? width : "",
        height: height ? height : "",
        fontSize: fontSize ? fontSize : "17px",
      }}
    >
      <p>{name}</p>
    </div>
  );
};

export default UserProfile;
