import React from "react";

const PaymentTabs = ({
  children,
  lg = false,
  icon,
  name,
  isActive,
  activeIcon,
}) => {
  return (
    <div
      className={`hoverBorderDarkGray  ${
        isActive ? "bg-[#EEF6FF] border-[#D3E7FF]" : "bg-white border-[#DFE4EC]"
      } ${children ? " justify-between" : "justify-start"} ${
        lg ? "h-[50px] bg-dropdownbg" : "h-[40px]"
      } 
      w-full rounded-[7px] cursor-pointer flex items-center px-[15px] py-[11px] text-[14px] border-[1px] hoverBgLightGray `}
    >
      <div className="flex gap-x-[9px] justify-center items-center">
        {isActive ? activeIcon : icon}
        <div className="text-[14px] leading-[18px] text-[#19224C]">{name}</div>
      </div>
      <div className="flex justify-center items-center">{children}</div>
    </div>
  );
};

export default PaymentTabs;
