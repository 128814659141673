import React, { useState } from "react";
import { ButtonExeFilled } from "../Button";
import { cn } from "../utils";
import axios from "axios";

const LabelCss =
  "block text-LightStrokActive uppercase text-[12px] font-bold mb-[3px]";
const InputCss =
  "appearance-none text-[12px] bg-transparent h-[32px] border rounded-[6px] border-LightStrokPrimary w-full text-LightStrokActive py-1 px-2 focus:outline-none";

const BankPay = () => {
  const [formData, setFormData] = useState({
    holderName: "",
    accountNumber: "",
    reEnterAccountNumber: "",
    rountingNumber: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(undefined);

  const [bankName, setBankName] = useState("");
  const [displayInvalidNumberError, setDisplayInvalidNumberError] =
    useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData?.accountNumber !== formData?.reEnterAccountNumber) {
      setErrMsg("Account number not matched.");
      setDisplayInvalidNumberError(true);
      return;
    }
  };

  const handleChange = (e, type) => {
    setDisplayInvalidNumberError(false);
    if (type === "rountingNumber") {
      if (e.target.value.length < 10) {
        setFormData({
          ...formData,
          [type]: e.target.value,
        });
        setBankName("");
        // setErrMsg(undefined)
        if (e.target.value.length === 9) {
          findBankByRoutingNumber(e.target.value);
        }
      }
    } else if (type === "reEnterAccountNumber") {
      if (formData?.accountNumber?.length === e.target.value?.length) {
        if (formData?.accountNumber !== e.target.value) {
          setDisplayInvalidNumberError(true);
        } else {
          setDisplayInvalidNumberError(false);
        }
      }
      setFormData({
        ...formData,
        [type]: e.target.value,
      });
    } else {
      console.log("update here....");
      setFormData({
        ...formData,
        [type]: e.target.value,
      });
    }
  };

  const findBankByRoutingNumber = (routingNumber) => {
    setIsLoading(true);
    axios
      .get(`https://www.routingnumbers.info/api/data.json?rn=${routingNumber}`)
      .then((res) => {
        if (res.data.code === 200) {
          const { customer_name, address, city, state } = res.data;
          setFormData((prev) => {
            return {
              ...prev,
              customer_name,
              address,
              city,
              state,
            };
          });
          setIsLoading(false);
          setErrMsg(undefined);
          setBankName(res.data.customer_name + ", " + res.data.state);
        } else {
          setIsLoading(false);
          setErrMsg("Invalid routing number");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setErrMsg("Invalid routing number");
      });
  };

  return (
    <form onSubmit={handleSubmit} className="w-full mt-[40px]">
      <div className="mt-[20px]">
        <label className={cn(LabelCss, "")}>Account holder name</label>

        <input
          className={cn(InputCss, "text-LightPrimary")}
          type="text"
          value={formData.holderName}
          placeholder="Full name of account holder"
          onChange={(e) => handleChange(e, "holderName")}
        />
      </div>
      <div className="mt-[20px]">
        <label className={cn(LabelCss, "")}>Account Number</label>

        <input
          className={cn(InputCss, "text-LightPrimary")}
          type="text"
          value={formData.accountNumber}
          placeholder="000123456789"
          onChange={(e) => handleChange(e, "accountNumber")}
        />
      </div>
      <div className="mt-[20px]">
        <label className={cn(LabelCss, "")}>Re-enter Account Number</label>

        <input
          className={cn(InputCss, "text-LightPrimary")}
          type="text"
          value={formData.reEnterAccountNumber}
          placeholder="000123456789"
          onChange={(e) => handleChange(e, "reEnterAccountNumber")}
        />
      </div>
      <div className="mt-[20px]">
        <label className={cn(LabelCss, "")}>Routing number</label>

        <input
          className={cn(InputCss, "text-LightPrimary")}
          type="text"
          value={formData.rountingNumber}
          placeholder="123456789"
          onChange={(e) => handleChange(e, "rountingNumber")}
        />
      </div>

      <div className="w-full mt-[40px]">
        <ButtonExeFilled
          type="submit"
          height={40}
          isDisable={false}
          text={"Subscribe"}
          fontColor={"#FFFFFF"}
          onClick={() => {}}
          hoverCss={"hover:!bg-DarkHover"}
        />
      </div>
    </form>
  );
};

export default BankPay;
