/* eslint-disable react-hooks/exhaustive-deps */
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import AppleLogin from "react-apple-login";
import ReactFacebookLogin from "react-facebook-login";
import { parsePhoneNumber } from "react-phone-number-input";
import { useLocation, useNavigate } from "react-router-dom";
import GradientBg from "../../Components/GradientBg";
import {
  OtpCheck,
  OtpMobileCheck,
  alreadyRegistered,
  checkFbAccExistById,
  loginWithApple,
  loginWithFacebook,
  loginWithGoogle,
  registerFBSendOtp,
  registerUser,
} from "../../api/auth/auth";
import {
  APPLE_CLIENT_ID,
  APPLE_REDIRECT_URI,
  FACEBOOK_APP_ID,
} from "../../config";
import PasswordValidator from "./Components/AccoutSetting/PasswordValidator";
import SelectMobileNumber from "./Components/AccoutSetting/SelectMobileNumber";
import {
  CloseEyeIcon,
  EyeIcon,
} from "./Components/AccoutSetting/UpdatePassword";
import { ButtonExeFilled } from "./Components/Button";
import InputField, { ErrorMsg } from "./Components/InputField";
import Successful from "./Components/Successful";
import WhatsappCheck from "./Components/WhatsappCheck";
import {
  AppleIcon,
  FacebookIcon,
  GoogleIcon,
  ResalaLogo,
  Socialbutton,
} from "./Icons/Icons";
import Otp from "./Otp";
import { useDetectCountry } from "./customHook/useDetectCountry";
import { ButtonFilled } from "../../Components/Button";
import SpinnerLoader from "../../utils/LoaderCommon";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { ArabicLogo } from "./layout/Sidebar";
import { cn } from "./Components/utils";
import { Footer, langEnum } from "./Login";
import { TransparentLangSelect } from "./Components/LangSelect";

const Register = () => {
  const passRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const ReferCode = location?.search
    ? location.search.replace("?", "")
    : undefined;

  const userBrowserCountry = useDetectCountry();
  const [currentStep, setCurrentStep] = useState(1);
  const [isPassShow, setIsPassShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isWhatsApp, setIsWhatsApp] = useState("true");
  const [isOtpSented, setIsOtpSented] = useState(false);
  const [showToaster, setShowToaster] = useState(false);

  const [userInput, setUserInput] = useState({
    email: "",
    password: "",
    fname: "",
    lname: "",
    otp: "",
  });
  const ErrInitial = {
    email: undefined,
    password: undefined,
    fname: undefined,
    lname: undefined,
    mobileNumber: undefined,
    otp: undefined,
    register: undefined,
  };
  const [errorMsg, setErrorMsg] = useState(ErrInitial);

  const initialState = {
    isEmailOtpVerifyScreen: false,
    isEmailOtpSented: false,
    isEmailOtpVerifying: false,
    emailOtp: "",
    emailOtpError: undefined,
    emailOtpInvalidError: undefined,
    isSendingEmailOTP: false,
    startTimer: false,
  };
  const [emailOtpMeta, setEmailOtpMeta] = useState(initialState);

  const [inputMobileData, setInputMobileData] = useState({
    country_code: "",
    phone_number: "",
    is_whatapp: false,
  });

  const {
    i18n: { language },
  } = useTranslation();
  const isArabic = language === "ar";

  useEffect(() => setErrorMsg(ErrInitial), [inputMobileData]);

  const handleChange = (e) => {
    setErrorMsg(ErrInitial);
    const { name, value } = e.target;
    if (name === "otp") {
      if (e.target.value.length > 6) return;
      setUserInput({ ...userInput, [name]: value });
    } else {
      setUserInput({ ...userInput, [name]: value });
    }
  };

  useEffect(() => {
    if (userInput?.otp.length === 6) {
      verifyOtp();
    }
  }, [userInput?.otp]);

  useEffect(() => {
    if (emailOtpMeta.emailOtp?.length === 6) {
      verifyEmailOtp();
    }
  }, [emailOtpMeta.emailOtp]);

  const verifyEmailOtp = async () => {
    setEmailOtpMeta((prev) => ({
      ...prev,
      isEmailOtpVerifying: true,
    }));
    try {
      const res = await OtpCheck({
        otp: emailOtpMeta.emailOtp,
        email: userInput.email.trim(),
        interface_language: langEnum[language],
      });
      if (res.data?.Error) {
        // setIsLoading(false);
        // setErrMsg({ ...errMsg, otp: res.data.Message });
        setEmailOtpMeta((prev) => ({
          ...prev,
          isEmailOtpVerifying: false,
          emailOtpInvalidError: res.data.Message,
        }));
        return;
      }
      setEmailOtpMeta(initialState);
      setCurrentStep(3);
    } catch (error) {
      console.log("error");
      setEmailOtpMeta((prev) => ({
        ...prev,
        isEmailOtpVerifying: false,
      }));
    }
  };

  const checkAlreadyExist = async () => {
    setIsLoading(true);
    try {
      const res = await alreadyRegistered({
        email: userInput.email.trim(),
        interface_language: langEnum[language],
      });
      if (res.data?.Error) {
        if (res.data?.is_deleted) {
          navigate("/account-error");
          return;
        }
        setErrorMsg({ ...errorMsg, email: res.data.Message });
        return;
      }
      setCurrentStep(2);
    } catch (error) {
      console.log("err");
    } finally {
      setIsLoading(false);
    }
  };

  const handleContinue = (e) => {
    e.preventDefault();
    switch (currentStep) {
      case 1:
        checkAlreadyExist();
        break;
      case 2:
        console.log("--- verify email Register.js [Line-no 145] ---");
        handleEmailOtpSend();
        // setCurrentStep(3);
        break;
      case 3:
        setCurrentStep(4);
        break;
      case 4:
        handleSendOtp();
        break;
      case 5:
        verifyOtp();

        // signUpUser();
        // setCurrentStep(6);
        break;
      default:
    }
  };

  const handleEmailOtpSend = async () => {
    setEmailOtpMeta((prev) => ({
      ...prev,
      isSendingEmailOTP: true,
      startTimer: false,
    }));
    try {
      const res = await registerFBSendOtp({
        email: userInput.email.trim(),
        interface_language: langEnum[language],
      });
      if (res?.data?.Error || res.detail) {
        setEmailOtpMeta((prev) => ({
          ...prev,
          emailOtpError: res?.data?.Message,
        }));
        return;
      }
      setEmailOtpMeta((prev) => ({
        ...prev,
        isEmailOtpVerifyScreen: true,
        isEmailOtpSented: true,
        isSendingEmailOTP: false,
        startTimer: true,
      }));
      setIsOtpSented(true);
      setCurrentStep(3);
    } catch (error) {
      console.log(error);
      setEmailOtpMeta((prev) => ({
        ...prev,
        isSendingEmailOTP: false,
        isEmailOtpSented: false,
      }));
    }
  };

  const handleSendOtp = async () => {
    setInputMobileData((prev) => {
      return {
        ...prev,
        is_whatapp: isWhatsApp === "true" ? true : false,
      };
    });
    setIsLoading(true);
    setIsOtpSented(false);
    try {
      const parseNum = parsePhoneNumber(inputMobileData.phone_number);
      const res = await OtpMobileCheck({
        is_whatapp: isWhatsApp === "true" ? true : false,
        phone_number: parseNum.nationalNumber,
        country_code: `+${parseNum.countryCallingCode}`,
        interface_language: langEnum[language],
      });

      if (res.data?.Error || res.data?.detail) {
        setErrorMsg({
          ...errorMsg,
          mobileNumber: res.data?.detail ? "Server Error" : res.data.Message,
        });
        return;
      }
      setIsOtpSented(true);
      setErrorMsg(ErrInitial);
      showToast();
      setCurrentStep(5);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const verifyOtp = async () => {
    setIsLoading(true);
    const parseNum = parsePhoneNumber(inputMobileData.phone_number);
    const payload = {
      country_code: `+${parseNum.countryCallingCode}`,
      otp: userInput.otp,
      phone_number: parseNum.nationalNumber,
      interface_language: langEnum[language],
    };
    try {
      const res = await OtpCheck(payload);
      if (res.data?.Error) {
        setIsLoading(false);
        setErrorMsg({ ...errorMsg, otp: res.data.Message });
        return;
      }
      signUpUser({ isAddMobile: true });
    } catch (error) {
      console.log("err");
      setIsLoading(false);
    }
  };

  const signUpUser = async ({ isAddMobile }) => {
    const { phone_number, is_whatapp } = inputMobileData || {};
    const parseNum = parsePhoneNumber(phone_number) ?? undefined;
    const payload = {
      first_name: userInput.fname,
      last_name: userInput.lname,
      email: userInput.email,
      password: userInput.password,
      // organization_name: organization,
      interface_language: langEnum[language],
      ...(isAddMobile && {
        country_code: parseNum ? `+${parseNum?.countryCallingCode}` : "",
      }),
      ...(isAddMobile && {
        phone_number: parseNum ? parseNum?.nationalNumber : "",
      }),
      is_whatapp: is_whatapp,
      ...(ReferCode && { reference_code: ReferCode }),
      country_name: userBrowserCountry ?? "US",
    };
    setIsLoading(true);
    try {
      const res = await registerUser(payload);
      if (res.data?.Error) {
        setErrorMsg({ ...errorMsg, otp: res.data.Message });
        return;
      }
      setCurrentStep(6);
      const { access_token } = res.Result || {};
      sendTokenToExe(access_token);
      localStorage.setItem("token", JSON.stringify(access_token));
      setIsLoading(false);
    } catch (error) {
      console.log("err");
    } finally {
      setIsLoading(false);
    }
  };

  const inputPassword = useMemo(() => {
    const value = userInput.password;
    return {
      minLength: value ? value?.length >= 8 : false,
      uppercase: value ? /[A-Z]/.test(value) : false,
      lowercase: value ? /[a-z]/.test(value) : false,
      number: value ? /\d/.test(value) : false,
      specialChar: value
        ? /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value)
        : false,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInput]);

  const showToast = () => {
    setShowToaster(true);
    setTimeout(() => {
      setShowToaster(false);
    }, 5000);
    // resetToaster(TOAST_TIME); // Reset the timer when showing the toaster
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${tokenResponse.access_token}`
        )
        .then((res) => {
          const message = {
            type: "GOOGLE_LOGIN",
            data: { ...res.data, token: tokenResponse.access_token },
          };
          if (message?.data) {
            googleLogin(message.data);
          }
        })
        .catch((err) => console.log(err));
    },
    onError: () => console.log("Login Failed"),
  });

  const googleLogin = async (data) => {
    const { email, id, given_name, family_name, picture } = data;
    const payload = {
      email: email,
      google_id: id,
      first_name: given_name,
      last_name: family_name,
      profile_picture: picture,
      interface_language: langEnum[language],
      country_name: userBrowserCountry ?? "US",
      ...(ReferCode && { reference_code: ReferCode }),
    };

    try {
      const res = await loginWithGoogle(payload);
      if (res.data?.Error || res.detail) {
        if (res.data?.is_deleted) {
          navigate("/account-error");
          return;
        }
        console.log(res);
        // setErrorMsg({ ...errorMsg, email: res.data.Message });
        return;
      }

      // const { access_token } = res.Result || {};
      // navigate(`/auth/${access_token}`);
      setIsLoading(true);
      const { access_token } = res.Result || {};
      sendTokenToExe(access_token);
      localStorage.setItem("token", JSON.stringify(access_token));
      window.location.href = "/profile";
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const responseFacebook = (response) => {
    if (response.status === "unknown") return;
    const message = {
      type: "FACEBOOK_LOGIN",
      data: response,
    };
    const { email } = message.data;
    if (email) {
      facebookLogin(message.data);
    } else {
      checkFBAlreadyLinked(message.data);
    }
  };

  const checkFBAlreadyLinked = async (fbMetaData) => {
    const { id } = fbMetaData;
    setIsLoading(true);
    try {
      const res = await checkFbAccExistById({
        facebook_id: id,
        interface_language: langEnum[language],
      });
      if (res.Error || res.detail) {
        console.log(res);
        return;
      }
      if (res.Result.is_exist) {
        setIsLoading(false);
        facebookLogin(fbMetaData);
      } else {
        setIsLoading(false);
        localStorage.setItem("fb_meta", JSON.stringify(fbMetaData));
        navigate(`/email-connect`);
      }

      // navigate(`/auth/${access_token}`);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const facebookLogin = async (data) => {
    const { name, picture, email, userID } = data;
    const payload = {
      email: email,
      name: name,
      facebook_id: userID,
      profile_picture: picture.data.url,
      interface_language: langEnum[language],
      country_name: userBrowserCountry ?? "US",
      ...(ReferCode && { reference_code: ReferCode }),
    };
    try {
      const res = await loginWithFacebook(payload);
      if (res.data?.Error || res.detail) {
        if (res.data?.is_deleted) {
          navigate("/account-error");
          return;
        }
        console.log(res);
        return;
      }
      setIsLoading(true);
      // const { access_token } = res.Result || {};
      // navigate(`/auth/${access_token}`);

      const { access_token } = res.Result || {};
      sendTokenToExe(access_token);
      localStorage.setItem("token", JSON.stringify(access_token));

      window.location.href = "/profile";
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const responseApple = (response) => {
    if (response.error) return;
    const message = {
      type: "APPLE_LOGIN",
      data: response,
    };
    console.log(message);
    appleLogin(message.data);
  };

  const appleLogin = async (data) => {
    const { code, id_token } = data.authorization;
    const userData = data;
    const payload = {
      code: code,
      token: id_token,
      interface_language: langEnum[language],
      country_name: userBrowserCountry ?? "US",
      ...(userData?.user !== undefined && {
        first_name: userData.user.name.firstName,
      }),
      ...(userData?.user !== undefined && {
        last_name: userData.user.name.lastName,
      }),
      ...(ReferCode && { reference_code: ReferCode }),
    };
    try {
      const res = await loginWithApple(payload);
      if (res.data?.Error || res.detail) {
        if (res.data?.is_deleted) {
          navigate("/account-error");
          return;
        }
        console.log(res);
        return;
      }
      setIsLoading(true);
      // const { access_token } = res.Result || {};
      // navigate(`/auth/${access_token}`);

      const { access_token } = res.Result || {};
      sendTokenToExe(access_token);
      localStorage.setItem("token", JSON.stringify(access_token));

      window.location.href = "/profile";
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const sendTokenToExe = (access_token) => {
    window.postMessage({ type: "TOKEN_PASS", access_token: access_token }, "*");
  };

  const isContinueDisable =
    isLoading ||
    (currentStep === 1 && userInput.email.length === 0) ||
    (currentStep === 2 && userInput.password.length === 0) ||
    (currentStep === 3 && userInput.fname.length === 0) ||
    (currentStep === 3 && userInput.lname.length === 0);

  return (
    <GradientBg>
      <div className="w-full flex justify-center items-center min-h-screen">
        <div
          style={{ boxShadow: "0px 4px 60px 0px #3C425714" }}
          className="w-[600px] h-fit rounded-[20px] bg-white py-[30px] px-[60px] flex flex-col items-center"
        >
          {currentStep !== 6 && (
            <>
              <ResalaLogo
                onClick={() => navigate("/")}
                className={cn(
                  "min-h-[40px] cursor-pointer",
                  !isArabic ? "block" : "hidden"
                )}
              />
              <ArabicLogo
                onClick={() => navigate("/")}
                className={cn(
                  "min-h-[40px] cursor-pointer",
                  isArabic ? "block" : "hidden"
                )}
              />
            </>
          )}
          {currentStep < 3 && (
            <>
              <div className="Heading pb-[8px] !mb-[30px] mt-[30px]">
                {t("create_account")}
              </div>
              {/* {currentStep === 1 && (
                <div className="SubHeading pb-[40px] font-normal w-[84%]">
                  {t("phono_verify_reqired")} <br /> {t("purpose")}.
                </div>
              )} */}
              <form className="w-full" onSubmit={handleContinue}>
                {/* EMIAL FIELD */}
                <InputField
                  name="email"
                  type={"text"}
                  className={"mb-16px"}
                  inputCss="px-[18px] py-[15px]"
                  value={userInput.email}
                  isDisabled={currentStep > 1}
                  onChange={handleChange}
                  placeholder={t("email_address")}
                  error={errorMsg.email}
                  suffix={
                    currentStep === 2 && (
                      <div
                        className="min-w-25px text-LightPrimaryActivefont-normal"
                        onClick={() => {
                          setCurrentStep(1);
                        }}
                      >
                        {t("edit")}
                      </div>
                    )
                  }
                />

                {/* CAPTCH */}
                {/* {currentStep === 1 && (
              <Recaptch
                isDisabled={isCheckingEmail}
                ref={register}
                error={errors?.isValid?.message ?? undefined}
                {...register("isValid")}
              />
            )} */}

                {currentStep === 2 && (
                  <>
                    <div className="w-full">
                      <InputField
                        ref={passRef}
                        name="password"
                        onChange={handleChange}
                        className={"mt-[12px]"}
                        value={userInput.password}
                        inputCss="px-[18px] py-[15px]"
                        placeholder={t("password")}
                        type={isPassShow ? "text" : "password"}
                        error={errorMsg.password}
                        suffix={
                          <div
                            onClick={() => setIsPassShow((prev) => !prev)}
                            className="min-w-[25px]"
                          >
                            {isPassShow ? <CloseEyeIcon /> : <EyeIcon />}
                          </div>
                        }
                      />
                    </div>
                    <div className="mt-[12px]">
                      <PasswordValidator require={inputPassword} />
                    </div>
                  </>
                )}
                <div className="pt-[24px]">
                  <ButtonExeFilled
                    height={"52px"}
                    variant={"primary"}
                    fontColor={"#FFFFFF"}
                    isDisable={
                      isContinueDisable ||
                      (currentStep === 2 &&
                        Object.values(inputPassword).includes(false)) ||
                      emailOtpMeta.isSendingEmailOTP
                    }
                    hoverCss={"hover:!bg-DarkHover"}
                    text={
                      isLoading || emailOtpMeta.isSendingEmailOTP ? (
                        <SpinnerLoader />
                      ) : (
                        t("continue")
                      )
                    }
                  ></ButtonExeFilled>
                </div>
              </form>

              <div
                dir={isArabic ? "rtl" : "ltr"}
                className="resala-signup-login"
              >
                <span className="text-[#6d77a0] font-medium">
                  {t("already_account")}
                </span>
                <button
                  className={`font-medium ${
                    false ? "opacity-50 cursor-not-allowed" : "text-[#1678f2]"
                  }`}
                  onClick={() => {
                    navigate("/login");
                  }}
                  disabled={false}
                >
                  {t("log_in")}
                </button>
              </div>

              {currentStep === 1 && (
                <>
                  {/* SEPERATOR LINE */}
                  <div className="h-[1px] bg-LightStrokPrimary w-full min-h-[1px] relative mt-[32px]">
                    <p
                      style={{
                        transform: "translate(-50%)",
                      }}
                      className="absolute top-[-8px] left-1/2 text-[14px] leading-[16px] font-bold text-[#6D77A0] px-[6px] bg-white w-fit -translate-x-1/5"
                    >
                      {t("or")}
                    </p>
                  </div>

                  {/* SECTION 2 */}
                  <div className="mt-[32px] flex flex-col gap-[12px] w-full">
                    {/* GOOGLE LOGIN */}
                    <Socialbutton
                      dir={isArabic ? "rtl" : "ltr"}
                      className="socialLogin  hover:bg-LightBgPrimary hover:text-LightPrimary hover:border-[#B1BED2]"
                      icon={<GoogleIcon />}
                      text={t("continue_with_google")}
                      onClick={() => login()}
                    />

                    {/* FACEBOOK LOGIN */}
                    <ReactFacebookLogin
                      buttonStyle={{ direction: isArabic ? "rtl" : "ltr" }}
                      cssClass={cn(
                        "socialLogin px-[15px] py-[14px] text-[14px] leading-[16px] text-[#6D77A0] border text-[#6D77A0] border-LightStrokPrimary flex gap-x-[8px] justify-start items-center w-full rounded-[6px] hover:bg-LightBgPrimary hover:text-LightPrimary hover:border-[#B1BED2]"
                      )}
                      appId={FACEBOOK_APP_ID}
                      autoLoad={false}
                      icon={<FacebookIcon />}
                      fields="name,email,picture"
                      callback={responseFacebook}
                      textButton={t("continue_with_facebook")}
                    />

                    {/* APPLE LOGIN */}
                    <AppleLogin
                      scope="email name"
                      state="origin:web"
                      clientId={APPLE_CLIENT_ID}
                      redirectURI={APPLE_REDIRECT_URI}
                      responseType="code id_token"
                      responseMode="form_post query fragment"
                      usePopup={true}
                      callback={responseApple}
                      render={(props) => (
                        <Socialbutton
                          {...props}
                          dir={isArabic ? "rtl" : "ltr"}
                          className="socialLogin hover:bg-LightBgPrimary hover:text-LightPrimary hover:border-[#B1BED2]"
                          icon={<AppleIcon />}
                          text={t("continue_with_apple")}
                        />
                      )}
                    />
                  </div>
                </>
              )}
            </>
          )}

          {emailOtpMeta.isEmailOtpVerifyScreen && (
            <div className="w-full mt-[30px]">
              <div className="Heading !mb-[8px] text-center">
                {t("enter_code")}
              </div>
              <div className="SubHeading mb-[30px] !gap-[4px] !flex-row">
                {t("enter_sented_to")}{" "}
                <span className="font-bold">{userInput.email}</span>
              </div>
              <InputField
                name="otp"
                type="number"
                maxlength={6}
                inputCss="text-center w-full"
                placeholder={"000 000"}
                value={emailOtpMeta.emailOtp || ""}
                error={emailOtpMeta.emailOtpInvalidError}
                onChange={(e) => {
                  if (e.target.value.length > 6) return;
                  setEmailOtpMeta((prev) => ({
                    ...prev,
                    emailOtp: e.target.value,
                  }));
                }}
              />
              <Otp
                isLoading={emailOtpMeta.isEmailOtpVerifying}
                startTimer={emailOtpMeta.startTimer}
                resendOtp={() => {
                  setEmailOtpMeta((prev) => ({
                    ...prev,
                    isSendingEmailOTP: false,
                    startTimer: true,
                  }));
                  handleEmailOtpSend();
                }}
              />
              <ButtonExeFilled
                type="button"
                isDisabled={emailOtpMeta.isEmailOtpVerifying}
                borderColor={"transparent"}
                backgroundColor={"transparent"}
                hoverCss={
                  "text-[#1678F2] hover:text-[#1263CB] !text-[14px] leading-[16px] font-bold mt-[20px]"
                }
                onClick={() => {
                  setCurrentStep(2);
                  setEmailOtpMeta(initialState);
                }}
                text={t("go_back")}
              ></ButtonExeFilled>
            </div>
          )}

          {!emailOtpMeta.isEmailOtpVerifyScreen && currentStep === 3 && (
            <>
              <div className="Heading my-[30px]">{t("tell_us_about_you")}</div>
              <form className="w-full" onSubmit={handleContinue}>
                <div className="grid grid-cols-2 gap-[8px]">
                  <InputField
                    name="fname"
                    onChange={handleChange}
                    placeholder={`${t("first_name")}*`}
                    value={userInput.fname}
                    error={errorMsg.fname}
                    className={"mt-[16px]"}
                    containerCss={"w-171px"}
                    inputCss="px-[18px] py-[15px]"
                    maxlength={15}
                  />
                  <InputField
                    name="lname"
                    onChange={handleChange}
                    // placeholder={"Last Name*"}
                    placeholder={`${t("last_name")}*`}
                    value={userInput.lname}
                    error={errorMsg.lname}
                    className={"mt-[16px]"}
                    containerCss={"w-171px"}
                    inputCss="px-[18px] py-[15px]"
                    maxlength={15}
                  />
                </div>
                <ButtonExeFilled
                  height={"52px"}
                  text={t("continue")}
                  fontColor={"#FFFFFF"}
                  variant={"primary"}
                  isDisable={isContinueDisable}
                  hoverCss={"hover:!bg-DarkHover mt-[16px]"}
                ></ButtonExeFilled>
                <ButtonExeFilled
                  type="button"
                  height={52}
                  isDisabled={isLoading}
                  // fontColor={"#1678F2"}
                  borderColor={"transparent"}
                  backgroundColor={"transparent"}
                  hoverCss={
                    "text-[#1678F2] hover:text-[#1263CB] !text-[14px] leading-[16px] font-bold"
                  }
                  onClick={() => {
                    setCurrentStep(2);
                    // handleBack();
                  }}
                  text={t("go_back")}
                ></ButtonExeFilled>
              </form>
            </>
          )}

          {currentStep === 4 && (
            <>
              <div className="Heading my-[30px]">{t("verify_phone")}</div>
              <SelectMobileNumber
                userCountry={userBrowserCountry ?? "US"}
                setInputValue={setInputMobileData}
              />
              {errorMsg.mobileNumber && (
                <div className="w-full">
                  <ErrorMsg
                    errorMsg={errorMsg.mobileNumber}
                    dir={isArabic ? "rtl" : "ltr"}
                  />
                </div>
              )}
              <WhatsappCheck
                selectedOption={isWhatsApp}
                onChange={(e) => {
                  setIsWhatsApp(e.target.value);
                }}
              />
              <div className="col-span-full mt-[24px] w-full">
                {isLoading && (
                  <div class="text-center mb-[9px]">
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        class="inline w-8 h-8 mr-2 text-gray-200 dark:text-gray-600 fill-blue-600 spin-animation "
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                )}

                {/* {isWhatsApp === "true" && ( */}
                <ButtonFilled
                  className={"mt-[10px]"}
                  height={"52px"}
                  onClick={() => {
                    handleSendOtp(true, true);
                    setInputMobileData((prev) => {
                      return {
                        ...prev,
                        is_whatapp: true,
                      };
                    });
                  }}
                  title={
                    isWhatsApp === "true" ? (
                      <div className="inline-flex items-center gap-4px">
                        <span className="text-16px font-normal leading-16px">
                          {t("send_code_via")}{" "}
                        </span>
                        <span>
                          <svg
                            className="mx-[6px]"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.7 2.27334C11.0888 1.65595 10.3608 1.16645 9.55843 0.833371C8.75606 0.500294 7.89541 0.330311 7.02667 0.333337C3.38667 0.333337 0.420002 3.3 0.420002 6.94C0.420002 8.10667 0.726669 9.24 1.3 10.24L0.366669 13.6667L3.86667 12.7467C4.83333 13.2733 5.92 13.5533 7.02667 13.5533C10.6667 13.5533 13.6333 10.5867 13.6333 6.94667C13.6333 5.18 12.9467 3.52 11.7 2.27334ZM7.02667 12.4333C6.04 12.4333 5.07334 12.1667 4.22667 11.6667L4.02667 11.5467L1.94667 12.0933L2.5 10.0667L2.36667 9.86C1.8185 8.98465 1.52743 7.97283 1.52667 6.94C1.52667 3.91334 3.99333 1.44667 7.02 1.44667C8.48667 1.44667 9.86667 2.02 10.9 3.06C11.4117 3.56931 11.8171 4.17511 12.0929 4.84229C12.3687 5.50946 12.5094 6.22474 12.5067 6.94667C12.52 9.97334 10.0533 12.4333 7.02667 12.4333ZM10.04 8.32667C9.87333 8.24667 9.06 7.84667 8.91333 7.78667C8.76 7.73334 8.65333 7.70667 8.54 7.86667C8.42667 8.03334 8.11333 8.40667 8.02 8.51334C7.92667 8.62667 7.82667 8.64 7.66 8.55334C7.49333 8.47334 6.96 8.29334 6.33333 7.73334C5.84 7.29334 5.51333 6.75334 5.41333 6.58667C5.32 6.42 5.4 6.33334 5.48667 6.24667C5.56 6.17334 5.65334 6.05334 5.73334 5.96C5.81333 5.86667 5.84667 5.79334 5.9 5.68667C5.95334 5.57334 5.92667 5.48 5.88667 5.4C5.84667 5.32 5.51333 4.50667 5.38 4.17334C5.24667 3.85334 5.10667 3.89334 5.00667 3.88667H4.68667C4.57333 3.88667 4.4 3.92667 4.24667 4.09334C4.1 4.26 3.67334 4.66 3.67334 5.47334C3.67334 6.28667 4.26667 7.07334 4.34667 7.18C4.42667 7.29334 5.51333 8.96 7.16667 9.67334C7.56 9.84667 7.86667 9.94667 8.10667 10.02C8.5 10.1467 8.86 10.1267 9.14667 10.0867C9.46667 10.04 10.1267 9.68667 10.26 9.3C10.4 8.91334 10.4 8.58667 10.3533 8.51334C10.3067 8.44 10.2067 8.40667 10.04 8.32667Z"
                              fill="#FFFFFF"
                            />
                          </svg>
                        </span>
                        <span className="text-16px font-normal leading-16px">
                          {t("whatsapp")}
                        </span>
                      </div>
                    ) : (
                      t("send_via_sms")
                    )
                  }
                  isDisabled={
                    isLoading || inputMobileData?.phone_number?.length === 0
                  }
                  variant={"primary"}
                />
                {/* )} */}

                {/* <ButtonExeFilled
                  height={"52px"}
                  isDisabled={isLoading}
                  variant={"primary"}
                  borderColor={"transparent"}
                  backgroundColor={"transparent"}
                  fontColor={"#1678F2"}
                  isDisable={
                    isLoading || inputMobileData?.phone_number?.length === 0
                  }
                  // text={isLoading ? <SpinnerLoader /> : "Send via SMS instead"}
                  text={t("send_via_sms")}
                  // onClick={handleContinue}
                  onClick={handleContinue}
                /> */}
                {/* 
                <ButtonExeFilled
                  type="button"
                  // height={52}
                  isDisabled={isLoading}
                  // fontColor={"#1678F2"}
                  borderColor={"transparent"}
                  backgroundColor={"transparent"}
                  hoverCss={
                    "text-[#1678F2] hover:text-[#1263CB] !text-[14px] leading-[16px] font-bold"
                  }
                  onClick={() => {
                    setCurrentStep(3);
                    // handleBack();
                  }}
                  text={t("go_back")}
                ></ButtonExeFilled> */}

                <ButtonExeFilled
                  type="button"
                  // height={52}
                  isDisabled={isLoading}
                  // fontColor={"#1678F2"}
                  borderColor={"transparent"}
                  backgroundColor={"transparent"}
                  hoverCss={
                    "text-[#1678F2] hover:text-[#1263CB] !text-[14px] leading-[16px] font-bold mt-[20px]"
                  }
                  onClick={() => {
                    if (!isLoading) {
                      signUpUser({ isAddMobile: false });
                    }
                  }}
                  text={t("add_later")}
                ></ButtonExeFilled>
              </div>
            </>
          )}

          {/* CURRENT = 5 ENTER OTP */}
          {currentStep === 5 && (
            <div className="w-full">
              {/* SUCCESS MSG */}
              {/* {showToaster ? (
                <OtpToast placeholder={inputMobileData.phone_number} />
              ) : (
                <button
                  disabled={isVerifing || isSendingOtp || loading}
                  className="absolute left-0 top-57px px-15px py-15px text-14px font-medium text-Primary cursor-pointer"
                  onClick={() => setCurrentStep(4)}
                >
                  Go Back
                </button>
              )} */}
              <div
                style={{ marginBottom: "8px", marginTop: "40px" }}
                className="Heading mt-[8px] text-center"
              >
                {t("enter_code")}
              </div>
              {/* <div className="SubHeading mb-[48px]">
                {t("enter_sented_code")}
              </div> */}
              <div className="SubHeading mb-[30px] !gap-[4px] !flex-row">
                {t("enter_sented_to")}{" "}
                <span className="font-bold">
                  {inputMobileData?.phone_number}
                </span>
              </div>
              <InputField
                name="otp"
                type="number"
                maxlength={6}
                inputCss="text-center w-full"
                // placeholder={t("enter_otp")}
                placeholder={"000 000"}
                value={userInput?.otp || ""}
                error={errorMsg.otp}
                onChange={handleChange}
              />
              <Otp
                isLoading={isLoading}
                startTimer={isOtpSented}
                resendOtp={() => handleSendOtp()}
              />
              {/* <div className="flex gap-x-[20px] mt-[20px] w-full">
                <ButtonExeFilled
                  height={52}
                  isDisable={isLoading}
                  text={isLoading ? <SpinnerLoader /> : t("continue")}
                  fontColor={"#FFFFFF"}
                  onClick={handleContinue}
                  hoverCss={"hover:!bg-DarkHover"}
                />
              </div> */}
              <ButtonExeFilled
                type="button"
                isDisabled={isLoading}
                borderColor={"transparent"}
                backgroundColor={"transparent"}
                hoverCss={
                  "text-[#1678F2] hover:text-[#1263CB] !text-[14px] leading-[16px] font-bold"
                }
                onClick={() => {
                  setCurrentStep(4);
                }}
                text={t("go_back")}
              ></ButtonExeFilled>
            </div>
          )}
          {currentStep < 2 && (
            <div className={cn("w-full flex justify-end mt-[16px]")}>
              <TransparentLangSelect />
            </div>
          )}
          {currentStep !== 6 && <Footer />}

          {/* CURRENT = 6 SUCCESS SCREEN */}
          {currentStep === 6 && <Successful isLoading={false} />}
        </div>
      </div>
    </GradientBg>
  );
};
export default Register;
