import axios from "axios";

import { commonHeaders, token } from "./apis";
import { BASE_URL } from "../config";

export const submitRequest = async (payload) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/support/submit_issue_request`,
      payload,
      {
        headers: { Authorization: token, ...commonHeaders },
      }
    );
    return res.data;
  } catch (error) {
    return error?.response?.data || "Something went wrong";
  }
};
