import { useEffect } from "react";

/**
 * Custom hook to set the title in a navigation bar.
 * @param {string} title The title to set in the navigation bar. Defaults to an empty string.
 */
export const useNavbar = (title = "") => {
  useEffect(() => {
    const navTitleElements = document.getElementsByClassName("NavTitle");

    if (navTitleElements.length === 0) {
      console.error("No element with the class 'NavTitle' found.");
      return;
    }

    const navTitleElement = navTitleElements[0]; // Assuming you want to set the title of the first 'NavTitle' element found.
    const originalTitle = navTitleElement.innerText; // Store the original title to reset it later if needed.

    // Set the new title.
    navTitleElement.innerText = title;

    // Cleanup function to reset the title when the component using this hook unmounts or the title changes.
    return () => {
      navTitleElement.innerText = originalTitle;
    };
  }, [title]); // This effect depends on the 'title' prop, so it runs again if 'title' changes.
};


