import React from "react";
import GradientBg from "../../../Components/GradientBg";
import SadIcon from "../Icons/SadIcon.png";
import { cn } from "../Components/utils";
import { fontCommons } from "../Components/AccoutSetting/UpdateEmail";

const OneDayLaterError = () => {
  return (
    <GradientBg>
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-[600px] px-[75px] py-[40px] rounded-[12px] bg-white">
          <div className="flex justify-center items-center w-full">
            <img src={SadIcon} alt="SadIcon" />
          </div>
          <p className="my-[20px] text-[24px] leading-[32px] tracking-[-1px] font-bold text-center text-LightPrimary">
            Sorry!
          </p>
          <p
            className={cn(
              fontCommons,
              "mt-[20px] text-center leading-[24px] font-medium text-[16px]"
            )}
          >
            To maintain security, please wait 24 hours after canceling an
            account before registering again with the same email address.
          </p>
        </div>
      </div>
    </GradientBg>
  );
};

export default OneDayLaterError;
