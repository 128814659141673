import React from "react";
import { cn } from "./utils";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const WhatsAppIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7 2.27334C11.0888 1.65595 10.3608 1.16645 9.55843 0.833371C8.75606 0.500294 7.89541 0.330311 7.02667 0.333337C3.38667 0.333337 0.420002 3.3 0.420002 6.94C0.420002 8.10667 0.726669 9.24 1.3 10.24L0.366669 13.6667L3.86667 12.7467C4.83333 13.2733 5.92 13.5533 7.02667 13.5533C10.6667 13.5533 13.6333 10.5867 13.6333 6.94667C13.6333 5.18 12.9467 3.52 11.7 2.27334ZM7.02667 12.4333C6.04 12.4333 5.07334 12.1667 4.22667 11.6667L4.02667 11.5467L1.94667 12.0933L2.5 10.0667L2.36667 9.86C1.8185 8.98465 1.52743 7.97283 1.52667 6.94C1.52667 3.91334 3.99333 1.44667 7.02 1.44667C8.48667 1.44667 9.86667 2.02 10.9 3.06C11.4117 3.56931 11.8171 4.17511 12.0929 4.84229C12.3687 5.50946 12.5094 6.22474 12.5067 6.94667C12.52 9.97334 10.0533 12.4333 7.02667 12.4333ZM10.04 8.32667C9.87333 8.24667 9.06 7.84667 8.91333 7.78667C8.76 7.73334 8.65333 7.70667 8.54 7.86667C8.42667 8.03334 8.11333 8.40667 8.02 8.51334C7.92667 8.62667 7.82667 8.64 7.66 8.55334C7.49333 8.47334 6.96 8.29334 6.33333 7.73334C5.84 7.29334 5.51333 6.75334 5.41333 6.58667C5.32 6.42 5.4 6.33334 5.48667 6.24667C5.56 6.17334 5.65334 6.05334 5.73334 5.96C5.81333 5.86667 5.84667 5.79334 5.9 5.68667C5.95334 5.57334 5.92667 5.48 5.88667 5.4C5.84667 5.32 5.51333 4.50667 5.38 4.17334C5.24667 3.85334 5.10667 3.89334 5.00667 3.88667H4.68667C4.57333 3.88667 4.4 3.92667 4.24667 4.09334C4.1 4.26 3.67334 4.66 3.67334 5.47334C3.67334 6.28667 4.26667 7.07334 4.34667 7.18C4.42667 7.29334 5.51333 8.96 7.16667 9.67334C7.56 9.84667 7.86667 9.94667 8.10667 10.02C8.5 10.1467 8.86 10.1267 9.14667 10.0867C9.46667 10.04 10.1267 9.68667 10.26 9.3C10.4 8.91334 10.4 8.58667 10.3533 8.51334C10.3067 8.44 10.2067 8.40667 10.04 8.32667Z"
      fill="#6D77A0"
    />
  </svg>
);

const WhatsappCheck = ({ onChange, selectedOption }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isArabic = language === "ar";
  return (
    <>
      <div
        dir={isArabic ? "rtl" : "ltr"}
        className="flex items-center justify-between mt-[10px] w-full"
      >
        <div className="inline-flex items-center gap-[4px] text-[#6d77a0] text-[14px] font-normal">
          <span>{t("do_you_have")}</span>
          <span>
            <WhatsAppIcon />
          </span>
          <span>{t("whats_app?")}</span>
        </div>
        <div className="flex rounded-[5px] overflow-hidden">
          <div className="flex items-center relative cursor-pointer select-none">
            <input
              id="whatsapp_yes"
              type="radio"
              name="whatsapp_radio"
              className="appearance-none absolute h-[0] w-[0] opacity-0"
              value="true"
              onChange={onChange}
              checked={selectedOption === "true"}
            />
            <label
              htmlFor="whatsapp_yes"
              className={cn(
                "flex items-center h-[30px] px-[15px] bg-[#eef6ff]  cursor-pointer",
                {
                  "bg-[#bfd6f2]": selectedOption === "true",
                }
              )}
            >
              <span className="font-bold text-[11px] uppercase text-[#6d77a0]">
                {t("yes")}
              </span>
            </label>
          </div>
          <div className="flex items-center relative cursor-pointer select-none">
            <input
              id="whatsapp_no"
              type="radio"
              name="whatsapp_radio"
              className="appearance-none absolute h-[0] w-[0] opacity-0"
              value="false"
              onChange={onChange}
              checked={selectedOption === "false"}
            />
            <label
              htmlFor="whatsapp_no"
              className={cn(
                "flex items-center h-[30px] px-[15px] bg-[#eef6ff]  cursor-pointer",
                {
                  "bg-[#bfd6f2]": selectedOption === "false",
                }
              )}
            >
              <span className="font-bold text-[11px] uppercase text-[#6d77a0]">
                {t("no")}
              </span>
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatsappCheck;
