/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { authenticateUser } from "../../../../Exe-api/apis";
// import Loader from "../../../../assets/images/Navbar/Logo.gif";
// import { SplashLoader } from "../../../../utils/LoaderCommon";

export const langHelper = (langId) => {
  switch (langId) {
    case 1:
      return "en";
    case 2:
      return "ar";
    default:
      return "en";
  }
};

const AuthCheck = ({ children }) => {
  const { authToken } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const tokenFromExe = JSON.parse(localStorage.getItem("token"));

  const authUser = async () => {
    setIsLoading(true);
    try {
      const res = await authenticateUser({
        token: children ? tokenFromExe : authToken,
      });
      if (res.Error || res.detail) {
        localStorage.clear();
        return;
      }
      if (children) {
        return;
      }

      localStorage.setItem("token", JSON.stringify(authToken));

      window.location.href = "/profile";
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const isToken = tokenFromExe || authToken;
    if (isToken) {
      authUser();
    }
  }, []);

  if (isLoading) {
    return <div></div>;
    // return (
    //   <div
    //     style={{
    //       display: "flex",
    //       alignItems: "center",
    //       justifyContent: "center",
    //       height: "100vh",
    //       background: "white",
    //     }}
    //   >
    //     <SplashLoader />
    //     {/* <img width={300} height={300} src={Loader} alt="" /> */}
    //   </div>
    // );
  }

  return <div></div>;
  // return children ? children : <Navigate to="/" state={{ from: location }} />;
};

export default AuthCheck;
