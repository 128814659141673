import moment from "moment";
import MASTERCardIcon from "./MasterCardIcon1.svg";
import AMEXCardIcon from "./AmExCard.svg";
import JCBCardIcon from "./Jcb.svg";
import DINERSCardIcon from "./dinersClub.svg";
import VISACardIcon from "./VisaCardIcon.svg";
import DISCOVERCardIcon from "./discover.svg";

export const redirectToNewPage = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
  // const anchor = document.createElement("a");
  // anchor.href = url;
  // anchor.target = "_blank";
  // anchor.rel = "noopener noreferrer";
  // anchor.click();
};

export function creditCardType(cc) {
  let amex = new RegExp("^3[47][0-9]{13}$");
  let visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
  let cup1 = new RegExp("^62[0-9]{14}[0-9]*$");
  let cup2 = new RegExp("^81[0-9]{14}[0-9]*$");

  let mastercard = new RegExp("^5[1-5][0-9]{14}$");
  let mastercard2 = new RegExp("^2[2-7][0-9]{14}$");

  let disco1 = new RegExp("^6011[0-9]{12}[0-9]*$");
  let disco2 = new RegExp("^62[24568][0-9]{13}[0-9]*$");
  let disco3 = new RegExp("^6[45][0-9]{14}[0-9]*$");

  let diners = new RegExp("^3[0689][0-9]{12}[0-9]*$");
  let jcb = new RegExp("^35[0-9]{14}[0-9]*$");

  if (visa.test(cc)) {
    return "VISA";
  }
  if (amex.test(cc)) {
    return "AMEX";
  }
  if (mastercard.test(cc) || mastercard2.test(cc)) {
    return "MASTER";
  }
  if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
    return "DISCOVER";
  }
  if (diners.test(cc)) {
    return "DINERS";
  }
  if (jcb.test(cc)) {
    return "JCB";
  }
  if (cup1.test(cc) || cup2.test(cc)) {
    return "UNIONPAY";
  }
  return undefined;
}

export const expiryDateValidation = (inputValue) => {
  let isValid;
  let errorMessage;
  if (/^(0[1-9]|1[0-2])\/([0-9]{2})$/.test(inputValue)) {
    // tmpError.dateError = true;
    const inputDate = inputValue.split("/");
    const inputMonth = Number(inputDate[0]);
    let inputYear = inputDate[1];
    if (inputYear?.length === 4) {
      inputYear = inputYear.slice(-2);
    }

    const currentMonth = Number(moment().format("MM"));
    const currentYear = Number(moment().format("YY"));
    if (inputYear > currentYear) {
      isValid = true;
    } else if (inputYear == currentYear) {
      if (inputMonth > currentMonth) {
        // console.log("Valid Month");
        isValid = true;
      } else {
        // console.log("Invalid Month");
        isValid = false;
        // errorMessage = "Invalid Month";
      }
    } else {
      // console.log("Invalid Year");
      isValid = false;
      errorMessage = "Invalid Year";
    }
  } else {
    isValid = false;
    errorMessage = "Invalid Expiry Date";
  }
  return { isValid, errorMessage };
};

export const cardIcons = {
  VISA: VISACardIcon,
  AMEX: AMEXCardIcon,
  MASTER: MASTERCardIcon,
  DISCOVER: DISCOVERCardIcon,
  JCB: JCBCardIcon,
  DINERS: DINERSCardIcon,
};
