import React from "react";
import { Oval, TailSpin } from "react-loader-spinner";

const SpinnerLoader = ({
  color = "white",
  height = "16",
  strokeWidth = 5,
  width = "16",
}) => {
  return (
    <TailSpin
      className="compose-popup-container"
      height={height}
      width={width}
      radius="1"
      color={color}
      strokeWidth={strokeWidth}
    />
  );
};

export const SplashLoader = ({
  height = 40,
  width = 40,
  color = "#1678F2",
  secondaryColor = "#1263CB",
  strokeWidth = 5,
}) => (
  <Oval
    visible={true}
    height={height}
    width={width}
    color={color}
    ariaLabel="oval-loading"
    wrapperStyle={{}}
    wrapperClass=""
    strokeWidth={strokeWidth}
    secondaryColor={secondaryColor}
  />
);

export default SpinnerLoader;
