import React from "react";
import GradientBg from "../../../Components/GradientBg";
import { SuccessIcon } from "../Components/AccoutSetting/DeleteAccount";
import { cn } from "../Components/utils";
import { fontCommons } from "../Components/AccoutSetting/UpdateEmail";
import { t } from "i18next";

const DeleteAccount = () => {
  return (
    <GradientBg>
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-[600px] px-[75px] py-[40px] rounded-[12px] bg-white">
          <div className="flex justify-center items-center w-full">
            <SuccessIcon />
          </div>
          <p className="my-[20px] text-[24px] leading-[32px] tracking-[-1px] font-bold text-center text-LightPrimary">
            {t("success_deleted")}
          </p>
          <p
            className={cn(
              fontCommons,
              "mt-[20px] text-center leading-[24px] font-medium text-[16px]"
            )}
          >
            {t("success_deleted_desc")}
          </p>
        </div>
      </div>
    </GradientBg>
  );
};

export default DeleteAccount;
