import React from "react";
import GradientBg from "../Components/GradientBg";
import { ResalaLogo } from "./Extension/Icons/Icons";
import { ButtonFilled } from "../Components/Button";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { cn } from "./Extension/Components/utils";
import { ArabicLogo } from "./Extension/layout/Sidebar";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const navigate = useNavigate();
  const {
    i18n: { language },
  } = useTranslation();
  const isArabic = language === "ar";
  
  return (
    <GradientBg>
      <div className="w-full flex justify-center items-center min-h-screen">
        {/* SECTION 1 */}
        <div className="w-[684px] h-fit rounded-[20px] flex flex-col items-center">
          <ResalaLogo
            className={cn("min-h-[40px]", !isArabic ? "block" : "hidden")}
          />
          <ArabicLogo
            className={cn("min-h-[40px]", isArabic ? "block" : "hidden")}
          />
          <p className="mt-[60px] text-LightPrimaryActive text-center text-[100px] leading-[130px] font-[900]">
            404
          </p>
          <p className="mt-[30px] text-LightPrimary text-center text-[40px] leading-[52px] font-bold">
            {t("oops")}
          </p>
          <p className="mt-[16px] text-LightTextParagraph text-center text-[26px] leading-[33px]">
            {t("page_no_available")}
          </p>
          <div className="mt-[60px]">
            <ButtonFilled
              variant={"primary"}
              title={t("return_home")}
              onClick={() => {
                navigate("/");
              }}
            />
          </div>
        </div>
      </div>
    </GradientBg>
  );
};

export default NotFound;

// Common filters (design + api integration)	7-8 + 7-8 = 15-16 hours 	1 days
// Payments listing + filters	5-6 hours	5 hours
// Payments => View Customer (design + api integration)	need to finalized options and functionalities
// Subscription	5-6 hours	5 hours
// Top countries	2 hours	1 hours
// common pagination	3 hours
// Analytics & Reports	future work
// Notifications	future work
