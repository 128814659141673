import React from "react";
import { useNavigate } from "react-router-dom";

import { ButtonExeFilled } from "./Button";
import { t } from "i18next";

const PasswordChanged = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full flex flex-col justify-center">
      <div className="flex items-center justify-center gap-[8px] mb-[30px]">
        <PasswordSuccessLogo className="cursor-pointer h-[64px] w-[64px]" />
      </div>
      <div className="text-[22px] text-LightPrimary flex justify-center mb-[8px] font-bold">
        Password Changed!
      </div>
      <div className="flex justify-center px-[10px] text-center text-[#6d77a0] mb-[30px] flex-col text-[14px] leading-[20px] gap-[8px]">
        Your password has been changed successfully.
      </div>

      <ButtonExeFilled
        height={50}
        text={t("continue")}
        fontColor={"#FFFFFF"}
        onClick={() => navigate("/login")}
        variant={"primary"}
      ></ButtonExeFilled>
    </div>
  );
};
export default PasswordChanged;

export const PasswordSuccessLogo = (props) => (
  <svg
    {...props}
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="35"
      cy="35"
      r="33.6"
      fill="white"
      stroke="#1678F2"
      strokeWidth="2.8"
    />
    <g clipPath="url(#clip0_464_62178)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.7282 25.8148C48.1031 26.1898 48.3138 26.6984 48.3138 27.2288C48.3138 27.7591 48.1031 28.2677 47.7282 28.6428L32.7375 43.6334C32.5394 43.8316 32.3042 43.9888 32.0454 44.096C31.7865 44.2032 31.5091 44.2584 31.2289 44.2584C30.9487 44.2584 30.6712 44.2032 30.4124 44.096C30.1535 43.9888 29.9183 43.8316 29.7202 43.6334L22.2722 36.1868C22.0812 36.0023 21.9288 35.7816 21.824 35.5376C21.7192 35.2936 21.664 35.0311 21.6617 34.7656C21.6594 34.5 21.71 34.2366 21.8106 33.9908C21.9111 33.7451 22.0596 33.5217 22.2474 33.334C22.4352 33.1462 22.6585 32.9977 22.9043 32.8971C23.1501 32.7965 23.4134 32.7459 23.679 32.7483C23.9446 32.7506 24.207 32.8057 24.451 32.9106C24.695 33.0154 24.9157 33.1677 25.1002 33.3588L31.2282 39.4868L44.8989 25.8148C45.0846 25.6289 45.3051 25.4815 45.5479 25.3809C45.7906 25.2803 46.0508 25.2285 46.3135 25.2285C46.5763 25.2285 46.8365 25.2803 47.0792 25.3809C47.3219 25.4815 47.5425 25.6289 47.7282 25.8148Z"
        fill="#1678F2"
      />
    </g>
    <defs>
      <clipPath id="clip0_464_62178">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(19 19)"
        />
      </clipPath>
    </defs>
  </svg>
);
