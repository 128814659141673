import React, { useEffect, useState } from "react";
import { cn } from "../utils";
import InputField from "../InputField";
import { ButtonExeFilled, ButtonOutLine } from "../Button";
import Otp from "../../Otp";
import { ModalTitle, SuccessIcon } from "./DeleteAccount";
import {
  sendEmailOtp,
  updateFinalEmail,
  verifyOtp,
} from "../../../../Exe-api/apis";
import SpinnerLoader from "../../../../utils/LoaderCommon";
import { t } from "i18next";

export const fontCommons = "text-[14px] leading-[18px] text-LightStrokActive";
const UpdateEmail = ({ onClose, email, updateUserData }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isOtpSented, setIsOtpSented] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(undefined);
  const [updateEmail, setUpdateEmail] = useState({
    otp: undefined,
    newEmail: "",
    finalOtp: undefined,
  });

  const sendOtp = async (topic, email, callback, is_email_check = false) => {
    setIsOtpSented(false);
    try {
      await sendEmailOtp({
        topic: topic,
        email,
        is_email_check: is_email_check,
      });
      callback();
    } catch (err) {
      console.log("err", err);
    }
  };

  const verifyEmailOtp = async () => {
    setIsLoading(true);
    try {
      const res = await verifyOtp({ email, otp: updateEmail.otp });
      if (res.Error || res.detail) {
        console.log(res.Message);
        setErrMsg(res.Message);
        return;
      }
      setCurrentStep(2);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateEmailFinally = async () => {
    setIsLoading(true);
    try {
      const res = await updateFinalEmail({
        new_email: updateEmail.newEmail.trim(),
        otp: updateEmail.finalOtp,
      });
      if (res.Error || res.detail) {
        console.log(res.Message);
        setErrMsg(res.Message);
        return;
      }
      const { new_email } = res;
      updateUserData((prev) => {
        return {
          ...prev,
          email: new_email.trim(),
        };
      });
      setCurrentStep(4);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    sendOtp(t("update_email"), email, () => setIsOtpSented(true));
  }, []);

  const handleCancel = () => {
    onClose();
  };

  const handleContinue = async () => {
    setErrMsg(undefined);
    switch (currentStep) {
      case 1:
        verifyEmailOtp();
        break;
      case 2:
        setIsLoading(true);
        sendOtp(
          t("update_email"),
          updateEmail.newEmail,
          () => {
            setIsLoading(false);
            setCurrentStep(3);
          },
          true
        );
        break;
      case 3:
        updateEmailFinally();
        break;
      default:
    }
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <>
      <div className="w-[400px]">
        {currentStep === 1 && (
          <>
            <ModalTitle title={t("otp_verification")} />
            <div className="pt-[20px] pb-[40px]">
              <TwoToneText
                fontCommons={fontCommons}
                text1={t("enter_code_sent")}
                text2={email}
              />
            </div>
            <InputField
              type={t("number")}
              maxlength={6}
              inputCss="text-center"
              // placeholder={t("enter_otp")}
              placeholder={"000 000"}

              value={updateEmail?.otp || ""}
              error={errMsg}
              onChange={(e) => {
                if (e.target.value?.length > 6) return;
                setUpdateEmail((prev) => {
                  return {
                    ...prev,
                    otp: e.target.value,
                  };
                });
              }}
            />
            <Otp
              startTimer={isOtpSented}
              resendOtp={() =>
                sendOtp(t("update_email"), email, () => setIsOtpSented(true))
              }
            />
            <div className="flex gap-x-[20px] mt-[20px]">
              <ButtonExeFilled
                height={40}
                isDisable={isLoading || updateEmail?.otp?.length === 0}
                text={isLoading ? <SpinnerLoader /> : t("continue")}
                fontColor={"#FFFFFF"}
                onClick={handleContinue}
                hoverCss={"hover:!bg-DarkHover"}
              />
            </div>
          </>
        )}

        {currentStep === 2 && (
          <>
            <ModalTitle title={t("update_email_id")} />
            <InputField
              label={t("email")}
              placeholder={t("enter_new_email")}
              error={errMsg}
              value={updateEmail.newEmail}
              onChange={(e) =>
                setUpdateEmail((prev) => {
                  return {
                    ...prev,
                    newEmail: e.target.value,
                  };
                })
              }
            />
            <div className="flex gap-x-[20px] mt-[20px]">
              <ButtonOutLine
                height={40}
                text={t("cancel")}
                fontColor={"#5F6583"}
                borderColor={"#DFE4EC"}
                onClick={handleCancel}
                hoverCss={
                  "hover:!text-LightPrimaryActive hover:!bg-[#eef6ff] hover:!border-[#D3E7FF]"
                }
              />
              <ButtonExeFilled
                height={40}
                isDisable={
                  isLoading ||
                  updateEmail.newEmail.length === 0 ||
                  !validateEmail(updateEmail.newEmail)
                }
                text={isLoading ? <SpinnerLoader /> : t("send_verification")}
                fontColor={"#FFFFFF"}
                onClick={handleContinue}
                hoverCss={"hover:!bg-DarkHover"}
              />
            </div>
          </>
        )}

        {currentStep === 3 && (
          <>
            <ModalTitle title={t("otp_verification")} />
            <div className="pt-[20px] pb-[40px]">
              <TwoToneText
                fontCommons={fontCommons}
                text1={t("enter_code_sent")}
                text2={updateEmail.newEmail}
              />
            </div>
            <InputField
              type={t("number")}
              maxlength={6}
              inputCss="text-center"
              // placeholder={t("enter_otp")}
              placeholder={"000 000"}

              value={updateEmail?.finalOtp || ""}
              error={errMsg}
              onChange={(e) => {
                if (e.target.value?.length > 6) return;
                setUpdateEmail((prev) => {
                  return {
                    ...prev,
                    finalOtp: e.target.value,
                  };
                });
              }}
            />
            <Otp
              startTimer={isOtpSented}
              resendOtp={() =>
                sendOtp(
                  t("update_email"),
                  updateEmail.newEmail,
                  () => setIsOtpSented(true),
                  true
                )
              }
            />
            <div className="flex gap-x-[20px] mt-[20px]">
              <ButtonExeFilled
                height={40}
                isDisable={isLoading || updateEmail?.finalOtp?.length === 0}
                text={isLoading ? <SpinnerLoader /> : t("continue")}
                fontColor={"#FFFFFF"}
                onClick={handleContinue}
                hoverCss={"hover:!bg-DarkHover"}
              />
            </div>
          </>
        )}

        {currentStep === 4 && (
          <>
            <div className="flex justify-center items-center w-full">
              <SuccessIcon />
            </div>
            <p className="my-[20px] text-[24px] leading-[32px] tracking-[-1px] font-bold text-center text-LightPrimary">
              {t("success")}
            </p>
            <p
              className={cn(
                fontCommons,
                "mt-[20px] text-center leading-[24px] font-medium text-[16px]"
              )}
            >
              {t("email_successfully_update")}
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default UpdateEmail;

const DeviderDashed = ({ className }) => (
  <div className={cn("border-b border-dashed w-full", className)} />
);

export const TwoToneText = ({ fontCommons, text1 = "", text2 = "" }) => (
  <p className={cn(fontCommons, "flex gap-x-[4px]")}>
    {text1}
    <span className={cn(fontCommons, "font-bold text-LightPrimary")}>
      {" "}
      {text2}
    </span>
  </p>
);
