import React from "react";
import ExistUserModal from "../../Components/ReferEarn/ExistUserModal";

const ExistError = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-slate-50">
      <ExistUserModal />
    </div>
  );
};

export default ExistError;
