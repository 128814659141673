import React, { useContext, useState } from "react";
import UserProfile from "./UserProfile";
import LoadingContext from "../Context/LoadingContext";
import Skeleton from "react-loading-skeleton";

const Header = ({ title, Initials, profile_picture }) => {
  const {
    loading: { isAccountsPage },
  } = useContext(LoadingContext);

  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div className="h-[80px] flex justify-between items-center px-10 border-b-[1px] border-dashed border-LightStrokPrimary">
      <p className="NavTitle text-TextRegularLarge font-DMSansFont font-medium text-LightPrimary">
        {title}
      </p>

      {isAccountsPage ? (
        <Skeleton circle height={50} width={50} />
      ) : profile_picture ? (
        <img
          style={{
            display: isLoaded ? 'block' : 'none',
          }}
          onLoad={handleImageLoad}
          onError={() => setIsLoaded(false)}
          className="w-[50px] h-[50px] rounded-full object-cover"
          src={profile_picture}
          alt="profile_picture"
        />
      ) : (
        <UserProfile name={Initials} />
      )}
      {/* {profile_picture ? (
        <img
          className="w-[50px] h-[50px] rounded-full object-cover"
          src={profile_picture}
          alt="profile_picture"
        />
      ) : (
        <UserProfile name={Initials} />
      )} */}
    </div>
  );
};

export default Header;
