import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DownArrow from '../../assets/images/HomePage/arrow-down.svg';
import ArrowUp from '../../assets/images/HomePage/arrow-up.svg';

const FAQ = () => {
  const { t } = useTranslation();
  const [openQuestionIndex, setOpenQuestionIndex] = useState(-1);

  const [questionList, setQuestionList] = useState([]);
  useEffect(() => {
    // Update lists when language changes
    setQuestionList(getTranslatedList(t));
  }, [t]);
  return (
    <>
      <div
        style={{
          zIndex: 20,
        }}
        className={``}
      >
        <div className="z-40 m-auto max-w-[1040px] max_xl:max-w-[980px] max_md:px-[40px] max_sm:px-[20px] pb-[160px] max_md:pb-[120px] max_sm:pb-[80px] ">
          <div className="flex flex-col">
            <div className="title-fonts HeadingCommon text-center mb-5 max_md:mb-2">
              {t('FAQ')}
            </div>
            <div className="flex flex-col">
              {questionList.map((list, index) => {
                return (
                  <React.Fragment key={index + 1}>
                    <div
                      className="py-10 max_md:py-5 border-b-[1px] border-[#B1BED2] cursor-pointer"
                      onClick={() => {
                        if (openQuestionIndex === index) {
                          setOpenQuestionIndex(-1);
                        } else {
                          setOpenQuestionIndex(index);
                        }
                      }}
                    >
                      <div className="flex justify-between">
                        <div
                          className={`discoverHeading ${
                            openQuestionIndex === index
                              ? 'text-LightPrimary'
                              : 'text-LightStrokActive'
                          } font-DMSansFont !font-semibold w-[95%] description-fonts`}
                        >
                          {list.question}
                        </div>
                        <div className="w-[30px]">
                          <img
                            src={
                              openQuestionIndex === index ? ArrowUp : DownArrow
                            }
                            alt="Downarrow"
                            className={`${
                              openQuestionIndex !== index
                                ? 'rotate-[360deg]'
                                : 'rotate-180'
                            } transition-all`}
                          />
                        </div>
                      </div>
                      <div
                        className={`answerSection ${
                          openQuestionIndex === index ? 'open' : ''
                        }`}
                      >
                        <div
                          style={{ letterSpacing: '-1px' }}
                          className={`SubTextCommon leading-[30px] text-LightTextParagraph mt-2 w-[90%] max_sm:w-[97%] `}
                        >
                          {list.answer}
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
 
};
 const getTranslatedList = (t) => {
   // Define your lists here
   const lists = [
     {
       question: t('WhatIsResala'),
       answer: t('WhatIsResalaAns'),
     },
     {
       question: t('HowResalaWork'),
       answer: t('HowResalaWorkAns'),
     },
     {
       question: t('IsResalaFree'),
       answer: t('IsResalaFreeAns'),
     },
     {
       question: t('ResalaCompatible'),
       answer: t('ResalaCompatibleAns'),
     },
     {
       question: t('HowCanIStarted'),
       answer: t('HowCanIStartedAns'),
     },
   ];

   return lists;
 };
export default FAQ;

// relative text-[100px]  before:content-['FAQ'] before:z-[-1] before:bg-cover before:block before:absolute before:inset-0
//         before:text-[700px] before:max_1500:text-[620px] before:max_1500:px-[10px]
//         before:max_xl:text-[550px] before:top-[-150px] before:text-center
//         before:max_xl1160:text-[470px]
//         before:max_md:text-[380px] before:max_md:top-[-75px]
//         before:max_800:text-[300px] before:max_800:top-[-30px]
//         before:max_720:text-[250px] before:max_sm:text-[150px] before:max_sm:top-[20px]
//         before:max_esm:text-[120px]
//         before:text-LightTextParagraph before:opacity-5
