import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { authenticateUser } from "../Exe-api/apis";
import { langHelper } from "../Pages/Extension/Pages/AuthCheck/AuthCheck";
import { useTranslation } from "react-i18next";

const ProtectedRoutes = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    i18n: { changeLanguage, language, dir },
  } = useTranslation();

  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    document.documentElement.dir = dir(language);
  }, [dir, language]);

  useEffect(() => {
    (async () => {
      try {
        const res = await authenticateUser({ token: token });

        if (res.Error || res.detail || res.message) {
          navigate("/");
        }
        console.log('--- language ProtectedRoutes.js [Line-no 28] ---', language);
        const lang = langHelper(res.interface_language);
        const isArabic = lang === "ar";
        const dashboardRoot = document.querySelector("#dashboard-root");
        dashboardRoot.dir = isArabic ? "rtl" : "ltr";
        changeLanguage(lang);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return token ? children : <Navigate to="/" state={{ from: location }} />;
};

export default ProtectedRoutes;
