import { t } from "i18next";
import React, { useEffect } from "react";

const SuccessIcon = (props) => (
  <svg
    {...props}
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fill="#23B339" fill-opacity="0.15" />
    <path
      d="M32 12C20.98 12 12 20.98 12 32C12 43.02 20.98 52 32 52C43.02 52 52 43.02 52 32C52 20.98 43.02 12 32 12ZM41.56 27.4L30.22 38.74C29.94 39.02 29.56 39.18 29.16 39.18C28.76 39.18 28.38 39.02 28.1 38.74L22.44 33.08C21.86 32.5 21.86 31.54 22.44 30.96C23.02 30.38 23.98 30.38 24.56 30.96L29.16 35.56L39.44 25.28C40.02 24.7 40.98 24.7 41.56 25.28C42.14 25.86 42.14 26.8 41.56 27.4Z"
      fill="#23B339"
    />
  </svg>
);

const Successful = ({ isLoading }) => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = "profile";
    }, 2000);
  }, []);

  return (
    <div className="flex flex-col justify-center w-full my-[40px]">
      <div className="flex items-center justify-center gap-[8px]">
        <SuccessIcon className="cursor-pointer h-[64px] w-[64px]" />
      </div>
      <div className="text-[22px] flex justify-center mt-[30px] font-bold text-LightPrimary">
        {t("successfullyLogin")}
      </div>
      <div className="flex justify-center px-[10px] text-center text-[#6d77a0] mt-[20px] flex-col text-[14px] leading-[20px] gap-[8px]">
        {t("welcome_msg")}
      </div>

      {/* <ButtonExeFilled
        fontColor={"#FFFFFF"}
        // onClick={() => navigate("/auth")}
        className={"w-full"}
        variant={"primary"}
        isDisable={isLoading}
        text={isLoading ? <SpinnerLoader /> : "Continue Using"}
      /> */}
    </div>
  );
};
export default Successful;
