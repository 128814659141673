/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from "react";

// Phone number input related imports
import countryNames from "react-phone-number-input/locale/en";
import ar from "react-phone-number-input/locale/ar";
import en from "react-phone-number-input/locale/en";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { getCountryCallingCode, getCountries } from "react-phone-number-input";
import countryNamesen from "react-phone-number-input/locale/en";
import countryNamesAr from "react-phone-number-input/locale/ar";

import "react-phone-number-input/style.css";
import { t } from "i18next";
import { checkLanguage, cn } from "../utils";
import { useTranslation } from "react-i18next";

const SearchIcon = () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.7775 12.3363C9.57137 12.3363 11.8363 10.0714 11.8363 7.2775C11.8363 4.48363 9.57137 2.21875 6.7775 2.21875C3.98363 2.21875 1.71875 4.48363 1.71875 7.2775C1.71875 10.0714 3.98363 12.3363 6.7775 12.3363Z"
      stroke="#19224C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3545 10.8545L13.2814 13.7814"
      stroke="#19224C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SelectMobileNumber = ({
  userCountry,
  number,
  setInputValue,
  errMsg,
  onChange = undefined,
}) => {
  const countryRef = useRef(null);
  const {
    i18n: { language },
  } = useTranslation();
  const isArabic = language === "ar";
  const countryNames = isArabic ? countryNamesAr : countryNamesen;
  const [value, setValue] = useState(number ? number : "");
  const [isOpen, setIsOpen] = useState(false);
  const [countryLib, setCountryLib] = useState(userCountry);
  const [searchInput, setSearchInput] = useState("");
  const [countries, setCountries] = useState(getCountries());

  const handleCountrySelect = (countryName) => {
    setCountryLib(countryName);
    setValue(`+${getCountryCallingCode(countryName)}`);
  };

  // dorpDownRef
  useEffect(() => {
    function handleClickOutside(event) {
      if (countryRef.current && !countryRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [countryRef]);

  useEffect(() => {
    const names = getCountries();
    let filteredData = names;

    if (searchInput) {
      filteredData = names.filter((el) => {
        const lowerCaseEl = el.toLowerCase();
        const lowerCaseSearchInput = searchInput.toLowerCase();
        return (
          lowerCaseEl.includes(lowerCaseSearchInput) ||
          countryNames[el].toLowerCase().includes(lowerCaseSearchInput)
        );
      });
    }

    setCountries(filteredData);
  }, [searchInput]);

  useEffect(() => {
    setCountries(getCountries());
  }, []);

  const handleOnChangeSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const handleNumberChange = (data) => {
    if (onChange) {
      onChange(data);
    }

    setValue(data);
    setInputValue((prev) => {
      return {
        ...prev,
        phone_number: data,
      };
    });
  };

  return (
    <div dir={isArabic ? "rtl" : "ltr"} className="relative w-full">
      {/* COUNTRY SELECT COMPONENT */}
      <div
        ref={countryRef}
        style={{
          display: !isOpen ? "none" : "block",
          boxShadow: "0px 0px 10px 0px #00000026",
        }}
        className="absolute z-30 w-full top-[55px] border-[1px] border-gray rounded-[7px] left-0 bg-white"
      >
        <div className="Phone relative rounded-[6px] overflow-hidden">
          <input
            placeholder={t("search_country")}
            className={cn(
              "border-b-StrokePrimary font-normal border-b-[1px] text-TextPrimary h-[40px] w-full focus:outline-none placeholder:text-StrokePrimary",
              isArabic ? "pr-[40px] pl-[20px] " : "pl-[40px]"
            )}
            name="searchInput"
            value={searchInput}
            dir={
              searchInput.length
                ? isArabic && checkLanguage(searchInput)
                  ? "rtl"
                  : "ltr"
                : isArabic
                ? "rtl"
                : "ltr"
            }
            onChange={handleOnChangeSearch}
            onKeyUp={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
          />
          <div
            className={cn(
              "absolute top-[11px]",
              isArabic ? "right-[16px]" : "left-[16px]"
            )}
          >
            <SearchIcon />
          </div>
        </div>
        <div className="bg-BgPrimary px-[8px] py-[6px] h-fit max-h-[288px] overflow-y-auto ">
          {countries &&
            countries.map((itm, index) => (
              <div
                key={`country-${index}`}
                className={`relative flex cursor-pointer justify-between px-[10px] py-[6px] text-LightBlue h-[32px] rounded-[7px] mb-[8px] hover:bg-BgSecondary`}
                onClick={() => {
                  setIsOpen(false);
                  handleCountrySelect(itm || undefined);
                }}
              >
                <div className="flex gap-[8px] items-center">
                  <img
                    className="w-[28px]"
                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${itm}.svg`}
                  />
                  <div className="text-[12px] text-TextPrimary font-medium">
                    {/* {countryNames[itm]} */}
                    {countryNames[itm] || itm}
                  </div>
                </div>
                <div className="text-[14px] text-TextPrimary">
                  {"+"}
                  {getCountryCallingCode(itm)}
                </div>
                {countryLib === itm && (
                  <div className="absolute right-[9px] top-[9px]">
                    {/* <img src={CheckedIcon} alt="CheckedIcon" /> */}
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>

      <PhoneInput
        onFocus={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onBlur={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        international
        value={value}
        onChange={(number) => {
          handleNumberChange(number);
        }}
        locales={isArabic ? ar : en}
        defaultCountry={userCountry}
        placeholder={t("enter_phone_number")}
        countryCallingCodeEditable={false}
        limitMaxLength={true}
        className="flex gap-[8px]"
        countrySelectComponent={(props) => {
          const { value } = props;
          return (
            <div
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className="flex cursor-pointer font-normal h-[45px] gap-[6px] px-[11px] py-[9px] border-[1px] border-gray items-center rounded-[6px]"
            >
              <img
                className="w-[24px] h-[16px]"
                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryLib}.svg`}
                alt=""
              />
              <p
                className={`transform w-[14px] h-[14px] flex justify-center items-center transition-transform duration-200 ${
                  isOpen ? "rotate-180" : "rotate-0"
                }`}
              >
                <svg
                  width="11"
                  height="7"
                  viewBox="0 0 11 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.91278 1.89698L5.94784 5.93174L1.91309 1.9668"
                    stroke="#6D77A0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </p>
            </div>
          );
        }}
        error={
          errMsg || value
            ? isValidPhoneNumber(value)
              ? undefined
              : t("invalid_phone_number")
            : t("phone_number_required")
        }
      />
    </div>
  );
};

export default SelectMobileNumber;
