import axios from "axios";
import { BASE_URL } from "../config";

// export const BASE_URL =
//   "https://c00c-2405-201-200d-2044-48ae-a8e-1473-eda7.ngrok-free.app";

// export const BASE_URL = 'https://api-qa.resala.ai'

export const SubmitRequest = async (payload) => {
  try {
    const res = await axios.post(`${BASE_URL}/web/join_waitlist`, payload);
    return res.data;
  } catch (error) {
    return error?.response || "Something went wrong";
  }
};

export const SubscribeEmail = async (payload) => {
  try {
    const res = await axios.post(`${BASE_URL}/web/new_alerts`, payload);
    return res.data.Message;
  } catch (error) {
    return error?.response || "Something went wrong";
  }
};
export const SubmitReferrer = async (payload) => {
  try {
    const res = await axios.post(`${BASE_URL}/web/referral_domain`, payload);
    return res.data.Message;
  } catch (error) {
    return error?.response || "Something went wrong";
  }
};

export const getCount = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/web/waitlist_user_count`);
    return res.data;
  } catch (error) {
    return error?.response || "Something went wrong";
  }
};
