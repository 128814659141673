import copy from "copy-to-clipboard";
import React, { useEffect, useState } from "react";
import {
  ArrowDottedIcon,
  DescribedIcon,
  GiftIcon,
  InviteBanner,
  PCIcon,
  ReferIcon,
} from "../../Components/ReferEarn/InviteBanner";

import { getReferHistory } from "../../../../Exe-api/refer-api";
import ReferByLinkEmail from "../../Components/ReferEarn/ReferByLinkEmail";
import TableComp from "../../Components/ReferEarn/TableComp";
import { useNavbar } from "../../customHook/useNavbar";
import CardSubscription from "../Subscriptions/CardSubscription";
import { Toggle } from "../Subscriptions/ToggleYear";
import Skeleton from "react-loading-skeleton";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const ReferEarn = () => {
  const {
    i18n: { language, dir },
  } = useTranslation();
  const isArabic = language === "ar";
  useNavbar(t("refer_earn_nav"));

  const ReferTypes = [
    { id: 1, name: t("refer_friend") },
    { id: 2, name: t("invite_records") },
  ];

  const [selected, setSelected] = useState(ReferTypes[0]);
  const [referHistory, setReferHistory] = useState(undefined);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyDraft = (draft) => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const ReferHistory = async () => {
    setIsPageLoading(true);
    try {
      const res = await getReferHistory();
      if (res.Error || res.detail) {
        console.log(res.Message);
        return;
      }
      setReferHistory(res);
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    ReferHistory();
  }, []);

  // if (!referHistory || isPageLoading) {
  //   return (
  //     <div className="w-full">
  //       <div className="flex justify-center">Loading data...</div>
  //     </div>
  //   );
  // }

  const BannerQuery = referHistory?.invitation_query.total_count;
  const referalURL = `http://resala.ai/register?${referHistory?.referral_code}`;
  return (
    <>
      {/* <Header title="Refer & Earn"Initials="VS"/> */}
      <div className="w-full">
        <div className="px-[80px] py-[40px]">
          {isPageLoading ? (
            <Skeleton containerClassName="w-full" height={250} />
          ) : (
            <InviteBanner queryCount={BannerQuery} />
          )}
          <div className="my-[40px]">
            {isPageLoading ? (
              <Skeleton width={364} height={48} />
            ) : (
              <Toggle
                Options={ReferTypes}
                selected={selected}
                setSelected={setSelected}
              />
            )}
          </div>

          {selected.id === 1 &&
            (isPageLoading ? (
              <Skeleton containerClassName="w-full" height={352} />
            ) : (
              <CardSubscription>
                <div className="flex justify-between items-center w-full px-[80px]">
                  <DescribedIcon
                    icon={<ReferIcon />}
                    desc={t("invite_via_email_link")}
                  />
                  <ArrowDottedIcon className={isArabic && "rotate-180"} />
                  <DescribedIcon icon={<PCIcon />} desc={t("friend_sign_up")} />
                  <ArrowDottedIcon className={isArabic && "rotate-180"} />
                  <DescribedIcon
                    icon={<GiftIcon />}
                    desc={t("receive_rewards")}
                  />
                </div>
                <div className="mt-[30px]">
                  <CardSubscription padding={"20px"}>
                    <ReferByLinkEmail
                      title={
                        <div className="flex gap-x-[4px]">
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.49173 18.4156C6.1084 18.4156 4.71673 17.8906 3.6584 16.8323C1.55007 14.7156 1.55007 11.2823 3.6584 9.17396C3.90007 8.93229 4.30007 8.93229 4.54173 9.17396C4.7834 9.41563 4.7834 9.81563 4.54173 10.0573C2.91673 11.6823 2.91673 14.324 4.54173 15.949C6.16673 17.574 8.8084 17.574 10.4334 15.949C11.2167 15.1656 11.6501 14.1156 11.6501 12.999C11.6501 11.8906 11.2167 10.8406 10.4334 10.049C10.1917 9.80729 10.1917 9.40729 10.4334 9.16562C10.6751 8.92396 11.0751 8.92396 11.3167 9.16562C12.3417 10.1906 12.9001 11.549 12.9001 12.999C12.9001 14.449 12.3334 15.8073 11.3167 16.8323C10.2667 17.8906 8.8834 18.4156 7.49173 18.4156Z"
                              fill="#1678F2"
                            />
                            <path
                              d="M15.8917 12.3005C15.7334 12.3005 15.5751 12.2422 15.4501 12.1172C15.2084 11.8755 15.2084 11.4755 15.4501 11.2339C17.1584 9.52552 17.1584 6.75052 15.4501 5.05052C13.7417 3.34219 10.9667 3.34219 9.26673 5.05052C8.44173 5.87552 7.9834 6.97552 7.9834 8.14219C7.9834 9.30885 8.44173 10.4089 9.26673 11.2339C9.5084 11.4755 9.5084 11.8755 9.26673 12.1172C9.02506 12.3589 8.62507 12.3589 8.3834 12.1172C7.32507 11.0589 6.7334 9.64219 6.7334 8.14219C6.7334 6.64219 7.31673 5.22552 8.3834 4.16719C10.5751 1.97552 14.1417 1.97552 16.3417 4.16719C18.5334 6.35885 18.5334 9.93385 16.3417 12.1255C16.2167 12.2422 16.0501 12.3005 15.8917 12.3005Z"
                              fill="#1678F2"
                            />
                          </svg>
                          <p>{isCopied ? t("copied") : t("copy_link")}</p>
                        </div>
                      }
                      onCopy={() => {
                        handleCopyDraft();
                        copy(referalURL);
                      }}
                    />
                  </CardSubscription>
                </div>
              </CardSubscription>
            ))}

          {selected.id === 2 && (
            <>
              <CardSubscription>
                <p className="text-[16px] leading-[24px] text-LightPrimary font-bold">
                  {t("invite_records")}
                </p>
                <div className="flex justify-between px-[20px] py-[16px] text-[14px] leading-[18px] bg-[#F9F9FB] rounded-[7px] mt-[12px]">
                  <Records title={t("my_friends")} value={referHistory.count} />
                  <Records
                    title={t("standard_query_reward")}
                    value={referHistory.standard_queries}
                  />
                  <Records
                    title={t("advanced_query_reward")}
                    value={referHistory.advance_queries}
                  />
                </div>
                <p className="text-[16px] leading-[24px] text-LightPrimary font-bold mt-[30px] mb-[12px]">
                  {t("details")}
                </p>
                <CardSubscription padding={"16px 20px"}>
                  <TableComp data={referHistory.history} />
                </CardSubscription>
              </CardSubscription>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ReferEarn;

const Records = ({ title, value }) => (
  <p className="flex items-center gap-[8px]">
    {title}
    <span className="font-bold">{value}</span>
  </p>
);
