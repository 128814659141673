import React from "react";
import { ReactComponent as Grad1 } from "../Pages/Extension/Icons/Grad1.svg";
import { ReactComponent as Grad2 } from "../Pages/Extension/Icons/Grad2.svg";

const GradientBg = ({ children }) => {
  return (
    <div className="relative h-screen overflow-hidden">
      <Grad1 className="absolute z-10 -top-[80px] bottom-0 -left-[600px]" />
      <Grad2 className="absolute z-10 -top-[130px] -right-[300px]" />
      <div className="absolute inset-0 z-20 h-screen overflow-auto">{children}</div>
    </div>
  );
};

export default GradientBg;
