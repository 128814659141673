import React, { useState } from "react";
import { ModalTitle } from "../AccoutSetting/DeleteAccount";
import { cn } from "../utils";
import { fontCommons } from "../AccoutSetting/UpdateEmail";
import { ButtonExeFilled } from "../Button";
import { ReactComponent as CelebrateBG } from "../../Icons/ModalCelebrate.svg";
import { t } from "i18next";

const GetDiscount = ({ setClose }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [data, setData] = useState({
    isAcceptTerms: false,
  });

  return (
    <div className="w-[480px]">
      {currentStep === 1 && (
        <>
          <ModalTitle title={t("get_discount")} />
          <div className="pt-[20px] flex justify-between items-center">
            <TitleValue title={t("discount_amount")} value={"60%"} />
            <TitleValue
              title={t("validity_period")}
              value={"Feb 24 - Feb 28"}
              className={"items-end"}
            />
          </div>
          <div className="mt-[20px] flex flex-col gap-[8px]">
            <p className={cn(fontCommons, "font-bold text-LightPrimary")}>
              {t("terms_conditions")}
            </p>
            <Terms />
          </div>
          <div className="mt-[20px] flex gap-x-[12px]">
            <input
              type="checkbox"
              checked={data.isAcceptTerms}
              onChange={(e) => {
                setData({ ...data, isAcceptTerms: e.target.checked });
              }}
              className="h-[18px] w-[18px] text-blue-600 border-gray-300 rounded cursor-pointer"
            />
            <p className={cn(fontCommons, "font-medium text-LightPrimary")}>
              {t("agree_terms")}
            </p>
          </div>
          <div className="mt-[40px]">
            <ButtonExeFilled
              isDisable={!data.isAcceptTerms}
              onClick={() => {
                setCurrentStep(2);
              }}
              text={t("get_promo_code")}
              fontColor={"#FFFFFF"}
              hoverCss={"hover:!bg-DarkHover"}
            />
          </div>
        </>
      )}

      {currentStep === 2 && (
        <div className="w-full flex justify-center overflow-hidden">
          <CelebrateBG className="absolute top-[-48px] left-[-68px] min-w-[646px] z-[-1]" />
          <div className="flex w-full flex-col pt-[90px]">
            <p className="px-[96px] text-[24px] leading-[32px] font-bold text-center text-LightPrimary">
              {t("promo_code")}
            </p>
            <p className={cn(fontCommons, "mt-[20px] text-center px-[50px]")}>
            {t("max_saving_text")}
              <span className="text-LightPrimary font-bold"> 60%</span> {t("discount")}
              {t("on_your_payment")}
            </p>
            <p className="mt-[40px] text-[36px] leading-[46px] font-bold text-LightPrimaryActive text-center">
              1D2c88eF
            </p>
            <div className="mt-[40px]">
              <ButtonExeFilled
                height={40}
                text={t("apply_promo_code")}
                fontColor={"#FFFFFF"}
                onClick={() => {}}
                hoverCss={"hover:!bg-DarkHover"}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GetDiscount;

const Terms = () => {
  const TermsList = [t("terms_1"), t("terms_2"), t("terms_3"), t("terms_4")];

  return (
    <>
      {TermsList.map((itm, idx) => (
        <div
          key={idx}
          className={cn(fontCommons, "flex font-medium leading-[20px]")}
        >
          <span className="w-[16px] min-w-[16px] h-[16px] flex justify-center items-center">
            •
          </span>{" "}
          <p>{itm}</p>
        </div>
      ))}
    </>
  );
};

const TitleValue = ({ className, title, value }) => (
  <div className={cn("flex flex-col gap-[8px] items-start", className)}>
    <p className={cn(fontCommons, "font-bold text-LightPrimary")}>{title}</p>
    <p
      className={cn(fontCommons, "flex gap-x-[4px] text-[#5F6583] font-medium")}
    >
      {value}
    </p>
  </div>
);
