import React, { Fragment, Suspense, useContext } from "react";
import AccountManagment from "../../Components/AccoutSetting/AccountManagement";
import { ProfileSection } from "../../Components/AccoutSetting/ProfileSettings";
import SocialCompo from "../../Components/SocialCompo";
import TitleComp from "../../Components/TitleComp";
import { cn } from "../../Components/utils";
import {
  AppleIcon,
  FacebookIcon,
  GoogleIcon,
  LinkedAccountIcon,
} from "../../Icons/Icons";
import { useNavbar } from "../../customHook/useNavbar";
import { DeviderLine, SettingCard } from "./SettingRow";
import LoadingContext from "../../Context/LoadingContext";
import Skeleton from "react-loading-skeleton";
import { t } from "i18next";

const AccountSetting = ({ userData, setUserData }) => {
  useNavbar(t("account_setting"));

  if (typeof userData === "string") {
    return (
      <div className="w-full">
        <div className="flex justify-center">{t("server_error")}</div>
      </div>
    );
  }

  const { apple_account, facebook_account, google_account } = userData || {};

  const isSocialAccountLinked =
    apple_account.length > 0 ||
    facebook_account.length > 0 ||
    google_account.length > 0;

  return (
    <>
      <Suspense fallback={<div>{t("loading")}</div>}>
        <div className="w-full">
          <div className="px-20 py-10 flex flex-col">
            <div className="my-[40px]">
              <ProfileSection userData={userData} setUserData={setUserData} />
            </div>
            <div
              className={cn("hidden", {
                "block mb-[40px]": isSocialAccountLinked,
              })}
            >
              <LinkedAccount userData={userData} />
            </div>
            <AccountManagment userData={userData} />
          </div>
        </div>
      </Suspense>
    </>
  );
};

export default AccountSetting;

const LinkedAccount = ({ userData }) => {
  const { google_account, facebook_account, apple_account } = userData;
  const {
    loading: { isAccountsPage },
  } = useContext(LoadingContext);

  return (
    <>
      <TitleComp icon={<LinkedAccountIcon />} title={t("link_account")} />
      <SettingCard>
        {isAccountsPage ? (
          [...Array(2).keys()].map((_, idx) => (
            <Fragment key={idx}>
              <SocialCompo
                key={idx}
                icon={<Skeleton width={32} height={32} circle />}
                email={<Skeleton width={200} height={18} />}
                loginType={<Skeleton width={140} height={30} />}
              />
            </Fragment>
          ))
        ) : (
          <>
            {google_account.map((itm, idx) => (
              <Fragment key={idx}>
                <SocialCompo
                  key={idx}
                  icon={<GoogleIcon className="w-8 h-8" />}
                  email={itm}
                  loginType="Google"
                />
                {facebook_account.length === 0 &&
                  google_account.length - 1 !== idx && <DeviderLine />}
              </Fragment>
            ))}
            {facebook_account.map((itm, idx) => (
              <Fragment key={idx}>
                <SocialCompo
                  key={idx}
                  icon={<FacebookIcon className="w-8 h-8" />}
                  email={itm}
                  loginType="Facebook"
                />
                {apple_account.length === 0 &&
                  facebook_account.length - 1 !== idx && <DeviderLine />}
                {/* {google_account.length - 1 !== idx && <DeviderLine />} */}
              </Fragment>
            ))}
            {apple_account.map((itm, idx) => (
              <Fragment key={idx}>
                <SocialCompo
                  key={idx}
                  icon={<AppleIcon className="w-8 h-8" />}
                  email={itm}
                  loginType="Apple"
                />
              </Fragment>
            ))}
          </>
        )}
      </SettingCard>
    </>
  );
};

export const SettingIcon = (props) => {
  return (
    <svg
      {...props}
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="22" fill="#F3F4F8" />
      <path
        d="M22 25C23.6569 25 25 23.6569 25 22C25 20.3431 23.6569 19 22 19C20.3431 19 19 20.3431 19 22C19 23.6569 20.3431 25 22 25Z"
        stroke="#6D77A0"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 22.8794V21.1194C12 20.0794 12.85 19.2194 13.9 19.2194C15.71 19.2194 16.45 17.9394 15.54 16.3694C15.02 15.4694 15.33 14.2994 16.24 13.7794L17.97 12.7894C18.76 12.3194 19.78 12.5994 20.25 13.3894L20.36 13.5794C21.26 15.1494 22.74 15.1494 23.65 13.5794L23.76 13.3894C24.23 12.5994 25.25 12.3194 26.04 12.7894L27.77 13.7794C28.68 14.2994 28.99 15.4694 28.47 16.3694C27.56 17.9394 28.3 19.2194 30.11 19.2194C31.15 19.2194 32.01 20.0694 32.01 21.1194V22.8794C32.01 23.9194 31.16 24.7794 30.11 24.7794C28.3 24.7794 27.56 26.0594 28.47 27.6294C28.99 28.5394 28.68 29.6994 27.77 30.2194L26.04 31.2094C25.25 31.6794 24.23 31.3994 23.76 30.6094L23.65 30.4194C22.75 28.8494 21.27 28.8494 20.36 30.4194L20.25 30.6094C19.78 31.3994 18.76 31.6794 17.97 31.2094L16.24 30.2194C15.33 29.6994 15.02 28.5294 15.54 27.6294C16.45 26.0594 15.71 24.7794 13.9 24.7794C12.85 24.7794 12 23.9194 12 22.8794Z"
        stroke="#6D77A0"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
