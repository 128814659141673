import React, { useState } from "react";
import { cn } from "../utils";
import { RadioGroup } from "@headlessui/react";
import { ButtonExeFilled, ButtonOutLine } from "../Button";
import SelectList from "../SelectList";
import Textarea from "../Textarea";
import { TwoToneText } from "./UpdateEmail-old";
import Otp from "../../Otp";
import InputField from "../InputField";
import { deleteAccount, sendEmailOtp } from "../../../../Exe-api/apis";
import { useNavigate } from "react-router-dom";
import { Celebrate } from "../ReferEarn/Celebrate";
import { ReactComponent as CelebrateBG } from "../../Icons/ModalCelebrate.svg";
import SpinnerLoader from "../../../../utils/LoaderCommon";
import { t } from "i18next";
import i18n from "../../../../i18";

const fontCommons = "text-[14px] leading-[18px] text-LightStrokActive";
const CONTINUE = "continue_free_member";
const DELETE_ACC = "delete_acc";
const SUCCESS_DELETE = "Succes Delete Account";

const options = [CONTINUE, DELETE_ACC];

const continueMembersTerms = ["point_1", "point_2", "point_3"];

const Reasons = [
  {
    id: 1,
    title: "reasons_1",
  },
  {
    id: 2,
    title: "reasons_2",
  },
  {
    id: 3,
    title: "reasons_3",
  },
  {
    id: 4,
    title: "reasons_4",
  },
  {
    id: 5,
    title: "reasons_5",
  },
  {
    id: 6,
    title: "reasons_6",
  },
  {
    id: 7,
    title: "reasons_7",
  },
  {
    id: 8,
    title: "reasons_8",
  },
  {
    id: 9,
    title: "reasons_9",
  },
];

const DeleteAccount = ({ onClose, email }) => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(CONTINUE);
  const [selectedReason, setSelectedReason] = useState(Reasons[0]);
  const [isSuccessDelete, setIsSuccessDelete] = useState(false);
  const [isOtpSented, setIsOtpSented] = useState(false);
  const [errMsg, setErrMsg] = useState(undefined);
  const [curentStep, setCurentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [feedBack, setFeedBack] = useState("");
  const [isContinue, setIsContinue] = useState(false);

  const [otp, setOtp] = useState("");

  const handleCancel = () => {
    onClose();
  };

  const sendOtp = async () => {
    try {
      await sendEmailOtp({
        topic: "Delete Account",
        email,
        is_email_check: false,
      });
      setIsOtpSented(true);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleUpdate = () => {
    console.log("update");
    setIsContinue(true);
  };

  const handleDeleteAccount = async () => {
    setIsLoading(true);
    try {
      const res = await deleteAccount({
        reason: t(selectedReason.title),
        message: feedBack,
        otp: otp,
      });
      if (res.Error || res.detail) {
        console.log(res.Message);
        setErrMsg(res.Message);
        return;
      }
      // setIsSuccessDelete(true);
      window.location.href = "/delete";

      // navigate("/delete");
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(false);
    }
  };

  const isDisable = selected.length === 0;

  return (
    <>
      {!isContinue && (
        <div className="w-[480px]">
          <ModalTitle title={t("delete_account_capitlise")} />
          <p
            className={cn(
              fontCommons,
              i18n.language === "ar" ? "text-right" : "text-left"
            )}
          >
            {t("delete_description")}
          </p>
          {/* CONTENT */}
          <div className="mt-[40px]">
            <RadioGroup value={selected} onChange={setSelected}>
              <div className="flex flex-col gap-2">
                {options.map((option, index) => (
                  <RadioGroup.Option
                    key={option}
                    value={option}
                    as="div"
                    className="form-check flex justify-center flex-col"
                  >
                    {({ checked }) => (
                      <>
                        <div className="flex items-center gap-x-[10px]">
                          <input
                            type="radio"
                            name="radio"
                            className={`form-check-input h-4 w-4 border border-[#B1BED2] cursor-pointer ${
                              checked ? "bg-LightPrimaryActive" : "bg-white"
                            }`}
                            checked={checked}
                            readOnly
                          />
                          <label
                            className={cn(
                              fontCommons,
                              "form-check-label text-[16px] leading-[20px] text-LightTextParagraph",
                              {
                                "text-LightPrimary": checked,
                              }
                            )}
                          >
                            {t(option)}
                          </label>
                        </div>
                        {index === 0 && (
                          <div className="flex flex-col gap-[8px] my-[24px]">
                            <ContinueMember />
                          </div>
                        )}
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
              <div className="flex gap-x-[20px] mt-[20px]">
                <ButtonOutLine
                  height={40}
                  text={t("cancel")}
                  fontColor={"#5F6583"}
                  borderColor={"#DFE4EC"}
                  onClick={handleCancel}
                  hoverCss={
                    "hover:!text-LightPrimaryActive hover:!bg-[#eef6ff] hover:!border-[#D3E7FF]"
                  }
                />
                <ButtonExeFilled
                  height={40}
                  isDisable={isDisable}
                  text={t("continue")}
                  fontColor={"#FFFFFF"}
                  onClick={handleUpdate}
                  hoverCss={"hover:!bg-DarkHover"}
                />
              </div>
            </RadioGroup>
          </div>
        </div>
      )}

      {/* SELECTED MEMBER CONTINUE */}
      {selected === CONTINUE && isContinue && (
        <div className="w-[480px] relative">
          <CelebrateBG className="absolute top-[-55px] left-[-68px] min-w-[646px] z-[-1]" />
          <div className="flex w-full flex-col pt-[90px]">
            <p className="px-[96px] text-[24px] leading-[32px] tracking-[-1px] font-bold text-center text-LightPrimary">
              {t("welcome_free_member")}
            </p>
            <p className={cn(fontCommons, "mt-[20px] text-center px-[50px]")}>
              {t("welcome_free_desc")}
            </p>
            <div className="mt-[40px]">
              <ButtonExeFilled
                height={40}
                text={t("continue")}
                fontColor={"#FFFFFF"}
                onClick={handleCancel}
                hoverCss={"hover:!bg-DarkHover"}
              />
            </div>
          </div>
        </div>
      )}

      {/* SELECTED DELETE OPTION */}
      {selected === DELETE_ACC &&
        !isSuccessDelete &&
        curentStep === 1 &&
        isContinue && (
          <div className="w-[600px]">
            <ModalTitle title={t("delete_account_confirm")} />
            <p
              className={cn(
                fontCommons,
                "my-[40px]",
                i18n.language === "ar" ? "text-right" : "text-left"
              )}
            >
              {t("request_received")}{" "}
              <span className="text-LightPrimary font-semibold">
                10 {t("days")}
              </span>{" "}
              {t("request_received_1")}
            </p>
            <p
              className={cn(
                fontCommons,
                "text-LightPrimary font-bold mb-[16px]",
                i18n.language === "ar" ? "text-right" : "text-left"
              )}
            >
              {" "}
              {t("delete_reason_text")}
            </p>

            <SelectList
              label={t("reason")}
              optionsWidth={"100%"}
              selected={selectedReason}
              optionsList={Reasons}
              onChangeSelect={(option) => {
                setSelectedReason(option);
              }}
            />

            <div className="mt-[20px]">
              <Textarea
                label={t("message")}
                disabled={false}
                value={feedBack}
                placeholder={t("share_feedback")}
                onChange={(e) => setFeedBack(e.target.value)}
              />
            </div>

            <div className="">
              {/* <TwoToneText
              fontCommons={fontCommons}
              text1="Verify your old email"
              text2={email}
            />
            <div className="mt-[16px] flex gap-x-[12px] items-end">
              <InputField
                className={"w-full"}
                label={t('otp')}
                placeholder={t("enter_otp")}
              />
              <ButtonOutLine
                height={42}
                width={"147px"}
                paddingBlock={9}
                text={"Send OTP"}
                fontColor={"#5F6583"}
                fontSize={16}
                borderColor={"#DFE4EC"}
                onClick={() => setSelected(SUCCESS_DELETE)}
              />
            </div>
            <div className="mt-[16px]">
              <TwoToneText
                fontCommons={fontCommons}
                text1="A verification code has been sent to"
                text2="vsonani@dcdial.com."
              />
              <Otp startTimer={isOtpSented} resendOtp={() => sendOtp()} />
            </div> */}
              <div className="flex gap-x-[20px] mt-[20px]">
                <ButtonOutLine
                  height={40}
                  text={t("cancel")}
                  fontColor={"#5F6583"}
                  borderColor={"#DFE4EC"}
                  onClick={handleCancel}
                  hoverCss={
                    "hover:!text-LightPrimaryActive hover:!bg-[#eef6ff] hover:!border-[#D3E7FF]"
                  }
                />
                <ButtonExeFilled
                  height={40}
                  text={t("delete")}
                  borderColor={"#FF0000"}
                  backgroundColor={"#FF0000"}
                  fontColor={"#FFFFFF"}
                  hoverCss={"hover:!bg-[#D90000]"}
                  onClick={() => {
                    setCurentStep(2);
                    sendOtp();
                  }}
                />
              </div>
            </div>
          </div>
        )}

      {/* SELECTED DELETE SEND OTP */}
      {selected === DELETE_ACC &&
        curentStep === 2 &&
        !isSuccessDelete &&
        isContinue && (
          <div className="w-[400px]">
            <ModalTitle title={t("otp_verification")} />
            <div className="pt-[20px] pb-[40px]">
              <TwoToneText
                fontCommons={fontCommons}
                text1={t("enter_code_sent")}
                text2={email}
              />
            </div>
            <InputField
              // placeholder={t("enter_otp_lower")}
              placeholder={"000 000"}
              type="number"
              maxlength={6}
              inputCss="text-center"
              value={otp || ""}
              error={errMsg}
              onChange={(e) => {
                if (e.target.value.length > 6) return;
                setOtp(e.target.value);
              }}
            />
            <Otp startTimer={isOtpSented} resendOtp={() => sendOtp()} />
            <div className="flex gap-x-[20px] mt-[20px]">
              <ButtonExeFilled
                height={40}
                isDisable={isLoading || otp.length === 0}
                text={isLoading ? <SpinnerLoader /> : t("continue")}
                fontColor={"#FFFFFF"}
                onClick={() => handleDeleteAccount()}
                hoverCss={"hover:!bg-DarkHover"}
              />
            </div>
          </div>
        )}

      {/* SUCCESS DELETE */}
      {selected === DELETE_ACC && isSuccessDelete && (
        <div className="w-[370px]">
          <div className="flex justify-center items-center w-full">
            <SuccessIcon />
          </div>
          <p className="my-[20px] text-[24px] leading-[32px] tracking-[-1px] font-bold text-center text-LightPrimary">
            {t("success_deleted")}
          </p>
          <p
            className={cn(
              fontCommons,
              "mt-[20px] text-center leading-[24px] font-medium text-[16px]"
            )}
          >
            {t("success_deleted_desc")}
          </p>
        </div>
      )}
    </>
  );
};

export default DeleteAccount;

export const ModalTitle = ({ title }) => (
  <div
    className={cn(
      "text-[24px] leading-[32px] font-bold text-LightPrimary flex justify-between mb-[20px]"
    )}
  >
    {title}
  </div>
);

const ContinueMember = () => (
  <>
    {continueMembersTerms.map((itm, idx) => (
      <div
        key={idx}
        className={cn(fontCommons, "flex font-medium leading-[20px]")}
      >
        <span className="w-[16px] min-w-[16px] h-[16px] flex justify-center items-center">
          •
        </span>{" "}
        <p className={i18n.language === "ar" ? "text-right" : "text-left"}>
          {t(itm)}
        </p>
      </div>
    ))}
  </>
);

export const SuccessIcon = (props) => (
  <svg
    {...props}
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 56.875C15.175 56.875 3.125 44.825 3.125 30C3.125 15.175 15.175 3.125 30 3.125C44.825 3.125 56.875 15.175 56.875 30C56.875 44.825 44.825 56.875 30 56.875ZM30 6.875C17.25 6.875 6.875 17.25 6.875 30C6.875 42.75 17.25 53.125 30 53.125C42.75 53.125 53.125 42.75 53.125 30C53.125 17.25 42.75 6.875 30 6.875Z"
      fill="#23B339"
    />
    <path
      d="M26.4496 38.95C25.9496 38.95 25.4746 38.75 25.1246 38.4L18.0496 31.325C17.3246 30.6 17.3246 29.4 18.0496 28.675C18.7746 27.95 19.9746 27.95 20.6996 28.675L26.4496 34.425L39.2996 21.575C40.0246 20.85 41.2246 20.85 41.9496 21.575C42.6746 22.3 42.6746 23.5 41.9496 24.225L27.7746 38.4C27.4246 38.75 26.9496 38.95 26.4496 38.95Z"
      fill="#23B339"
    />
  </svg>
);
