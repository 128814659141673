import axios from "axios";
import { BASE_URL } from "../config";


export const commonHeaders = {
  "Access-Control-Allow-Origin": "*",
  "ngrok-skip-browser-warning": "true",
};

export const token = `Bearer ${JSON.parse(localStorage.getItem("token"))}`;

export const authenticateUser = async ({ token }) => {
  try {
    const res = await axios.get(
      `${BASE_URL}/profile/check_token?token=${token}`,
      {
        headers: { ...commonHeaders },
      }
    );

    if (!res.data.Result.Error || !res.data.Result.detail) {
      localStorage.setItem("token", JSON.stringify(token));
    }

    return res.data.Result;
  } catch (error) {
    return error;
  }
};

export const logoutCurrentUser = async () => {
  const token = `Bearer ${JSON.parse(localStorage.getItem("token"))}`;

  const removeBearerToken = token.replace("Bearer", "").trim();

  try {
    const res = await axios.post(
      `${BASE_URL}/auth/logout`,
      { token: removeBearerToken },
      {
        headers: { Authorization: token, ...commonHeaders },
      }
    );
    return res.data.Result;
  } catch (error) {
    return error?.response?.data || "Something went wrong";
  }
};

export const getProfileData = async (parmaToken) => {
  try {
    const res = await axios.get(`${BASE_URL}/profile/profile`, {
      headers: { Authorization: parmaToken, ...commonHeaders },
    });
    return res.data.Result;
  } catch (error) {
    return error?.response?.data?.Message || "Something went wrong";
  }
};

export const updateProfile = async (payload) => {
  const formData = new FormData();

  // Append first_name and last_name
  formData.append("first_name", payload.first_name);
  formData.append("last_name", payload.last_name);

  // Conditionally append profile_picture if it exists in the payload
  if (payload.profile_picture) {
    formData.append("profile_picture", payload.profile_picture);
  }

  try {
    const res = await axios.patch(
      `${BASE_URL}/profile/update_information`,
      formData,
      {
        headers: { Authorization: token, ...commonHeaders },
      }
    );
    return res.data.Result;
  } catch (error) {
    return error?.response?.data || "Something went wrong";
  }
};

export const sendEmailOtp = async (payload) => {
  try {
    const res = await axios.post(`${BASE_URL}/profile/send_otp_mail`, payload, {
      headers: { Authorization: token, ...commonHeaders },
    });
    return res.data.Result;
  } catch (error) {
    console.log(error.response.data);
    return error?.response?.data || "Something went wrong";
  }
};

export const verifyOtp = async (payload) => {
  try {
    const res = await axios.post(`${BASE_URL}/profile/verify_otp`, payload, {
      headers: { Authorization: token, ...commonHeaders },
    });
    return res.data.Result;
  } catch (error) {
    return error?.response?.data || "Something went wrong";
  }
};

export const updateFinalEmail = async (payload) => {
  try {
    const res = await axios.patch(`${BASE_URL}/profile/update_email`, payload, {
      headers: { Authorization: token, ...commonHeaders },
    });
    return res.data.Result;
  } catch (error) {
    return error?.response?.data || "Something went wrong";
  }
};

export const sendMobileOtp = async (payload) => {
  try {
    const res = await axios.post(`${BASE_URL}/profile/send_otp_sms`, payload, {
      headers: { Authorization: token, ...commonHeaders },
    });
    return res.data.Result;
  } catch (error) {
    return error?.response?.data || "Something went wrong";
  }
};

export const updateMobileFinally = async (payload) => {
  try {
    const res = await axios.patch(
      `${BASE_URL}/profile/update_mobile_number`,
      payload,
      {
        headers: { Authorization: token, ...commonHeaders },
      }
    );
    return res.data.Result;
  } catch (error) {
    return error?.response?.data || "Something went wrong";
  }
};

export const updatePassword = async (payload) => {
  try {
    const res = await axios.patch(
      `${BASE_URL}/profile/change_password`,
      payload,
      {
        headers: { Authorization: token, ...commonHeaders },
      }
    );
    return res.data.Result;
  } catch (error) {
    return error?.response?.data || "Something went wrong";
  }
};

export const forgotPass = async (payload) => {
  try {
    const res = await axios.patch(`${BASE_URL}/profile/set_password`, payload, {
      headers: { Authorization: token, ...commonHeaders },
    });
    return res.data.Result;
  } catch (error) {
    return error?.response?.data || "Something went wrong";
  }
};

export const deleteAccount = async (payload) => {
  try {
    const res = await axios.patch(
      `${BASE_URL}/profile/profile_delete`,
      payload,
      {
        headers: { Authorization: token, ...commonHeaders },
      }
    );
    return res.data.Result;
  } catch (error) {
    return error?.response?.data || "Something went wrong";
  }
};
