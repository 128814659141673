import axios from "axios";

import { commonHeaders, token } from "./apis";
import { BASE_URL } from "../config";

export const getResalaPlans = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/subscription/details`, {
      headers: { Authorization: token, ...commonHeaders },
    });
    return res.data.Result;
  } catch (error) {
    return error?.response?.data?.Message || "Something went wrong";
  }
};

export const getUserPlan = async ({ token }) => {
  try {
    const res = await axios.get(`${BASE_URL}/subscription/user_subscription`, {
      headers: { Authorization: token, ...commonHeaders },
    });
    return res.data.Result;
  } catch (error) {
    return error?.response?.data?.Message || "Something went wrong";
  }
};

export const getDiscount = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/subscription/discount`, {
      headers: { Authorization: token, ...commonHeaders },
    });
    return res.data.Result;
  } catch (error) {
    return error?.response?.data?.Message || "Something went wrong";
  }
};

export const sendPriceId = async (payload) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/subscription/checkout_session`,
      payload,
      {
        headers: { Authorization: token, ...commonHeaders },
      }
    );
    return res.data.Result;
  } catch (error) {
    return error?.response?.data?.Message || "Something went wrong";
  }
};

export const changePlan = async (payload) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/subscription/change_subscription`,
      payload,
      {
        headers: { Authorization: token, ...commonHeaders },
      }
    );
    return res.data.Result;
  } catch (error) {
    return error?.response?.data?.Message || "Something went wrong";
  }
};
