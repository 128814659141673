import React from 'react';

const BgAnimatedLayer = () => {
  return (
    <>
      <div className="relative w-full h-full">
        <div className="absolute top-[40px] left-[40px]">
          <DonatsHalf className="animate-round-half-top" stroke="#EEF6FF" />
        </div>
        <div className="absolute bottom-[154px] right-[160px]">
          <DonatsHalf
            className="animate-round-half rotate-180"
            stroke="#EEF6FF"
          />
        </div>
        <div className="absolute left-[196px] top-0">
          <StarIcon className="animate-vertical-bottom-up" />
        </div>
        <div className="absolute right-[563px] top-[161px]">
          <StarIcon className="animate-vertical-bottom-up" />
        </div>
        <div className="absolute right-[143px] top-[65px] max_sm:right-[630px]">
          <Triangle className="animate-vertical-bottom-up" />
        </div>
        <div className="absolute left-[113px] bottom-[130px]">
          <Triangle className="animate-vertical-bottom-up" />
        </div>
        <div className="absolute left-[357px] bottom-[40px]">
          <Square2 className="animate-vertical-bottom-up" />
        </div>
        <div className="absolute right-[52px] bottom-[165px]">
          <Square2 className="animate-vertical-bottom-up" />
        </div>
        <div className="absolute left-[420px] top-[112px]">
          <Square className="animate-vertical-bottom-up" />
        </div>
        <div className="absolute left-[218px] bottom-[164px]">
          <RoundedGroup />
        </div>
        <div className="absolute right-[480px] bottom-[18px] max_sm:right-[560px]">
          <GearGroupIcons />
        </div>
        <div className="absolute right-[328px] top-[38px]">
          <StarGroupIcon />
        </div>
      </div>
    </>
  );
};

export default BgAnimatedLayer;

const DonatsHalf = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="134"
    height="118"
    viewBox="0 0 134 118"
    fill="none"
  >
    <path
      d="M101.642 25.248C112.834 46.4158 104.805 72.4499 83.6373 83.6421C62.4694 94.8343 36.4353 86.8051 25.2431 65.6373"
      stroke={props.stroke}
      strokeWidth="40"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const StarIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
  >
    <path
      d="M5.22955 1.98771C5.94419 -0.211722 9.05581 -0.211722 9.77045 1.98771V1.98771C10.09 2.97133 11.0067 3.63729 12.0409 3.63729V3.63729C14.3535 3.63729 15.3151 6.59661 13.4441 7.95593V7.95593C12.6074 8.56384 12.2573 9.64138 12.5769 10.625V10.625C13.2915 12.8244 10.7742 14.6534 8.90321 13.2941V13.2941C8.0665 12.6862 6.9335 12.6862 6.09679 13.2941V13.2941C4.22584 14.6534 1.70849 12.8244 2.42313 10.625V10.625C2.74273 9.64138 2.39261 8.56384 1.5559 7.95593V7.95593C-0.315054 6.59661 0.646487 3.63729 2.95911 3.63729V3.63729C3.99335 3.63729 4.90996 2.97133 5.22955 1.98771V1.98771Z"
      fill="#EEF6FF"
    />
  </svg>
);

const Square = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M2.70173 3.58929C2.71699 0.733464 6.27679 -0.562197 8.12417 1.61569C8.70498 2.3004 9.55594 2.69721 10.4538 2.70201C13.3096 2.71727 14.6053 6.27707 12.4274 8.12445C11.7427 8.70526 11.3459 9.55622 11.3411 10.4541C11.3258 13.3099 7.76603 14.6056 5.91865 12.4277C5.33784 11.743 4.48688 11.3462 3.58901 11.3414C0.733186 11.3261 -0.562474 7.76631 1.61541 5.91892C2.30013 5.33811 2.69694 4.48715 2.70173 3.58929Z"
      fill="#EEF6FF"
    />
  </svg>
);

const Triangle = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
  >
    <path
      d="M0.828607 5.23062C0.50085 1.81684 4.33967 -0.399496 7.13221 1.59124L9.26069 3.10858C9.57027 3.32928 9.90975 3.50471 10.2689 3.62958L11.5372 4.07062C14.9927 5.27217 15.1553 10.0984 11.7885 11.5298L9.73272 12.4039C9.43396 12.5309 9.15177 12.6938 8.89238 12.8891L7.10752 14.2324C4.18451 16.4324 0.0861548 13.8785 0.773311 10.2852L1.02554 8.96621C1.09695 8.59277 1.11476 8.21107 1.07842 7.83261L0.828607 5.23062Z"
      fill="#EEF6FF"
    />
  </svg>
);

const Square2 = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g clipPath="url(#clip0_850_24112)">
      <path
        d="M5.07107 1.5344L1.53553 5.06993C-0.417088 7.02256 -0.417088 10.1884 1.53553 12.141L5.07107 15.6765C7.02369 17.6292 10.1895 17.6292 12.1421 15.6765L15.6777 12.141C17.6303 10.1884 17.6303 7.02256 15.6777 5.06994L12.1421 1.5344C10.1895 -0.41822 7.02369 -0.418221 5.07107 1.5344Z"
        fill="#EEF6FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_850_24112">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const RoundedGroup = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="86"
    height="89"
    viewBox="0 0 86 89"
    fill="none"
  >
    <circle
      className="animate-fade-in-out-slow"
      cx="46"
      cy="47"
      r="20"
      fill="#19224C"
    />
    <circle
      className="animate-fade-in-out"
      cx="66"
      cy="8"
      r="8"
      fill="#19224C"
    />
    <circle
      className="animate-fade-in-out"
      cx="84"
      cy="37"
      r="2"
      fill="#19224C"
    />
    <circle
      className="animate-fade-in-out"
      cx="5"
      cy="32"
      r="5"
      fill="#19224C"
    />
    <circle
      className="animate-fade-in-out"
      cx="42"
      cy="83"
      r="6"
      fill="#19224C"
    />
  </svg>
);

const GearGroupIcons = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="62"
    height="51"
    viewBox="0 0 62 51"
    fill="none"
  >
    <path
      className="animate-fade-in-out-slow"
      d="M24.407 15.0707C24.5794 13.7974 26.4206 13.7974 26.593 15.0707C26.7297 16.0803 28.0452 16.3806 28.6065 15.5302C29.3143 14.4579 30.9731 15.2567 30.576 16.4787C30.2611 17.4477 31.3161 18.289 32.1907 17.7664C33.2937 17.1073 34.4416 18.5468 33.5536 19.4754C32.8495 20.2119 33.4349 21.4276 34.4497 21.3362C35.7294 21.221 36.1391 23.0159 34.9361 23.4674C33.9822 23.8253 33.9822 25.1747 34.9361 25.5326C36.1391 25.9841 35.7294 27.779 34.4497 27.6638C33.4349 27.5724 32.8495 28.7881 33.5536 29.5246C34.4416 30.4532 33.2937 31.8927 32.1907 31.2336C31.3161 30.711 30.2611 31.5523 30.576 32.5213C30.9731 33.7433 29.3143 34.5421 28.6065 33.4698C28.0452 32.6194 26.7297 32.9197 26.593 33.9293C26.4206 35.2026 24.5794 35.2026 24.407 33.9293C24.2703 32.9197 22.9548 32.6194 22.3935 33.4698C21.6857 34.5421 20.0269 33.7433 20.424 32.5213C20.7389 31.5523 19.6839 30.711 18.8093 31.2336C17.7063 31.8927 16.5584 30.4532 17.4464 29.5246C18.1505 28.7881 17.5651 27.5724 16.5503 27.6638C15.2706 27.779 14.8609 25.9841 16.0639 25.5326C17.0178 25.1747 17.0178 23.8253 16.0639 23.4674C14.8609 23.0159 15.2706 21.221 16.5503 21.3362C17.5651 21.4276 18.1505 20.2119 17.4464 19.4754C16.5584 18.5468 17.7063 17.1073 18.8093 17.7664C19.6839 18.289 20.7389 17.4477 20.424 16.4787C20.0269 15.2567 21.6857 14.4579 22.3935 15.5302C22.9548 16.3806 24.2703 16.0803 24.407 15.0707Z"
      fill="#6D77A0"
    />
    <path
      className="animate-fade-in-out"
      d="M48.6877 6.3059C48.737 5.94211 49.263 5.94211 49.3123 6.3059C49.3513 6.59438 49.7272 6.68016 49.8876 6.43721C50.0898 6.13082 50.5637 6.35906 50.4503 6.7082C50.3603 6.98505 50.6617 7.22542 50.9116 7.07611C51.2268 6.88781 51.5547 7.29907 51.301 7.56441C51.0999 7.77481 51.2671 8.12216 51.5571 8.09606C51.9227 8.06314 52.0397 8.57598 51.696 8.70496C51.4235 8.80724 51.4235 9.19276 51.696 9.29504C52.0397 9.42402 51.9227 9.93686 51.5571 9.90394C51.2671 9.87784 51.0999 10.2252 51.301 10.4356C51.5547 10.7009 51.2268 11.1122 50.9116 10.9239C50.6617 10.7746 50.3603 11.0149 50.4503 11.2918C50.5637 11.6409 50.0898 11.8692 49.8876 11.5628C49.7272 11.3198 49.3513 11.4056 49.3123 11.6941C49.263 12.0579 48.737 12.0579 48.6877 11.6941C48.6487 11.4056 48.2728 11.3198 48.1124 11.5628C47.9102 11.8692 47.4363 11.6409 47.5497 11.2918C47.6397 11.0149 47.3383 10.7746 47.0884 10.9239C46.7732 11.1122 46.4453 10.7009 46.699 10.4356C46.9001 10.2252 46.7329 9.87784 46.4429 9.90394C46.0773 9.93686 45.9603 9.42402 46.304 9.29504C46.5765 9.19276 46.5765 8.80724 46.304 8.70496C45.9603 8.57598 46.0773 8.06314 46.4429 8.09606C46.7329 8.12216 46.9001 7.77481 46.699 7.56441C46.4453 7.29907 46.7732 6.88781 47.0884 7.07611C47.3383 7.22542 47.6397 6.98505 47.5497 6.7082C47.4363 6.35906 47.9102 6.13082 48.1124 6.43721C48.2728 6.68016 48.6487 6.59438 48.6877 6.3059Z"
      fill="#6D77A0"
    />
    <path
      className="animate-fade-in-out"
      d="M5.62526 2.76708C5.68438 2.33054 6.31562 2.33054 6.37474 2.76708C6.42162 3.11325 6.87265 3.2162 7.06508 2.92465C7.30776 2.55699 7.87648 2.83087 7.74034 3.24983C7.63238 3.58206 7.99407 3.87051 8.29395 3.69133C8.67212 3.46537 9.06569 3.95889 8.76124 4.27729C8.51982 4.52978 8.72055 4.94659 9.06847 4.91527C9.50723 4.87576 9.64769 5.49117 9.23525 5.64595C8.90819 5.76869 8.90819 6.23131 9.23525 6.35405C9.64769 6.50883 9.50723 7.12424 9.06847 7.08473C8.72055 7.05341 8.51982 7.47022 8.76124 7.72271C9.06569 8.04111 8.67212 8.53463 8.29395 8.30867C7.99407 8.12949 7.63238 8.41794 7.74034 8.75016C7.87648 9.16913 7.30776 9.44301 7.06508 9.07535C6.87265 8.7838 6.42162 8.88675 6.37474 9.23292C6.31562 9.66946 5.68438 9.66946 5.62526 9.23292C5.57838 8.88675 5.12735 8.7838 4.93492 9.07535C4.69224 9.44301 4.12352 9.16913 4.25966 8.75016C4.36762 8.41794 4.00593 8.12949 3.70605 8.30867C3.32788 8.53463 2.93431 8.04111 3.23876 7.72271C3.48018 7.47022 3.27945 7.05341 2.93153 7.08473C2.49277 7.12424 2.35231 6.50883 2.76475 6.35405C3.09181 6.23131 3.09181 5.76869 2.76475 5.64595C2.35231 5.49117 2.49277 4.87576 2.93153 4.91527C3.27945 4.94659 3.48018 4.52978 3.23876 4.27729C2.93431 3.95889 3.32788 3.46537 3.70605 3.69133C4.00593 3.87051 4.36762 3.58206 4.25966 3.24983C4.12352 2.83087 4.69224 2.55699 4.93492 2.92465C5.12735 3.2162 5.57838 3.11325 5.62526 2.76708Z"
      fill="#6D77A0"
    />
    <path
      className="animate-fade-in-out"
      d="M5.81263 35.3835C5.84219 35.1653 6.15781 35.1653 6.18737 35.3835C6.21081 35.5566 6.43632 35.6081 6.53254 35.4623C6.65388 35.2785 6.93824 35.4154 6.87017 35.6249C6.81619 35.791 6.99704 35.9353 7.14698 35.8457C7.33606 35.7327 7.53284 35.9794 7.38062 36.1386C7.25991 36.2649 7.36028 36.4733 7.53424 36.4576C7.75361 36.4379 7.82385 36.7456 7.61762 36.823C7.45409 36.8843 7.45409 37.1157 7.61762 37.177C7.82385 37.2544 7.75361 37.5621 7.53424 37.5424C7.36028 37.5267 7.25991 37.7351 7.38062 37.8614C7.53284 38.0206 7.33606 38.2673 7.14698 38.1543C6.99704 38.0647 6.81619 38.209 6.87017 38.3751C6.93824 38.5846 6.65388 38.7215 6.53254 38.5377C6.43632 38.3919 6.21081 38.4434 6.18737 38.6165C6.15781 38.8347 5.84219 38.8347 5.81263 38.6165C5.78919 38.4434 5.56368 38.3919 5.46746 38.5377C5.34612 38.7215 5.06176 38.5846 5.12983 38.3751C5.18381 38.209 5.00296 38.0647 4.85302 38.1543C4.66394 38.2673 4.46716 38.0206 4.61938 37.8614C4.74009 37.7351 4.63972 37.5267 4.46576 37.5424C4.24639 37.5621 4.17615 37.2544 4.38238 37.177C4.54591 37.1157 4.54591 36.8843 4.38238 36.823C4.17615 36.7456 4.24639 36.4379 4.46576 36.4576C4.63972 36.4733 4.74009 36.2649 4.61938 36.1386C4.46716 35.9794 4.66394 35.7327 4.85302 35.8457C5.00296 35.9353 5.18381 35.791 5.12983 35.6249C5.06176 35.4154 5.34612 35.2785 5.46746 35.4623C5.56368 35.6081 5.78919 35.5566 5.81263 35.3835Z"
      fill="#6D77A0"
    />
    <path
      className="animate-fade-in-out"
      d="M51.3754 35.6118C51.474 34.8842 52.526 34.8842 52.6246 35.6118C52.7027 36.1888 53.4544 36.3603 53.7751 35.8744C54.1796 35.2616 55.1275 35.7181 54.9006 36.4164C54.7206 36.9701 55.3235 37.4508 55.8233 37.1522C56.4535 36.7756 57.1095 37.5981 56.6021 38.1288C56.1997 38.5496 56.5343 39.2443 57.1141 39.1921C57.8454 39.1263 58.0795 40.152 57.3921 40.4099C56.847 40.6145 56.847 41.3855 57.3921 41.5901C58.0795 41.848 57.8454 42.8737 57.1141 42.8079C56.5343 42.7557 56.1997 43.4504 56.6021 43.8712C57.1095 44.4019 56.4535 45.2244 55.8233 44.8478C55.3235 44.5492 54.7206 45.0299 54.9006 45.5836C55.1275 46.2819 54.1796 46.7384 53.7751 46.1256C53.4544 45.6397 52.7027 45.8112 52.6246 46.3882C52.526 47.1158 51.474 47.1158 51.3754 46.3882C51.2973 45.8112 50.5456 45.6397 50.2249 46.1256C49.8204 46.7384 48.8725 46.2819 49.0994 45.5836C49.2794 45.0299 48.6765 44.5492 48.1767 44.8478C47.5465 45.2244 46.8905 44.4019 47.3979 43.8712C47.8003 43.4504 47.4657 42.7557 46.8859 42.8079C46.1546 42.8737 45.9205 41.848 46.6079 41.5901C47.153 41.3855 47.153 40.6145 46.6079 40.4099C45.9205 40.152 46.1546 39.1263 46.8859 39.1921C47.4657 39.2443 47.8003 38.5496 47.3979 38.1288C46.8905 37.5981 47.5465 36.7756 48.1767 37.1522C48.6765 37.4508 49.2794 36.9701 49.0994 36.4164C48.8725 35.7181 49.8204 35.2616 50.2249 35.8744C50.5456 36.3603 51.2973 36.1888 51.3754 35.6118Z"
      fill="#6D77A0"
    />
  </svg>
);

const StarGroupIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="74"
    height="53"
    viewBox="0 0 74 53"
    fill="none"
  >
    <path
      className="animate-fade-in-out-slow"
      d="M32.1243 17.069C32.7686 15.3278 35.2314 15.3278 35.8757 17.069L37.7322 22.0862C37.9348 22.6336 38.3664 23.0652 38.9138 23.2678L43.931 25.1243C45.6722 25.7686 45.6722 28.2314 43.931 28.8757L38.9138 30.7322C38.3664 30.9348 37.9348 31.3664 37.7322 31.9138L35.8757 36.931C35.2314 38.6722 32.7686 38.6722 32.1243 36.931L30.2678 31.9138C30.0652 31.3664 29.6336 30.9348 29.0862 30.7322L24.069 28.8757C22.3278 28.2314 22.3278 25.7686 24.069 25.1243L29.0862 23.2678C29.6336 23.0652 30.0652 22.6336 30.2678 22.0862L32.1243 17.069Z"
      fill="#1678F2"
    />
    <path
      className="animate-fade-in-out"
      d="M65.0621 10.5345C65.3843 9.66389 66.6157 9.66389 66.9379 10.5345L68.0012 13.408C68.1024 13.6818 68.3182 13.8976 68.592 13.9988L71.4655 15.0621C72.3361 15.3843 72.3361 16.6157 71.4655 16.9379L68.592 18.0012C68.3182 18.1024 68.1024 18.3182 68.0012 18.592L66.9379 21.4655C66.6157 22.3361 65.3843 22.3361 65.0621 21.4655L63.9988 18.592C63.8976 18.3182 63.6818 18.1024 63.408 18.0012L60.5345 16.9379C59.6639 16.6157 59.6639 15.3843 60.5345 15.0621L63.408 13.9988C63.6818 13.8976 63.8976 13.6818 63.9988 13.408L65.0621 10.5345Z"
      fill="#1678F2"
    />
    <path
      className="animate-fade-in-out"
      d="M4.53107 1.26725C4.69215 0.831944 5.30785 0.831944 5.46893 1.26725L6.27067 3.43393C6.32131 3.57078 6.42921 3.67869 6.56607 3.72933L8.73275 4.53107C9.16806 4.69215 9.16806 5.30785 8.73275 5.46893L6.56607 6.27067C6.42921 6.32131 6.32131 6.42921 6.27067 6.56607L5.46893 8.73275C5.30785 9.16806 4.69215 9.16806 4.53107 8.73275L3.72933 6.56607C3.67869 6.42921 3.57079 6.32131 3.43393 6.27067L1.26725 5.46893C0.831944 5.30785 0.831944 4.69215 1.26725 4.53107L3.43393 3.72933C3.57078 3.67869 3.67869 3.57079 3.72933 3.43393L4.53107 1.26725Z"
      fill="#1678F2"
    />
    <path
      className="animate-fade-in-out"
      d="M16.7186 36.7604C16.8153 36.4992 17.1847 36.4992 17.2814 36.7604L17.7624 38.0604C17.7928 38.1425 17.8575 38.2072 17.9396 38.2376L19.2396 38.7186C19.5008 38.8153 19.5008 39.1847 19.2396 39.2814L17.9396 39.7624C17.8575 39.7928 17.7928 39.8575 17.7624 39.9396L17.2814 41.2396C17.1847 41.5008 16.8153 41.5008 16.7186 41.2396L16.2376 39.9396C16.2072 39.8575 16.1425 39.7928 16.0604 39.7624L14.7604 39.2814C14.4992 39.1847 14.4992 38.8153 14.7604 38.7186L16.0604 38.2376C16.1425 38.2072 16.2072 38.1425 16.2376 38.0604L16.7186 36.7604Z"
      fill="#1678F2"
    />
    <path
      className="animate-fade-in-out"
      d="M54.5311 44.2673C54.6922 43.8319 55.3078 43.8319 55.4689 44.2673L56.2707 46.4339C56.3213 46.5708 56.4292 46.6787 56.5661 46.7293L58.7327 47.5311C59.1681 47.6922 59.1681 48.3078 58.7327 48.4689L56.5661 49.2707C56.4292 49.3213 56.3213 49.4292 56.2707 49.5661L55.4689 51.7327C55.3078 52.1681 54.6922 52.1681 54.5311 51.7327L53.7293 49.5661C53.6787 49.4292 53.5708 49.3213 53.4339 49.2707L51.2673 48.4689C50.8319 48.3078 50.8319 47.6922 51.2673 47.5311L53.4339 46.7293C53.5708 46.6787 53.6787 46.5708 53.7293 46.4339L54.5311 44.2673Z"
      fill="#1678F2"
    />
  </svg>
);
