import React, { useEffect, useState } from 'react';
import { TextAreaField } from '../../Components/InputFields';
import { UseResalaButton } from '../../Components/Button';
import StarImg from '../../assets/images/Extension/Star.png';
import { useTranslation } from 'react-i18next';

const SlowDownComp = ({
  inputValue,
  handleChangeText,
  titleText,
  text,
  name,
  placeholderT,
  isImage,
  buttonText,
  image,
  buttonWidth,
  isInput,
  isNotFree,
  isNotEnough,
}) => {
  const { t } = useTranslation();
  const [featureList, setFeatureList] = useState(getTranslatedList(t));
  useEffect(() => {
    setFeatureList(getTranslatedList(t));
  }, [t]);
  return (
    <div className="mt-[120px] w-[424px] h-max rounded-[24px] bg-white py-[30px] px-10 flex flex-col gap-6 shadow-[0_1px_2px_0_#f3f8fc]">
      <p className="text-[24px] font-bold text-LightPrimary text-center">
        {titleText}
      </p>
      {isImage && (
        <>
          <div className="min-h-[210px]">
            <img src={image} alt={name} />
          </div>
        </>
      )}
      {isInput && (
        <TextAreaField
          type="text"
          value={inputValue}
          placeholder={placeholderT}
          name={name}
          handleOnChange={handleChangeText}
          background="#F9F9FB"
          radius="8px"
          row="4"
          fontSize="14px"
        />
      )}
      {isNotFree && (
        <div className="flex flex-col gap-5 w-full">
          <div className="p-3 bg-LightBgPrimary rounded-2xl flex flex-col gap-4 w-full">
            <div className="">
              <div className="flex justify-between">
                <p className="text-TextSmall font-medium font-DMSansFont text-LightPrimary">
                  {t('FreeQueries')}
                </p>
                <div className="flex items-center">
                  <p className="text-TextSmall font-semibold font-DMSansFont text-LightPrimary">
                    <span className={`${isNotEnough && 'text-[#1678f2]'}`}>
                      0
                    </span>
                    /50
                  </p>
                  <p className="text-MediumText font-normal font-DMSansFont text-LightStrokActive">
                    /day
                  </p>
                </div>
              </div>
              <div className="w-full h-2 bg-LightStrokPrimary rounded mt-2"></div>
            </div>
            <div className="">
              <div className="flex justify-between">
                <p className="text-TextSmall font-medium font-DMSansFont text-LightPrimary">
                  {t('FreeAdvancedQueries')}
                </p>
                <div className="flex items-center">
                  <p className="text-TextSmall font-semibold font-DMSansFont text-LightPrimary">
                    <span className={`${isNotEnough && 'text-[#1678f2]'}`}>
                      0
                    </span>
                    /10
                  </p>
                </div>
              </div>
              <div className="w-full h-2 bg-LightStrokPrimary rounded mt-2"></div>
            </div>
          </div>
          <div>
            <div className="flex justify-between">
              <div className="flex gap-1 items-center">
                <div>
                  <img src={StarImg} alt="star" />
                </div>
                <p className="text-MediumText text-LightPrimary font-medium font-DMSansFont">
                  {t('ProFeaturesTrial')}
                </p>
              </div>
              <p className="text-MediumText text-[#1678F2] font-medium font-DMSansFont">
                More
              </p>
            </div>
            <div className="p-3 bg-LightBgPrimary rounded-2xl flex flex-col gap-4 w-full mt-2">
              {featureList.map((items, index) => (
                <div
                  className="flex justify-between text-TextSmall font-DMSansFont text-LightPrimary font-medium"
                  key={index}
                >
                  <p>{items.text}</p>
                  <div className="flex items-center">
                    <p
                      className={`font-semibold ${
                        isNotEnough && 'text-[#1678f2]'
                      }`}
                    >
                      {items.value}/
                    </p>
                    <p className="font-semibold">{items.total}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {text && (
        <p
          className="text-textSmall font-DMSansFont font-normal text-LightPrimary"
          dangerouslySetInnerHTML={{ __html: text }}
        ></p>
      )}

      <div className="flex justify-center">
        <UseResalaButton
          width={buttonWidth ? buttonWidth : '236px'}
          title={buttonText}
        />
      </div>
    </div>
  );
};

const getTranslatedList = (t) => {
  // Define your lists here
  const lists = [
    {
      text: t('ReadPage'),
      value: 0,
      total: 2,
    },
    {
      text: t('VideoSummary'),
      value: 0,
      total: 2,
    },
    {
      text: t('DocChat'),
      value: 0,
      total: 10,
    },
    {
      text: t('WebAccess'),
      value: 0,
      total: 5,
    },
  ];

  return lists;
};

export default SlowDownComp;
