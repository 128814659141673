import React from 'react'

const CommonData = ({ heading, data, text, list,text2 }) => {
  return (
    <div>
      <div className="discoverHeadingDM text-LightPrimary">{heading}</div>
      {text && (
        <>
          <div className="privacyTextDM mt-[16px] max_sm:mt-[10px]">{text}</div>
        </>
      )}
      {text2 && (
        <>
          <div className="privacyTextDM mt-[10px] max_sm:mt-[10px]">{text2}</div>
        </>
      )}

      {list && (
        <>
          {data?.map((text1, index1) => {
            return (
              <React.Fragment key={index1}>
                <div className="flex mt-[10px] max_sm:mt-[5px] items-start">
                  <div className="w-[3%] p-[8px] max_md:w-[5%] max_sm:w-[7%]">
                    <div className="w-[6px] h-[6px] bg-LightTextParagraph rounded-full"></div>
                  </div>
                  <div className=" w-[95%] privacyTextDM">{text1.title}</div>
                </div>
              </React.Fragment>
            );
          })}
        </>
      )}
    </div>
  );
};

export default CommonData;