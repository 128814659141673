import React, { useEffect, useState } from 'react';
import { ButtonFilledArrow } from '../../Components/Button';
import ArrowIcon from '../../assets/images/HomePage/arrowOutline.svg';
import ArrowIconAR from '../../assets/images/HomePage/arrow-outward.svg';
import { useTranslation } from 'react-i18next';
import './Feature.css';

  let functionRunning = false;


const ResalaGuidance = ({ ref1, toggleModal }) => {
  // const [activeStep, setActiveStep] = React.useState(0);
  // const [functionRunning,setFunctionRunnig] = useState(false)
  const { t, i18n } = useTranslation();
  const [resalaGuidanceList, setResalaGuidanceList] = useState([]);

  useEffect(() => {
    // Update lists when language changes
    setResalaGuidanceList(getTranslatedList(t, 'list1'));
  }, [t]);
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepLine, setActiveStepLine] = useState(0);
  const [visitedSteps, setVisitedSteps] = useState([0]);
  const [visitedStepsLine, setVisitedStepsLine] = useState([0]);
  const discoverElement = document.getElementById('discover-element');
  const handleNext = (index) => {
    const updatedVisitedSteps = Array.from({ length: index + 1 }, (_, i) => i);

    setActiveStep(index);
    setActiveStepLine(index);
    setVisitedSteps(updatedVisitedSteps);
    setVisitedStepsLine(updatedVisitedSteps);
  };

  const handlePrevious = () => {
    if (!functionRunning && activeStep > 0) {
      functionRunning = true;
      const newActiveStep = activeStep - 1;
      setActiveStep(newActiveStep);
      setActiveStepLine(newActiveStep);
      setVisitedSteps(visitedSteps.filter((step) => step !== activeStep)); // Remove the current step from visitedSteps
      setVisitedStepsLine(
        visitedStepsLine.filter((step) => step !== activeStep)
      ); // Remove the current step from visitedStepsLine

      setTimeout(() => {
        functionRunning = false;
      }, 1000);
    }
  };

  // useEffect(() => {
  //   if (startGauidanceAnimation) {
  //     if (ref1.current) {
  //       const handleScroll = (e) => {
  //         if (e.deltaY > 0) {
  //           // Scrolling down
  //           if (activeStep < steps.length - 1) {
  //             handleNext();
  //           }
  //         } else if (e.deltaY < 0) {
  //           // Scrolling up
  //           handlePrevious();
  //         }
  //       };

  //       const wheelHandler = (e) => {
  //         if (e && e.deltaY) {
  //           // Check if deltaY exists on the event

  //               if (activeStep === 3) {
  //             document.body.style.overflow = 'auto';
  //           } else {
  //             if (activeStep === 0) {
  //               document.body.style.overflow = 'auto';
  //             } else {
  //               document.body.style.overflow = 'hidden';
  //             }
  //           }
  //           handleScroll(e);

  //           // if (activeStep === 0 || activeStep === 3) {
  //           //   document.body.style.overflow = 'auto';
  //           // } else {
  //           //   document.body.style.overflow = 'hidden';
  //           // }
  //           // handleScroll(e);
  //         }
  //       };

  //       ref1.current.addEventListener('wheel', wheelHandler);

  //       return () => {
  //         if (ref1.current)
  //           ref1.current.removeEventListener('wheel', wheelHandler);
  //       };
  //     }
  //   }
  // }, [activeStep, ref1, startGauidanceAnimation]);
  // useEffect(() => {
  //   if (discoverElement && discoverElement.offsetWidth < 900) {
  //     if (startGauidanceAnimation) {
  //       setTimeout(() => {
  //         if (activeStep !== steps.length - 1) {
  //           handleNext();
  //         }
  //       }, 5000);
  //     }
  //   }
  // }, [activeStep, startGauidanceAnimation]);

  const steps = [1, 2, 3, 4];
  return (
    <>
      <div
        className="relative w-full pb-[160px] max_md:pb-[120px] max_sm:pb-[80px] pt-[60px] max_sm:pt-[0px]"
        ref={ref1}
      >
        <div className="m-auto max-w-[1040px] max_xl:max-w-[980px] max_md:px-[40px] max_sm:px-[20px] ">
          <div
            id="discover-element"
            className="backGroundClass max_md:backGroundClass980 flex px-11 pt-11 pb-5 justify-between max_md:flex-col max_sm:p-5"
          >
            <div className="w-[40%] max_md:w-[100%] max_md:text-center">
              <div
                className="text-SubHeading leading-[52px] font-DMSansFont font-bold max_md:text-MediumSubHeading text-LightPrimary 
                max_sm:text-SmallHeading"
              >
                {resalaGuidanceList[activeStep]?.heading}
              </div>
              <div
                style={{ letterSpacing: '-1px' }}
                className="leading-[30px] text-TextRegular font-DMSansFont font-normal text-LightTextParagraph max_md:text-TextMedium max_sm:text-TextSmall mt-4 max_sm:mt-1"
              >
                {resalaGuidanceList[activeStep]?.text}
              </div>
              <div className="mt-10 max_md:flex max_md:justify-center max_sm:mt-5">
                <ButtonFilledArrow
                  title={t('GetStarted')}
                  onClick={toggleModal}
                  background="#1678F2"
                  image={i18n.language === 'en' ? ArrowIcon : ArrowIconAR}
                  maxWidth="178px"
                />
              </div>
            </div>

            {/* Stepper Component */}
            <div className="w-[18%] max_md:w-[100%] max_md:mt-10">
              <div className="stepper">
                {steps.map((stepItem, index) => (
                  <React.Fragment key={index}>
                    <div className="stepOuter">
                      <div
                        key={index}
                        className={`step ${
                          index === activeStep
                            ? 'active'
                            : visitedSteps.includes(index)
                            ? 'visited'
                            : ''
                        } font-DMSansFont font-medium text-[20px] cursor-pointer`}
                        onClick={() => handleNext(index)}
                      >
                        {stepItem}
                      </div>
                    </div>
                    {stepItem !== steps.length && (
                      <>
                        <div className="ProgressOuter">
                          <div
                            className={`ProgressInner ${
                              index === activeStepLine
                                ? 'ProgressInner'
                                : visitedStepsLine.includes(index)
                                ? 'visitedProgress'
                                : ''
                            } font-DMSansFont font-medium text-[20px]`}
                          ></div>
                        </div>
                      </>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>

            <div className="w-[40%]  max_md:w-[100%] max_md:mt-10 max_sm:mt-9">
              <div
                style={{ letterSpacing: '-1px' }}
                className="font-DMSansFont font-bold leading-[52px] text-TextRegularLarge max_md:text-SmallHeading max_sm:text-TextRegular  text-LightPrimary"
              >
                {resalaGuidanceList[activeStep]?.step}
              </div>
              <div
                style={{ letterSpacing: '-1px' }}
                className="font-DMSansFont font-medium w-[90%] leading-[35px] min-h-[190px] max_md:min-h-[80px] max_sm:min-h-[125px] text-SmallsubHeading max_md:text-TextMedium max_sm:text-TextSmall text-LightTextParagraph mt-4 max_sm:mt-2"
              >
                {resalaGuidanceList[activeStep]?.description}
              </div>
            </div>
          </div>
        </div>

        <div
          className="absolute top-[-300px] text-[white] max_md:top-[-170px] left-0 z-[-1]"
          id="HowItWorksId"
        >
          o
        </div>
      </div>
    </>
  );
};
const getTranslatedList = (t, listKey) => {
  // Define your lists here
  const lists = [
      {
        heading: t('StartResala'),
        text: t('StartText'),
        step: t('Step1'),
        description: t('Step1-description'),
      },
      {
        heading: t('StartResala'),
        text: t('StartText'),
        step: t('Step2'),
        description: t('Step2-description'),
      },
      {
        heading: t('StartResala'),
        text: t('StartText'),
        step: t('Step3'),
        description: t('Step3-description'),
      },
      {
        heading: t('StartResala'),
        text: t('StartText'),
        step: t('Step4'),
        description: t('Step4-description'),
      },
      // ... rest of the items in list1
    ]
  
  return lists;
};
export default ResalaGuidance;
