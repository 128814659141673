import { cn } from "../../Components/utils";

export const gradientText = {
  background:
    "linear-gradient(90deg, #19224C -1.66%, #433DA2 50.08%, #5A4AE9 99.8%)",
  WebkitBackgroundClip: "text",
  backgroundClip: "text",
  WebkitTextFillColor: "transparent",
  color: "transparent",
};

export const AddBanner = ({ className, title, description, onClick }) => (
  <div
    style={{
      background:
        "linear-gradient(94.68deg, #A2C9FA 0%, #E9F3FF 31.25%, #D6CEFF 59.08%, #D5B6FF 100%)",
    }}
    className={cn("relative bg-slate-200 p-[20px]", className)}
  >
    {/* <DiscountBG width={"100%"} className="absolute inset-0 z-0" /> */}
    <div className="flex justify-between items-center">
      <span
        className="text-[30px] font-bold uppercase leading-[40px]"
        style={gradientText}
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      ></span>
      <div className="flex gap-x-[8px] text-[20px] leading-[30px] text-LightPrimary font-semibold text-center">
        <DiscountIcon />
        <span
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </div>
      <button
        onClick={onClick}
        className="rounded-[6px] text-LightPrimary text-[16px] leading-[16px] font-medium bg-white flex justify-center items-center h-[40px] w-[150px]"
      >
        Get Discount
      </button>
    </div>
  </div>
);
export const DiscountIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.324 11.7756L21.6773 10.129C21.3956 9.84729 21.1681 9.29479 21.1681 8.90479V6.56479C21.1681 5.61146 20.3881 4.83146 19.4348 4.83146H17.1056C16.7156 4.83146 16.1631 4.60396 15.8815 4.32229L14.2348 2.67563C13.5631 2.00396 12.4581 2.00396 11.7865 2.67563L10.1181 4.32229C9.84729 4.60396 9.29479 4.83146 8.89396 4.83146H6.56479C5.61146 4.83146 4.83146 5.61146 4.83146 6.56479V8.89396C4.83146 9.28396 4.60396 9.83646 4.32229 10.1181L2.67563 11.7648C2.00396 12.4365 2.00396 13.5415 2.67563 14.2131L4.32229 15.8598C4.60396 16.1415 4.83146 16.694 4.83146 17.084V19.4131C4.83146 20.3665 5.61146 21.1465 6.56479 21.1465H8.89396C9.28396 21.1465 9.83646 21.374 10.1181 21.6556L11.7648 23.3023C12.4365 23.974 13.5415 23.974 14.2131 23.3023L15.8598 21.6556C16.1415 21.374 16.694 21.1465 17.084 21.1465H19.4131C20.3665 21.1465 21.1465 20.3665 21.1465 19.4131V17.084C21.1465 16.694 21.374 16.1415 21.6556 15.8598L23.3023 14.2131C24.0065 13.5523 24.0065 12.4473 23.324 11.7756ZM8.66646 9.74979C8.66646 9.15396 9.15396 8.66646 9.74979 8.66646C10.3456 8.66646 10.8331 9.15396 10.8331 9.74979C10.8331 10.3456 10.3565 10.8331 9.74979 10.8331C9.15396 10.8331 8.66646 10.3456 8.66646 9.74979ZM10.324 16.824C10.1615 16.9865 9.95563 17.0623 9.74979 17.0623C9.54396 17.0623 9.33813 16.9865 9.17563 16.824C8.86146 16.5098 8.86146 15.9898 9.17563 15.6756L15.6756 9.17563C15.9898 8.86146 16.5098 8.86146 16.824 9.17563C17.1381 9.48979 17.1381 10.0098 16.824 10.324L10.324 16.824ZM16.2498 17.3331C15.6431 17.3331 15.1556 16.8456 15.1556 16.2498C15.1556 15.654 15.6431 15.1665 16.239 15.1665C16.8348 15.1665 17.3223 15.654 17.3223 16.2498C17.3223 16.8456 16.8456 17.3331 16.2498 17.3331Z"
      fill="#19224C"
    />
  </svg>
);
