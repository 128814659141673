import React, { useMemo, useRef, useState } from "react";
import InputField, { ErrorMsg } from "../InputField";
import { cn } from "../utils";
import PasswordValidator from "./PasswordValidator";
import { ButtonExeFilled, ButtonOutLine } from "../Button";
import { TwoToneText } from "./UpdateEmail-old";
import Otp from "../../Otp";
import {
  forgotPass,
  sendEmailOtp,
  updatePassword,
  verifyOtp,
} from "../../../../Exe-api/apis";
import SpinnerLoader from "../../../../utils/LoaderCommon";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { ModalTitle } from "./DeleteAccount";

const fontCommons = "text-[14px] leading-[18px] text-LightStrokActive";

const UPDATE_PASS = "updatePass";
const FORGOT_PASS = "forgotPass";
const NEW_PASS_UPDATE = "newPassUpdate";

const UpdatePassword = ({ onClose, email, updateUserData }) => {
  const intials = {
    pass: "",
    newPass: "",
    confPass: "",
  };
  const passRef = useRef();
  const newPassRef = useRef();
  const confirmPassRef = useRef();
  const [isPassShow, setIsPassShow] = useState(false);
  const [isOtpSented, setIsOtpSented] = useState(false);
  const [isNewPassShow, setIsNewPassShow] = useState(false);
  const [isConfirmNewPass, setIsConfirmNewPass] = useState(false);
  const [passwords, setPasswords] = useState(intials);
  const [currentStep, setCurrentStep] = useState(UPDATE_PASS);
  const [errMsg, setErrMsg] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const {
    i18n: { language, dir },
  } = useTranslation();
  const isArabic = language === "ar";

  const [updateNewPass, setUpdateNewPass] = useState({
    otp: "",
    newPass: "",
    confPass: "",
  });

  const handleUpdatePass = (e) => {
    const { value, name } = e.target;
    setUpdateNewPass({ ...updateNewPass, [name]: value });
  };

  const handleCancel = () => {
    onClose();
  };

  const changePass = async () => {
    if (passwords.confPass !== passwords.newPass) {
      setErrMsg("Passwords not matched");
      return;
    }
    setIsLoading(true);
    try {
      const res = await updatePassword({
        current_password: passwords.pass,
        new_password: passwords.newPass,
      });
      if (res.Error || res.detail) {
        setErrMsg(res.Message);
        return;
      }
      onClose();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const forgotPassword = async () => {
    if (updateNewPass.confPass !== updateNewPass.newPass) {
      setErrMsg("Passwords not matched");
      return;
    }
    setIsLoading(true);
    try {
      const res = await forgotPass({
        password: updateNewPass.newPass,
      });
      if (res.Error || res.detail) {
        setErrMsg(res.Message);
        return;
      }
      onClose();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
    // updateNewPass()
    // forgotPassword
  };

  const handleInput = (e) => {
    setErrMsg(undefined);
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });
  };

  const sendOtp = async () => {
    setIsOtpSented(false);
    try {
      const res = await sendEmailOtp({
        topic: "Forgot Password",
        email,
        is_email_check: false,
      });
      setIsOtpSented(true);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const verifyEmailOtp = async (callback) => {
    setIsLoading(true);
    try {
      const res = await verifyOtp({ email, otp: updateNewPass.otp });
      if (res.Error || res.detail) {
        setErrMsg(res.Message);
        return;
      }
      callback();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = () => {
    // verifyEmailOtp(() => setCurrentStep(FORGOT_PASS));
    setCurrentStep(FORGOT_PASS);
    sendOtp();
  };

  const handleContinue = () => {
    verifyEmailOtp(() => setCurrentStep(NEW_PASS_UPDATE));
  };

  const activePassValidate = () => {
    switch (document.activeElement) {
      case passRef.current:
        return passwords.pass;
      case newPassRef.current:
        return passwords.newPass;
      case confirmPassRef.current:
        return passwords.confPass;
      default:
        return passwords.pass;
    }
  };

  const inputPassword = useMemo(() => {
    const value =
      currentStep === UPDATE_PASS
        ? activePassValidate()
        : updateNewPass.newPass;
    return {
      minLength: value ? value?.length >= 8 : false,
      uppercase: value ? /[A-Z]/.test(value) : false,
      lowercase: value ? /[a-z]/.test(value) : false,
      number: value ? /\d/.test(value) : false,
      specialChar: value
        ? /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value)
        : false,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwords, activePassValidate]);

  return (
    <div className="w-[400px]">
      {currentStep === UPDATE_PASS && (
        <>
          <ModalTitle title={t("change_password")} />
          <div className="w-full">
            <InputField
              ref={passRef}
              name="pass"
              error={undefined}
              onChange={handleInput}
              className={"mb-[4px]"}
              value={passwords.pass}
              label={t("curr_password")}
              placeholder={t("curr_password")}
              type={isPassShow ? "text" : "password"}
              onKeyPress={(e) => {
                if (e.which === 32) e.preventDefault();
              }}
              suffix={
                <div
                  onClick={() => setIsPassShow((prev) => !prev)}
                  className="min-w-[25px]"
                >
                  {isPassShow ? <CloseEyeIcon /> : <EyeIcon />}
                </div>
              }
            />
            <ForgetPassword
              isArabic={isArabic}
              onClick={handleForgotPassword}
            />
          </div>
          <InputField
            ref={newPassRef}
            name="newPass"
            error={undefined}
            onChange={handleInput}
            className={"mt-[16px]"}
            value={passwords.newPass}
            label={t("new_password")}
            placeholder={t("new_password")}
            type={isNewPassShow ? "text" : "password"}
            suffix={
              <div
                onClick={() => setIsNewPassShow((prev) => !prev)}
                className="min-w-[25px]"
              >
                {isNewPassShow ? <CloseEyeIcon /> : <EyeIcon />}
              </div>
            }
          />

          <InputField
            ref={confirmPassRef}
            name="confPass"
            error={undefined}
            onChange={handleInput}
            className={"mt-[16px]"}
            value={passwords.confPass}
            label={t("confirm_new_password")}
            placeholder={t("confirm_pass")}
            type={isConfirmNewPass ? "text" : "password"}
            suffix={
              <div
                onClick={() => setIsConfirmNewPass((prev) => !prev)}
                className="min-w-[25px]"
              >
                {isConfirmNewPass ? <CloseEyeIcon /> : <EyeIcon />}
              </div>
            }
          />
          {errMsg && <ErrorMsg errorMsg={errMsg} />}
          <div className="mt-[20px]">
            <PasswordValidator require={inputPassword} />
          </div>
          <div className="flex gap-x-[20px] mt-[20px]">
            <ButtonOutLine
              height={40}
              text={t("cancel")}
              fontColor={"#5F6583"}
              borderColor={"#DFE4EC"}
              onClick={handleCancel}
              hoverCss={
                "hover:!text-LightPrimaryActive hover:!bg-[#eef6ff] hover:!border-[#D3E7FF]"
              }
            />
            <ButtonExeFilled
              height={40}
              isDisable={isLoading}
              text={isLoading ? <SpinnerLoader /> : t("change")}
              fontColor={"#FFFFFF"}
              onClick={changePass}
              hoverCss={"hover:!bg-DarkHover"}
            />
          </div>
        </>
      )}

      {currentStep === FORGOT_PASS && (
        <>
          <ModalTitle title={t("otp_verification")} />
          <TwoToneText
            fontCommons={fontCommons}
            text1={t("enter_code_sent")}
            text2={email}
          />
          <div className="mt-[16px]">
            <InputField
              type="number"
              maxlength={6}
              inputCss="text-center"
              value={updateNewPass.otp || ""}
              error={errMsg}
              // placeholder={t("enter_otp")}
              placeholder={"000 000"}

              onChange={(e) => {
                if (e.target.value?.length > 6) return;
                setUpdateNewPass((prev) => {
                  return {
                    ...prev,
                    otp: e.target.value,
                  };
                });
              }}
            />
          </div>
          <Otp startTimer={isOtpSented} resendOtp={() => sendOtp()} />
          <div className="flex gap-x-[20px] mt-[20px]">
            <ButtonExeFilled
              height={40}
              text={t("continue")}
              fontColor={"#FFFFFF"}
              onClick={handleContinue}
              hoverCss={"hover:!bg-DarkHover"}
            />
          </div>
        </>
      )}

      {currentStep === NEW_PASS_UPDATE && (
        <>
          <ModalTitle title={t("change_password")} />

          <InputField
            ref={newPassRef}
            name="newPass"
            error={undefined}
            onChange={handleUpdatePass}
            className={"mt-[16px]"}
            value={updateNewPass.newPass}
            label={t("new_password")}
            placeholder={t("new_password")}
            type={isNewPassShow ? "text" : "password"}
            suffix={
              <div
                onClick={() => setIsNewPassShow((prev) => !prev)}
                className="min-w-[25px]"
              >
                {isNewPassShow ? <CloseEyeIcon /> : <EyeIcon />}
              </div>
            }
          />

          <InputField
            ref={confirmPassRef}
            name="confPass"
            error={undefined}
            onChange={handleUpdatePass}
            className={"mt-[16px]"}
            value={updateNewPass.confPass}
            label={"Confirm new password"}
            placeholder={t("new_password")}
            type={isConfirmNewPass ? "text" : "password"}
            suffix={
              <div
                onClick={() => setIsConfirmNewPass((prev) => !prev)}
                className="min-w-[25px]"
              >
                {isConfirmNewPass ? <CloseEyeIcon /> : <EyeIcon />}
              </div>
            }
          />
          {errMsg && <ErrorMsg errorMsg={errMsg} />}
          <div className="mt-[20px]">
            <PasswordValidator require={inputPassword} />
          </div>
          <div className="flex gap-x-[20px] mt-[20px]">
            <ButtonOutLine
              height={40}
              text={t("cancel")}
              fontColor={"#5F6583"}
              borderColor={"#DFE4EC"}
              onClick={handleCancel}
              hoverCss={
                "hover:!text-LightPrimaryActive hover:!bg-[#eef6ff] hover:!border-[#D3E7FF]"
              }
            />
            <ButtonExeFilled
              height={40}
              text={isLoading ? <SpinnerLoader /> : t("update")}
              isDisable={isLoading}
              fontColor={"#FFFFFF"}
              onClick={forgotPassword}
              hoverCss={"hover:!bg-DarkHover"}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default UpdatePassword;

export const ForgetPassword = ({ onClick, isArabic }) => (
  <div
    className={cn("w-full flex", isArabic ? "justify-start" : "justify-end")}
  >
    <button
      onClick={onClick}
      className={cn(
        {
          "text-[14px] leading-[18px] text-LightPrimaryActive cursor-pointer font-normal": true,
        },
        isArabic ? "mr-auto" : "ml-auto"
      )}
    >
      {t("forget_password")}
    </button>
  </div>
);

export const EyeIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
      stroke="#6D77A0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9998 20.2702C15.5298 20.2702 18.8198 18.1902 21.1098 14.5902C22.0098 13.1802 22.0098 10.8102 21.1098 9.40021C18.8198 5.80021 15.5298 3.72021 11.9998 3.72021C8.46984 3.72021 5.17984 5.80021 2.88984 9.40021C1.98984 10.8102 1.98984 13.1802 2.88984 14.5902C5.17984 18.1902 8.46984 20.2702 11.9998 20.2702Z"
      stroke="#6D77A0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CloseEyeIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5301 9.46984L9.47008 14.5298C8.79908 13.8588 8.42212 12.9488 8.42212 11.9998C8.42212 11.53 8.51467 11.0647 8.69448 10.6306C8.87428 10.1965 9.13784 9.80208 9.47008 9.46984C9.80232 9.13759 10.1968 8.87404 10.6309 8.69423C11.065 8.51442 11.5302 8.42188 12.0001 8.42188C12.949 8.42188 13.8591 8.79884 14.5301 9.46984Z"
      stroke="#6D77A0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8201 5.77047C16.0701 4.45047 14.0701 3.73047 12.0001 3.73047C8.47009 3.73047 5.18009 5.81047 2.89009 9.41047C1.99009 10.8205 1.99009 13.1905 2.89009 14.6005C3.68009 15.8405 4.60009 16.9105 5.60009 17.7705M8.42009 19.5305C9.56009 20.0105 10.7701 20.2705 12.0001 20.2705C15.5301 20.2705 18.8201 18.1905 21.1101 14.5905C22.0101 13.1805 22.0101 10.8105 21.1101 9.40047C20.7801 8.88047 20.4201 8.39047 20.0501 7.93047"
      stroke="#6D77A0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.51 12.7C15.3745 13.3976 15.0337 14.0387 14.5312 14.5412C14.0287 15.0437 13.3876 15.3845 12.69 15.52M9.47 14.53L2 22M22 2L14.53 9.47"
      stroke="#6D77A0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
