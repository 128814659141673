import React, { useEffect, useRef, useState } from 'react';
import RequestModal from '../../Components/RequestModal';
import Layout from '../../utils/Layout';
import useIsInViewport from '../../utils/useIsInViewport';
import BannerPage from './BannerPage';
import ContinueWithResala from './ContinueWithResala';
import DiscoverResala from './DiscoverResala';
import FAQ from './FAQ';
import FeatureNewCopy from './FeatureNewCopy2';
import Productivity from './Productivity';
import ResalaGuidance from './ResalaGuidance';
// import { usePageTracking } from '../../utils/usePageTracking';

const Home = ({ referrer }) => {
  // usePageTracking()
  const ref1 = useRef(null);
  const discoverRef = useRef();
  const cardRef = useRef();
  const cardRef2 = useRef();
  const [isModalOpen, setModalOpen] = useState(false);
  // const productivityAnimRef = useRef();
  // const featureAnimRef = useRef();
  // const isproductivityAnim = useIsInViewport(productivityAnimRef);
  // const isfeatureAnimRef = useIsInViewport(featureAnimRef);
  // const [fetchAnim, setFetchAnim] = useState(false);

  // useEffect(() => {
  //   if (!fetchAnim && (isproductivityAnim || isfeatureAnimRef)) {
  //     setFetchAnim(true);
  //   }
  // }, [isproductivityAnim, fetchAnim, isfeatureAnimRef]);

  const discoverResalaTextRef = useRef();
  const [startGauidanceAnimation, setStartGauidanceAnimation] = useState(false);
  const [discoverFocus, setDiscoverFocus] = useState(false);
  const [discoverFocus2, setDiscoverFocus2] = useState(false);
  const [discoverFocusSecondCard, setDiscoverFocusSecondCard] = useState(false);

  const isInViewport1 = useIsInViewport(ref1);
  const cardViewport = useIsInViewport(cardRef);
  const cardViewport2 = useIsInViewport(cardRef2);
  const resalaTextInViewport = useIsInViewport(discoverResalaTextRef);


  useEffect(() => {
    if (isInViewport1) {
      setStartGauidanceAnimation(true);
    } else {
      document.body.style.overflow = 'auto';
      setStartGauidanceAnimation(false);
    }
  }, [isInViewport1]);

  useEffect(() => {
    if (cardViewport) {
      setDiscoverFocus2(true);
    }
  }, [resalaTextInViewport, cardViewport]);

  useEffect(() => {
    if (cardViewport2) {
      setDiscoverFocusSecondCard(true);
    }
  }, [cardViewport2]);

  function callback(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setStartGauidanceAnimation(true);
      } else {
        document.body.style.overflow = 'auto';
        setStartGauidanceAnimation(false);
      }
    });
  }

  useEffect(() => {
    const target = ref1.current;
    const options = {
      root: null,
      threshold: 1,
    };
    const observer = new IntersectionObserver(callback, options);
    if (target) {
      observer.observe(target);
    }

    // Clean up the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  const itemLeftRefs = useRef([]);
  const itemRightRefs = useRef([]);

  // Function to handle the intersection changes
  function handleIntersection(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('flip');
        observer.unobserve(entry.target);
      }
    });
  }

  // Create an Intersection Observer
  const options = {
    root: null, // Use the viewport as the root
    rootMargin: '0px',
    threshold: 0.5, // Adjust this threshold as needed
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, options);
    itemLeftRefs.current.forEach((itemLeftRef) =>
      observer.observe(itemLeftRef)
    );
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, options);

    // Attach the Intersection Observer to each item
    itemRightRefs.current.forEach((itemRightRef) => {
      observer.observe(itemRightRef);
    });

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const hideModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="relative bg-white w-[100%] fade-in">
        <Layout
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          hideModal={hideModal}
          toggleModal={toggleModal}
          referrer={referrer}
        >
          {/* Banner Section */}
          <BannerPage toggleModal={toggleModal} />

          <Productivity
            // productivityAnimRef={productivityAnimRef}
          />

          <FeatureNewCopy
            // fetchAnim={fetchAnim}
            // featureAnimRef={featureAnimRef}
          />

          <DiscoverResala
            discoverRef={discoverRef}
            discoverFocus={discoverFocus}
            setDiscoverFocus={setDiscoverFocus}
            discoverFocus2={discoverFocus2}
            discoverFocusSecondCard={discoverFocusSecondCard}
            cardRef={cardRef}
            cardRef2={cardRef2}
            discoverResalaTextRef={discoverResalaTextRef}
            itemLeftRefs={itemLeftRefs}
            itemRightRefs={itemRightRefs}
          />

          <ResalaGuidance
            ref1={ref1}
            startGauidanceAnimation={startGauidanceAnimation}
            toggleModal={toggleModal}
          />

          <FAQ />

          {/* <Continue /> */}

          <ContinueWithResala toggleModal={toggleModal} />
        </Layout>
      </div>

      <div>
        <RequestModal
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          toggleModal={toggleModal}
          hideModal={hideModal}
          referrer={referrer}
        />
      </div>
    </>
  );
};

export default Home;
