import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Layout from "../../utils/Layout";
import Feedback from "./Feedback";
import FeedbackReason from "./FeedbackReason";
import { useTranslation } from "react-i18next";
import { uninstallForm } from "../../Exe-api/uninstall-api";

const helperFeedbackId = {
  slow_down_pc: 1,
  not_working: 2,
  donotknow: 3,
  difficult2use: 4,
  is_not_free: 5,
  queries_not_enough: 6,
  price_is_more:7,
  product_manager: 8,
  find_better_tool: 9,
  privacy_security_concern: 10,
};

const UninstallScreen = () => {
  const { t,i18n } = useTranslation();
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    slow_down_pc: false,
    not_working: false,
    donotknow: false,
    difficult2use: false,
    is_not_free: false,
    queries_not_enough: false,
    price_is_more : false,
    product_manager: false,
    find_better_tool: false,
    privacy_security_concern: false,
  });

  const [reasonText, setReasonText] = useState({
    slow_down_pc: "",
    not_working: "",
    donotknow: "",
    difficult2use: "",
    is_not_free: "",
    queries_not_enough: "",
    price_is_more:"",
    product_manager: "",
    find_better_tool: "",
    privacy_security_concern: "",
  });
  const handleChangeText = (e, name) => {
    setReasonText({
      ...reasonText,
      [name]: e.target.value, // Update the specific field based on fieldName
    });
  };

  const [searchParams] = useSearchParams();
  const userToken = searchParams.get("authToken");

  const [lastChecked, setLastChecked] = useState([]);
  const [checkedValue, setCheckedValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitFeedback, setIsSubmitFeedback] = useState(false);

  const generateDynamicPayload = () => {
    const payload = [];
    for (const key in formState) {
      if (formState[key]) {
        payload.push({
          notes: reasonText[key],
          id: helperFeedbackId[key],
        });
      }
    }
    return payload;
  };

  const handleSubmit = async () => {
    setIsSubmitFeedback(true);
    const userFeedbackArray = generateDynamicPayload();
    // sendFeedbackApi(dynamicPayload);
    setIsLoading(true);
    try {
      const res = await uninstallForm({
        token: userToken,
        feedback: userFeedbackArray,
      });
      if (res?.Error || res?.detail) {
        console.log(res);
        return;
      }
      navigate("/chrome/uninstall-success");
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setFormState({
      ...formState,
      [name]: checked,
    });
    if (checked) {
      const newArray = [...lastChecked];
      newArray.push(name);
      setLastChecked(newArray);
      const lastElement = newArray.slice(-1);
      setCheckedValue(lastElement[0]);
    } else {
      const newArray = [...lastChecked];
      const updatedArray = newArray.filter((item) => item !== name);
      setLastChecked(updatedArray);
      if (updatedArray.length === 0) {
        setCheckedValue("");
      } else {
        const lastElement = updatedArray.slice(-1);
        setCheckedValue(lastElement[0]);
      }
    }
  };
  return (
    <Layout>
      <div className="backImage">
        <div className="py-10 h-[calc(100vh-80px)] overflow-y-auto">
          <div className="text-[30px] font-DMSansFont font-bold text-LightPrimary text-center">
            <p>{t("FeedbackTitle1")}</p>
            <p>{t("FeedbackTitle2")}</p>
          </div>

          <div className={`relative flex justify-center gap-10 ${i18n.language === 'en' ? 'flex-row' : 'flex-row-reverse'}`}>
            <Feedback
              formState={formState}
              checkedValue={checkedValue}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isLoading={isLoading}
            />
            <FeedbackReason
              formState={formState}
              checkedValue={checkedValue}
              reasonText={reasonText}
              handleChangeText={handleChangeText}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UninstallScreen;
