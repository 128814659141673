import React from 'react';
import { t } from 'i18next';
import BgAnimatedLayer from '../../Components/BgAnimatedLayer';

const ContinueWithResala = ({toggleModal}) => {
  return (
    <div className="relative w-full h-[550px] max_sm:h-[450px] flex justify-center items-center overflow-hidden mb-[120px] max_md:mb-[80px] max_sm:mb-[40px]">
      <div className="flex flex-col items-center gap-[40px] z-50">
        <p className="HeadingCommon">{t('LetsGo')}</p>
        <p className="description-fonts SubTextCommon mt-5 max_sm:mt-4 px-2 text-LightTextParagraph text-center">
          {t('MakeSmarterDecision')}
        </p>
        <button
          className="SubTextCommon font-DMSansFont font-medium rounded-[10px] text-center border-[1px] mt-10 max_md:mt-[30px] cursor-pointer bg-[#2376e1] hover:bg-[#1263CB]"
          onClick={toggleModal}
        >
          <div className="text-white p-4 max_sm:p-3">
            {t('ContinueWithResala')}
          </div>
        </button>
      </div>
      <div className="animated-layer absolute w-[1440px] h-full">
        <BgAnimatedLayer />
      </div>
    </div>
  );
};

export default ContinueWithResala;
