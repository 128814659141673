import React from "react";
import { Arrow } from "./SupportCardLayout";
import { useTranslation } from "react-i18next";
import { cn } from "../utils";

const SupportCard = ({ icon, title, desc, btnTitle, onClick = () => {} }) => {
  const {
    i18n: { language, dir },
  } = useTranslation();

  const isArabic = language === "ar";
  return (
    <div className="w-[250px] flex-col justify-start items-start inline-flex">
      <div className="w-10 h-10 justify-center items-center inline-flex">
        {icon}
      </div>
      <div className="flex-col justify-start items-start gap-2 flex mt-5">
        <div className="text-center text-LightPrimary text-[20px] font-bold font-DMSansFont">
          {title}
        </div>
        <div className="w-[246px] text-LightTextParagraph text-[18px] leading-[25px] font-normal font-DMSansFont">
          {desc}
        </div>
      </div>
      <button
        onClick={onClick}
        className="justify-start items-center gap-2 inline-flex mt-5"
      >
        <div className="text-[#1678F2] text-[14px] font-bold font-DMSansFont leading-none">
          {btnTitle}
        </div>
        <div className="w-[18px] h-[18px] justify-center items-center flex">
          <div className="w-[18px] h-[18px] relative">
            <Arrow className={cn(isArabic ? "rotate-180" : "right-0")} />
          </div>
        </div>
      </button>
    </div>
  );
};

export default SupportCard;

export const SupportIcon = () => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="44" height="44" rx="22" fill="#F3F4F8" />
    <path
      d="M28 28.8617H27.24C26.44 28.8617 25.68 29.1717 25.12 29.7317L23.41 31.4218C22.63 32.1918 21.36 32.1918 20.58 31.4218L18.87 29.7317C18.31 29.1717 17.54 28.8617 16.75 28.8617H16C14.34 28.8617 13 27.5317 13 25.8917V14.9817C13 13.3417 14.34 12.0117 16 12.0117H28C29.66 12.0117 31 13.3417 31 14.9817V25.8917C31 27.5217 29.66 28.8617 28 28.8617Z"
      stroke="#6D77A0"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 19.1627C17 18.2327 17.76 17.4727 18.69 17.4727C19.62 17.4727 20.38 18.2327 20.38 19.1627C20.38 21.0427 17.71 21.2427 17.12 23.0327C17 23.4027 17.31 23.7727 17.7 23.7727H20.38"
      stroke="#6D77A0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.0398 23.7608V18.0509C26.0398 17.7909 25.8698 17.5608 25.6198 17.4908C25.3698 17.4208 25.0998 17.5208 24.9598 17.7408C24.2398 18.9008 23.4598 20.2208 22.7798 21.3808C22.6698 21.5708 22.6698 21.8208 22.7798 22.0108C22.8898 22.2008 23.0998 22.3208 23.3298 22.3208H26.9998"
      stroke="#6D77A0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
