import React, { useState } from "react";
import { CashPayUtilIcon } from "./PayIcons";
import { cn } from "../utils";
import { fontCommons } from "../AccoutSetting/UpdateEmail";
import { ButtonExeFilled } from "../Button";

const CashPay = () => {
  const [isRevealQR, setIsRevealQR] = useState(false);

  return (
    <>
      <div className="mt-[40px] p-[12px] border border-LightStrokPrimary rounded-[4px] flex items-start gap-[8px]">
        <CashPayUtilIcon style={{ width: "50px" }} />
        <p className={cn(fontCommons, "text-[16px] leading-[25px]")}>
          After submitting your order, scan the QR code using Cash App Pay.
        </p>
      </div>
      <div className="w-full mt-[40px]">
        {!isRevealQR && (
          <ButtonExeFilled
            height={40}
            isDisable={false}
            text={"Reveal QR code"}
            fontColor={"#FFFFFF"}
            onClick={() => {
              setIsRevealQR(true);
            }}
            hoverCss={"hover:!bg-DarkHover"}
          />
        )}
        {isRevealQR && (
          <div className="flex flex-col items-center">
            <div className="w-[400px] h-[351px] bg-[#F3F4F8]">QR</div>
            <span
              className={cn(
                fontCommons,
                "text-[16px] leading-[25px] mt-[40px] flex w-full max-w-[298px] self-center text-center"
              )}
            >
              Use{" "}
              <button className="whitespace-nowrap h-fit mx-[4px] underline">
                Cash App
              </button>{" "}
              or your phone’s camera
            </span>
            <span className={cn(fontCommons, "text-[16px] leading-[25px]")}>
              to scan and pay.
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default CashPay;
